<form (submit)="submit()" [formGroup]="form">
  <div class="gc-dialog-content">
    <div class="gc-dialog-content-header">
      <h3>Manage 2-Step Verification</h3>
      <button class="header-close-button" type="button" (click)="close()">
        <gc-icon svgIcon="close"></gc-icon>
      </button>
    </div>
    <div class="gc-dialog-content-body" [class.min-height]="!successMessageMode">
      <div *ngIf="!loading; else spinner">
        <div *ngIf="!successMessageMode && userTwoFAStatus">
          <div *ngIf="twoFactorAuthEnabled">
            <p class="large-message">
              <span>
                <gc-icon fontIcon="checkmark" fontSet="icons8-regular"></gc-icon>
                <b> 2-Step Verification is currently enabled.</b>
              </span>
            </p>
            <hr/>
            <div class="password-input-wrapper">
              <p>To disable or reset 2-Step Verification, you are required to enter your password.</p>
              <div class="stacked-actions">
                <gc-form-field class="input-field">
                  <input
                    #password
                    type="password"
                    formControlName="password"
                    name="password"
                    placeholder="Enter password"
                    gcInput
                    autofocus
                    tabindex="1"
                    automation-id="manage-tfa__password-input"
                  />
                </gc-form-field>

                <div class="buttons-row">
                  <button
                    *ngIf="!userTwoFAStatus.isRadius2fa"
                    type="button"
                    class="default-button"
                    (click)="submit(true)"
                    [disabled]="!password.value.length"
                    automation-id="manage-tfa__reset-button"
                  >
                    Reset Code
                  </button>

                  <button
                    type="submit"
                    class="danger-button"
                    [disabled]="twoFactorAuthPolicyEnforced || !password.value.length"
                    automation-id="manage-tfa__disable-button"
                  >
                    Disable 2-Step Verification
                  </button>
                </div>
              </div>
            </div>

            <p *ngIf="twoFactorAuthPolicyEnforced" class="enforced-message">An administrator has enforced 2-Step Verification on this server.</p>
          </div>

          <div *ngIf="!twoFactorAuthEnabled">
            <!-- Authenticator App text  -->
            <p *ngIf="!userTwoFAStatus.isRadius2fa">
              By setting up 2-Step Verification, you will be asked to provide a 6-digit code in addition to your password on every login to
              the Guardicore service. This code can be obtained using mobile authentication apps, such as
              <a href="https://support.google.com/accounts/answer/1066447" target="_blank">Google Authenticator</a>,
              <a href="https://www.authy.com/" target="_blank">Authy</a>, or <a href="https://duo.com/" target="_blank">Duo</a>.
            </p>

            <div class="qr-code-wrapper">
              <ng-container *ngIf="!qrCodeLoading; else spinner">
                <div *ngIf="showQRCode$ | async as showQRCode">
                  <gc-authentication-two-fa-qr
                    [key]="userTwoFAQRCode?.secretKey"
                    [qrCode]="userTwoFAQRCode?.qrCode"
                  ></gc-authentication-two-fa-qr>
                </div>
              </ng-container>
            </div>

            <div class="verification-code-input-wrapper">
              <p *ngIf="!userTwoFAStatus.isRadius2fa">
                To continue, enter the 6-digit code generated by your authenticated device after scanning the QR code.
              </p>

              <!-- Radius server text  -->
              <div *ngIf="userTwoFAStatus.isRadius2fa">
                <p>
                  By setting up 2-Step Verification, you will be asked to provide a PIN code in addition to your password on every login to the
                  Guardicore service. This code is obtained from your MFA device; contact the Guardicore administrator for more details.
                </p>
                <p>To continue, enter the PIN code generated by your MFA device.</p>
              </div>

              <gc-form-field class="input-field">
                <input
                  #verificationCode
                  type="text"
                  formControlName="verificationCode"
                  name="verificationCode"
                  [placeholder]="getVerificationCodePlaceholder()"
                  gcInput
                  autofocus
                  tabindex="1"
                  autocomplete="off"
                  automation-id="manage-tfa__verification-code-input"
                />
              </gc-form-field>
              <button
                type="submit"
                class="primary-button"
                [disabled]="!verificationCode.value.length"
                automation-id="manage-tfa__enable-button"
              >
                Enable 2-Step Verification
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="successMessageMode">
          <p class="large-message" automation-id="manage-tfa__success-message">
            <b>2-Step Verification has been <span>{{ getSuccessMessageStatus() }}</span> successfully.</b>
          </p>
        </div>

        <div *ngIf="errorMessage$ | async as errorLabel" class="error-block">
          <gc-error-block automation-id="manage-tfa__error-message">
            {{ errorLabel }}
          </gc-error-block>
        </div>
      </div>
    </div>
    <div class="gc-dialog-content-footer">
      <button class="default-button" type="button" (click)="close()" automation-id="manage-tfa__close-button">Close</button>
    </div>
  </div>
</form>

<ng-template #spinner>
  <gc-loader></gc-loader>
</ng-template>
