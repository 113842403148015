import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SKIP_CASING_CONVERTERS } from '@guardicore-ui/shared/data';
import { toCamel } from '@guardicore-ui/shared/utils';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class ToCamelInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.context.get(SKIP_CASING_CONVERTERS)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      filter(event => event.type === HttpEventType.Response),
      map(event => event as HttpResponse<unknown>),
      map((event: HttpResponse<unknown>) => {
        return event.clone({
          body: toCamel(event.body),
        });
      }),
    );
  }
}
