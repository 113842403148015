import { Component, ChangeDetectionStrategy, Input, ElementRef, ViewChild } from '@angular/core';
import { CanBeDisabled, CombinedLabel, Label } from '@guardicore-ui/shared/data';
import { mixinDisabled } from '@guardicore-ui/shared/utils';
import { CanHaveKey, mixinWithKey, CanHaveIcon, mixinWithIcon, CanBeSelected, mixinSelected } from '@guardicore-ui/ui/common';

// eslint-disable-next-line @typescript-eslint/naming-convention
const LabelComponentMixin = mixinDisabled(mixinSelected(mixinWithKey(mixinWithIcon(class {}, 'label'))));

@Component({
  selector: 'gc-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  inputs: ['withKey', 'withIcon', 'iconName', 'iconColor', 'disabled', 'selected'],
})
export class LabelComponent extends LabelComponentMixin implements CanHaveKey, CanHaveIcon, CanBeDisabled, CanBeSelected {
  private _label?: CombinedLabel;
  private _labelsToShow: Label[] = [];

  get labelsToShow(): Label[] {
    return this._labelsToShow;
  }

  get label(): CombinedLabel | undefined {
    return this._label;
  }

  @Input()
  set label(value: CombinedLabel | undefined) {
    this._labelsToShow = value ? (Array.isArray(value) ? value.slice(0) : [value]) : [];
    this._label = value;
  }

  @ViewChild('wrapper') labelsHost?: ElementRef;
}
