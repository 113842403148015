export const checkCircle = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 20">
    <g id="check-circle-v3">
      <g id="ic">
      <path d="M12.6001 8.09999C12.9125 8.4124 12.9125 8.91896 12.6001 9.23136L8.76576 13.0657C8.45336 13.3781 7.9468 13.3781 7.6344 13.0657L5.80008 11.2314C5.48766 10.919 5.48766 10.4124 5.80008 10.1C6.1125 9.7876 6.61903 9.7876 6.93145 10.1L8.20008 11.3686L11.4687 8.09999C11.5078 8.06093 11.5498 8.02676 11.5942 7.99748C11.9047 7.79245 12.3267 7.82662 12.6001 8.09999Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17 10.5C17 14.9182 13.4182 18.5 9 18.5C4.58172 18.5 1 14.9182 1 10.5C1 6.08172 4.58172 2.5 9 2.5C13.4182 2.5 17 6.08172 17 10.5ZM15.4 10.5C15.4 14.0346 12.5346 16.9 9 16.9C5.46538 16.9 2.6 14.0346 2.6 10.5C2.6 6.96538 5.46538 4.1 9 4.1C12.5346 4.1 15.4 6.96538 15.4 10.5Z"/>
      </g>
    </g>
  </svg>
`;
