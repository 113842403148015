import { Injectable } from '@angular/core';
import { FilterOptionsSource, FilterOptions } from '@guardicore-ui/filters/shared';
import { Observable } from 'rxjs';

import { MsspService } from '../infrastructure';

@Injectable()
export class MsspOptionsSource extends FilterOptionsSource {
  constructor(private readonly msspService: MsspService) {
    super();
  }

  getFilterOptions(): Observable<FilterOptions> {
    return this.msspService.getFilterOptions();
  }

  search(search: string): Observable<FilterOptions> {
    return this.msspService.search(search);
  }

  loadMore(offset: number): Observable<FilterOptions> {
    return this.msspService.loadMore(offset);
  }
}
