<ng-container *ngIf="state$ | async as state">
  <div *ngIf="!state.hidden" class="wrapper">
    <div *ngIf="state.active" class="active-mark"></div>
    <a *ngIf="!url || state.disabled; else routerLink" [attr.disabled]="state.disabled">
      <ng-container [ngTemplateOutlet]="itemEntry"></ng-container>
    </a>
  </div>

  <ng-template #routerLink>
    <a
      [queryParams]="queryParams"
      [routerLink]="url"
      [state]="{ navigationReference: 'Navbar', navigationLinkText: label }"
      [attr.disabled]="state.disabled"
      automation-id="navbar-item__router-link"
    >
      <ng-container [ngTemplateOutlet]="itemEntry"></ng-container>
    </a>
  </ng-template>

  <ng-template #itemEntry>
    <div class="item" [ngClass]="{ active: state.active }" [attr.disabled]="state.disabled">
      <p
        [gcTooltip]="state.tooltip"
        [panelCssClass]="'tooltip white-tooltip'"
        [aligns]="{ vertical: 'center', horizontal: 'after' }"
        [attr.disabled]="state.disabled"
        automation-id="navbar-item__label"
      >{{ label }}</p>
      <div *ngIf="isNew" class="badge-new"></div>
    </div>
  </ng-template>
</ng-container>
