import { SimpleChange } from '@angular/core';

export declare class SimpleChangeGeneric<T = unknown> extends SimpleChange {
  previousValue: T;
  currentValue: T;
  firstChange: boolean;
  constructor(previousValue: T, currentValue: T, firstChange: boolean);

  /**
   * Check whether the new value is the first value assigned.
   */
  isFirstChange(): boolean;
}

export type SimpleChangesGeneric<T> = {
  [P in keyof T]: SimpleChangeGeneric<T[P]>;
};
