import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators, AbstractControl, AbstractControlOptions, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChangePasswordFacade } from '@guardicore-ui/authentication/domain';
import { passwordRequirementsValidator } from '@guardicore-ui/authentication/ui';
import { PasswordRequirements } from '@guardicore-ui/shared/system-status';
import { matchValuesValidator } from '@guardicore-ui/shared/utils';
import { EMPTY, Subject } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';

interface ChangePasswordModel {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
}

@Component({
  selector: 'gc-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordComponent implements OnDestroy, OnInit {
  @Input() dismissButtonLabel = 'Cancel';
  @Input() submitButtonLabel = 'Change password';
  @Input() passwordRequirements: PasswordRequirements = {
    minimalLength: 8,
    uppercaseRequired: true,
    lowercaseRequired: true,
    letterRequired: true,
    symbolRequired: true,
    numberRequired: true,
  };

  @Input() username?: string;

  busy = false;
  isPasswordTouched = false;
  readonly error = new Subject<string>();
  changePasswordForm?: FormGroup;

  private destroy$ = new Subject<void>();

  get currentPasswordCtrl(): AbstractControl | null {
    return this.changePasswordForm?.get('currentPassword') || null;
  }

  get passwordCtrl(): AbstractControl | null {
    return this.changePasswordForm?.get('password') || null;
  }

  get passwordConfirmationCtrl(): AbstractControl | null {
    return this.changePasswordForm?.get('passwordConfirmation') || null;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      username?: string;
      passwordRequirements?: PasswordRequirements;
      dismissButtonLabel?: string;
      submitButtonLabel?: string;
    },
    readonly dialogRef: MatDialogRef<ChangePasswordComponent>,
    private readonly facade: ChangePasswordFacade,
    private readonly formBuilder: FormBuilder,
  ) {
    this.username = data.username;
    this.passwordRequirements = data.passwordRequirements ?? this.passwordRequirements;
    this.submitButtonLabel = data.submitButtonLabel ?? this.submitButtonLabel;
    this.dismissButtonLabel = data.dismissButtonLabel ?? this.dismissButtonLabel;
  }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group(
      {
        currentPassword: ['', Validators.required],
        password: ['', [Validators.required, passwordRequirementsValidator(this.passwordRequirements)]],
        passwordConfirmation: ['', [Validators.required]],
      },
      { validator: matchValuesValidator('password', 'passwordConfirmation') } as AbstractControlOptions,
    );

    this.changePasswordForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.error.next('');
    });

    if (!this.username) {
      this.dialogRef.close();
    }
  }

  changePassword(): void {
    if (!this.changePasswordForm?.valid || !this.username) {
      return;
    }

    const model = this.changePasswordForm.value as ChangePasswordModel;

    this.busy = true;
    this.facade
      .changePassword(this.username, model.currentPassword, model.password, model.passwordConfirmation)
      .pipe(
        takeUntil(this.destroy$),
        catchError((e: HttpErrorResponse) => {
          this.error.next(`${e.error.error_dump}`);

          return EMPTY;
        }),
        finalize(() => {
          this.busy = false;
        }),
      )
      .subscribe(() => {
        this.dialogRef.close({ success: true });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
