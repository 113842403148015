import { AnimationEvent } from '@angular/animations';
import { ConfigurableFocusTrapFactory } from '@angular/cdk/a11y';
import { DOCUMENT } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewContainerRef, Inject, Optional, ChangeDetectorRef } from '@angular/core';
import { detectHover } from '@guardicore-ui/ui/common';
import { DEFAULT_TRANSITION, SatPopover, SatPopoverAnchoringService } from '@ncstate/sat-popover';
import { fromEvent, Observable, BehaviorSubject, Subject, of, combineLatest } from 'rxjs';
import { switchMap, takeUntil, repeatWhen, filter, map, tap, delay } from 'rxjs/operators';

import { PopoverConfiguration } from '../popover-configuration';
import { POPOVER_REF } from '../popover-ref';
import { PopoverRef } from '../popover-ref.service';

const WRAPPER_STYLE_KEYS = ['marginTop.px', 'marginBottom.px', 'marginLeft.px', 'marginRight.px'] as const;
const DIRECTIONS = ['top', 'bottom', 'left', 'right'] as const;

type WrapperStyleKey = typeof WRAPPER_STYLE_KEYS[number];
type Direction = typeof DIRECTIONS[number];

@Component({
  selector: 'gc-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  providers: [SatPopoverAnchoringService],
})
export class PopoverComponent extends SatPopover implements OnInit {
  private readonly destroy$ = new Subject<void>();
  private readonly destroyHoverDetection$ = new Subject<void>();
  private configuration?: PopoverConfiguration;

  hideDelay = 0;
  template?: TemplateRef<unknown>;
  configuration$?: Observable<PopoverConfiguration>;
  margin = 0;
  wrapperStyle?: Record<WrapperStyleKey, number>;

  readonly isOpen$ = new BehaviorSubject<boolean>(false);
  readonly clickedOutside$!: Observable<MouseEvent>;
  readonly clickedInside$!: Observable<MouseEvent>;
  readonly isHovered$ = new BehaviorSubject<boolean>(false);
  readonly animationDone$ = new Subject<AnimationEvent>();

  constructor(
    _focusTrapFactory: ConfigurableFocusTrapFactory,
    private _anchoringService: SatPopoverAnchoringService,
    _viewContainerRef: ViewContainerRef,
    @Inject(DEFAULT_TRANSITION) _defaultTransition: string,
    @Optional() @Inject(POPOVER_REF) readonly ref: PopoverRef,
    @Optional() @Inject(DOCUMENT) _document: Document,
    private readonly cd: ChangeDetectorRef,
  ) {
    super(_focusTrapFactory, _anchoringService, _viewContainerRef, _defaultTransition, _document);
    _anchoringService.popoverOpened.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.isOpen$.next(true);
      ref?.open();
    });

    _anchoringService.popoverClosed.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.isOpen$.next(false);
      this.isHovered$.next(false);
      ref?.close();
    });

    this.clickedOutside$ = fromEvent<MouseEvent>(_document, 'mousedown').pipe(
      filter(event => this.isOpen() && !_anchoringService._overlayRef?.overlayElement.contains(event.target as HTMLElement)),
      takeUntil(this._anchoringService.popoverClosed),
      repeatWhen(() => _anchoringService.popoverOpened),
    );

    this.clickedInside$ = fromEvent<MouseEvent>(_document, 'mousedown').pipe(
      filter(event => _anchoringService._overlayRef?.overlayElement.contains(event.target as HTMLElement)),
      takeUntil(this._anchoringService.popoverClosed),
      repeatWhen(() => _anchoringService.popoverOpened),
    );

    ref?.close$.pipe(takeUntil(this.destroy$)).subscribe(() => this.close());

    this.animationDone$.pipe(delay(1)).subscribe(event => this._onAnimationDone(event));
  }

  override ngOnInit(): void {
    if (!this.configuration$) {
      return;
    }

    combineLatest([this.configuration$, this.isOpen$])
      ?.pipe(
        map(([configuration, isOpen]) => ({ configuration, isOpen })),
        tap(({ isOpen }) => (isOpen ? this.destroyHoverDetection$.next() : null)),
        delay(1),
        map(({ configuration, isOpen }) => {
          this.unsetLeaf();

          if (this.configuration !== configuration) {
            this.configuration = configuration;
            this.verticalAlign = configuration.aligns.vertical;
            this.horizontalAlign = configuration.aligns.horizontal;
            this.closeTransition = configuration.closeTransition;
            this.openTransition = configuration.openTransition;
            this.hideDelay = configuration.delays.hide;
            if (configuration.withLeaf && configuration.leafConfig?.size !== undefined) {
              this.margin = configuration.leafConfig.size;
            } else {
              this.margin = configuration.margin;
            }

            this.setWrapperStyle();

            if (configuration.panelCssClass) {
              this._classList[configuration.panelCssClass] = true;
            }
          }

          if (isOpen && configuration.withLeaf) {
            this.setLeaf();
          }

          return { configuration, isOpen };
        }),
        switchMap(({ configuration, isOpen }) =>
          isOpen && configuration.category === 'hover'
            ? detectHover(this._anchoringService._overlayRef?.overlayElement, this.destroyHoverDetection$).pipe(
                map(isHovered => ({ configuration, isOpen, isHovered })),
              )
            : of({ configuration, isOpen, isHovered: undefined }),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe(({ isHovered }) => {
        if (isHovered !== undefined) {
          this.isHovered$.next(isHovered);
        }
      });
  }

  private setWrapperStyle(): void {
    const wrapperStyle = { 'marginBottom.px': 0, 'marginTop.px': 0, 'marginLeft.px': 0, 'marginRight.px': 0 };

    if (this.verticalAlign === 'above') {
      wrapperStyle['marginBottom.px'] = this.margin;
    } else if (this.verticalAlign === 'below') {
      wrapperStyle['marginTop.px'] = this.margin;
    } else if (this.horizontalAlign === 'before') {
      wrapperStyle['marginRight.px'] = this.margin;
    } else if (this.horizontalAlign === 'after') {
      wrapperStyle['marginLeft.px'] = this.margin;
    }

    this.wrapperStyle = wrapperStyle;
  }

  private unsetLeaf(): void {
    this._classList['data-popover'] = false;
    DIRECTIONS.forEach(dir => (this._classList[`data-popover-${dir}`] = false));
  }

  private setLeaf(): void {
    if (this.configuration?.leafConfig?.size === undefined) {
      return;
    }

    const leafSize = this.configuration.leafConfig.size;
    const leafWidth = Math.sqrt(2) * leafSize;
    const halfLeafWidth = Math.ceil(leafWidth / 2);

    this._classList['data-popover'] = this.configuration?.withLeaf;

    const direction = this.getDirection();

    if (!direction) {
      return;
    }

    const overlayElement = this._anchoringService._overlayRef?.overlayElement;
    const overlayRect = overlayElement?.getBoundingClientRect();
    const anchorElement = this.anchor as HTMLElement;
    const anchorRect = anchorElement.getBoundingClientRect();

    if (overlayElement && overlayRect) {
      overlayElement.style.setProperty('--border-before', `${leafSize}px`);
      overlayElement.style.setProperty('--border-after', `${leafSize - 1}px`);
      const k = (this.configuration.leafConfig?.position || 0) / 100;

      if (['top', 'bottom'].includes(direction)) {
        const delta = Math.floor(anchorRect.width * k);
        let shift = anchorRect.left - overlayRect.left + delta;

        if (shift < halfLeafWidth) {
          shift = halfLeafWidth + 1;
        } else if (Math.abs(shift - overlayRect.width) < halfLeafWidth) {
          shift = overlayRect.width - halfLeafWidth - 3;
        }

        overlayElement.style.setProperty('--left', `${shift}px`);
      } else if (['right', 'left'].includes(direction)) {
        const delta = Math.floor(anchorRect.height * k);
        let shift = anchorRect.top - overlayRect.top + delta;

        if (shift < halfLeafWidth) {
          shift = halfLeafWidth + 1;
        } else if (Math.abs(shift - overlayRect.height) < halfLeafWidth) {
          shift = overlayRect.height - halfLeafWidth - 3;
        }

        overlayElement.style.setProperty('--top', `${shift}px`);
      }
    }

    this._classList[`data-popover-${direction}`] = true;

    this.cd.markForCheck();
  }

  private getDirection(): Direction | undefined {
    let direction: Direction | undefined;

    // Forbidden combinations
    if (
      (this.horizontalAlign === 'center' && this.verticalAlign === 'center') ||
      ((this.verticalAlign === 'above' || this.verticalAlign === 'below') &&
        (this.horizontalAlign === 'before' || this.horizontalAlign === 'after'))
    ) {
      return undefined;
    }

    if (this.verticalAlign === 'below') {
      direction = 'top';
    } else if (this.verticalAlign === 'above') {
      direction = 'bottom';
    }

    if (this.horizontalAlign === 'before') {
      direction = 'right';
    } else if (this.horizontalAlign === 'after') {
      direction = 'left';
    }

    return direction;
  }
}
