export interface PageState {
  from: number;
  to: number;
  currentPage: number;
  resultsInPage: number;
  totalCount: number;
  offset?: number;
  limit?: number;
}

export const DEFAULT_PAGE_SIZE = 20;

export function createDefaultPageState(): PageState {
  return {
    from: 0,
    to: DEFAULT_PAGE_SIZE,
    currentPage: 1,
    resultsInPage: DEFAULT_PAGE_SIZE,
    totalCount: 0,
    offset: 0,
    limit: DEFAULT_PAGE_SIZE,
  };
}
