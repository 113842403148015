<div class="wrapper" [ngClass]="{ 'selected': entity?.isSelected, 'disabled': entity?.isDisabled }">
  <div class="content" (click)="onClick()">
    <ng-container *ngIf="!noInput">
      <input type="radio" [formControl]="ctrl" [value]="entity?.value" *ngIf="inputType === 'radio' else checkboxInput">
      <ng-template #checkboxInput>
        <input type="checkbox" [formControl]="ctrl" [value]="entity?.value" >
      </ng-template>
    </ng-container>
    <span class="text" [ngClass]="isMuted ? 'muted' : ''">{{ entity?.text }}</span>
  </div>
  <div *ngIf="withCount" class="count">
    <div class="count__element">
      <div class="count__element__text">{{ entity?.count }}</div>
    </div>
  </div>
</div>
