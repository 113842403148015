export interface UserTwoFAStatus {
  isRadius2fa: boolean;
  require2fa: boolean;
}

export interface UserTwoFAQRCode {
  qrCode: string;
  secretKey: string;
}

export interface UserTwoFAStatusUpdate {
  verificationCode?: string;
  password?: string;
  require2fa: boolean | string;
}

export interface UserTwoFAStatusUpdateResponse {
  require2fa: boolean | string;
  message?: string;
}

export interface NewRecoveryCodes {
  newRecoveryCodes: string[];
}
