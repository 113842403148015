import { Injectable } from '@angular/core';
import { Store } from '@datorama/akita';
import { FilterControl } from '@guardicore-ui/filters/shared';
import { FilterOptionsEntity, FiltersState } from '@guardicore-ui/shared/data';
import { equals } from '@guardicore-ui/shared/utils';

import { FilterOptions } from '../entities';

export interface FiltersComponentState {
  filtersValue: FiltersState;
  filtersQuery?: FiltersState;
  filterOptions: Record<string, FilterOptions>;
}

function createInitialState(): FiltersComponentState {
  return {
    filtersValue: {},
    filterOptions: {},
  };
}

@Injectable()
export class FiltersComponentStore extends Store<FiltersComponentState> {
  constructor() {
    super(createInitialState(), { name: FiltersComponentStore.name });
  }

  resetFilterList(): void {
    this.update({ filtersValue: {} });
  }

  registerFilter(control: FilterControl): void {
    const filtersValue = this.getValue().filtersValue;

    filtersValue[control.id] = [];
    this.update({ filtersValue });
  }

  updateFilterValue(id: string, value: FilterOptionsEntity[] | undefined, isQuery = true): void {
    const filtersValue = { ...this.getValue().filtersValue };

    if (!this.isFilterRegistered(id) || equals(filtersValue[id], value)) {
      return;
    }

    filtersValue[id] = value ?? [];
    isQuery
      ? this.update({ filtersQuery: { ...filtersValue } })
      : this.update({ filtersValue: { ...filtersValue }, filtersQuery: undefined });
  }

  clear(clearFilterValue = false): void {
    const filtersQuery = { ...this.getValue().filtersValue };

    for (const key in filtersQuery) {
      filtersQuery[key] = [];
    }

    clearFilterValue ? this.update({ filtersValue: { ...filtersQuery }, filtersQuery: undefined }) : this.update({ filtersQuery });
  }

  isFilterRegistered(id: string): boolean {
    const filtersValue = this.getValue().filtersValue;

    return Object.keys(filtersValue).filter(k => k === id).length > 0;
  }
}
