/* eslint-disable @typescript-eslint/ban-types */
import { AbstractConstructor, CanBeDisabled, CanBeUncommon, Constructor, HasId } from '@guardicore-ui/shared/data';

import { mixinDisabled } from './mixin-can-be-disabled';
import { mixinCanBeUncommon } from './mixin-can-be-uncommon';
import { mixinHasId } from './mixin-has-id';

export type IdCommonDisabledConstructor = AbstractConstructor<HasId & CanBeUncommon & CanBeDisabled>;

export function mixinIdCommonDisabled<T extends AbstractConstructor<{}>>(base: T): IdCommonDisabledConstructor & T;
export function mixinIdCommonDisabled<T extends Constructor<{}>>(base: T): IdCommonDisabledConstructor & T {
  return mixinHasId(mixinCanBeUncommon(mixinDisabled(base)));
}
