import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { FilterParam } from '@guardicore-ui/shared/data';
import { distinctUntilNotEqual } from '@guardicore-ui/shared/utils';
import { map, filter } from 'rxjs';

import { FiltersComponentState, FiltersComponentStore } from './filters-component.store';
import { FilterOptions } from '../entities';

@Injectable()
export class FiltersComponentQuery extends Query<FiltersComponentState> {
  readonly filtersValue$ = this.select('filtersValue').pipe(distinctUntilNotEqual());
  readonly filterParams$ = this.select('filtersQuery').pipe(
    distinctUntilNotEqual(),
    filter(Boolean),
    map(fv => {
      const result: FilterParam[] = [];

      for (const key in fv) {
        if (key && fv[key] !== null && fv[key]?.length) {
          result.push({ name: key, value: fv[key]?.map(v => v.value).join(',') ?? '' });
        }
      }

      return result;
    }),
  );

  readonly filterOptions$ = this.select('filterOptions');

  readonly filterOptions = (id: string): FilterOptions | undefined => this.getValue().filterOptions[id];

  readonly getOtherSelectedFilters = (id: string): Record<string, string> => {
    return Object.entries(this.getValue().filtersValue).reduce((state, [filterValueKey, filterValue]) => {
      if (filterValueKey !== id && filterValue.length) {
        return { ...state, [filterValueKey]: filterValue.map(v => v.value).join(',') };
      }

      return state;
    }, {});
  };

  constructor(protected override store: FiltersComponentStore) {
    super(store);
  }
}
