<div class="wrapper">
  <ul class="main-list">
    <li class="list-item">
      <button (click)="action.next('changePassword')">
        <gc-icon svgIcon="key" class="item-icon"></gc-icon><span>Change Password</span>
      </button>
    </li>
    <li class="list-item">
      <button (click)="action.next('manage2StepVerification')">
        <gc-icon svgIcon="key" class="item-icon"></gc-icon><span>Manage 2-Step Verification</span>
      </button>
    </li>
    <li class="list-item">
      <button (click)="action.next('userPreferences')">
        <gc-icon svgIcon="edit" class="item-icon"></gc-icon><span>User Preferences</span>
      </button>
    </li>
    <li class="divider"></li>
    <li class="list-item">
      <button (click)="action.next('apiDocsV4')">
        <gc-icon svgIcon="api" class="item-icon"></gc-icon><span>API v4 Documentation</span>
      </button>
    </li>
    <li class="list-item" *ngIf="userMenuSet?.showInternalDocumentation">
      <button (click)="action.next('apiDocsV3')">
        <gc-icon svgIcon="api" class="item-icon"></gc-icon><span>API v3 Documentation</span>
      </button>
    </li>
    <li class="list-item" *ngIf="userMenuSet?.showAgentSdkDocs">
      <button (click)="action.next('apiDocsAgent')">
        <gc-icon svgIcon="api" class="item-icon"></gc-icon><span>Agent SDK API Documentation</span>
      </button>
    </li>
    <li class="divider"></li>
    <li class="list-item">
      <button (click)="action.next('eula')"> <gc-icon svgIcon="license" class="item-icon"></gc-icon><span>End User License Agreement</span> </button>
    </li>
    <li class="list-item" *ngIf='isEvaluationMode'>
      <button (click)="action.next('evaluation')"> <gc-icon svgIcon="license" class="item-icon"></gc-icon><span>Evaluation Agreement</span> </button>
    </li>
    <li class="list-item">
      <button (click)="action.next('help')"> <gc-icon svgIcon="api" class="item-icon"></gc-icon><span>Help</span> </button>
    </li>
    <li class="list-item">
      <button (click)="action.next('relNotes')"> <gc-icon svgIcon="api" class="item-icon"></gc-icon><span>Release Notes</span> </button>
    </li>
    <li class="divider"></li>
    <li class="list-item">
      <button (click)="action.next('logout')">
        <gc-icon svgIcon="logOut" class="item-icon"></gc-icon><span>Log Out</span>
      </button>
    </li>
    <ng-container *ngIf="userMenuSet?.evaluationModeState">
      <li class="divider"></li>
      <li class="list-item app-version">
        <div [ngPlural]="userMenuSet?.evaluationDaysPassed || 0">Evaluation License:
          <ng-template ngPluralCase="=0">started today</ng-template>
          <ng-template ngPluralCase="=1">{{ userMenuSet?.evaluationDaysPassed }} day passed</ng-template>
          <ng-template ngPluralCase="other">{{ userMenuSet?.evaluationDaysPassed }} days passed</ng-template>
        </div>
      </li>
    </ng-container>
    <li class="divider"></li>
    <li class="list-item app-version">
      <div class="app-title">
        <span>{{ appTitle }} {{ userMenuSet?.release?.appVersion }}</span>
        <a [href]="systemStatusLogUrl" target="_blank" *ngIf="isInDebugMode">
          <gc-icon svgIcon="bug" class="item-icon"></gc-icon>
        </a>
      </div>
      <div>
        <div class="more-info">
          <gc-text [useCopyTextTooltip]="false" [value]="userMenuSet?.release?.tag" [tooltipText]="userMenuSet?.release?.tag" >
          </gc-text>
          <div *ngIf="userMenuSet?.release?.buildTime">{{ userMenuSet?.release?.buildTime | date: "yyyy-MM-dd HH:mm:ss" }}</div>
          <div *ngIf="userMenuSet?.release?.buildJobId">{{ userMenuSet?.release?.buildJobId }}</div>
          <div *ngIf="userMenuSet?.build">{{ userMenuSet?.build }}</div>
        </div>
      </div>
      <div>
        <hr class="info-separator" />
        <div class="system-time-title">
          Current System Time:<br />
          <span class="system-time" *ngIf="userMenuSet?.systemTime">{{ userMenuSet?.systemTime }}</span>
        </div>
      </div>
    </li>
  </ul>
</div>
