import { RowDataObject } from '@guardicore-ui/shared/data';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Subject } from 'rxjs';

import { RowData } from './row-data';

export interface RowSelectEvent {
  getSelectionState: () => SelectionState;
}

export type RowSelectEventType = 'select' | 'deselect';
export type HeaderSelectEventType = 'none' | 'deselectPage' | 'allPage' | 'allGrid';
export type PaginationEventType = 'pagination';
export type SomeSelectEventType = 'some';

export type RowSelectType = RowSelectEventType | HeaderSelectEventType | PaginationEventType | SomeSelectEventType;

export interface SelectedRowsState<T extends RowDataObject = RowDataObject> {
  selected: number;
  selectedRowIds: string[];
  unselectedRowIds: string[];
  isUnselectionMode: boolean;
  selectedData: Partial<T>[];
}

export interface SelectionState {
  totalItems: number;
  itemsInPage: number;
  currentPageState: RowSelectType;
  selectedRowIds: Set<string>;
  unselectedRowIds: Set<string>;
  isUnselectionMode: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedRowData: Map<string, RowData<any>>;
}

export interface SelectionModeState {
  handleRowSelectedEvent: () => void;
}

export interface RowSelection<T> {
  rowId: string;
  selectedRowType: T;
  rowData: unknown;
}

export const rowSelectionPagination: RowSelection<PaginationEventType> = {
  selectedRowType: 'pagination',
  rowId: '',
  rowData: {},
};

export const rowSelectionNone: RowSelection<HeaderSelectEventType> = {
  selectedRowType: 'none',
  rowId: '',
  rowData: {},
};

export interface GcSelectionState<T> {
  configuration: T;
  rowSelection$: Subject<RowSelection<RowSelectType>>;
}

export interface GcSelectionCell extends ICellRendererParams, GcSelectionState<RowSelectEvent> {}
