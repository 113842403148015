import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DEFAULT_ICON_SET } from '@guardicore-ui/shared/data';

import { IconComponent } from './icon';

@NgModule({
  imports: [CommonModule],
  declarations: [IconComponent],
  exports: [IconComponent],
  providers: [{ provide: DEFAULT_ICON_SET, useValue: '' }],
})
export class UiIconModule {}
