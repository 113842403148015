export const list = `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 2.3C0 3.57025 1.02974 4.6 2.3 4.6C3.57025 4.6 4.6 3.57025 4.6 2.3C4.6 1.02974 3.57025 0 2.3 0C1.02974 0 0 1.02974 0 2.3Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.98875C0 9.25901 1.02974 10.2888 2.3 10.2888C3.57025 10.2888 4.6 9.25901 4.6 7.98875C4.6 6.7185 3.57025 5.68875 2.3 5.68875C1.02974 5.68875 0 6.7185 0 7.98875Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 13.6778C0 14.948 1.02974 15.9778 2.3 15.9778C3.57025 15.9778 4.6 14.948 4.6 13.6778C4.6 12.4075 3.57025 11.3778 2.3 11.3778C1.02974 11.3778 0 12.4075 0 13.6778Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.55103 13.8667C5.55103 14.063 5.71021 14.2222 5.90658 14.2222H15.6444C15.8408 14.2222 16 14.063 16 13.8667C16 13.6703 15.8408 13.5111 15.6444 13.5111H5.90658C5.71021 13.5111 5.55103 13.6703 5.55103 13.8667Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.55103 8.17779C5.55103 8.37416 5.71021 8.53335 5.90658 8.53335H15.6444C15.8408 8.53335 16 8.37416 16 8.17779C16 7.98142 15.8408 7.82224 15.6444 7.82224H5.90658C5.71021 7.82224 5.55103 7.98142 5.55103 8.17779Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.55103 2.48889C5.55103 2.68525 5.71021 2.84444 5.90658 2.84444H15.6444C15.8408 2.84444 16 2.68525 16 2.48889C16 2.29252 15.8408 2.13333 15.6444 2.13333H5.90658C5.71021 2.13333 5.55103 2.29252 5.55103 2.48889Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.653076 13.8195C0.653076 14.631 1.31094 15.2889 2.12246 15.2889C2.93398 15.2889 3.59185 14.631 3.59185 13.8195V13.5583C3.59185 12.7468 2.93398 12.0889 2.12246 12.0889C1.31094 12.0889 0.653076 12.7468 0.653076 13.5583V13.8195Z"/>
</svg>
`;
