import { ComponentHarness, TestElement } from '@angular/cdk/testing';

export class FiltersContentRowHarness extends ComponentHarness {
  static hostSelector = 'gc-filters-content-row';

  protected wrapper = this.locatorFor('.wrapper');
  protected content = this.locatorFor('.content');
  protected input = this.locatorFor('.content input');
  protected text = this.locatorFor('.content span');
  protected count = this.locatorFor('.count');

  async getInput(): Promise<TestElement | null> {
    try {
      return await this.input();
    } catch {
      return null;
    }
  }

  async getInputType(): Promise<string | null> {
    const input = await this.getInput();

    if (!input) {
      return null;
    }

    return await input.getAttribute('type');
  }

  async getText(): Promise<string> {
    const span = await this.text();

    return await span.text();
  }

  async isMuted(): Promise<boolean> {
    const span = await this.text();

    return await span.hasClass('muted');
  }

  async getCount(): Promise<TestElement | null> {
    try {
      return await this.count();
    } catch {
      return null;
    }
  }

  async selectRow(): Promise<void> {
    await (await this.content()).click();
  }

  async isSelected(): Promise<boolean> {
    const wrapper = await this.wrapper();

    return await wrapper.hasClass('selected');
  }

  async isDisabled(): Promise<boolean> {
    const wrapper = await this.wrapper();

    return await wrapper.hasClass('disabled');
  }
}
