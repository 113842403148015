import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { UiIconModule } from '@guardicore-ui/ui/icon';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { UiTooltipModule } from '@guardicore-ui/ui/popovers/tooltip';

import { NotificationComponent } from './notification-component/notification.component';
import { NotificationsComponent } from './notifications.component';

@NgModule({
  declarations: [NotificationsComponent, NotificationComponent],
  imports: [CommonModule, UiIconModule, UiTooltipModule, RouterModule],
  exports: [NotificationsComponent],
})
export class NotificationsDomainModule {}
