import { Injectable } from '@angular/core';
import { Store } from '@datorama/akita';
import { FilterOptionsEntity } from '@guardicore-ui/shared/data';

import { MsspState, createInitialState } from '../entities';

@Injectable({ providedIn: 'root' })
export class MsspStore extends Store<MsspState> {
  constructor() {
    super(createInitialState(), { name: 'mssp', resettable: true });
  }

  appendAvailableOptions(entities: FilterOptionsEntity[], offset: number): void {
    const currentState = this.getValue();

    if (!currentState?.options) {
      return;
    }

    let currentOptions = { ...currentState.options };
    let availableOptions = currentState.options.availableOptions.slice(0);

    availableOptions = availableOptions.concat(entities);
    currentOptions = { ...currentOptions, availableOptions, offset };
    this.update({ options: currentOptions });
  }
}
