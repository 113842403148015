import { Component, ChangeDetectionStrategy, TemplateRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { GcDetailCellRendererParams } from '../entities/detail-cell-renderer-params';

@Component({
  selector: 'gc-detail-cell-renderer',
  templateUrl: './detail-cell-renderer.component.html',
  styleUrls: ['./detail-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailCellRendererComponent implements ICellRendererAngularComp {
  expansionTemplate?: TemplateRef<unknown>;
  params?: GcDetailCellRendererParams;

  agInit(params: GcDetailCellRendererParams): void {
    this.setConfiguration(params);
  }

  refresh(params: GcDetailCellRendererParams): boolean {
    this.setConfiguration(params);

    return true;
  }

  private setConfiguration(params: GcDetailCellRendererParams): void {
    this.expansionTemplate = params.expansionTemplate;
    this.params = params;
  }
}
