<div class="wrapper">
  <div class="logo-link">
    <a routerLink="/" gcAnalytics="NavBar 2.0 Logo Clicked"><gc-icon svgIcon="akamai-wave"></gc-icon></a>
  </div>

  <div class="navbar-groups">
    <gc-navbar-group
      label="Dashboard"
      icon="navbar-dashboard"
      url="/overview/security-dashboard"
      [queryParams]="{ time_frame: 'DAY' }"
    ></gc-navbar-group>

    <gc-navbar-group
      label="Cluster"
      viewName="Centra Cluster"
      icon="navbar-cluster"
      url="/overview/cluster-manager"
    ></gc-navbar-group>

    <gc-navbar-group icon="reveal" label="Reveal">
      <div class="column">
        <gc-navbar-sub-group>
          <gc-navbar-item label="Network Log" url="/overview/network-log"></gc-navbar-item>
          <gc-navbar-item label="Explore" url="/overview/reveal/explore"></gc-navbar-item>
          <gc-navbar-item label="Saved Maps" url="/overview/reveal/saved-maps"></gc-navbar-item>
        </gc-navbar-sub-group>
      </div>
    </gc-navbar-group>

    <gc-navbar-group icon="navbar-enforce" label="Enforce">
      <div class="column">
        <gc-navbar-sub-group>
          <gc-navbar-item label="Create Policy" url="/overview/segmentation/create-policy"></gc-navbar-item>
          <gc-navbar-item label="Projects" url="/overview/segmentation/projects"></gc-navbar-item>
          <gc-navbar-item label="Rules" viewName="Segmentation Rules" url="/overview/segmentation/segmentation-policy"></gc-navbar-item>
          <gc-navbar-item label="Revisions" url="/overview/segmentation/policy-revisions"></gc-navbar-item>
          <gc-navbar-item label="User Groups" url="/overview/segmentation/user-groups"></gc-navbar-item>
        </gc-navbar-sub-group>
      </div>
    </gc-navbar-group>

    <gc-navbar-group icon="navbar-inventory" label="Inventory">
      <div class="column">
        <gc-navbar-sub-group label="DATA CENTER" [hasBottomSeparator]="true">
          <gc-navbar-item label="Assets" url="/overview/inventory/assets" [queryParams]="{ 'status': 'on,off' }"></gc-navbar-item>
          <gc-navbar-item label="K8s Clusters" viewName="Kubernetes Clusters" url="/overview/inventory/kubernetes-clusters"></gc-navbar-item>
        </gc-navbar-sub-group>

        <gc-navbar-sub-group label="LABELS">
          <gc-navbar-item label="Labels" url="/overview/reveal/labels"></gc-navbar-item>
          <gc-navbar-item label="Label Groups" url="/overview/segmentation/label-groups" [queryParams]="{ 'assets_status': 'on,off' }"></gc-navbar-item>
          <gc-navbar-item label="Labels Log" url="/overview/activity/labels-log"></gc-navbar-item>
        </gc-navbar-sub-group>
      </div>

      <div class="column">
        <gc-navbar-sub-group label="AGENTS">
          <gc-navbar-item label="Agents" viewName="Agents" url="/overview/inventory/agents/agents" [queryParams]="{ 'activity': 'last_month' }" *ngIf="!systemStatus.featureFlags()?.agents?.enableNew"></gc-navbar-item>
          <gc-navbar-item label="Agents" viewName="Agents" url="/overview/inventory/agents/agents-new" *ngIf="systemStatus.featureFlags()?.agents?.enableNew"></gc-navbar-item>
          <gc-navbar-item label="Agents Log" viewName="Agents Log" url="/overview/inventory/agents/logs"></gc-navbar-item>
          <gc-navbar-item
            label="Installation Profiles"
            viewName="Agent Installation Profiles"
            url="/overview/inventory/agents/agent-installation-profiles"
          ></gc-navbar-item>
          <gc-navbar-item
            label="Installation Instructions"
            viewName="Agent Installation"
            url="/overview/inventory/agents/agents-installation"
          ></gc-navbar-item>
          <gc-navbar-item
            label="Upgrade History"
            viewName="Agent Upgrade History"
            url="/overview/inventory/agents/agent-upgrade-history"
          ></gc-navbar-item>
        </gc-navbar-sub-group>
      </div>
    </gc-navbar-group>

    <gc-navbar-group icon="navbar-security" label="Protect" *ngIf="!systemStatus.featureFlags()?.centraCluster?.isManager">
      <div class="column">
        <gc-navbar-sub-group label="HUNT" [hasBottomSeparator]="true">
          <gc-navbar-item label="Hunt Incidents" viewName="Security Events" url="/overview/hunt"></gc-navbar-item>
        </gc-navbar-sub-group>

        <gc-navbar-sub-group label="INVESTIGATION">
          <gc-navbar-item label="Incidents" viewName="All Incidents" url="/overview/incidents"></gc-navbar-item>
        </gc-navbar-sub-group>

        <gc-navbar-sub-group label="THREAT PREVENTION" [hasTopSeparator]="true">
          <gc-navbar-item label="DNS Security" url="/overview/segmentation/dns-security"></gc-navbar-item>
          <gc-navbar-item label="Reputation Policy" viewName="Reputation" url="/administration/detection/reputation"></gc-navbar-item>
          <gc-navbar-item label="Reputation Log" url="/overview/activity/reputation-log"></gc-navbar-item>
        </gc-navbar-sub-group>

        <gc-navbar-sub-group label="INSIGHT" [hasTopSeparator]="true">
          <gc-navbar-item label="Run Query" viewName="Guardicore Query" url="/overview/guardicore-query/run"></gc-navbar-item>
          <gc-navbar-item label="Scheduled Queries" url="/overview/guardicore-query/scheduled"></gc-navbar-item>
        </gc-navbar-sub-group>
      </div>

      <div class="column">
        <gc-navbar-sub-group label="DETECTION" [hasBottomSeparator]="true">
          <gc-navbar-item label="Detectors" viewName="Detection" url="/administration/detection/detectors"></gc-navbar-item>
          <gc-navbar-item label="Mitigation & IoCs" viewName="Mitigation" url="/administration/mitigation"></gc-navbar-item>
        </gc-navbar-sub-group>

        <gc-navbar-sub-group label="DECEPTION">
          <gc-navbar-item label="Deception Policy" viewName="Inspection Policy" url="/administration/inspection-policy"></gc-navbar-item>
          <gc-navbar-item label="Deception Log" viewName="Redirection Log" url="/overview/activity/redirections-log"></gc-navbar-item>
        </gc-navbar-sub-group>

        <gc-navbar-sub-group label="FIM" [hasTopSeparator]="true">
          <gc-navbar-item label="FIM Policy" viewName="Integrity Monitoring" url="/administration/integrity-monitor/templates"></gc-navbar-item>
          <gc-navbar-item label="FIM Log" viewName="Integrity Log" url="/overview/activity/integrity-log"></gc-navbar-item>
        </gc-navbar-sub-group>
      </div>
    </gc-navbar-group>

    <ng-container *ngIf="isAdminModeAllowed$ | async" >
      <div class="space-dots">
        <gc-icon svgIcon="space-dots"></gc-icon>
      </div>

      <gc-navbar-group  icon="navbar-system" label="System">
        <div class="column">
          <gc-navbar-sub-group label="SETUP" [hasBottomSeparator]="true">
            <gc-navbar-item label="Configuration" url="/administration/system/configuration"></gc-navbar-item>
            <gc-navbar-item label="Aggregators" url="/administration/components/agent-aggregators" *ngIf="!systemStatus.featureFlags()?.aggregators?.enableNew"></gc-navbar-item>
            <gc-navbar-item label="Aggregators" url="/administration/components/aggregators" *ngIf="systemStatus.featureFlags()?.aggregators?.enableNew"></gc-navbar-item>
            <gc-navbar-item label="Collectors" url="/administration/components/collectors"></gc-navbar-item>
            <gc-navbar-item label="CloudApp" viewName="Cloud App" url="/administration/components/cloud-app"></gc-navbar-item>
            <gc-navbar-item label="Deception Servers" url="/administration/components/deception-servers"></gc-navbar-item>
          </gc-navbar-sub-group>

          <gc-navbar-sub-group label="USER MANAGEMENT">
            <gc-navbar-item label="Users" url="/administration/user-management/users"></gc-navbar-item>
            <gc-navbar-item label="Providers" viewName="User Directories" url="/administration/user-management/user-directories"></gc-navbar-item>
            <gc-navbar-item label="Permission Schemes" url="/administration/user-management/user-permission-schemes"></gc-navbar-item>
          </gc-navbar-sub-group>
        </div>

        <div class="column">
          <gc-navbar-sub-group label="INTEGRATION" [hasBottomSeparator]="true">
            <gc-navbar-item label="Plugins" iconCssClass="disconnected" url="/administration/plugins"></gc-navbar-item>
            <gc-navbar-item label="Orchestrations" url="/administration/datacenter/orchestration"></gc-navbar-item>
            <gc-navbar-item label="Deployment" url="/administration/datacenter/deployment"></gc-navbar-item>
            <gc-navbar-item label="Azure" viewName="Cloud" url="/overview/inventory/cloud-inventory"></gc-navbar-item>
          </gc-navbar-sub-group>

          <gc-navbar-sub-group label="DATA EXPORTERS">
            <gc-navbar-item label="Slack" url="/administration/integrations/slack"></gc-navbar-item>
            <gc-navbar-item label="Syslog" url="/administration/integrations/syslog"></gc-navbar-item>
            <gc-navbar-item label="Email" url="/administration/integrations/email"></gc-navbar-item>
            <gc-navbar-item label="Azure Threat Feed" url="/administration/integrations/azure_threat_feed"></gc-navbar-item>
            <gc-navbar-item label="Interflow" url="/administration/integrations/interflow"></gc-navbar-item>
            <gc-navbar-item label="Firewalls" url="/administration/integrations/firewalls"></gc-navbar-item>
          </gc-navbar-sub-group>
        </div>

        <div class="column">
          <gc-navbar-sub-group label="LOG">
            <gc-navbar-item label="Audit" viewName="Auditing" url="/administration/system/audit-logs"></gc-navbar-item>
            <gc-navbar-item label="System" viewName="Log" url="/administration/system/log" [queryParams]="{ 'status': 'ERROR,WARNING' }"></gc-navbar-item>
          </gc-navbar-sub-group>

          <gc-navbar-sub-group [hasTopSeparator]="true">
            <gc-navbar-item label="Repo Key" url="/administration/system/repo-key"></gc-navbar-item>
          </gc-navbar-sub-group>
        </div>
      </gc-navbar-group>
    </ng-container>
  </div>
  <div class="spacer"></div>
  <div class="guardicore-logo">
    <div class="title"><gc-icon svgIcon="guardicore-title"></gc-icon></div>
    <div><gc-icon svgIcon="guardicore-g"></gc-icon></div>
  </div>
</div>
