import { IDictionary } from './dictionary';
import { Severity } from './severities';

const FLAG_TYPES = [
  'PollingMode',
  'OutdatedPolicy',
  'OutdatedConfiguration',
  'NoRevealReceived',
  'HighDropRate',
  'AgentMissing',
  'PartialPolicy',
  'EnforcementPaused',
  'NoRevealReported',
  'RevealOffline',
  'EnforcementOffline',
  'RevealModuleError',
  'EnforcementModuleError',
  'DetectionModuleError',
  'DeceptionModuleError',
  'ControllerModuleError',
  'NoResourceLimits',
  'MemoryLimitReached',
  'RevealMemoryLimitReached',
  'EnforcementMemoryLimitReached',
  'DetectionMemoryLimitReached',
  'DeceptionMemoryLimitReached',
  'ControllerMemoryLimitReached',
  'LimitedPolicy',
  'EnforcementModeRevealOnly',
  'EnforcementModeMonitoring',
  'ConfigurationPartiallyApplied',
  'EnforcementMissingKO',
  'DeceptionMissingKO',
  'DeprecatedAgentFlagUsed',
  'DeceptionRedirectionChannelError',
  'DeceptionLimitedCapabilities',
  'SecuritySoftwareDetected',
  'PartiallySupportedInstallation',
  'DNSSecurityOutdated',
  'OrchestrationError',
  'HighPerformanceHWProperties',
  'PartialRevealReported',
  'EnforcementMetricsOnlyMode',
  'SyslogServerRestartFailure',
  'RevealDisabled',
  'OperationRequestFailed',
  'RevealAgentWarning',
  'RevealAgentError',
  'K8sCniNotSupported',
  'K8sEnforcementDisabled',
  'ConflictingNetworkInterfaceDetected',
  'CertificateAboutToExpire',
  'K8sEnforcementOffline',
] as const;

export type FlagType = typeof FLAG_TYPES[number];

export interface StatusFlag {
  description: string;
  details?: IDictionary<string>;
  flagType: FlagType;
  raisedTime: number;
  severity: Severity;
}
