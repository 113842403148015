import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { UiFormComponentsModule } from '@guardicore-ui/ui/form-components';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { UiIconModule } from '@guardicore-ui/ui/icon';

import { FiltersContentRowComponent } from './filters-content-row/filters-content-row.component';
import { FiltersFooterComponent } from './filters-footer/filters-footer.component';
import { SearchBoxComponent } from './search-box/search-box.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, UiIconModule, UiFormComponentsModule],
  declarations: [SearchBoxComponent, FiltersContentRowComponent, FiltersFooterComponent],
  exports: [SearchBoxComponent, FiltersContentRowComponent, FiltersFooterComponent],
})
export class UiFiltersCommonModule {}
