import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { SortingOrder } from '@guardicore-ui/shared/data';
import { toSnakeCase } from '@guardicore-ui/shared/utils';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-enterprise';
import { map, tap } from 'rxjs';

import { GcHeaderParams, GRID_DATA_SOURCE } from '../entities';
import { GridComponentQuery, GridDataSource } from '../infrastructure';

@Component({
  selector: 'gc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements IHeaderAngularComp {
  private colId?: string;
  readonly query = new GridComponentQuery(this.dataSource.store);
  params?: GcHeaderParams;
  sortingOrder?: SortingOrder;
  sort$ = this.dataSource.sortState$.pipe(
    map(sortState => (this.colId ? sortState[this.colId] : null)),
    tap(sortingOrder => (this.sortingOrder = sortingOrder)),
  );

  constructor(@Inject(GRID_DATA_SOURCE) readonly dataSource: GridDataSource) {}

  agInit(params: GcHeaderParams): void {
    this.params = params;
    this.setColId();
  }

  refresh(params: IHeaderParams): boolean {
    this.params = params;
    this.setColId();

    return true;
  }

  setSort(sort?: SortingOrder): void {
    const colId = this.colId;

    if (!colId || !this.params?.enableSorting) {
      return;
    }

    if (sort === undefined) {
      switch (this.sortingOrder) {
        case 'asc':
          sort = 'desc';
          break;
        case 'desc':
          sort = null;
          break;
        default:
          sort = 'asc';
      }
    }

    this.params.sortEventSubject?.next({ colId, sort });
  }

  private setColId(): void {
    const sortField = this.params?.column?.getColDef()?.headerComponentParams;

    const colId = this.params?.column?.getColId?.();

    this.colId = sortField?.sortField
      ? toSnakeCase(sortField.sortField).toLowerCase()
      : colId
      ? toSnakeCase(colId).toLowerCase()
      : undefined;
  }
}
