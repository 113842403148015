<div class="simple-toastr" [ngClass]="toastrData.state">
  <div class="header">
    <gc-icon class="icon" [svgIcon]="iconClass"></gc-icon>
    <p class="title">{{ toastrData.title }}</p>
    <gc-icon class="close" svgIcon="close"></gc-icon>
  </div>
  <div class="message-content">
    <p [innerHTML]="toastrData.message"></p>
  </div>
</div>

