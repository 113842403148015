import { ElementRef } from '@angular/core';

import { Coordinates } from '../entities';

export function isInsideHost(coords: Coordinates, host: ElementRef): boolean {
  const rect = (host.nativeElement as Element).getBoundingClientRect();

  const xInside = rect.x <= coords.x && rect.x + rect.width >= coords.x;
  const yInside = rect.y <= coords.y && rect.y + rect.height >= coords.y;

  return xInside && yInside;
}
