import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UiActionListModule } from '@guardicore-ui/ui/action-list';
import { UiCommonModule } from '@guardicore-ui/ui/common';
import { UiFormComponentsModule } from '@guardicore-ui/ui/form-components';
import { UiIconModule } from '@guardicore-ui/ui/icon';
import { UiPopoverModule } from '@guardicore-ui/ui/popovers/popover';
import { FormlyModule } from '@ngx-formly/core';

import { ConfigurationArrayComponent } from './configuration-array/configuration-array.component';
import { ConfigurationArrayEditDialogComponent } from './configuration-array-edit-dialog/configuration-array-edit-dialog.component';
import { ConfigurationBooleanComponent } from './configuration-boolean/configuration-boolean.component';
import { ConfigurationInputComponent } from './configuration-input/configuration-input.component';
import { ConfigurationObjectComponent } from './configuration-object/configuration-object.component';
import { ConfigurationSelectComponent } from './configuration-select/configuration-select.component';
import { ConfigurationWidgetBaseComponent } from './configuration-widget-base/configuration-widget-base.component';
import { ConfigurationWidgetWarningsComponent } from './configuration-widget-warnings/configuration-widget-warnings.component';

@NgModule({
  imports: [
    CommonModule,
    UiCommonModule,
    UiIconModule,
    UiFormComponentsModule,
    ReactiveFormsModule,
    FormlyModule,
    UiPopoverModule,
    UiActionListModule,
  ],
  declarations: [
    ConfigurationInputComponent,
    ConfigurationArrayComponent,
    ConfigurationSelectComponent,
    ConfigurationObjectComponent,
    ConfigurationBooleanComponent,
    ConfigurationArrayEditDialogComponent,
    ConfigurationWidgetBaseComponent,
    ConfigurationWidgetWarningsComponent,
  ],
})
export class ConfigurationUiWidgetsModule {}
