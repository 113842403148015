import { FeatureWithPaginationAndFiltersState, RowDataObject, parseSortParam } from '@guardicore-ui/shared/data';

import { ApiResponse } from '../entities';

export function apiResponseToFeatureState<T extends RowDataObject>(res: ApiResponse<T>): FeatureWithPaginationAndFiltersState<T> {
  return {
    pageState: {
      from: res.from,
      to: res.objects.length + res.from,
      currentPage: res.currentPage,
      resultsInPage: res.resultsInPage,
      totalCount: res.totalCount,
    },
    filtersState: res.resolvedFilters || {},
    entities: res.objects,
    sortState: parseSortParam(res.sort),
  };
}
