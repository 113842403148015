import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { NotificationsStore } from './notifications.store';
import { NotificationsState } from '../entities';

@Injectable({ providedIn: 'root' })
export class NotificationsQuery extends QueryEntity<NotificationsState> {
  readonly state$ = this.select();
  readonly newCount$ = this.select('newCount');
  readonly toasts$ = this.select('toasts');

  constructor(protected override store: NotificationsStore) {
    super(store);
  }
}
