import { DOCUMENT } from '@angular/common';
import { Component, ChangeDetectionStrategy, Inject, OnDestroy, inject } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { fromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { GcCellRendererParams } from '../entities';
import { CreateNewRowService, NEW_ROW_ID } from '../infrastructure';

@Component({
  selector: 'gc-confirm-action-cell',
  templateUrl: './confirm-action-cell.component.html',
  styleUrls: ['./confirm-action-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmActionCellComponent implements AgRendererComponent, OnDestroy {
  private destroy$ = new Subject();
  params?: ICellRendererParams;
  isNewRow = false;
  addNewRowService = inject(CreateNewRowService);
  constructor(@Inject(DOCUMENT) private document: Document) {}

  agInit(params: GcCellRendererParams): void {
    if (params?.data?.id === NEW_ROW_ID) {
      this.isNewRow = true;
      fromEvent(this.document, 'keydown')
        .pipe(
          takeUntil(this.destroy$),
          filter((e: Event) => (e as KeyboardEvent).code === 'Escape'),
          filter(() => !this.addNewRowService.isDiscardModalOpen),
        )
        .subscribe(() => this.dismiss());
    }

    this.setConfiguration(params);
  }

  refresh(params: GcCellRendererParams): boolean {
    this.setConfiguration(params);

    return true;
  }

  confirm(): void {
    this.addNewRowService.confirmActionClick(true);
  }

  dismiss(): void {
    this.addNewRowService.confirmActionClick(false);
  }

  private setConfiguration(params: GcCellRendererParams): void {
    this.params = params;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
