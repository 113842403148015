import { Injectable } from '@angular/core';
import { FilterOptionsEntity } from '@guardicore-ui/shared/data';
import { VALUE_UNDEFINED } from '@guardicore-ui/ui/filters/common';
import { map, take } from 'rxjs/operators';

import { VALUE_NO_TENANT } from '../entities';
import { MsspQuery, MsspService, MsspStore } from '../infrastructure';

@Injectable({
  providedIn: 'root',
})
export class MsspMainFacade {
  readonly selected$ = this.query.selected$.pipe(
    map(ent => ({
      ...ent,
      value: ent?.value === VALUE_UNDEFINED ? VALUE_NO_TENANT : ent?.value,
    })),
  );

  readonly selected = (): FilterOptionsEntity | undefined => this.store.getValue()?.selected;

  constructor(private readonly service: MsspService, private readonly query: MsspQuery, private readonly store: MsspStore) {}

  setSelected(id: string): void {
    this.service.read(id).pipe(take(1)).subscribe();
  }
}
