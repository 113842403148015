import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthenticationUiModule, AuthenticationUiTwoFaQrModule } from '@guardicore-ui/authentication/ui';
import { UiCommonModule } from '@guardicore-ui/ui/common';
import { UiDialogsModule } from '@guardicore-ui/ui/dialogs';
import { UiFormComponentsModule } from '@guardicore-ui/ui/form-components';
import { UiIconModule } from '@guardicore-ui/ui/icon';

import { ChangePasswordComponent } from './change-password/change-password.component';
import { GenericAuthenticationModalComponent } from './generic-authentication-modal/generic-authentication-modal.component';
import { ManageTwoFaComponent } from './manage-two-fa/manage-two-fa.component';
import { RecoveryCodesComponent } from './recovery-codes/recovery-codes.component';
import { SessionExpirationDialogComponent } from './session-expiration-dialog/session-expiration-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    UiIconModule,
    ReactiveFormsModule,
    UiCommonModule,
    AuthenticationUiModule,
    AuthenticationUiTwoFaQrModule,
    UiDialogsModule,
    UiFormComponentsModule,
  ],
  declarations: [
    ChangePasswordComponent,
    ManageTwoFaComponent,
    RecoveryCodesComponent,
    GenericAuthenticationModalComponent,
    SessionExpirationDialogComponent,
  ],
  exports: [ChangePasswordComponent, ManageTwoFaComponent, SessionExpirationDialogComponent],
})
export class AuthenticationDialogsModule {}
