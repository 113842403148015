import { Inject, Injectable } from '@angular/core';
import { UserAgreementAction, POLLING_INTERVAL } from '@guardicore-ui/shared/data';
import { PollingService } from '@guardicore-ui/shared/polling';
import { timer, EMPTY, merge, Observable, combineLatest } from 'rxjs';
import { switchMap, takeUntil, catchError } from 'rxjs/operators';

import { SystemStatusState, SystemStatusUserPreferences } from '../entities';
import { SystemStatusService, SystemStatusQuery } from '../infrastructure';

@Injectable({
  providedIn: 'root',
})
export class SystemStatusFacade {
  private isPolling = false;

  constructor(
    private readonly systemStatusService: SystemStatusService,
    private readonly query: SystemStatusQuery,
    private readonly pollingService: PollingService,
    @Inject(POLLING_INTERVAL) private readonly pollingInterval: number,
  ) {}

  get systemStatus(): SystemStatusState {
    return this.query.getValue();
  }

  initialize(): void {
    merge(this.pollingService.reloadAll$, this.pollingService.reloadSystemStatus$)
      .pipe(
        switchMap(() => this.readSystemStatus()),
        catchError(() => EMPTY),
      )
      .subscribe();

    this.pollingService.start$.subscribe(() => this.startPolling(!this.query.wasSystemStatusJustRead()));
    this.pollingService.stop$.subscribe(() => (this.isPolling = false));
  }

  readSystemStatus(): Observable<SystemStatusState> {
    return this.systemStatusService.read();
  }

  startPolling(immediately = false, force = false): void {
    if (!force && (this.isPolling || !this.pollingService.arePollingsAllowed)) {
      return;
    }

    this.isPolling = true;
    timer(immediately ? 0 : this.pollingInterval, this.pollingInterval)
      .pipe(
        switchMap(() => this.systemStatusService.read()),
        catchError(() => EMPTY),
        takeUntil(this.pollingService.stop$),
      )
      .subscribe();
  }

  acceptAgreements(actions: UserAgreementAction[]): Observable<SystemStatusState> {
    const actions$ = actions.map(action => {
      switch (action) {
        case 'accept_eula':
          return this.systemStatusService.acceptEula();
        case 'accept_evaluation':
          return this.systemStatusService.acceptEvaluation();
        default:
          return EMPTY;
      }
    });

    return combineLatest(actions$).pipe(switchMap(() => this.readSystemStatus()));
  }

  saveUserPreferences(preferences: SystemStatusUserPreferences): Observable<SystemStatusState> {
    if (!this.query.user()) {
      throw new Error(`Can't update user preferences`);
    }

    return this.systemStatusService
      .saveUserPreferences(preferences, `${this.query.user()?.username}`)
      .pipe(switchMap(() => this.readSystemStatus()));
  }

  confirmNewNavigation(): Observable<SystemStatusState> {
    return this.systemStatusService.confirmNewNavigation().pipe(switchMap(() => this.readSystemStatus()));
  }

  openLink(url: string): Observable<string | unknown> {
    return this.systemStatusService.openLink(url);
  }
}
