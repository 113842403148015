import { CdkPortalOutlet, ComponentPortal } from '@angular/cdk/portal';
import { Component, ChangeDetectionStrategy, ViewChild, ComponentRef, ElementRef, AfterViewInit, Inject, Renderer2 } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import {
  transformPopover,
  AnimationParams,
  DEFAULT_TRANSITION,
  DEFAULT_CLOSE_ANIMATION_END_SCALE,
  LEEF_SIZE,
  OpenPopoverOptions,
  POPOVER_OPTIONS,
} from '../entities';

const POPOVER_CONTAINER_CSS_CLASS = 'gc-popover-container';

@Component({
  selector: 'gc-popover-container',
  templateUrl: './popover-container.component.html',
  styleUrls: ['./popover-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [transformPopover],
})
export class PopoverContainerComponent implements AfterViewInit {
  state: 'enter' | 'void' | 'exit' = 'enter';

  get animationParams(): AnimationParams {
    return {
      openTransition: DEFAULT_TRANSITION,
      closeTransition: DEFAULT_TRANSITION,
      endAtScale: DEFAULT_CLOSE_ANIMATION_END_SCALE,
    };
  }

  private readonly sizeSubject = new ReplaySubject<DOMRect>(1);
  readonly size$ = this.sizeSubject.asObservable();

  @ViewChild(CdkPortalOutlet, { static: true }) private readonly portalOutlet?: CdkPortalOutlet;
  @ViewChild('popoverContainer') private popoverContainer?: ElementRef;

  constructor(
    @Inject(POPOVER_OPTIONS) private readonly popoverConfig: OpenPopoverOptions,
    private readonly renderer: Renderer2,
    readonly elementRef: ElementRef,
  ) {}

  ngAfterViewInit(): void {
    if (!this.popoverContainer) {
      throw new Error('Popover container initialization error.');
    }

    this.popoverConfig.withLeaf && this.setLeaf();
  }

  attachContentPortal<C>(componentPortal: ComponentPortal<C>): ComponentRef<C> | undefined {
    if (!this.portalOutlet || this.portalOutlet.hasAttached()) {
      return undefined;
    }

    return this.portalOutlet.attachComponentPortal<C>(componentPortal);
  }

  animationDone(): void {
    this.sizeSubject.next(this.popoverContainer?.nativeElement.getBoundingClientRect());
  }

  private setLeaf(): void {
    if (!this.popoverContainer || !this.popoverConfig.direction || !this.popoverConfig.anchor) {
      return;
    }

    const popoverContainer = this.popoverContainer.nativeElement as HTMLElement;
    const popoverContainerRect = popoverContainer.getBoundingClientRect();
    const direction = this.popoverConfig.direction;
    const anchorRect = this.popoverConfig.anchor?.nativeElement.getBoundingClientRect();
    const halfLeafWidth = Math.ceil(LEEF_SIZE / 2);
    const k = 0.5;

    popoverContainer.style.setProperty('--border-before', `${LEEF_SIZE}px`);
    popoverContainer.style.setProperty('--border-after', `${LEEF_SIZE - 1}px`);

    if (['top', 'bottom'].includes(direction)) {
      const delta = Math.floor(anchorRect.width * k);
      let shift = anchorRect.left - popoverContainerRect.left + delta;

      if (shift < halfLeafWidth) {
        shift = halfLeafWidth + 1;
      } else if (Math.abs(shift - popoverContainerRect.width) < halfLeafWidth) {
        shift = popoverContainerRect.width - halfLeafWidth - 3;
      }

      popoverContainer.style.setProperty('--left', `${shift}px`);
    } else if (['left', 'right'].includes(direction)) {
      const delta = Math.floor(anchorRect.height * k);
      let shift = anchorRect.top - popoverContainerRect.top + delta;

      if (shift < halfLeafWidth) {
        shift = halfLeafWidth + 1;
      } else if (Math.abs(shift - popoverContainerRect.height) < halfLeafWidth) {
        shift = popoverContainerRect.height - halfLeafWidth - 3;
      }

      popoverContainer.style.setProperty('--top', `${shift}px`);
    }

    this.renderer.addClass(popoverContainer, `${POPOVER_CONTAINER_CSS_CLASS}-${direction}`);
  }
}
