import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ActionListDividerDirective } from './action-list-divider.directive';
import { ActionListItemComponent } from './action-list-item/action-list-item.component';
import { ActionListComponent } from './action-list.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ActionListComponent, ActionListItemComponent, ActionListDividerDirective],
  exports: [ActionListComponent, ActionListItemComponent, ActionListDividerDirective],
})
export class UiActionListModule {}
