import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ProjectsService } from './infrastructure';

@NgModule({
  imports: [CommonModule],
  providers: [ProjectsService],
})
export class ProjectsDomainModule {}
