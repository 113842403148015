export interface ServiceStatus {
  serviceStatus: string;
  msg?: string;
}

export interface ComponentApplicationHealth {
  applicationStatus: string;
  servicesStatus?: {
    [key: string]: ServiceStatus;
  };
}

export interface ComponentHealth {
  [key: string]: ComponentApplicationHealth;
}
