import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

const DEBOUNCE_MILLISECONDS = 300;

@Component({
  selector: 'gc-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBoxComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  @Input() placeholder?: string;
  @Input() set focus(value: boolean) {
    if (value) {
      this.focusSearchEl();
    }
  }

  @Output() searchTerm = new EventEmitter<string>();

  @ViewChild('searchTermEl', { static: false }) searchTermEl?: ElementRef;
  readonly searchTermCtrl = new FormControl<string>('', { nonNullable: true });

  ngOnInit(): void {
    this.searchTermCtrl.valueChanges
      .pipe(debounceTime(DEBOUNCE_MILLISECONDS), takeUntil(this.destroy$))
      .subscribe(term => this.searchTerm.emit(term));
  }

  private focusSearchEl(): void {
    if (!this.searchTermEl) {
      return;
    }

    this.searchTermEl?.nativeElement.focus();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
