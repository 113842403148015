import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiResponse, ApiService } from '@guardicore-ui/shared/api';
import { API_URL } from '@guardicore-ui/shared/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GenerateProjectViolationsMapApi, Project, SavedMaps } from '../entities';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService extends ApiService<Project> {
  constructor(@Inject(API_URL) protected override readonly apiUrl: string, protected override readonly http: HttpClient) {
    // Api segment hardcoded until projects screen fully migrated to FE3
    super(apiUrl, http, '/workflow/projects');
  }

  createNewMap(projectId: Project['id'], startTimeFilter: number, endTimeFilter: number): Observable<GenerateProjectViolationsMapApi> {
    const url = `${this.apiUrl}/workflow/generate-project-violations-map`;

    return this.http.post<GenerateProjectViolationsMapApi>(url, {
      project_id: projectId,
      start_time_filter: startTimeFilter,
      end_time_filter: endTimeFilter,
    });
  }

  getSavedMapById(projectId: string, additionalParams?: Record<string, string | number>): Observable<SavedMaps[]> {
    const params = new HttpParams({
      fromObject: {
        ...additionalParams,
      },
    }).set('related_project_id__is', projectId);

    return this.http
      .get<ApiResponse<SavedMaps>>(`${this.apiUrl}/visibility/saved-maps`, { params })
      .pipe(map((response: ApiResponse<SavedMaps>) => response.objects));
  }
}
