import { Pipe, PipeTransform } from '@angular/core';
import { TimeagoDefaultFormatter } from 'ngx-timeago';

import { TimeagoShortFormatter } from './timeago-short-formatter';

@Pipe({
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
  transform(then: number, short = true): string {
    const shortFormatter = new TimeagoShortFormatter();
    const defaultFormatter = new TimeagoDefaultFormatter();

    return !then ? '' : short ? shortFormatter.format(then) : defaultFormatter.format(then);
  }
}
