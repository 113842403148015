import { Inject, Injectable } from '@angular/core';
import { Project, ProjectsService } from '@guardicore-ui/projects/domain';
import { PollingService } from '@guardicore-ui/shared/polling';
import { GridDataSource, GRID_COMPONENT_STORE } from '@guardicore-ui/ui/grid';

import { ProjectsFeatureStore } from './projects.store';

@Injectable()
export class ProjectsGridDataSourceService extends GridDataSource<Project> {
  constructor(
    protected readonly projectsService: ProjectsService,
    @Inject(GRID_COMPONENT_STORE) override readonly store: ProjectsFeatureStore,
    pollingService: PollingService,
  ) {
    super(projectsService, store, pollingService);
  }
}
