import { Injectable } from '@angular/core';
import { SystemStatusState } from '@guardicore-ui/shared/system-status';

import { AuthData, HybridMessageSubject } from '../entities';
import { HybridCommunicationService } from '../infrastructure';

@Injectable({
  providedIn: 'root',
})
export class HybridMainFacade {
  /**
   * Observable of click inside iframe event
   */
  readonly clickOnIframe$ = this.communicationService.clickOnIframe$.asObservable();

  /**
   * Observable of hotkey press inside iframe
   */
  readonly hotkey$ = this.communicationService.hotkey$.asObservable();

  readonly isModalBackdropOpen$ = this.communicationService.isModalBackdropOpen$;

  readonly matDialogModal$ = this.communicationService.matDialogModal$;

  readonly isOldAppLoading$ = this.communicationService.isOldAppLoading$;

  readonly isOldAppServerConnectivityLost$ = this.communicationService.isOldAppServerConnectivityLost$;

  readonly title$ = this.communicationService.title$;

  readonly toastr$ = this.communicationService.toastr$;

  readonly analytics$ = this.communicationService.analytics$;

  readonly dialog$ = this.communicationService.dialog$;

  readonly navigationLink$ = this.communicationService.navigationLink$;

  readonly violationsDialog$ = this.communicationService.violationsDialog$;

  constructor(private readonly communicationService: HybridCommunicationService) {}

  setAuthData(ad: AuthData): void {
    this.communicationService.authData = ad;
  }

  /**
   * Send System status update
   * @param systemStatusState
   */
  sendSystemStatusUpdate(systemStatusState: SystemStatusState): void {
    this.communicationService.sendMessage(HybridMessageSubject.SystemStatusUpdate, systemStatusState, true);
  }

  sendTenantId(id: string | null): void {
    this.communicationService.sendMessage(HybridMessageSubject.TenantId, { id });
  }

  logout(): void {
    this.communicationService.sendMessage(HybridMessageSubject.Logout);
  }

  closeModalBackdrop(): void {
    this.communicationService.closeModalBackdrop();
  }

  sendMinifyState(isMinified: boolean): void {
    this.communicationService.sendMessage(HybridMessageSubject.Minify, { minified: isMinified });
  }

  setIsIframeCollapsed(status: boolean): void {
    this.communicationService.isIframeCollapsed = status;
  }
}
