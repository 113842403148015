export const spaceDots = `<svg width="80" height="24" viewBox="0 0 80 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="80" height="24"/>
<g clip-path="url(#clip0_3091_18557)">
  <circle cx="4.5" cy="4" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="13.5" cy="4" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="22.5" cy="4" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="31.5" cy="4" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="40.5" cy="4" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="49.5" cy="4" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="58.5" cy="4" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="67.5" cy="4" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="76.5" cy="4" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
</g>
<g clip-path="url(#clip1_3091_18557)">
  <circle cx="4.5" cy="12" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="13.5" cy="12" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="22.5" cy="12" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="31.5" cy="12" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="40.5" cy="12" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="49.5" cy="12" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="58.5" cy="12" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="67.5" cy="12" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="76.5" cy="12" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
</g>
<g clip-path="url(#clip2_3091_18557)">
  <circle cx="4.5" cy="20" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="13.5" cy="20" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="22.5" cy="20" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="31.5" cy="20" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="40.5" cy="20" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="49.5" cy="20" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="58.5" cy="20" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="67.5" cy="20" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
  <circle cx="76.5" cy="20" r="0.5" fill="#E5E7FA" fill-opacity="0.81"/>
</g>
<defs>
  <clipPath id="clip0_3091_18557">
    <rect width="80" height="8" fill="white"/>
  </clipPath>
  <clipPath id="clip1_3091_18557">
    <rect width="80" height="8" fill="white" transform="translate(0 8)"/>
  </clipPath>
  <clipPath id="clip2_3091_18557">
    <rect width="80" height="8" fill="white" transform="translate(0 16)"/>
  </clipPath>
</defs>
</svg>`;
