import { ChangeDetectionStrategy, Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { delay, map, repeatWhen, takeWhile, tap, takeUntil } from 'rxjs/operators';

const RETRY_START_SECONDS = 5;
const MAX_RETRY_START_SECONDS = 60;
const TICK_TIME_MILLISECONDS = 1000;

interface ViewModel {
  secondsLeft: number;
  progressBarText: string;
  progressBarValue: number;
  retryTime?: number;
}

@Component({
  selector: 'gc-full-screen-error',
  templateUrl: './full-screen-error.component.html',
  styleUrls: ['./full-screen-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullScreenErrorComponent implements OnInit, OnChanges {
  private readonly secondsLeftStopSubj = new Subject<void>();
  private readonly startCountdown$ = new Subject<void>();
  private retryTime = RETRY_START_SECONDS;
  private secondsLeft = RETRY_START_SECONDS;
  readonly viewModel$ = new BehaviorSubject<ViewModel>({
    secondsLeft: 0,
    progressBarText: '',
    progressBarValue: 100,
  });

  readonly secondsLeft$ = timer(0, TICK_TIME_MILLISECONDS).pipe(
    map(ticksLeft => this.retryTime - ticksLeft),
    takeWhile(ticksLeft => ticksLeft >= 0),
    tap(ticksLeft => {
      this.secondsLeft = Math.round(ticksLeft);

      const secondsLeftString = `${this.secondsLeft} ${this.secondsLeft > 1 ? 'seconds' : 'second'}`;
      const progressBarValue = (ticksLeft / this.retryTime) * 100;

      this.viewModel$.next({
        retryTime: this.retryTime,
        secondsLeft: this.secondsLeft,
        progressBarText: this.secondsLeft > 0 ? `Retrying in ${secondsLeftString}...` : 'Retrying now',
        progressBarValue,
      } as ViewModel);
    }),
    takeUntil(this.secondsLeftStopSubj),
    repeatWhen(() =>
      this.startCountdown$.pipe(
        tap(() => {
          if (this.secondsLeft > 1) {
            return;
          }

          const retryTime = this.retryTime * 2;

          this.retryTime = retryTime > MAX_RETRY_START_SECONDS ? MAX_RETRY_START_SECONDS : retryTime;
        }),
        delay(TICK_TIME_MILLISECONDS),
      ),
    ),
  );

  @Input() restartHash?: number | null;

  @Output() retryNow = new EventEmitter<void>();

  ngOnInit(): void {
    this.secondsLeft$.subscribe(secondsLeft => {
      if (secondsLeft > 0) {
        return;
      }

      this.secondsLeftStopSubj.next();
      this.retryNow.emit();
    });
  }

  ngOnChanges(): void {
    this.secondsLeftStopSubj.next();
    this.startCountdown$.next();
  }

  onRetryNowClick(): void {
    this.secondsLeftStopSubj.next();
    this.retryTime = RETRY_START_SECONDS;
    this.viewModel$.next({
      secondsLeft: 0,
      progressBarValue: 0,
      progressBarText: 'Retrying now',
    });
    this.retryNow.emit();
  }
}
