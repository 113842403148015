import { Component, ChangeDetectionStrategy, Inject, ElementRef, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';

export type ArrayValue = string | number;
const minimumLines = 18;

@Component({
  selector: 'gc-configuration-array-edit-dialog',
  templateUrl: './configuration-array-edit-dialog.component.html',
  styleUrls: ['./configuration-array-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigurationArrayEditDialogComponent implements AfterViewInit {
  arrayString: string;
  isIntegersArray = false;
  readOnly = false;
  min?: number;
  max?: number;
  invalidLines: number[] = [];
  changes: ArrayValue[] = [];
  requirementsText: string;
  codeLines: number[] = [];
  scrollTop?: number;
  @ViewChild('textArea') textArea?: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public config: { title: string; value: ArrayValue[]; field: FormlyFieldConfig },
    public readonly ref: MatDialogRef<ConfigurationArrayEditDialogComponent>,
    private cdr: ChangeDetectorRef,
  ) {
    this.arrayString = this.config.value.join('\n');
    this.codeLines = this.generateCodeLines();
    // TODO: fix!!!
    // this.isIntegersArray = this.config.field.fieldArray?.type === 'integer';
    // this.max = this.config.field.fieldArray?.templateOptions?.max;
    // this.min = this.config.field.fieldArray?.templateOptions?.min;
    // this.readOnly = this.config.field.fieldArray?.templateOptions?.disabled || false;
    this.requirementsText = this.calculateRequirementsTest();
  }

  ngAfterViewInit(): void {
    this.initLineNumbers(this.textArea?.nativeElement as HTMLTextAreaElement);
    this.cdr.detectChanges();
  }

  private initLineNumbers(textArea: HTMLTextAreaElement): void {
    this.scrollTop = textArea.scrollHeight;
    this.arrayString = textArea.value;
    this.codeLines = this.generateCodeLines();
  }

  private generateCodeLines(): number[] {
    return (this.codeLines = Array.from({ length: Math.max(minimumLines, this.arrayString.split('\n').length) }, (_, i) => i + 1));
  }

  private calculateRequirementsTest(): string {
    const text = [];

    if (this.isIntegersArray) {
      text.push('a whole number');
    }

    if (this.min !== undefined) {
      text.push('from ' + this.min);
    }

    if (this.max !== undefined) {
      text.push('to ' + this.max);
    }

    return text.length ? `${text.join(' ')}.` : '';
  }

  inputChanged(event: Event): void {
    this.initLineNumbers(event.target as HTMLTextAreaElement);
  }

  closeWithoutChanges(): void {
    this.ref.close();
  }

  apply(): void {
    if (!this.isListChanged()) {
      this.closeWithoutChanges();

      return;
    }

    this.changes = this.arrayString.length ? this.arrayString.split('\n') : [];
    this.scrollTop = 0;

    if (!this.formatChangesAndValidate()) {
      this.generateCodeLines();
      this.cdr.detectChanges();

      return;
    }

    this.ref.close(this.changes.length ? this.changes : null);
  }

  clear(): void {
    this.arrayString = '';
    this.invalidLines = [];
    this.generateCodeLines();
  }

  private isListChanged(): boolean {
    this.arrayString = this.arrayString.replace(/\n\s*\n/g, '\n').trim();

    return this.arrayString !== this.config.value.join('\n');
  }

  private formatChangesAndValidate(): boolean {
    this.invalidLines = [];

    this.changes = this.changes.map((value: ArrayValue, index) => {
      let isValid = true;

      if (this.isIntegersArray) {
        isValid = /^\d+$/.test(value as string);
        value = parseInt(value as string);
      }

      // TODO: Logic is broken
      // if (isValid && this.max !== undefined) {
      //   isValid = value <= this.max;
      // }

      // if (isValid && this.min !== undefined) {
      //   isValid = value >= this.min;
      // }

      if (!isValid) {
        this.invalidLines.push(index + 1);
      }

      return value;
    });

    return this.invalidLines.length === 0;
  }
}
