import {
  Component,
  ChangeDetectionStrategy,
  ContentChildren,
  QueryList,
  AfterContentInit,
  Output,
  EventEmitter,
  HostListener,
  OnDestroy,
  Input,
} from '@angular/core';
import { KeyCode } from '@guardicore-ui/shared/data';
import { focusNextQueryListItem, focusPreviousQueryListItem } from '@guardicore-ui/shared/utils';
import { PopoverRef } from '@guardicore-ui/ui/popovers/popover';
import { merge, Subject } from 'rxjs';
import { map, tap, takeUntil } from 'rxjs/operators';

import { ActionListItemComponent } from './action-list-item/action-list-item.component';

@Component({
  selector: 'gc-action-list',
  templateUrl: './action-list.component.html',
  styleUrls: ['./action-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionListComponent<T> implements AfterContentInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  @ContentChildren(ActionListItemComponent) private itemsQueryList?: QueryList<ActionListItemComponent<T>>;
  @Input() popoverRef?: PopoverRef;
  @Output() readonly itemClick = new EventEmitter<T>();

  private focusedItemIndex?: number;

  @HostListener('keydown', ['$event'])
  handleKeydown(event: KeyboardEvent): void {
    if (!this.itemsQueryList) {
      return;
    }

    switch (event.code as KeyCode) {
      case 'ArrowDown':
        this.clearFocusFromItems();
        this.focusedItemIndex = focusNextQueryListItem<ActionListItemComponent<T>>(
          this.focusedItemIndex === undefined ? -1 : this.focusedItemIndex,
          this.itemsQueryList,
        );
        break;
      case 'ArrowUp':
        this.clearFocusFromItems();
        this.focusedItemIndex = focusPreviousQueryListItem<ActionListItemComponent<T>>(
          this.focusedItemIndex === undefined ? 0 : this.focusedItemIndex,
          this.itemsQueryList,
        );
        break;
      case 'Space':
      case 'Enter':
      case 'NumpadEnter': {
        if (this.focusedItemIndex === undefined) {
          break;
        }

        const focusedItem = this.itemsQueryList.get(this.focusedItemIndex);

        if (focusedItem?.itemId) {
          this.emitItemClick(focusedItem.itemId);
        }

        this.focusedItemIndex = undefined;
        this.clearFocusFromItems();
        break;
      }
    }

    if (this.focusedItemIndex === undefined) {
      return;
    }
  }

  ngAfterContentInit(): void {
    if (!this.itemsQueryList?.length) {
      return;
    }

    this.itemsQueryList.forEach((item, index) => {
      if (!item.itemId) {
        throw new Error(`[itemId] wasn't set on list item #${index + 1}`);
      }
    });

    merge(...this.itemsQueryList.map((item, index) => item.click$.pipe(map(() => ({ index, item })))))
      .pipe(
        tap(({ item }) => {
          if (item.itemId && !item.disabled) {
            this.emitItemClick(item.itemId);
            this.focusedItemIndex = undefined;
            this.clearFocusFromItems();
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.itemsQueryList.last.showDivider = false;
  }

  private clearFocusFromItems(): void {
    if (!this.itemsQueryList) {
      return;
    }

    this.itemsQueryList.forEach(item => (item.isFocused = false));
  }

  private emitItemClick(value: T): void {
    this.itemClick.emit(value);
    this.popoverRef?.close(value);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
