import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, filter, switchMap, throwError, timer } from 'rxjs';

import { PollingService } from './polling.service';

@Injectable()
export class DynamicIntervalService extends PollingService {
  static defaultInterval = 30_000;
  static fastInterval = 10_000;
  private _interval$: BehaviorSubject<number> = new BehaviorSubject<number>(DynamicIntervalService.defaultInterval);

  interval$ = this._interval$.asObservable().pipe(
    switchMap(duration => timer(0, duration)),
    filter(() => this._arePollingsAllowed),
    catchError(() => {
      return throwError(() => new Error(`Error`));
    }),
  );

  changeInterval(interv: number): void {
    this._interval$.next(interv);
  }

  get interval(): number {
    return this._interval$.getValue();
  }
}
