import { ComponentRef } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { ToastrContainerComponent } from './toastr-container/toastr-container.component';

export class ToastrRef<T> {
  componentInstance: T | undefined;
  afterClose: Observable<void>;
  clicked: Observable<void>;

  constructor(private toastrContainerRef: ComponentRef<ToastrContainerComponent>) {
    this.clicked = this.toastrContainerRef.instance.clicked;
    this.afterClose = this.toastrContainerRef.instance.afterClose.pipe(shareReplay(1));
    this.afterClose.subscribe(() => this.toastrContainerRef.destroy());
  }

  close(): void {
    this.toastrContainerRef.instance.close();
  }
}
