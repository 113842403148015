import { TimeagoDefaultFormatter } from 'ngx-timeago';

export class TimeagoShortFormatter extends TimeagoDefaultFormatter {
  override format(then: number): string {
    return super
      .format(then)
      .replace(/^about/, '')
      .replace(/ weeks/, 'w')
      .replace(/ week/, 'w')
      .replace(/ days/, 'd')
      .replace(/ day/, 'd')
      .replace(/ hours/, 'h')
      .replace(/ hour/, 'h')
      .replace(/ minutes/, 'm')
      .replace(/ minute/, 'm')
      .replace(/ seconds/, 's')
      .replace(/ second/, 's')
      .replace(/less than a/, '1')
      .replace(/from now$/, '');
  }
}
