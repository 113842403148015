import { CanBeSorted } from './can-be-sorted';
import { HasEntities } from './has-entities';
import { HasFilters } from './has-filters';
import { HasPagination } from './has-pagination';
import { RowDataObject } from '../../core-types';
import { createDefaultPageState } from '../../interfaces';

export type FeatureWithPaginationAndFiltersState<T extends RowDataObject = RowDataObject> = HasFilters &
  HasPagination &
  CanBeSorted &
  HasEntities<T>;

export function createInitialFeatureWithPaginationAndFiltersState(): FeatureWithPaginationAndFiltersState {
  return {
    pageState: createDefaultPageState(),
    filtersState: {},
    sortState: {},
    entities: [],
  };
}

export const DEFINED_QUERY_PARAMS = ['offset', 'limit', 'sort'];
