import { Injectable } from '@angular/core';
import { FilterOptionsSource, FilterOptions, FilterDataSource } from '@guardicore-ui/filters/shared';
import { Observable, map } from 'rxjs';

import { MsspService } from '../infrastructure';

@Injectable()
export class TenantsAssignmentOptionsSource extends FilterOptionsSource {
  constructor(private readonly msspService: MsspService, private readonly dataSource: FilterDataSource) {
    super();
  }

  getFilterOptions(): Observable<FilterOptions> {
    return this.msspService.getTenantClustersFilterOptions().pipe(this.processOptions());
  }

  search(search: string): Observable<FilterOptions> {
    return this.msspService.getTenantClustersFilterOptions({ filter_value: search }).pipe(this.processOptions());
  }

  loadMore(offset: number): Observable<FilterOptions> {
    return this.msspService.getTenantClustersFilterOptions({ offset }).pipe(this.processOptions());
  }

  private processOptions(): (options$: Observable<FilterOptions>) => Observable<FilterOptions> {
    return (options$: Observable<FilterOptions>): Observable<FilterOptions> =>
      options$.pipe(
        map(fo => ({ ...fo, availableOptions: [...fo.availableOptions, ...this.dataSource.getFilterValue()] })),
        map(fo => ({ ...fo, totalCount: fo.availableOptions.length + fo.selectedOptions.length })),
      );
  }
}
