<form *ngIf="changePasswordForm" [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
  <div class="gc-dialog-content">
    <div class="gc-dialog-content-header">
      <h3>Change Password</h3>
      <button class="header-close-button" type="button" (click)="dialogRef.close()">
        <gc-icon svgIcon="close"></gc-icon>
      </button>
    </div>
    <div class="gc-dialog-content-body">
      <div class="form-field">
        <div class="form-field__label" [ngClass]="(currentPasswordCtrl?.dirty || currentPasswordCtrl?.touched) && currentPasswordCtrl?.invalid ? 'text-danger' : ''">
          Current password:
        </div>
        <div class="form-field__input">
          <gc-form-field fullWidth>
            <input automation-id="change-password__current-password-field" type="password" formControlName="currentPassword" gcInput />
          </gc-form-field>
          <div class="input-error-label">
            <p automation-id="change-password__current-password-missing-label" class="text-danger" *ngIf="(currentPasswordCtrl?.dirty || currentPasswordCtrl?.touched) && currentPasswordCtrl?.invalid">Current password is required</p>
          </div>
        </div>
      </div>
      <div class="form-field password-input">
        <div class="form-field__label" [ngClass]="(passwordCtrl?.dirty || passwordCtrl?.touched) && passwordCtrl?.invalid ? 'text-danger' : ''">
          New password:
        </div>
        <div class="form-field__input">
          <gc-form-field fullWidth>
            <input automation-id="change-password__password-field" type="password" formControlName="password" gcInput (focus)="isPasswordTouched = true" />
          </gc-form-field>
          <div class="input-error-label">
            <gc-password-requirements-status
              *ngIf="isPasswordTouched || passwordCtrl?.value?.length"
              [validationErrors]="passwordCtrl?.errors"
              [passwordRequirements]="passwordRequirements"
            ></gc-password-requirements-status>
          </div>
        </div>
      </div>
      <div class="form-field">
        <div class="form-field__label" [ngClass]="(passwordConfirmationCtrl?.dirty || passwordConfirmationCtrl?.touched) && passwordConfirmationCtrl?.invalid ? 'text-danger' : ''">
          Confirm password:
        </div>
        <div class="form-field__input">
          <gc-form-field fullWidth>
            <input automation-id="change-password__password-confirmation-field" type="password" formControlName="passwordConfirmation" gcInput />
          </gc-form-field>
          <div class="input-error-label text-danger">
            <p automation-id="change-password__password-mismatch-label" class="text-danger" *ngIf="(passwordConfirmationCtrl?.dirty || passwordConfirmationCtrl?.touched) && passwordConfirmationCtrl?.invalid">
              Passwords do not match
            </p>
          </div>
        </div>
      </div>
      <div class="error-block" *ngIf="error | async as errorLabel">
        <gc-error-block automation-id="change-password__error-message">
          {{ errorLabel }}
        </gc-error-block>
      </div>
    </div>
    <div class="gc-dialog-content-footer">
      <button class="default-button" type="button" (click)="dialogRef.close()" automation-id="change-password__cancel-button">
        {{ dismissButtonLabel }}
      </button>
      <button class="primary-button" [disabled]="changePasswordForm.invalid || busy" type="submit" automation-id="change-password__ok-button">
        {{ submitButtonLabel }}
      </button>
    </div>
  </div>
</form>

