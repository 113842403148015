<div class="gc-dialog-content">
  <form [formGroup]="form" (ngSubmit)="apply()">
    <div class="gc-dialog-content-header">
      <h3 automation-id="export-csv-title">Export to CSV</h3>
      <button class="header-close-button" type="button" (click)="close()">
        <gc-icon svgIcon="close"></gc-icon>
      </button>
    </div>
    <div class="gc-dialog-content-body">
      <p class="top-message" automation-id="csv-export__header">
        <span *ngIf="config.header else defaultHeader">{{ config.header }}</span>
        <ng-template #defaultHeader>
          <span>There are {{ totalItems }} records matching the current filter.</span>
        </ng-template>
      </p>

      <ng-container *ngIf="config.showRangeOptions">
        <div class="input-row small-margin" automation-id="csv-export__range-input-all">
          <input type="radio" id="export-all" formControlName="exportChoice" name="exportChoice" [value]="exportChoice.all">
          <label for="export-all">{{ exportAllMessage }}</label>
        </div>
        <div class="input-row" automation-id="csv-export__range-input-range">
          <input automation-id="csv-export__range-input-specific" type="radio" id="export-range" formControlName="exportChoice" name="exportChoice" [value]="exportChoice.range">
          <label for="export-range">
            <span>Export records</span>
            <input type="number" min="1" formControlName="exportRangeStart" automation-id="csv-export__range-input-start">
            <span>-</span>
            <input type="number" min="1" formControlName="exportRangeEnd" automation-id="csv-export__range-input-end">
            <span>total of {{ totalRecords$ | async }} records</span>
          </label>
        </div>
      </ng-container>

      <ng-container *ngIf="config.showFormattingOptions">
        <p class="input-row-title" [class.disabled]="initialStateLoading">Formatting:</p>
        <div class="input-row">
          <input type="checkbox" id="compress" formControlName="compress" automation-id="csv-export__formatting-input">
          <label for="compress">Compress results to Zip file</label>
        </div>
      </ng-container>

      <div *ngIf="exportTaskStatus$ | async as exportStatus"
           [ngSwitch]="exportStatus.state"
           class="export-status"
           [class.error]="exportStatus.state === exportState.error"
           automation-id="csv-export__export-status"
      >

        <ng-container *ngSwitchCase="exportState.ready">
          <div>
            <div class="status-with-icon" automation-id="status_with_icon_text">
              <gc-icon svgIcon="confirm"></gc-icon>
              <p>Report is ready</p>
            </div>
            <p class="export-status-subtitle">Generated by {{ exportStatus.author.username }} at {{ exportStatus.creationTime | date: 'yyyy-MM-dd HH:mm:ss' }}</p>
          </div>
          <button class="text-button download-button" type="button" (click)="download()">
            <gc-icon svgIcon="download"></gc-icon>
            <span>Download</span></button>
        </ng-container>

        <ng-container *ngSwitchCase="exportState.inProgress">
          <div class="status-with-icon" automation-id="status_with_icon_text">
            <gc-icon svgIcon="spinner"></gc-icon>
            <p>Generating report: {{ exportStatus.recordsWritten }} of {{ exportStatus.totalRecords }} completed</p>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="exportState.error">
          <div>
            <div class="status-with-icon" automation-id="status_with_icon_text">
              <gc-icon svgIcon="alert"></gc-icon>
              <p>Report Failed</p>
            </div>
            <p class="export-status-subtitle">{{exportStatus.reason}}</p>
          </div>
        </ng-container>

      </div>
    </div>
    <div class="gc-dialog-content-footer">
      <gc-icon *ngIf="initialStateLoading" svgIcon="spinner"></gc-icon>
      <button
        type="button"
        class="default-button"
        (click)="close()"
        automation-id="csv-export__close-button">
          Close
      </button>
      <button
        type="submit"
        class="primary-button"
        [disabled]="initialStateLoading"
        automation-id="csv-export__export-button">
          {{ pendingTask ? 'New Report' : 'Export' }}
      </button>
    </div>
  </form>
  <div class="dropdown-message" [class.down]="(exportTaskStatus$ | async)?.state === exportState.inProgress">
    <gc-icon svgIcon="info"></gc-icon>
    <p automation-id="csv_export__status__msg">You can close the window and continue working while the report is being generated</p>
  </div> 
</div>

