export const turnedOn = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 4H4C1.79086 4 0 5.79086 0 8C0 10.2091 1.79086 12 4 12H12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4Z" fill="#4B70D0"/>
<g filter="url(#filter0_d_3649_29590)">
<path d="M11.8 11C13.4568 11 14.8 9.65685 14.8 8C14.8 6.34315 13.4568 5 11.8 5C10.1431 5 8.79999 6.34315 8.79999 8C8.79999 9.65685 10.1431 11 11.8 11Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d_3649_29590" x="6.79999" y="5" width="8" height="8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-1" dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3649_29590"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3649_29590" result="shape"/>
</filter>
</defs>
</svg>
`;
