import { FiltersState } from '@guardicore-ui/shared/data';

export enum CsvExportState {
  created,
  inProgress,
  ready,
  resolved,
  error,
  canceled,
}

export interface CSVExportTask {
  id: string;
  recordsWritten: number;
  totalRecords: number;
  reason?: string;
  exportedCsvFileId: string;
  state: CsvExportState;
  creationTime: number;
  author: { description: string; username: string };
}

export interface MaxRecordsNumResponse {
  maxRecords: number;
}

export interface NewCsvExportTaskId {
  exportTaskStatusId: string;
}

export interface CSVExportConfigBase {
  viewName: string;
  exportUrl?: string;
  showFormattingOptions?: boolean;
  showRangeOptions?: boolean;
  header?: string;
}

export interface CSVExportConfig extends CSVExportConfigBase {
  totalItems: number;
  filters?: FiltersState;
}
