import { SafeResourceUrl } from '@angular/platform-browser';
import { IconTypes } from '@guardicore-ui/shared/data';

export enum IconType {
  SVG = 'svg',
  FONT = 'font',
}

export interface IconOptions {
  viewBox?: string;
  withCredentials?: boolean;
}

export declare type IconResolver = (name: string, namespace: string) => IconConfig | null;

export interface IconConfig {
  url: SafeResourceUrl;
  options: IconOptions;
}

export class SvgIconConfig {
  url: SafeResourceUrl;
  svgText: string | null;
  options?: IconOptions;
  svgElement?: SVGElement;

  constructor(url: SafeResourceUrl, svgText: string | null, options?: IconOptions) {
    this.url = url;
    this.svgText = svgText;
    this.options = options;
  }
}

export interface SvgIconItem extends Omit<SvgIconConfig, 'url' | 'svgText'> {
  url: string;
  type: IconTypes;
  svgText?: string | null;
}
