import { FiltersState, RowDataObject } from '@guardicore-ui/shared/data';

export type ApiResponseFilter = Record<string, string | number | boolean>;

export interface ApiResponse<T extends RowDataObject> {
  objects: T[];
  totalCount: number;
  currentPage: number;
  resultsInPage: number;
  from: number;
  to: number;
  filter: ApiResponseFilter;
  resolvedFilters?: FiltersState;
  sort: string[] | null;
}
