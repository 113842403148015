import { ComponentHarness, TestElement } from '@angular/cdk/testing';

export class FiltersFooterHarness extends ComponentHarness {
  static hostSelector = 'gc-filters-footer';

  protected buttons = this.locatorForAll('button');

  async getCancelButton(): Promise<TestElement> {
    return (await this.buttons())[0];
  }

  async getOkButton(): Promise<TestElement> {
    return (await this.buttons())[1];
  }

  async clickCancelButton(): Promise<void> {
    const cancelButton = await this.getCancelButton();

    await cancelButton.click();
  }

  async clickOkButton(): Promise<void> {
    const okButton = await this.getOkButton();

    await okButton.click();
  }
}
