import { Provider } from '@angular/core';
import { Constructor } from '@guardicore-ui/shared/data';

import { FilterDataSource } from './filter-data-source';
import { FilterOptionsSource } from './filter-options-source';

export function provideFilters(dataSource: Constructor<FilterDataSource>, optionsSource?: Constructor<FilterOptionsSource>): Provider[] {
  const providers: Provider[] = [{ provide: FilterDataSource, useClass: dataSource }];

  if (optionsSource) {
    providers.push({ provide: FilterOptionsSource, useClass: optionsSource });
  }

  return providers;
}
