<div class="wrapper" [ngClass]="{ 'selection-container': !isDisabled, 'selection-container-disabled': isDisabled }" [gcPopoverClickAnchor]="clickPopoverContent"
  [aligns]="{ vertical: 'below', horizontal: 'start' }" [margin]="6" panelCssClass="dropdown-filter"
  #container>
  <div class="selection-checkbox-container selection-checkbox-light" #checkboxContainer>
    <input id="Selection-main-checkbox" type="checkbox" [checked]="isMarked" [indeterminate]="isIndeterminate" [disabled]="isDisabled"
      (change)="toggleSelection($any($event.target).checked)" />
    <label for="Selection-main-checkbox"></label>
  </div>
  <div class="selection-arrow-container" [ngClass]="{ 'selection-arrow-disabled': isDisabled }" (click)="toggleDropdown()" #arrowContainer>
    <gc-icon svgIcon="caret-down"></gc-icon>
  </div>
</div>

<ng-template #clickPopoverContent>
  <div class="select-dropdown">
    <gc-action-list (itemClick)="runDropdownAction($event)">
      <button action-list-item *ngFor="let option of options; trackBy: trackBy;let last = last" [showDivider]="!last"
        [itemId]="option.value">{{option.text}}</button>
    </gc-action-list>
  </div>
</ng-template>