import { FilterParameters } from '@guardicore-ui/shared/data';

export interface ExtendedSelection {
  unselected: string[];
  filters: FilterParameters;
  quantity: number;
}

export interface SelectionData {
  selected: string[];
  total: number;
  extendedSelection?: ExtendedSelection | undefined;
}
