/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable max-lines */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, NotAuthGuard } from '@guardicore-ui/authentication/domain';
import { RbacGuard } from '@guardicore-ui/shared/rbac';

import { OldAppContainerComponent } from './old-app-container/old-app-container.component';

const routes: Routes = [
  {
    path: 'login',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    loadChildren: () => import('@guardicore-ui/authentication/feature-login-page').then(m => m.AuthenticationFeatureLoginPageModule),
    data: { isPublicRoute: true, title: 'Guardicore Centra' },
    canLoad: [NotAuthGuard],
  },
  {
    path: 'overview',
    canActivateChild: [AuthGuard],
    data: {
      label: 'Centra',
    },
    children: [
      {
        path: 'security-dashboard',
        component: OldAppContainerComponent,
        data: {
          navState: 'main.overview.dashboard',
          viewName: 'Dashboard',
          quickLinks: ['Network Log', 'Explore', 'Agents'],
        },
        canActivate: [RbacGuard],
      },
      {
        path: 'cluster-manager',
        loadChildren: () => import('@guardicore-ui/cluster-manager/features').then(m => m.ClusterManagerFeaturesModule),
        data: {
          viewName: 'Centra Cluster',
          quickLinks: ['Network Log', 'Explore', 'Agents'],
        },
        canLoad: [AuthGuard],
      },
      {
        path: 'network-log',
        component: OldAppContainerComponent,
        data: {
          navState: 'main.overview.networkLog',
          viewName: 'Network Log',
          quickLinks: ['Explore', 'Segmentation Rules', 'Agents'],
          useMssp: true,
        },
        canActivate: [RbacGuard],
      },
      {
        path: 'reveal',
        data: {
          label: 'Reveal',
        },
        children: [
          {
            path: 'explore',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.overview.visibility.explore',
              viewName: 'Explore',
              quickLinks: ['Network Log', 'Saved Maps', 'Segmentation Rules'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'labels',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.overview.visibility.labels',
              viewName: 'Labels',
              quickLinks: ['Assets', 'Explore', 'Segmentation Rules'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'saved-maps',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.overview.visibility.saved_maps',
              viewName: 'Saved Maps',
              quickLinks: ['Explore', 'Segmentation Rules', 'Network Log'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: '',
            redirectTo: 'explore',
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'activity',
        data: {
          label: 'Activity',
        },
        children: [
          {
            path: 'integrity-log',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.overview.activity.fimLog',
              viewName: 'Integrity Log',
              label: 'FIM Log',
              quickLinks: ['All Incidents', 'Integrity Monitoring'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'labels-log',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.overview.activity.labelsLog',
              viewName: 'Labels Log',
              quickLinks: ['Labels', 'Label Groups', 'Assets'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'redirections-log',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.overview.activity.redirectionsLog',
              viewName: 'Redirection Log',
              label: 'Deception Log',
              quickLinks: ['All Incidents', 'Inspection Policy'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'reputation-log',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.overview.activity.reputationLog',
              viewName: 'Reputation Log',
              label: 'Reputation Log',
              quickLinks: ['All Incidents', 'Reputation'],
            },
            canActivate: [RbacGuard],
          },
          { path: '', redirectTo: 'network-log', pathMatch: 'full' },
        ],
      },
      {
        path: 'hunt',
        component: OldAppContainerComponent,
        data: {
          navState: 'main.overview.hunt.allEvents',
          viewName: 'Security Events',
          label: 'Hunt',
          quickLinks: ['All Incidents', 'Network Log', 'Explore'],
        },
        children: [
          {
            path: 'incident/:incidentID',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.overview.hunt.event',
              viewName: 'Security Event',
              label: ':incidentID',
              quickLinks: ['All Incidents', 'Network Log', 'Explore'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'incident-preview',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.overview.hunt.incident-preview',
              viewName: 'Security Event Preview',
            },
            canActivate: [RbacGuard],
          },
        ],
        canActivate: [RbacGuard],
      },
      {
        path: 'incidents',
        component: OldAppContainerComponent,
        data: {
          navState: 'main.overview.incidents.allEvents',
          viewName: 'All Incidents',
          quickLinks: ['Network Log', 'Explore'],
          label: 'Incidents',
        },
        children: [
          {
            path: ':incidentID',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.overview.incidents.incidentView',
              viewName: 'Incident',
              quickLinks: ['Network Log', 'Explore'],
            },
            canActivate: [RbacGuard],
          },
        ],
        canActivate: [RbacGuard],
      },
      {
        path: 'inventory',
        data: {
          label: 'Inventory',
        },
        children: [
          {
            path: 'assets',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.overview.inventory.assets',
              viewName: 'Assets',
              quickLinks: ['Labels', 'Agents', 'Network Log'],
            },
            canActivate: [RbacGuard],
            children: [
              {
                path: ':assetID',
                component: OldAppContainerComponent,
                data: {
                  navState: 'main.overview.inventory.assetView',
                  viewName: 'Asset',
                  quickLinks: ['Labels', 'Agents', 'Network Log'],
                },
                canActivate: [RbacGuard],
              },
            ],
          },
          {
            path: 'kubernetes-clusters',
            loadChildren: () =>
              import('@guardicore-ui/kubernetes-clusters/features/kubernetes-clusters').then(m => m.KubernetesClustersModule),
            data: {
              label: 'K8s Clusters',
              viewName: 'Kubernetes Clusters',
              quickLinks: ['Agents', 'Orchestrations'],
            },
          },
          {
            path: 'agents',
            data: {},
            children: [
              {
                path: 'agents',
                component: OldAppContainerComponent,
                data: {
                  navState: 'main.overview.inventory.agents.agents',
                  viewName: 'Agents',
                  quickLinks: ['Assets', 'Network Log', 'Aggregators'],
                },
                canActivate: [RbacGuard],
              },
              {
                path: 'agents-installation',
                component: OldAppContainerComponent,
                data: {
                  navState: 'main.overview.inventory.agents.agentsInstallation',
                  viewName: 'Agent Installation',
                  label: 'Agent Installation Instructions',
                  quickLinks: ['Agents', 'Agent Installation Profiles', 'Aggregators'],
                },
                canActivate: [RbacGuard],
              },
              {
                path: 'agent-installation-profiles',
                component: OldAppContainerComponent,
                data: {
                  navState: 'main.overview.inventory.agents.agentInstallationProfiles',
                  viewName: 'Agent Installation Profiles',
                  label: 'Installation Profiles',
                  quickLinks: ['Agents', 'Agent Installation', 'Agent Upgrade History'],
                },
                canActivate: [RbacGuard],
              },
              {
                path: 'agent-upgrade-history',
                component: OldAppContainerComponent,
                data: {
                  navState: 'main.overview.inventory.agents.agent-upgrade-history',
                  viewName: 'Agent Upgrade History',
                  quickLinks: ['Agents', 'Agents Log', 'Assets'],
                },
                canActivate: [RbacGuard],
              },
              {
                path: 'logs',
                component: OldAppContainerComponent,
                data: {
                  navState: 'main.overview.inventory.agents.logs',
                  viewName: 'Agents Log',
                  quickLinks: ['Agents', 'Agent Installation Profiles', 'Assets'],
                },
                canActivate: [RbacGuard],
              },
              {
                path: 'agents-new',
                loadChildren: () => import('@guardicore-ui/agents/features').then(m => m.AgentsFeaturesModule),
                data: {
                  viewName: 'Agents',
                  quickLinks: ['Assets', 'Network Log', 'Aggregators'],
                  v3: true,
                },
                canLoad: [AuthGuard],
              },
              { path: '', redirectTo: '/overview/inventory/agents/agents', pathMatch: 'full' },
            ],
          },
          {
            path: 'cloud-inventory',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.overview.inventory.cloud',
              viewName: 'Cloud',
              label: 'Azure',
              quickLinks: ['Log', 'Orchestrations'],
            },
            canActivate: [RbacGuard],
          },
          { path: '', redirectTo: 'assets', pathMatch: 'full' },
        ],
      },
      {
        path: 'labs',
        data: {},
        children: [
          {
            path: 'dns-requests',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.overview.labs.dnsRequests',
              viewName: 'DNS Requests',
            },
            canActivate: [RbacGuard],
          },
          { path: '', redirectTo: 'dns-requests', pathMatch: 'full' },
        ],
      },
      {
        path: 'segmentation',
        data: {
          label: 'Policy',
        },
        children: [
          {
            path: 'dns-security',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.overview.policy.dns_security',
              viewName: 'DNS Security',
              quickLinks: ['Network Log', 'Explore'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'create-policy',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.overview.policy.project-store',
              viewName: 'Create Policy',
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'projects',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.overview.policy.projects',
              viewName: 'Projects',
              quickLinks: ['Segmentation Rules', 'Create Policy', 'Network Log'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'policy-revisions',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.overview.policy.revisions',
              viewName: 'Revisions',
              quickLinks: ['Segmentation Rules', 'Network Log', 'Agents'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'revisions',
            loadChildren: () => import('@guardicore-ui/revisions/features/revisions').then(m => m.RevisionsModule),
            data: {},
          },
          {
            path: 'segmentation-policy',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.overview.policy.segmentation',
              viewName: 'Segmentation Rules',
              label: 'Rules',
              quickLinks: ['Create Policy', 'Network Log', 'Explore'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'label-groups',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.overview.policy.label_groups',
              viewName: 'Label Groups',
              quickLinks: ['Labels', 'Assets', 'Segmentation Rules'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'user-groups',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.overview.policy.user_groups',
              viewName: 'User Groups',
              quickLinks: ['Create Policy', 'Segmentation Rules', 'Label Groups'],
            },
            canActivate: [RbacGuard],
          },
          { path: '', redirectTo: 'segmentation-policy', pathMatch: 'full' },
        ],
      },
      {
        path: 'guardicore-query',
        data: {
          label: 'Insight',
        },
        children: [
          {
            path: 'run',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.overview.guardicore-query.run',
              viewName: 'Guardicore Query',
              label: 'Run Query',
              quickLinks: ['Scheduled Queries', 'Agents', 'Labels'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'scheduled',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.overview.guardicore-query.scheduled',
              viewName: 'Scheduled Queries',
              quickLinks: ['Guardicore Query', 'Agents', 'Labels'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: '',
            redirectTo: 'run',
            pathMatch: 'full',
          },
        ],
      },
    ],
  },
  {
    path: 'administration',
    canActivateChild: [AuthGuard],
    data: {
      label: 'Administration',
    },
    children: [
      {
        path: 'inspection-policy',
        component: OldAppContainerComponent,
        data: {
          navState: 'main.administration.inspectionPolicy',
          viewName: 'Inspection Policy',
          label: 'Deception Policy',
          quickLinks: ['All Incidents', 'Redirection Log'],
        },
        canActivate: [RbacGuard],
      },
      {
        path: 'detection',
        data: {
          label: 'Detection',
        },
        component: OldAppContainerComponent,
        children: [
          {
            path: 'detectors',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.administration.detection.detectors',
              viewName: 'Detection',
              label: 'Detectors',
              quickLinks: ['All Incidents', 'Integrity Monitoring', 'Inspection Policy'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'reputation',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.administration.detection.reputation',
              viewName: 'Reputation',
              label: 'Reputation Policy',
              quickLinks: ['All Incidents', 'Reputation Log'],
            },
            canActivate: [RbacGuard],
          },
          { path: '', redirectTo: 'detectors', pathMatch: 'full' },
        ],
      },
      {
        path: 'integrity-monitor',
        data: {
          label: 'Integrity Monitoring',
        },
        children: [
          {
            path: 'templates',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.administration.fim.templates',
              viewName: 'Integrity Monitoring',
              label: 'FIM Policy',
              quickLinks: ['All Incidents', 'Integrity Log'],
            },
            canActivate: [RbacGuard],
          },
          { path: '', redirectTo: 'templates', pathMatch: 'full' },
        ],
      },
      {
        path: 'mitigation',
        component: OldAppContainerComponent,
        data: {
          navState: 'main.administration.mitigation',
          viewName: 'Mitigation',
          label: 'Mitigation & IoCs',
          quickLinks: ['All Incidents', 'Collectors', 'Deception Servers'],
        },
        canActivate: [RbacGuard],
      },
      {
        path: 'components',
        data: {
          label: 'Components',
        },
        children: [
          {
            path: 'deception-servers',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.administration.components.honeypots',
              viewName: 'Deception Servers',
              quickLinks: ['Aggregators', 'Collectors', 'Agents'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'collectors',
            loadChildren: () => import('@guardicore-ui/collectors/features').then(m => m.CollectorsFeaturesModule),
            canLoad: [AuthGuard],
            data: {
              viewName: 'Collectors',
              quickLinks: ['Aggregators', 'Deception Servers', 'Agents'],
            },
          },
          {
            path: 'aggregators',
            loadChildren: () => import('@guardicore-ui/aggregators/features').then(m => m.AggregatorsFeaturesModule),
            canLoad: [AuthGuard],
            data: {
              viewName: 'Aggregators',
              useMssp: true,
            },
          },
          {
            path: 'cloud-app',
            loadChildren: () => import('@guardicore-ui/cloud-app/features').then(m => m.CloudAppFeaturesModule),
            data: {
              viewName: 'Cloud App',
              quickLinks: ['Assets', 'Network Log', 'Agents'],
              v3: true,
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'agent-aggregators',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.administration.components.agentAggregators',
              viewName: 'Aggregators',
              useMssp: true,
              quickLinks: ['Collectors', 'Deception Servers', 'Agents'],
            },
            canActivate: [RbacGuard],
          },
          { path: '', redirectTo: 'aggregators', pathMatch: 'full' },
        ],
      },
      {
        path: 'datacenter',
        data: {
          label: 'Data Center',
        },
        children: [
          {
            path: 'orchestration',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.administration.dataCenter.orchestration',
              viewName: 'Orchestrations',
              quickLinks: ['Aggregators', 'Deployment', 'Agents'],
              useMssp: true,
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'deployment',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.administration.dataCenter.deployment',
              viewName: 'Deployment',
              quickLinks: ['Log', 'Orchestrations', 'Collectors'],
            },
            canActivate: [RbacGuard],
          },
          { path: '', redirectTo: 'orchestration', pathMatch: 'full' },
        ],
      },
      {
        path: 'integrations',
        data: {
          label: 'Integrations',
        },
        children: [
          {
            path: 'syslog',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.administration.integrations.syslog',
              viewName: 'Syslog',
              quickLinks: ['Log', 'Auditing'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'slack',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.administration.integrations.slack',
              viewName: 'Slack',
              quickLinks: ['Log', 'Auditing'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'email',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.administration.integrations.email',
              viewName: 'Email',
              quickLinks: ['Log', 'Auditing'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'firewalls',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.administration.integrations.firewalls',
              viewName: 'Firewalls',
              quickLinks: ['Log', 'Auditing'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'azure_threat_feed',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.administration.integrations.azure_threat_feed',
              viewName: 'Azure Threat Feed',
              quickLinks: ['Log', 'Auditing'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'interflow',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.administration.integrations.interflow',
              viewName: 'Interflow',
              quickLinks: ['Log', 'Auditing'],
            },
            canActivate: [RbacGuard],
          },
          { path: '', redirectTo: 'syslog', pathMatch: 'full' },
        ],
      },
      {
        path: 'user-management',
        data: {
          label: 'User Management',
        },
        children: [
          {
            path: 'users',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.administration.userManagement.users',
              viewName: 'Users',
              quickLinks: ['Permission Schemes', 'User Directories', 'Log'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'user-directories',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.administration.userManagement.userDirectories',
              viewName: 'User Directories',
              label: 'User Providers',
              quickLinks: ['Users', 'Permission Schemes', 'Log'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'user-permission-schemes',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.administration.userManagement.userPermissionSchemes',
              viewName: 'Permission Schemes',
              label: 'Permissions Scheme',
              quickLinks: ['Users', 'User Directories', 'Log'],
            },
            canActivate: [RbacGuard],
          },
          { path: '', redirectTo: 'users', pathMatch: 'full' },
        ],
      },
      {
        path: 'system',
        data: {
          quickLinks: ['Auditing'],
          label: 'System',
        },
        children: [
          {
            path: 'log',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.administration.system.log',
              viewName: 'Log',
              label: 'System Log',
              quickLinks: ['Auditing', 'Configuration'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'configuration',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.administration.system.configuration',
              viewName: 'Configuration',
              quickLinks: ['Aggregators', 'Collectors', 'Deception Servers'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'audit-logs',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.administration.system.auditLog',
              viewName: 'Auditing',
              label: 'Audit Log',
              quickLinks: ['Log', 'Configuration'],
            },
            canActivate: [RbacGuard],
          },
          {
            path: 'repo-key',
            component: OldAppContainerComponent,
            data: {
              navState: 'main.administration.system.repoKey',
              viewName: 'Repo Key',
              quickLinks: ['Log', 'Auditing'],
            },
            canActivate: [RbacGuard],
          },
          { path: '', redirectTo: 'log', pathMatch: 'full' },
        ],
      },
      {
        path: 'plugins',
        component: OldAppContainerComponent,
        data: {
          navState: 'main.administration.plugins',
          viewName: 'Plugins',
          quickLinks: ['Log', 'Orchestrations'],
        },
        canActivate: [RbacGuard],
      },
    ],
  },
  { path: '', component: OldAppContainerComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
