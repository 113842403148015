import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { TextAlign } from '../text-align';

@Component({
  selector: 'gc-tooltip-content',
  templateUrl: './tooltip-content.component.html',
  styleUrls: ['./tooltip-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipContentComponent implements AfterViewInit {
  @ViewChild('tooltipContent') tooltipContentTemplate?: TemplateRef<unknown>;

  text?: string[];
  textAlign: TextAlign = 'center';

  readonly tooltipContentTemplate$ = new ReplaySubject(1);

  ngAfterViewInit(): void {
    this.tooltipContentTemplate$.next(this.tooltipContentTemplate);
  }
}
