import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { HybridConfig, hybridConfigToken } from './entities';

@NgModule({
  imports: [CommonModule],
})
export class HybridDomainModule {
  static forRoot(config: HybridConfig): ModuleWithProviders<HybridDomainModule> {
    return {
      ngModule: HybridDomainModule,
      providers: [{ provide: hybridConfigToken, useValue: config }],
    };
  }
}
