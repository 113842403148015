/* eslint-disable @angular-eslint/no-host-metadata-property */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, OnInit, DoCheck, OnDestroy, ElementRef, Input, Optional, Self, HostListener } from '@angular/core';
import { FormControl, FormGroupDirective, NgControl, NgForm, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FormFieldControl } from './form-field-control';

const INVALID_TYPES = ['button', 'checkbox', 'file', 'hidden', 'image', 'radio', 'range', 'reset', 'submit'];

@Directive({
  selector: '[gcInput]',
  host: {
    class: 'gc-input-element',
    '[disabled]': 'disabled',
    '[required]': 'required',
  },
  providers: [{ provide: FormFieldControl, useExisting: InputDirective }],
})
export class InputDirective implements DoCheck, OnInit, OnDestroy, FormFieldControl {
  private destroy$ = new Subject<void>();
  private _type = 'text';
  private _disabled = false;
  private _required?: boolean;

  @Input()
  get type(): string {
    return this._type;
  }

  set type(value: string) {
    if (INVALID_TYPES.includes(value?.toLowerCase())) {
      throw new Error(`Input directive doesn't support input type ${value}`);
    }

    this._type = value || 'text';
    if (!this.isTextArea) {
      (this.elementRef.nativeElement as HTMLInputElement).type = this._type;
      this.stateChanges.next();
    }
  }

  @Input()
  get value(): string {
    return this.elementRef.nativeElement.value;
  }

  set value(_value: string) {
    if (_value !== this.value) {
      this.elementRef.nativeElement.value = _value;
      if (this.ngControl?.control) {
        this.ngControl.control.setValue(_value);
      }

      this.stateChanges.next();
    }
  }

  @Input()
  get disabled(): boolean {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }

    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);

    if (this.isFocused) {
      this.isFocused = false;
      this.stateChanges.next();
    }
  }

  @Input()
  get required(): boolean {
    return this._required ?? this.ngControl?.control?.hasValidator(Validators.required) ?? false;
  }

  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
  }

  isInErrorState = false;
  hasValue = false;
  isFocused = false;
  readonly stateChanges = new Subject<void>();
  readonly isTextArea: boolean;

  @HostListener('input')
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onInput(): void {}

  @HostListener('focus')
  setFocus(): void {
    this.updateFocusState(true);
  }

  @HostListener('blur')
  setBlur(): void {
    this.updateFocusState(false);
  }

  constructor(
    private readonly elementRef: ElementRef<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    @Optional() private readonly parentForm: NgForm,
    @Optional() private readonly parentFormGroup: FormGroupDirective,
    @Optional() @Self() readonly ngControl?: NgControl,
  ) {
    this.isTextArea = elementRef.nativeElement.nodeName.toLowerCase() === 'textarea';
  }

  ngOnInit(): void {
    this.stateChanges.next();
    this.ngControl?.valueChanges?.pipe(takeUntil(this.destroy$)).subscribe(() => this.ngDoCheck());
  }

  ngDoCheck(): void {
    this.updateHasValue();
    this.updateErrorState();
  }

  focus(): void {
    this.elementRef.nativeElement.focus();
  }

  containerClick(): void {
    if (!this.isFocused) {
      this.focus();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private updateHasValue(): void {
    const hasValue = this.value?.length > 0;

    if (this.hasValue !== hasValue) {
      this.hasValue = hasValue;
      this.stateChanges.next();
    }
  }

  private updateErrorState(): void {
    const control = this.ngControl ? (this.ngControl.control as FormControl) : null;
    const parent = this.parentFormGroup || this.parentForm;
    const isInErrorState = !!control && control.invalid && (control.touched || !!parent?.submitted);

    if (this.isInErrorState !== isInErrorState) {
      this.isInErrorState = isInErrorState;
      this.stateChanges.next();
    }
  }

  private updateFocusState(isFocused: boolean): void {
    if (isFocused !== this.isFocused) {
      this.isFocused = isFocused;
      this.stateChanges.next();
    }
  }
}
