import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { ElementRef, Injectable, Injector, Optional } from '@angular/core';
import { ClickObserver } from '@guardicore-ui/ui/click-observer';
import { skip, takeUntil } from 'rxjs';

import { POPOVER_OPTIONS, OpenPopoverOptions } from './entities';
import { PopoverContainerComponent } from './popover-container/popover-container.component';
import { PopoverRefV2 } from './popover-ref';
import { calculateConnectedPosition } from './util';

@Injectable()
export class PopoverService {
  constructor(
    @Optional() private readonly elementRef: ElementRef<HTMLElement>,
    private readonly overlay: Overlay,
    private readonly injector: Injector,
    private readonly clickObserver: ClickObserver,
  ) {}

  open<C, InputData = unknown, OutputData = unknown>(
    component: ComponentType<C>,
    options?: OpenPopoverOptions,
  ): PopoverRefV2<C, InputData, OutputData> {
    options = options ?? { anchor: this.elementRef };
    options.anchor = options.anchor ?? this.elementRef;
    if (!options.anchor) {
      throw new Error('No anchor for the popover.');
    }

    const overlayRef = this.overlay.create(this.createOverlayConfig(options));
    const containerInjector = Injector.create({
      parent: this.injector,
      providers: [{ provide: POPOVER_OPTIONS, useValue: options }],
    });
    const containerPortal = new ComponentPortal(PopoverContainerComponent, undefined, containerInjector);
    const containerRef = overlayRef.attach(containerPortal);
    const popoverRef = new PopoverRefV2<C, InputData, OutputData>(overlayRef);
    const contentInjector = Injector.create({
      parent: options?.injector ?? this.injector,
      providers: [{ provide: PopoverRefV2, useValue: popoverRef }],
    });
    const contentPortal = new ComponentPortal(component, undefined, contentInjector);
    const contentComponentRef = containerRef.instance.attachContentPortal(contentPortal);

    popoverRef.componentInstance = contentComponentRef?.instance;
    popoverRef.containerSize$ = containerRef.instance.size$;
    popoverRef.clickState$ = this.clickObserver
      .observe([options.anchor, containerRef.instance.elementRef])
      .pipe(skip(1), takeUntil(popoverRef.afterDismiss$));

    return popoverRef;
  }

  private createOverlayConfig(options: OpenPopoverOptions): OverlayConfig {
    if (!options.anchor) {
      throw new Error('Anchor was not defined.');
    }

    const overlayConfig = new OverlayConfig();
    const positionStrategy = this.overlay.position().flexibleConnectedTo(options.anchor);

    positionStrategy.withPositions([calculateConnectedPosition(options)]);
    positionStrategy.withTransformOriginOn('.gc-popover-container');
    overlayConfig.positionStrategy = positionStrategy;

    if (options.width) {
      overlayConfig.width = options.width;
    }

    if (options.height) {
      overlayConfig.height = options.height;
    }

    if (options.minWidth) {
      overlayConfig.minWidth = options.minWidth;
    }

    if (options.minHeight) {
      overlayConfig.minHeight = options.minHeight;
    }

    if (options.maxWidth) {
      overlayConfig.maxWidth = options.maxWidth;
    }

    if (options.maxHeight) {
      overlayConfig.maxHeight = options.maxHeight;
    }

    if (options.minWidthEqAnchor) {
      const anchorWidth = options.anchor.nativeElement.getBoundingClientRect().width;

      overlayConfig.minWidth =
        options.minWidth && typeof options.minWidth === 'number' && options.minWidth > anchorWidth ? options.minWidth : anchorWidth;
    }

    return overlayConfig;
  }
}
