import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { LogoutAction } from '../entities';
import { AuthenticationService, AuthenticationQuery } from '../infrastructure';

@Injectable({ providedIn: 'root' })
export class AuthenticationFacade {
  private readonly isInDebugModeSubj = new BehaviorSubject<boolean>(false);
  readonly isLoggedOutSub$ = new ReplaySubject<boolean>();

  /**
   * Observable of JWT
   */
  readonly authData$ = this.authenticationQuery.authData$;
  readonly isLoggedIn$ = this.authenticationQuery.isLoggedIn$;
  readonly isInDebugMode$ = this.isInDebugModeSubj.asObservable();

  constructor(private readonly authenticationService: AuthenticationService, private readonly authenticationQuery: AuthenticationQuery) {}

  logout(logoutAction?: LogoutAction): void {
    this.authenticationService.logout(logoutAction);
    this.isLoggedOutSub$.next(true);
  }

  setDebugMode(value: boolean): void {
    this.isInDebugModeSubj.next(value);
  }
}
