import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiActionListModule } from '@guardicore-ui/ui/action-list';
import { UiIconModule } from '@guardicore-ui/ui/icon';

import { UnpublishedChangesMenuComponent } from './unpublished-changes-menu.component';

@NgModule({
  imports: [CommonModule, UiActionListModule, UiIconModule],
  declarations: [UnpublishedChangesMenuComponent],
  exports: [UnpublishedChangesMenuComponent],
})
export class UiUnpublishedChangesMenuModule {}
