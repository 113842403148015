<div class="configuration-widget-wrapper">
  <p class="title">{{ to.label }}</p>
  <div class="input">
    <ng-container *ngIf="!to['conflict'] || (to['conflict'] && overrideConflict)">
      <gc-form-field fullWidth forceHideX>
        <input gcInput [type]="to.type || 'text'" [formlyAttributes]="field" [formControl]="control" />
      </gc-form-field>
      <p class="description" [innerHTML]="to.description"></p>
    </ng-container>
    <gc-configuration-widget-warnings
      (toggleOverrideConflict)="toggleOverrideConflict()"
      [hasConflict]="!!to['conflict']"
      [overrideConflict]="overrideConflict"
      [customWarning]="to['custom_warning']"
    ></gc-configuration-widget-warnings>
  </div>
</div>
