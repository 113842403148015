import { InlineAlert, CallToActionId } from './inline-alert.interface';

const PREVIOUS_RESULT_EXISTS_ALERT: InlineAlert = {
  status: 'warning',
  title: 'Previous results exist.',
  id: CallToActionId.PreviousResultsExist,
  callToAction: {
    text: 'Show Result',
  },
};

const SUCCESS_ALERT: InlineAlert = {
  id: CallToActionId.Success,
  status: 'success',
  title: 'Operation finished.',
};

const IN_PROGRESS_ALERT: InlineAlert = {
  id: CallToActionId.InProgress,
  status: 'info',
  title: `Operation in progress.`,
  callToAction: {
    text: '',
    icon: 'spinner',
  },
};

const UNSUPPORTED_ALERT: InlineAlert = {
  id: CallToActionId.Unsupported,
  status: 'warning',
  title: 'Operation is not supported.',
};

const DISCARDED_ALERT: InlineAlert = {
  id: CallToActionId.Discarded,
  status: 'info',
  title: 'Operation discarded.',
};

export function generateInlineAlert(data: Partial<InlineAlert>): InlineAlert {
  switch (data.id) {
    case CallToActionId.Discarded:
      return {
        ...DISCARDED_ALERT,
        ...data,
      };
    case CallToActionId.Success:
      return {
        ...SUCCESS_ALERT,
        ...data,
      };
    case CallToActionId.InProgress:
      return {
        ...IN_PROGRESS_ALERT,
        ...data,
      };
    case CallToActionId.Unsupported:
      return {
        ...UNSUPPORTED_ALERT,
        ...data,
      };
    case CallToActionId.PreviousResultsExist:
      return {
        ...PREVIOUS_RESULT_EXISTS_ALERT,
        ...data,
      };
    default:
      return {
        status: 'info',
        ...data,
      };
  }
}
