import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { PasswordRequirements } from '@guardicore-ui/shared/system-status';
import { capitalizeFirstLetters } from '@guardicore-ui/shared/utils';

import {
  passwordRequirementsValidatorInitialStatus,
  PasswordRequirementsStatus,
  PASSWORD_REQUIREMENTS_VALIDATOR_KEY,
} from './password-requirements.validator';

interface PasswordRequirementStatus {
  label: string;
  valid: boolean;
}

@Component({
  selector: 'gc-password-requirements-status',
  templateUrl: './password-requirements-status.component.html',
  styleUrls: ['./password-requirements-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordRequirementsStatusComponent implements OnChanges {
  @Input() passwordRequirements?: PasswordRequirements;
  @Input() validationErrors?: ValidationErrors | null;

  private status?: PasswordRequirementsStatus;
  statuses: PasswordRequirementStatus[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (this.passwordRequirements && changes['passwordRequirements']?.currentValue !== changes['passwordRequirements']?.previousValue) {
      this.status = passwordRequirementsValidatorInitialStatus(this.passwordRequirements);
      this.setStatuses();
    } else {
      this.validate();
    }
  }

  private setStatuses(): void {
    if (!this.status) {
      return;
    }

    this.statuses = [];
    let index = 0;

    for (const key in this.status) {
      this.statuses[index] = {
        label: key === 'minimalLength' ? `${this.passwordRequirements?.minimalLength}+ Characters` : capitalizeFirstLetters(key),
        valid: this.status[key as keyof PasswordRequirementsStatus] || false,
      };
      index++;
    }
  }

  private validate(): void {
    if (!this.status) {
      return;
    }

    if (!this.validationErrors) {
      this.setAllValid();
      this.setStatuses();

      return;
    }

    if (this.validationErrors['required']) {
      this.setAllInvalid();
      this.setStatuses();

      return;
    }

    for (const key in this.status) {
      this.status[key as keyof PasswordRequirementsStatus] = this.validationErrors[PASSWORD_REQUIREMENTS_VALIDATOR_KEY][key];
    }

    this.setStatuses();
  }

  private setAllValid(): void {
    if (!this.status) {
      return;
    }

    for (const key in this.status) {
      this.status[key as keyof PasswordRequirementsStatus] = true;
    }
  }

  private setAllInvalid(): void {
    if (!this.status) {
      return;
    }

    for (const key in this.status) {
      this.status[key as keyof PasswordRequirementsStatus] = false;
    }
  }
}
