<div class="configuration-widget-wrapper">
  <p class="title">{{ props.label }}</p>
  <div class="input" [ngClass]="{ padded: !props['conflict'] || (props['conflict'] && overrideConflict) }">
    <ng-container *ngIf="!props['conflict'] || (props['conflict'] && overrideConflict)">
      <div class="edit-button">
        <gc-icon svgIcon="list"></gc-icon>
        <div (click)="edit()" *ngIf="value | async as val">
          <ng-container *ngIf="!props['multi_line_text']; else multiLineText">
            <p>{{ val[0] ? val.length : '0' }} {{ 'Item' | pluralize: (val[0] ? val.length : 0) }}</p>
          </ng-container>
          <ng-template #multiLineText>
            <p>{{ val[0] ? 'Supplied' : 'Not supplied' }}</p>
          </ng-template>
        </div>
      </div>
      <p class="description" [innerHTML]="props.description"></p>
    </ng-container>
    <gc-configuration-widget-warnings
      (toggleOverrideConflict)="toggleOverrideConflict()"
      [hasConflict]="!!props['conflict']"
      [overrideConflict]="overrideConflict"
      [customWarning]="props['custom_warning']"
    ></gc-configuration-widget-warnings>
  </div>
</div>
