/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/component-selector */
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ChangeDetectionStrategy, ElementRef, HostListener, Input, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Focusable, CanDisable } from '@guardicore-ui/shared/data';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: '[action-list-item]',
  host: { '[disabled]': 'disabled' },
  templateUrl: './action-list-item.component.html',
  styleUrls: ['./action-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionListItemComponent<T> implements OnDestroy, OnInit, Focusable, CanDisable {
  private readonly destroy$ = new Subject<void>();
  private focused = false;
  private readonly clickSubj = new Subject<void>();
  private _disabled = false;

  @HostBinding('class.disabled')
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }

  @Input() itemId?: T;
  @HostBinding('attr.automation-id') automationId?: string;
  @Input() showDivider = true;

  readonly click$ = this.clickSubj.asObservable();

  @HostBinding('class.focused')
  @Input()
  get isFocused(): boolean {
    return this.focused;
  }

  set isFocused(value: boolean) {
    this.focused = this._disabled ? false : value;
  }

  @HostListener('click', ['$event'])
  handleClick(event: Event): void {
    if (this._disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  constructor(el: ElementRef) {
    fromEvent(el.nativeElement, 'mousedown')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.clickSubj.next());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.clickSubj.complete();
  }

  ngOnInit(): void {
    if (!this.itemId) {
      throw new Error('No item id provided to action list item');
    }

    this.automationId = `${this.itemId}`;
  }
}
