import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';

import { NavbarComponentStore } from './navbar-component-store.service';
import { NavbarItemState, NavbarState } from './navbar-state';

@Injectable()
export class NavbarComponentQuery extends Query<NavbarState> {
  activeItem$ = this.select(state => state.activeItemViewName);

  constructor(protected override readonly store: NavbarComponentStore) {
    super(store);
  }

  itemState(viewName: string): Observable<NavbarItemState | undefined> {
    return this.select(state => {
      return { ...state.itemsState[viewName], active: viewName === state.activeItemViewName };
    });
  }

  isSubGroupVisible(viewNames: string[]): Observable<boolean> {
    return this.select(state => {
      const visibleItems = viewNames.map(viewName => state.itemsState[viewName]).filter(itemState => itemState && !itemState.hidden);

      return !!visibleItems.length;
    });
  }
}
