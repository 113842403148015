import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ChangeDetectionStrategy, Input, forwardRef, OnDestroy } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateRangeLabelTypes, DEFAULT_DATE_PICKER_RANGES, DateRangeItem, DatePickerCallback } from '@guardicore-ui/shared/data';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'gc-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimePickerComponent),
      multi: true,
    },
  ],
})
export class DatetimePickerComponent implements ControlValueAccessor, OnDestroy {
  @Input() minDate: Date = new Date(new Date().setFullYear(new Date().getFullYear() - 10));
  @Input() maxDate: Date = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  @Input() range: DateRangeLabelTypes[] = [...DEFAULT_DATE_PICKER_RANGES.map(range => range.label), 'Custom range'];
  @Input() datePickerRange: DateRangeItem[] = DEFAULT_DATE_PICKER_RANGES;

  headerDates = [new Date()];
  protected destroy$ = new Subject<void>();
  private _withHeader = false;

  @Input()
  get withHeader(): boolean {
    return this._withHeader;
  }

  set withHeader(value: BooleanInput) {
    this._withHeader = coerceBooleanProperty(value);
  }

  calendarForm: FormGroup = new FormGroup({});
  activeRangeId?: number;
  private rangeStartDate: Date | undefined;
  private rangeEndDate: Date | undefined;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange: DatePickerCallback = (): void => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouch: DatePickerCallback = (): void => {};

  constructor(private readonly fb: FormBuilder) {
    this.calendarForm = this.fb.group({
      dates: [],
      startTime: '',
      endTime: '',
    });

    this.calendarForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.headerDates = this.setHeaderDates();
      this.setTime(this.dates?.value, this.startTime?.value, this.endTime?.value);
    });
  }

  get dates(): AbstractControl | null {
    return this.calendarForm.get('dates');
  }

  get startTime(): AbstractControl | null {
    return this.calendarForm?.get('startTime');
  }

  get endTime(): AbstractControl | null {
    return this.calendarForm?.get('endTime');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  selectRange(item: string, idx: number): void {
    this.activeRangeId = idx;
    if (item === 'Custom range') {
      this.dates?.setValue(undefined, { emitEvent: false });

      return;
    }

    const el = this.datePickerRange.find(element => element.label === item);

    this.dates?.setValue(el ? [new Date(el.start), new Date(el.end)] : undefined);
  }

  writeValue(value: number[]): void {
    this.checkRange();
    this.setDisabledItems();
    if (value) {
      const dateValue = value.map(date => new Date(date));

      this.dates?.patchValue(dateValue, { emitEvent: false });
      this.headerDates = this.setHeaderDates();
      this.startTime?.setValue(this.dateToTime(dateValue[0]), { emitEvent: false });
      this.endTime?.setValue(this.dateToTime(dateValue[1]), { emitEvent: false });
    }
  }

  registerOnChange(fn: DatePickerCallback): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: DatePickerCallback): void {
    this.onTouch = fn;
  }

  isItemDisabled(label: string): boolean {
    return !!this.datePickerRange.find(item => item.label === label)?.disabled;
  }

  private dateToTime(date: Date): string {
    return `${this.addLeadZero(date?.getHours())}:${this.addLeadZero(date?.getMinutes())}`;
  }

  private addLeadZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  private checkRange(): void {
    if (this.dates?.value?.filter(Boolean)?.length !== 2) {
      this.activeRangeId = DEFAULT_DATE_PICKER_RANGES.findIndex(rangItem => rangItem.label === 'Custom range') || 6;

      return;
    }

    this.datePickerRange.find((element, key) => {
      const startDate = this.dates?.value?.[0]?.getTime();
      const endDate = this.dates?.value?.[1]?.getTime();

      if (element.start === startDate && element.end === endDate) {
        this.activeRangeId = key;
      }
    });

    if (!this.activeRangeId && this.activeRangeId !== 0) {
      this.activeRangeId = 6;
    }
  }

  private setDisabledItems(): void {
    this.datePickerRange.map(item => {
      item.disabled = new Date(item.start) < new Date(this.minDate);

      return item;
    });
  }

  private setTime(values: Date[], startTime: string | undefined, endTime: string | undefined): void {
    const [startDate, endDate] = values || [];

    if (startDate) {
      this.rangeStartDate = this.combineDateWithTime(startDate, startTime);
    }

    this.rangeEndDate = endDate ? this.combineDateWithTime(endDate, endTime || '23:59') : undefined;
    this.updateState();
  }

  private combineDateWithTime(date: Date, time: string | undefined): Date {
    const dateTime = new Date(date.getTime());

    if (time) {
      const timeParts = time.split(':').map(Number);

      if (timeParts.length > 1) {
        const [hours, minutes] = timeParts;

        dateTime.setHours(hours, minutes);
      }
    }

    return dateTime;
  }

  private updateState(): void {
    if (this.rangeStartDate) {
      this.startTime?.setValue(this.dateToTime(this.rangeStartDate), { emitEvent: false });
    }

    if (this.rangeEndDate) {
      this.endTime?.setValue(this.dateToTime(this.rangeEndDate), { emitEvent: false });
    }

    if (this.rangeStartDate && this.rangeEndDate) {
      this.dates?.setValue([this.rangeStartDate, this.rangeEndDate], { emitEvent: false });
      this.onChange([this.dates?.value[0].getTime(), this.dates?.value[1].getTime()]);
    }
  }

  private setHeaderDates(): Date[] {
    if (this.dates?.value) {
      const filteredDates = this.dates?.value.filter(Boolean).map((date: Date) => date.getTime());

      return filteredDates.length > 1 && this.isOneDaySelected(filteredDates) ? [filteredDates.pop()] : filteredDates;
    }

    return [new Date()];
  }

  private isOneDaySelected(dates: Date[]): boolean {
    const datesToMidnight = dates.map(date => new Date(new Date(date).setHours(0, 0, 0, 0)).getTime() && date);

    return datesToMidnight[0] === datesToMidnight[1];
  }
}
