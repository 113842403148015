import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_URL } from '@guardicore-ui/shared/data';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AssetsStore } from './assets.store';
import { Asset } from '../entities';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  constructor(@Inject(API_URL) private readonly apiUrl: string, private readonly http: HttpClient, private readonly store: AssetsStore) {}

  read(id: string): Observable<Asset> {
    return this.http.get<Asset>(`${this.apiUrl}/assets/${id}`).pipe(tap(asset => this.store.upsert(asset.id, asset)));
  }

  invalidateCache(): void {
    this.store.setHasCache(false);
  }
}
