<div class="wrapper">
  <div class="prefix" *ngIf="prefixes?.length">
    <ng-content select="[gcPrefix]"></ng-content>
  </div>
  <div class="infix">
    <ng-content></ng-content>
  </div>
  <div class="suffix">
    <ng-content *ngIf="!showX" select="[gcSuffix]"></ng-content>
    <svg class="times-icon" *ngIf="showX && !isDisabled" (click)="clearValue()" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8633 11.4021C12.044 11.2214 12.044 10.9285 11.8634 10.7478L9.11517 7.99952L11.8635 5.25131C12.0442 5.07064 12.0442 4.77772 11.8635 4.59705L11.4021 4.13565C11.2214 3.95498 10.9285 3.95498 10.7478 4.13564L7.99952 6.88383L5.25132 4.13551C5.07066 3.95483 4.77773 3.95483 4.59706 4.1355L4.13566 4.5969C3.95499 4.77757 3.95499 5.0705 4.13565 5.25118L6.88383 7.99947L4.13551 10.7477C3.95483 10.9283 3.95483 11.2213 4.1355 11.4019L4.5969 11.8633C4.77757 12.044 5.0705 12.044 5.25118 11.8633L7.99948 9.11516L10.7477 11.8635C10.9284 12.0442 11.2213 12.0442 11.402 11.8635L11.8633 11.4021Z" fill="#09011D"/>
    </svg>
    <svg class="search-icon" *ngIf="showSearch && !showX" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.6953 1C3.54987 1 1 3.54673 1 6.68829C1 9.82984 3.54987 12.3766 6.6953 12.3766C9.84073 12.3766 12.3906 9.82984 12.3906 6.68829C12.3906 3.54673 9.84073 1 6.6953 1ZM6.69528 1.897C8.63596 1.89523 10.3865 3.06146 11.1304 4.8517C11.8743 6.64194 11.465 8.70348 10.0933 10.0747C8.7217 11.4459 6.658 11.8566 4.86487 11.1153C3.07174 10.3739 1.90247 8.62657 1.90247 6.68828C1.91444 4.0488 4.05256 1.91137 6.69528 1.897ZM11.6458 11.007L14.8746 14.2537C14.985 14.3647 15.0276 14.526 14.9866 14.677C14.9455 14.8279 14.8269 14.9455 14.6755 14.9855C14.5241 15.0254 14.3629 14.9817 14.2525 14.8707L11.0237 11.624L11.6458 11.007Z" fill="#09011D"/>
    </svg>
  </div>
</div>
