export const sortable = `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_116022_42797)">
<path d="M8.5 3.5L12 7H5L8.5 3.5Z"/>
<path d="M8.5 12.5L12 9H5L8.5 12.5Z"/>
</g>
<defs>
<clipPath id="clip0_116022_42797">
<path d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"/>
</clipPath>
</defs>
</svg>
`;
