import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  Injector,
  Input,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
  OnChanges,
  Inject,
  Optional,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ANCHOR_CATEGORY } from './anchor-category';
import { PopoverAnchorBase } from './popover-anchor-base';
import { PopoverAnchorService } from './popover-anchor.service';
import { AnchorCategory } from './popover-configuration';
import { POPOVER_REF } from './popover-ref';
import { PopoverRef } from './popover-ref.service';

@Directive({
  selector: '[gcPopoverClickAnchor]',
  providers: [
    { provide: POPOVER_REF, useClass: PopoverRef },
    { provide: ANCHOR_CATEGORY, useValue: 'click' },
  ],
})
export class PopoverClickAnchorDirective extends PopoverAnchorBase implements OnChanges {
  private _closeOnClickOutside = true;
  private _closeOnClickInside = false;

  @Input('gcPopoverClickAnchor') templateRef?: TemplateRef<unknown>;
  @Input()
  get closeOnClickOutside(): boolean {
    return this._closeOnClickOutside;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set closeOnClickOutside(value: any) {
    this._closeOnClickOutside = coerceBooleanProperty(value);
  }

  @Input()
  get closeOnClickInside(): boolean {
    return this._closeOnClickInside;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set closeOnClickInside(value: any) {
    this._closeOnClickInside = coerceBooleanProperty(value);
  }

  constructor(
    protected override readonly host: ElementRef,
    protected override readonly viewContainerRef: ViewContainerRef,
    renderer: Renderer2,
    protected override readonly componentFactoryResolver: ComponentFactoryResolver,
    protected override readonly injector: Injector,
    @Inject(ANCHOR_CATEGORY) protected override readonly category: AnchorCategory,
    @Inject(POPOVER_REF) protected override readonly ref: PopoverRef,
    @Optional() popoverAnchorService?: PopoverAnchorService,
  ) {
    super(host, viewContainerRef, renderer, componentFactoryResolver, injector, category, ref, popoverAnchorService);
    fromEvent<void>(this.host.nativeElement, 'click')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this._toggle());

    this.popover.clickedOutside$.pipe(takeUntil(this.destroy$)).subscribe((event: MouseEvent) => {
      if (
        this._closeOnClickOutside &&
        this.popover?.isOpen() &&
        !(this.host.nativeElement as HTMLElement).contains(event.target as HTMLElement) &&
        (this.popoverAnchorService?.isAllowClose() ?? true)
      ) {
        this.popover.close();
      }
    });

    this.popover.clickedInside$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this._closeOnClickInside && this.popover?.isOpen()) {
        this.popover.close();
      }
    });
  }

  override ngOnChanges(): void {
    if (!this.templateRef) {
      throw new Error('You need to provide content template');
    }

    super.ngOnChanges();
    this.popover.template = this.templateRef;
  }
}
