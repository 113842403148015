import { Injectable } from '@angular/core';
import { MenuActionWithIcon, SvgIconName, HybridMessagePayload } from '@guardicore-ui/shared/data';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { HybridMessageSubject, HEADER_PUBLISH_MENU, PublishKeys } from '../entities';
import { HybridCommunicationService } from '../infrastructure';

@Injectable({ providedIn: 'root' })
export class HybridDraftsFacade {
  constructor(private readonly communicationService: HybridCommunicationService) {}

  get drafts$(): Observable<MenuActionWithIcon<string>[]> {
    return this.communicationService.drafts$.pipe(debounceTime(300), map(this.buildMenu.bind(this)));
  }

  publishDraft(draftType: PublishKeys): void {
    this.communicationService.sendMessage(HybridMessageSubject.Draft, { draftType });
  }

  private buildMenu = (menu: HybridMessagePayload): MenuActionWithIcon<string>[] =>
    [
      ['showLabelGroupsDraft', ['LABEL_GROUPS', 'labelGroups']],
      ['showUserGroupsDraft', ['USER_GROUPS', 'userGroups']],
      ['showPolicyRulesDraft', [this.getPolicyTitle(menu), 'valid']],
      ['showFIMDraft', ['INTEGRITY', 'asset']],
    ]
      .filter(([menuFlag]) => !!menu[menuFlag as string])
      .map(menuItem => this.buildMenuItem(...(menuItem[1] as [string, SvgIconName, string | undefined])));

  private buildMenuItem = (id: string, icon: SvgIconName, iconExtraCssClass?: string): MenuActionWithIcon<string> => ({
    id,
    title: HEADER_PUBLISH_MENU[id] as string,
    icon: { icon, iconExtraCssClass },
  });

  private getPolicyTitle(menu: HybridMessagePayload): string {
    const widePolicyMessage = menu['showLabelGroupsDraft'] || menu['showUserGroupsDraft'];

    return widePolicyMessage ? 'POLICY_AND_LABEL_OR_USER_GROUPS' : 'POLICY';
  }
}
