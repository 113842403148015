import { SystemLogItem } from '@guardicore-ui/shared/data';

const ACTIONS = ['dismissAll', 'dismissOne', 'clickEvent'] as const;

export type NotificationsAction = typeof ACTIONS[number];

export interface NotificationsActionEvent {
  action: NotificationsAction;
  notification?: SystemLogItem;
}
