import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DEFAULT_TRANSITION, SatPopoverModule } from '@ncstate/sat-popover';

import { PopoverComponent } from './popover/popover.component';
import { PopoverClickAnchorDirective } from './popover-click-anchor.directive';
import { PopoverHoverAnchorDirective } from './popover-hover-anchor.directive';
import { PopoverContainerComponent } from './v2/popover-container/popover-container.component';

@NgModule({
  imports: [CommonModule, SatPopoverModule, PortalModule],
  declarations: [PopoverComponent, PopoverClickAnchorDirective, PopoverHoverAnchorDirective, PopoverContainerComponent],
  exports: [PopoverClickAnchorDirective, PopoverHoverAnchorDirective],
  providers: [{ provide: DEFAULT_TRANSITION, useValue: '80ms cubic-bezier(0.22, 0.61, 0.36, 1)' }],
})
export class UiPopoverModule {}
