import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { GcRowOperationsParams, GRID_DATA_SOURCE, RowOperationEvent } from '../entities';
import { GridComponentQuery, GridDataSource } from '../infrastructure';

@Component({
  selector: 'gc-row-operations',
  templateUrl: './row-operations.component.html',
  styleUrls: ['./row-operations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RowOperationsComponent implements AgRendererComponent {
  rowIndex?: number;
  rowData?: unknown;
  rowOperation$?: Subject<RowOperationEvent>;

  readonly query = new GridComponentQuery(this.dataSource.store);
  readonly rowOperations$ = this.query.rowOperations$.pipe(
    map(rowOps => (this.rowIndex !== undefined && !!rowOps ? rowOps[this.rowIndex] : [])),
  );

  constructor(@Inject(GRID_DATA_SOURCE) readonly dataSource: GridDataSource) {}

  agInit(params: GcRowOperationsParams): void {
    this.setConfiguration(params);
  }

  refresh(params: GcRowOperationsParams): boolean {
    this.setConfiguration(params);

    return true;
  }

  emitOperation(id: string): void {
    if (this.rowIndex === undefined) {
      return;
    }

    this.rowOperation$?.next({ id, index: this.rowIndex, data: this.rowData });
  }

  private setConfiguration(params: GcRowOperationsParams): void {
    this.rowIndex = params.rowIndex;
    this.rowData = params.node.data;
    this.rowOperation$ = params.rowOperation$;
  }
}
