import { Component, ChangeDetectionStrategy, OnDestroy, Inject, Input, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
/* eslint-disable @nx/enforce-module-boundaries */
import { ProjectsService, SavedMaps, ViolationsMap } from '@guardicore-ui/projects/domain';
import { calculateDateString, YEAR } from '@guardicore-ui/shared/data';
import { getAmountOfDays } from '@guardicore-ui/shared/utils';
import { PopoverClickAnchorDirective } from '@guardicore-ui/ui/popovers/popover';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'gc-violations-dialog',
  templateUrl: './violations-dialog.component.html',
  styleUrls: ['./violations-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViolationsDialogComponent implements OnDestroy {
  @Input() maxRange?: number;
  @Input() projectId?: string;
  @ViewChildren(PopoverClickAnchorDirective) readonly popoverTemplates?: QueryList<PopoverClickAnchorDirective>;

  form: FormGroup = this.formBuilder.group({
    violationsMap: ViolationsMap.lastLoaded,
    timeline: [],
  });

  violationsMap = ViolationsMap;
  savedMaps?: SavedMaps[];
  loadingData = new BehaviorSubject(true);
  selected: { [key: string]: { value: string | null; item: unknown } | undefined } = {};
  isApplyDisabled = true;
  timelineDates = '';
  showRangeWarning = false;

  maxDate = new Date(new Date().setHours(23, 59, 59, 999));
  minDate = new Date(new Date().setDate(this.maxDate.getDate() - YEAR));

  private destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: {
      maxRange?: number;
      projectId: string;
      projectCreationTime: number;
      service: ProjectsService;
    },
    protected readonly ref: MatDialogRef<ViolationsDialogComponent>,
    private readonly formBuilder: FormBuilder,
  ) {
    this.maxRange = data.maxRange;
    this.projectId = data.projectId;
    if (data.projectCreationTime) {
      this.minDate = new Date(data.projectCreationTime);
    }

    this.getSavedMaps(data.service);
    this.form
      .get('timeline')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.isApplyDisabled = this.applyDisabled;
        this.showRangeWarning = !this.isRangeValid;
        this.timelineDates = value ? `${calculateDateString(this.timelineValue[0])} - ${calculateDateString(this.timelineValue[1])}` : '';
      });
  }

  get violationsMapValue(): ViolationsMap | null {
    return this.form.get('violationsMap')?.value;
  }

  get timelineValue(): number[] {
    return this.form.get('timeline')?.value;
  }

  get isRangeValid(): boolean {
    const totalDaysSelected = this.timelineValue ? getAmountOfDays(this.timelineValue[1], this.timelineValue[0]) : 0;

    return totalDaysSelected <= (this.maxRange || YEAR);
  }

  get applyDisabled(): boolean {
    const lastLoadedValid = this.violationsMapValue === ViolationsMap.lastLoaded && this.isSelected;
    const timelineValid = this.violationsMapValue === ViolationsMap.timeline && this.timelineValue?.[1] && this.isRangeValid;

    return !lastLoadedValid && !timelineValid;
  }

  get isSelected(): boolean {
    return !!Object.keys(this.selected).some(key => this.selected[key]?.value);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  setTimeline(value: Date[] | undefined): void {
    if (!value) {
      this.setViolationsMap(ViolationsMap.lastLoaded);
    }

    this.form.get('timeline')?.setValue(value);
  }

  apply(): void {
    const active = this.form.value.violationsMap;

    this.ref.close({ item: this.selected[active]?.item, value: active });
    this.hidePopovers();
  }

  setViolationsMap(control: string): void {
    this.form.controls['violationsMap'].setValue(control);
  }

  close(): void {
    this.ref.close();
    this.hidePopovers();
  }

  chooseAction(id: string): void {
    const item = this.savedMaps?.find(map => map.id === id);

    this.setSelected(item, calculateDateString(item?.startTimeFilter as number), calculateDateString(item?.endTimeFilter as number));
  }

  selectRange(): void {
    if (!this.timelineValue || !this.timelineValue[1]) {
      this.selected['timeline'] = undefined;

      return;
    }

    const item = {
      id: this.projectId,
      startTime: this.timelineValue[0],
      endTime: this.timelineValue[1],
    };

    this.setSelected(item);
  }

  private hidePopovers(): void {
    if (!this.popoverTemplates?.length) {
      return;
    }

    this.popoverTemplates.forEach(popover => popover.close());
  }

  closeDatetimePicker(): void {
    this.popoverTemplates?.last?.close();
  }

  private setSelected(item: unknown, startDate?: string, endDate?: string): void {
    this.selected[this.form.value.violationsMap] = {
      value: startDate ? `${startDate} - ${endDate}` : null,
      item,
    };
    this.isApplyDisabled = this.applyDisabled;
    this.hidePopovers();
  }

  private getSavedMaps(service: ProjectsService): void {
    const params = {
      sort: '-last_access_time',
      state: 'READY',
      limit: 5,
    };

    service
      ?.getSavedMapById(this.projectId as string, params)
      .pipe(takeUntil(this.destroy$))
      ?.subscribe((response: SavedMaps[]) => {
        this.savedMaps = response;
        this.setViolationsMap(this.savedMaps?.length ? ViolationsMap.lastLoaded : ViolationsMap.timeline);
        this.loadingData.next(false);
      });
  }
}
