const ACTIONS = [
  'changePassword',
  'manage2StepVerification',
  'userPreferences',
  'eula',
  'help',
  'relNotes',
  'logout',
  'evaluation',
  'apiDocsV3',
  'apiDocsV4',
  'apiDocsAgent',
] as const;

export type UserMenuAction = typeof ACTIONS[number];
