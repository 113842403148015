import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'gc-configuration-object',
  templateUrl: './configuration-object.component.html',
  styleUrls: ['./configuration-object.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigurationObjectComponent extends FieldType {}
