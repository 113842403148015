import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { MsspStore } from './mssp.store';
import { MsspState } from '../entities';

@Injectable({ providedIn: 'root' })
export class MsspQuery extends Query<MsspState> {
  readonly options$ = this.select('options').pipe(
    map(options => {
      const selected = this.store.getValue()?.selected;

      if (options && selected) {
        options.selectedOptions = [selected];
        options.availableOptions = options.availableOptions.filter(ent => ent.value !== selected.value);
      }

      return options;
    }),
  );

  readonly selected$ = this.select('selected').pipe(distinctUntilChanged((prev, curr) => prev?.value === curr?.value));

  constructor(protected override readonly store: MsspStore) {
    super(store);
  }

  hasOptions(): boolean {
    return !!this.getValue()?.options;
  }

  isSelected(tenantId: string): boolean {
    return this.getValue()?.selected?.value === tenantId;
  }
}
