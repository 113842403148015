import { trigger, state, style, animate, transition, AnimationTriggerMetadata } from '@angular/animations';

export const transformPopover: AnimationTriggerMetadata = trigger('transformPopover', [
  state('enter', style({ opacity: 1, transform: 'scale(1)' }), { params: { startAtScale: 0.3 } }),
  state('void, exit', style({ opacity: 0, transform: 'scale({{endAtScale}})' }), { params: { endAtScale: 0.5 } }),
  transition('* => enter', [
    style({ opacity: 0, transform: 'scale({{endAtScale}})' }),
    animate('{{openTransition}}', style({ opacity: 1, transform: 'scale(1)' })),
  ]),
  transition('* => void, * => exit', [animate('{{closeTransition}}', style({ opacity: 0, transform: 'scale({{endAtScale}})' }))]),
]);

export interface AnimationParams {
  openTransition: string;
  closeTransition: string;
  endAtScale: number;
}

export const DEFAULT_TRANSITION = '80ms cubic-bezier(0.22, 0.61, 0.36, 1)';
export const DEFAULT_CLOSE_ANIMATION_END_SCALE = 0.5;
