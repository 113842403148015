import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { PopupOkAction, PopupCancelAction } from '@guardicore-ui/shared/data';

import { FilterFooterConfig } from '../data';

@Component({
  selector: 'gc-filters-footer',
  templateUrl: './filters-footer.component.html',
  styleUrls: ['./filters-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersFooterComponent {
  @Input() config?: FilterFooterConfig = { okText: 'Apply', cancelText: 'Clear' };
  @Output() readonly action = new EventEmitter<PopupOkAction | PopupCancelAction>();
}
