import { Injectable } from '@angular/core';
import { QuickLink, QuickLinkResponse } from '@guardicore-ui/shared/api';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { QuickLinksQuery, QuickLinksService } from '../infrastructure';

@Injectable({
  providedIn: 'root',
})
export class QuickLinksFacadeService {
  constructor(private quickLinksService: QuickLinksService, private query: QuickLinksQuery) {}

  readonly hasData$ = this.query.hasData$;

  read(): Observable<QuickLinkResponse> {
    return of({}).pipe(switchMap(() => this.quickLinksService.createQuickLinksFromRouterConfig()));
  }

  getQuickLinks(viewName: string): QuickLink[] {
    return this.query.getQuickLinks(viewName);
  }
}
