export interface ServiceStatusOriginal {
  'service-status': string;
  msg?: string;
}

export interface ComponentApplicationHealthOriginal {
  'application-status': string;
  'services-status'?: {
    [key: string]: ServiceStatusOriginal;
  };
}

export interface ComponentHealthResponse {
  [key: string]: ComponentApplicationHealthOriginal;
}
