import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CapitalizePipe } from './capitalize';
import { EllipsisPipe, HasCssEllipsisDirective } from './ellipsis';
import { ErrorBlockComponent, ErrorBlockDescriptionDirective } from './error-block';
import { FormatBytesPipe } from './format-bytes';
import { FullWidthDirective } from './full-width.directive';
import { HoverDirective } from './hover';
import { LoaderComponent } from './loader';
import { PluralizePipe } from './pluralize';
import { SnakeToTitleCasePipe } from './snake-to-title';
import { TimeAgoPipe } from './time-ago';

@NgModule({
  imports: [CommonModule],
  declarations: [
    FullWidthDirective,
    LoaderComponent,
    ErrorBlockComponent,
    ErrorBlockDescriptionDirective,
    HoverDirective,
    TimeAgoPipe,
    EllipsisPipe,
    PluralizePipe,
    CapitalizePipe,
    HasCssEllipsisDirective,
    FormatBytesPipe,
    SnakeToTitleCasePipe,
  ],
  exports: [
    FullWidthDirective,
    LoaderComponent,
    HoverDirective,
    ErrorBlockComponent,
    ErrorBlockDescriptionDirective,
    TimeAgoPipe,
    EllipsisPipe,
    PluralizePipe,
    CapitalizePipe,
    HasCssEllipsisDirective,
    FormatBytesPipe,
    SnakeToTitleCasePipe,
  ],
})
export class UiCommonModule {}
