import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UiCommonModule } from '@guardicore-ui/ui/common';
import { UiIconModule } from '@guardicore-ui/ui/icon';
import { UiTooltipModule } from '@guardicore-ui/ui/popovers/tooltip';

import { ButtonComponent } from './button.component';

@NgModule({
  imports: [CommonModule, UiCommonModule, UiIconModule, UiTooltipModule, RouterModule],
  declarations: [ButtonComponent],
  exports: [ButtonComponent],
})
export class UiButtonModule {}
