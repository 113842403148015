import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AuthenticationInterceptor, AuthenticationQuery, AuthenticationStore } from '@guardicore-ui/authentication/domain';
import { HybridMainFacade } from '@guardicore-ui/hybrid/domain';
import { AG_GRID_LICENSE, API_URL, POLLING_INTERVAL } from '@guardicore-ui/shared/data';
import { SystemStatusFacade } from '@guardicore-ui/shared/system-status';
import { LicenseManager } from 'ag-grid-enterprise';

import { appInitializer } from './app-initializer';
import { AppComponent } from './app.component';
import { CoreModule, CoreStore } from './core';
import { ToCamelInterceptor } from './infrastructure/to-camel.interceptor';
import { ToSnakeCaseInterceptor } from './infrastructure/to-snake.interceptor';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    CoreModule,
    MatAutocompleteModule,
    RouterModule.forRoot([], { initialNavigation: 'enabledBlocking' }),
    environment.production ? [] : AkitaNgDevtools.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthenticationStore, AuthenticationQuery, CoreStore, SystemStatusFacade, HybridMainFacade],
    },
    { provide: API_URL, useValue: environment.apiUrl },
    { provide: POLLING_INTERVAL, useValue: environment.systemStatus.pollingInterval },
    { provide: HTTP_INTERCEPTORS, useClass: ToSnakeCaseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ToCamelInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    { provide: 'Window', useValue: window },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    LicenseManager.setLicenseKey(AG_GRID_LICENSE);
  }
}
