<div class="gc-dialog-content">
  <div class="gc-dialog-content-header">
    <h3>2-Step Verification Recovery Codes</h3>
    <button class="header-close-button" type="button" (click)="close()">
      <gc-icon svgIcon="close"></gc-icon>
    </button>
  </div>

  <div class="gc-dialog-content-body">
    <div *ngIf="!loading; else spinner">
      <p>
        The following recovery codes can be used to login in the event you cannot provide 2-Step verification code. Each code can be used one
        time only. This list will not be shown again.
      </p>

      <p>
        <b>Treat these codes with the same level of attention as you would your password.</b>
      </p>

      <div>
        <ul automation-id="recovery-codes__codes-list">
          <li *ngFor="let code of recoveryCodes">{{ code }}</li>
        </ul>
      </div>

      <button
        class="default-button copy-button"
        [class.copied]="codesCopied"
        type="button"
        (click)="copyCodes()"
        automation-id="recovery-codes__copy-button"
      >
        <gc-icon class="fa" svgIcon="clipboard"></gc-icon>
      </button>
    </div>
  </div>

  <div class="gc-dialog-content-footer">
    <button class="default-button" type="button" (click)="close()" automation-id="recovery-codes__close-button">Close</button>
    <gc-error-block *ngIf="errorMessage$ | async as errorLabel" automation-id="recovery-codes__error-message">
      {{ errorLabel }}
    </gc-error-block>
  </div>
</div>
<ng-template #spinner>
  <gc-loader></gc-loader>
</ng-template>
