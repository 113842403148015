import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HybridIframeDirective } from './hybrid-iframe.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [HybridIframeDirective],
  exports: [HybridIframeDirective],
})
export class HybridUiModule {}
