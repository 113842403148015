import { BreadcrumbItem } from '@guardicore-ui/shared/data';

export function getBreadcrumbIdItem(items: BreadcrumbItem[], token: string): { idItem: BreadcrumbItem; id: string } | null {
  const idItem = items.find(i => i.label === token);
  const idItemUrlSplitted = idItem?.url.split('/');

  if (idItem && idItemUrlSplitted && idItemUrlSplitted.length > 0) {
    const id = idItemUrlSplitted[idItemUrlSplitted.length - 1];

    return { idItem, id };
  }

  return null;
}
