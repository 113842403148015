import { ComponentHarness, TestElement } from '@angular/cdk/testing';

export class SearchBoxHarness extends ComponentHarness {
  static hostSelector = 'gc-search-box';

  protected input = this.locatorFor('input');

  async getInput(): Promise<TestElement> {
    return await this.input();
  }

  async getPlaceholder(): Promise<string> {
    const input = await this.getInput();

    return await input.getProperty('placeholder');
  }

  async setSearchTerm(term: string): Promise<void> {
    const input = await this.getInput();

    if (input) {
      await input.sendKeys(term);
    }
  }
}
