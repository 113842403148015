import { ID } from '@datorama/akita';
export type VariableFieldsValues = {
  key: string;
  value: string;
};

export const PROJECT_STATE = ['GENERATING', 'DRAFT', 'PUBLISHED_ALERT', 'PUBLISHED_BLOCK', 'PUBLISHED'] as const;
export type ProjectState = typeof PROJECT_STATE[number];

export const VARIABLE_TYPE = ['LABEL', 'USER_GROUP', 'DOMAIN', 'SUBNET', 'PRIVATE', 'INTERNET', 'ANY'] as const;
export type VariableType = typeof VARIABLE_TYPE[number];

export const SEVERITY_LEVEL = ['LOW', 'MEDIUM', 'HIGH'] as const;
export type SeverityLevel = typeof SEVERITY_LEVEL[number];

export type AssignedVariables = Target[];

export interface Target {
  variableId: string;
  variableType: VariableType;
  variableFieldsValues?: VariableFieldsValues;
  existingObjectId?: ID;
  required?: boolean;
  suggestionId?: string;
  labelSuggestionId?: ID;
}

export interface Author {
  description: string;
  username: string;
}

export interface Coverage {
  value: number;
  description: string;
  severityLevel: SeverityLevel;
}

export interface Project {
  id: string;
  apiVersion: string;
  projectName: string;
  projectTemplateId: ID;
  projectVersion: string;
  rulesets: string[];
  assignedVariables: AssignedVariables;
  targets: string[];
  savedMapPermalinkId?: string;
  includeProcessLevel?: boolean;
  removeAllowedFlows?: boolean;
  preferIndividualItems?: boolean;
  state?: ProjectState;
  isCustom?: boolean;
  someRulesFailedValidation?: boolean;
  hasModifiedPublishedRules?: boolean;
  savedMapId?: string;
  savedMapTimeEstimateSeconds?: number;
  author?: Author;
  authorId?: string;
  creationTime?: string;
  lastUpdateTime?: string;
  coverage?: Coverage;
  savedMapStartTime?: number;
  savedMapEndTime?: number;
  overrideDefaultTimeFrame?: boolean;
  rules?: number;
  showReady?: boolean;
  assets?: number;
  violations?: number;
  canMoveToBlock?: boolean;
  readOnly?: boolean;
}

export interface GenerateProjectViolationsMapApi {
  savedMapPermalinkId: string;
}

export interface SavedMaps {
  _id: string;
  schemaVersion?: string;
  state?: string;
  error?: string;
  name?: string;
  mapType?: string;
  mapScope?: {
    scopeLabels: {
      orLabels: [
        {
          andLabels: string[];
        },
      ];
    };
    scopePermissions: string;
  };
  filters?: {
    include: unknown;
    exclude: unknown;
  };
  startTimeFilter?: number;
  endTimeFilter?: number;
  timeResolution?: boolean;
  includeFlowHitCount?: boolean;
  includeIncidentMarks?: boolean;
  trimToMaxAllowedFlows?: boolean;
  emailOnProgress?: boolean;
  exportArchive?: boolean;
  creationTime?: number;
  mapSize?: string;
  isStorageFinalize?: boolean;
  includedLabelIds?: unknown[];
  exportArchiveOutputFile?: string;
  scheduledMapSettings?: {
    interval: string;
    offsetFromInterval: number;
    startTime: number;
    endTime: number;
  };
  generationState?: {
    tryCount: number;
    stage: string;
    docCount: number;
    flowsCount: number;
    processedDocCount: number;
    docsToWriteCount: number;
    writtenDocsCount: number;
    originalConnectionPartitions: unknown[];
    mapSegmentsData: unknown[];
    creationStartTime: number;
    creationEndTime: number;
    creationTimeTotalSeconds: number;
  };
  storageSizeBytes?: number;
  completionPercentage?: number;
  estimatedCompletionTime?: number;
  finalizeTime?: number;
  id: string;
  timeEstimate?: number;
  lastAccessTime?: number;
}
