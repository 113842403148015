<header *ngIf="withHeader" class="calendar-header">
  <p class="title">Date picker</p>
  <div class="date-selections">
    <span class="text">Date selections:</span>
    <span class="dates" *ngFor="let selectedDate of headerDates; let last = last">
      {{ selectedDate | date }}
      <span *ngIf="!last"> - </span>
    </span>
  </div>
</header>
<div class="calendar-body">
  <div class="range">
    <ul class="list">
      <li
        [class.disabled]="isItemDisabled(item)"
        class="item"
        *ngFor="let item of range; let i = index"
        (click)="selectRange(item, i)"
        [ngClass]="{ active: activeRangeId === i }">{{item}}</li>  
    </ul> 
  </div>
  <form class="calendar" [formGroup]="calendarForm">
    <p-calendar
      formControlName="dates"
      [inline]="true"
      [numberOfMonths]="2"
      [minDate]="minDate"
      [maxDate]="maxDate"
      selectionMode="range">
      <ng-template pTemplate="date" let-date>
        <span class="calendar-day"
          [ngClass]="{
            'start-date': date.day === dates?.value?.[0]?.getDate() && date.month === dates?.value?.[0]?.getMonth(),
            'end-date': date.day === dates?.value?.[1]?.getDate() && date.month === dates?.value?.[1]?.getMonth()
          }"
          [attr.data-day]="date.day">{{ date.day }}</span>
      </ng-template>
    </p-calendar>
    <div class="start-time">
      <input type="time" formControlName="startTime" pattern="[0-2][0-9]:[0-5][0-9]">
    </div>
    <div class="end-time">
      <input type="time" formControlName="endTime" pattern="[0-2][0-9]:[0-5][0-9]">
    </div>
  </form>
</div>
