import { Pipe, PipeTransform } from '@angular/core';
import { pluralize } from '@guardicore-ui/shared/utils';

@Pipe({
  name: 'pluralize',
})
export class PluralizePipe implements PipeTransform {
  transform(value: string, count: number): string {
    return pluralize(value, count);
  }
}
