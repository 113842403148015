/* eslint-disable @typescript-eslint/no-explicit-any */
import { Camelize, Snakeize } from '@guardicore-ui/shared/data';

export function toSnakeCase(str: string): string {
  return str.replace(/([A-Z]|\d+)/g, letter => `_${letter.toLowerCase()}`);
}

function toCamelCase(snakeCaseWord: string): string {
  return snakeCaseWord
    .split('_')
    .reduce((res, word, i) => (i === 0 ? word.toLowerCase() : `${res}${word.charAt(0).toUpperCase()}${word.substr(1).toLowerCase()}`), '');
}

function convert<T extends Record<any, any>, R>(source: T, keyConverterFn: (str: string) => string): R {
  if (source instanceof Array) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return source.map(value => (value && typeof value === 'object' ? convert(value, keyConverterFn) : value)) as any;
  } else {
    const newObj = {} as Record<string, unknown>;
    let newKey: string;

    Object.entries(source).forEach(([key, value]) => {
      newKey = keyConverterFn(key);
      if (!!value && typeof value === 'object') {
        value = convert(value, keyConverterFn);
      }

      newObj[newKey] = value;
    });

    return newObj as R;
  }
}

/**
 * Converts all keys (of this object and nested ones) from snake_case to camelCase.
 * @param source Source object to map
 */
export function snakeToCamelCaseKeys<T extends Record<any, any>>(source: T): Camelize<T> {
  return convert<T, Camelize<T>>(source, toCamelCase);
}

/**
 * Converts all keys (of this object and nested ones) from camelCase to snake_case.
 * @param source Source object to map
 */
export function camelToSnakeCaseKeys<T extends Record<any, any>>(source: T): Snakeize<T> {
  return convert<T, Snakeize<T>>(source, toSnakeCase);
}

/**
 * Converts all keys (of this object and nested ones) from camelCase to snake_case.
 * if it's not object, or null, will return initial value
 * @param source Source object to map
 */
export function camelToSnakeCase(source: unknown): unknown {
  return !source || typeof source !== 'object' ? source : camelToSnakeCaseKeys(source);
}
