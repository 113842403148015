import { coerceBooleanProperty, BooleanInput } from '@angular/cdk/coercion';
import { Component, ChangeDetectionStrategy, OnDestroy, Input, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FilterOptions } from '@guardicore-ui/filters/domain';
import { FilterOptionsQuery, FilterOptionsSource, FilterOptionsStore } from '@guardicore-ui/filters/shared';
import { InputType } from '@guardicore-ui/filters/ui';
import { FilterOptionsEntity, FiltersListValue } from '@guardicore-ui/shared/data';
import { InputDirective } from '@guardicore-ui/ui/form-components';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, debounceTime, filter, distinctUntilChanged, switchMap } from 'rxjs/operators';

interface SelectionFilterComponentState extends FilterOptions {
  filteredAvailableOptions: FilterOptionsEntity[];
  searchTerm?: string;
  showLoadMore: boolean;
  isLoading: boolean;
}

function getInitialState(): SelectionFilterComponentState {
  return {
    selectedOptions: [],
    availableOptions: [],
    filteredAvailableOptions: [],
    displayMore: false,
    filterValue: '',
    isElasticDb: false,
    limit: 0,
    offset: 0,
    totalCount: 0,
    showLoadMore: false,
    isLoading: true,
  };
}

const DEBOUNCE_MILLISECONDS = 1000;

@Component({
  selector: 'gc-selection-filter',
  templateUrl: './selection-filter.component.html',
  styleUrls: ['./selection-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FilterOptionsStore, FilterOptionsQuery],
})
export class SelectionFilterComponent implements OnDestroy, OnInit {
  private readonly destroy$ = new Subject<void>();
  private state = getInitialState();
  private _withSearchBox = false;
  private _withCount = true;
  private _withActions = false;
  private _multiselect = false;
  private localSelection = new Map<FilterOptionsEntity['value'], FilterOptionsEntity>();

  @Input() searchBoxPlaceholder = '';
  @Input() clearBtnText = 'Clear filter';
  @Input() applyBtnText = 'Apply';
  @Input() id?: string;
  @Input() value?: FilterOptionsEntity[];

  @Output() valueChanges = new EventEmitter<FilterOptionsEntity[]>();

  @ViewChild(InputDirective) searchBoxInput?: InputDirective;

  readonly state$ = new BehaviorSubject<SelectionFilterComponentState>(this.state);
  readonly searchBoxControl = new FormControl<string | null>(null);

  @Input()
  get withSearchBox(): boolean {
    return !!this.searchBoxPlaceholder || this._withSearchBox;
  }

  set withSearchBox(value: BooleanInput) {
    this._withSearchBox = coerceBooleanProperty(value);
  }

  @Input()
  get withCount(): boolean {
    return this._withCount;
  }

  set withCount(value: BooleanInput) {
    this._withCount = coerceBooleanProperty(value);
  }

  @Input()
  get withActions(): boolean {
    return this._withActions;
  }

  set withActions(value: BooleanInput) {
    this._withActions = coerceBooleanProperty(value);
  }

  @Input()
  get multiselect(): boolean {
    return this._multiselect;
  }

  set multiselect(value: BooleanInput) {
    this._multiselect = coerceBooleanProperty(value);
  }

  get inputType(): InputType {
    return this._multiselect ? 'checkbox' : 'radio';
  }

  constructor(readonly filterOptionsSource: FilterOptionsSource, private readonly optionsStore: FilterOptionsStore) {
    this.searchBoxControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(DEBOUNCE_MILLISECONDS),
        distinctUntilChanged(),
        filter((term): term is string => term !== null && !!this.id),
        switchMap(term => {
          this.setLoading(true);

          return filterOptionsSource.search(term, this.id);
        }),
      )
      .subscribe(options => this.setOptions(options));
  }

  ngOnInit(): void {
    if (!this.id) {
      return;
    }

    this.filterOptionsSource
      .getFilterOptions(this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(options => this.setOptions(options));
  }

  processSelection(value: FiltersListValue): void {
    if (!this.state.availableOptions) {
      return;
    }

    let selectedEntities = this.state.selectedOptions.slice(0);
    let availableEntities = this.state.availableOptions.slice(0);

    if (!this._multiselect) {
      let selectedEntity = selectedEntities.find(ent => ent.value === value);

      if (selectedEntity) {
        selectedEntity = { ...selectedEntity, isSelected: false };
        selectedEntities = [];
        availableEntities.unshift(selectedEntity);
      } else {
        let entity = availableEntities.find(ent => ent.value === value);

        if (!entity) {
          entity = selectedEntities.find(ent => ent.value === value);
          if (!entity) {
            return;
          }
        }

        entity = { ...entity, isSelected: true };

        if (selectedEntities.length > 0) {
          availableEntities = [...availableEntities, ...selectedEntities];
        }

        selectedEntities = [{ ...entity }];
        availableEntities = availableEntities.filter(ent => ent.value !== value).map(ent => ({ ...ent, isSelected: false }));
      }
    } else {
      const allEntities = [...availableEntities, ...selectedEntities];

      selectedEntities = [];
      availableEntities = [];

      for (let e of allEntities) {
        if (e.value === value) {
          e = { ...e, isSelected: !e.isSelected };
          e.isSelected ? this.localSelection.set(e.value, e) : this.localSelection.delete(e.value);
        }

        e.isSelected ? selectedEntities.push(e) : availableEntities.push(e);
      }
    }

    this.state = {
      ...this.state,
      availableOptions: availableEntities,
      filteredAvailableOptions: availableEntities,
      selectedOptions: selectedEntities,
    };
    this.state$.next(this.state);
    if (!this._multiselect && this.state.selectedOptions.length > 0) {
      this.action('apply');
    }
  }

  action(action: 'apply' | 'clear'): void {
    if (action === 'apply') {
      this.localSelection.clear();
    } else {
      const searchValue = this.searchBoxControl.value;

      this.resetSelectedOptions();
      this.searchBoxControl.setValue(null);
      this.id && searchValue && this.withSearchBox && this.filterOptionsSource.search('', this.id);
    }

    this.valueChanges.emit(this.state.selectedOptions);
  }

  loadMoreAction(): void {
    this.setLoading(true);
    this.id &&
      this.filterOptionsSource.loadMore(this.state.offset + this.state.limit, this.id).subscribe(options => this.setOptions(options));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private updateOptionsStore(options: FilterOptions, value?: FilterOptionsEntity[]): FilterOptions {
    this.state.offset ? this.optionsStore.addMoreAvailableOptions(options) : this.optionsStore.updateFilterOptions(options, value);

    return this.optionsStore.getValue().options as FilterOptions;
  }

  private resetSelectedOptions(): void {
    let availableOptions = [...this.state.availableOptions, ...this.state.selectedOptions];

    availableOptions = availableOptions.map(opt => ({ ...opt, isSelected: false }));

    this.state = {
      ...this.state,
      availableOptions,
      selectedOptions: [],
      filteredAvailableOptions: availableOptions.slice(0),
      isLoading: false,
    };
    this.localSelection.clear();
    this.state$.next(this.state);
  }

  private setOptions(options: FilterOptions): void {
    options = this.updateOptionsStore(options, this.value);
    const showLoadMore = options.availableOptions.length + options.selectedOptions.length < options.totalCount;

    options.selectedOptions.forEach(select => {
      !this.localSelection.has(select.value) && this.localSelection.set(select.value, select);
    });

    const availableOptions = options.availableOptions.filter(available => !this.localSelection.has(available.value));

    this.state = {
      ...this.state,
      ...options,
      availableOptions,
      filteredAvailableOptions: availableOptions,
      showLoadMore,
      selectedOptions: Array.from(this.localSelection.values()),
      isLoading: false,
    };
    this.state$.next(this.state);

    if (!this.value?.length) {
      this.resetSelectedOptions();
    }
  }

  private setLoading(isLoading: boolean): void {
    this.state = { ...this.state, isLoading };
    this.state$.next(this.state);
  }
}
