import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { QuickLinkResponse } from '@guardicore-ui/shared/api';

export interface QuickLinksState {
  quickLinks: QuickLinkResponse;
}

export function createInitialState(): QuickLinksState {
  return {
    quickLinks: {},
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'quick-links' })
export class QuickLinksStore extends Store<QuickLinksState> {
  constructor() {
    super(createInitialState(), { name: 'quick-links' });
  }
}
