import { Query } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { GridComponentState } from './grid-component-state';
import { GridComponentStore } from './grid-component.store';

export class GridComponentQuery extends Query<GridComponentState> {
  readonly state$ = this.select();
  readonly busy$ = combineLatest([this.select('busy'), this.selectLoading()]).pipe(map(([busy, loading]) => busy || loading));
  readonly rowOperations$ = this.select('rowOperations');
  readonly pageStatus$ = this.select('pageStatus');

  get rowData(): unknown[] {
    return this.store.getValue().rowData || [];
  }

  constructor(protected override readonly store: GridComponentStore) {
    super(store);
  }
}
