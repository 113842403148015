export const editV3 = `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_1937_9918)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 2.10774C0.5 1.76647 0.773921 1.48981 1.11182 1.48981H5.93577C6.27367 1.48981 6.54759 1.76647 6.54759 2.10774C6.54759 2.449 6.27367 2.72566 5.93577 2.72566H1.72364V14.2642H13.207V10.1883C13.207 9.84698 13.4809 9.57033 13.8188 9.57033C14.1567 9.57033 14.4306 9.84698 14.4306 10.1883V14.8821C14.4306 15.2233 14.1567 15.5 13.8188 15.5H1.11182C0.773921 15.5 0.5 15.2233 0.5 14.8821V2.10774Z" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1318 0.680985C12.3708 0.439672 12.7581 0.439672 12.9971 0.680985L15.3208 3.0279C15.5597 3.26921 15.5597 3.66046 15.3208 3.90177L7.61424 11.6852C7.4995 11.8011 7.34388 11.8662 7.18162 11.8662H4.8873C4.5494 11.8662 4.27548 11.5895 4.27548 11.2483V8.87165C4.27548 8.70777 4.33994 8.5506 4.45468 8.43471L12.1318 0.680985ZM5.49912 9.1276V10.6304H6.92819L14.0229 3.46484L12.5644 1.9918L5.49912 9.1276Z" />
  </g>
  <defs>
  <clipPath id="clip0_1937_9918">
  <rect width="16" height="16" fill="white"/>
  </clipPath>
  </defs>
</svg>
`;
