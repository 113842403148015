import { Component, ChangeDetectionStrategy, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'gc-generic-modal',
  templateUrl: './generic-authentication-modal.component.html',
  styleUrls: ['./generic-authentication-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericAuthenticationModalComponent {
  @Input() title?: string;
  @Input() content?: string;
  @Input() primaryButtonLabel?: string;
  @Input() secondaryButtonLabel?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title?: string;
      content?: string;
      primaryButtonLabel?: string;
      secondaryButtonLabel?: string;
    },
    private readonly ref: MatDialogRef<GenericAuthenticationModalComponent>,
  ) {
    this.title = data.title;
    this.content = data.content;
    this.primaryButtonLabel = data.primaryButtonLabel;
    this.secondaryButtonLabel = data.secondaryButtonLabel;
  }

  dismiss(dismissed = false): void {
    this.ref.close(dismissed ? { dismissed } : undefined);
  }
}
