import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class PopoverRef implements OnDestroy {
  private closeEmitted = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly closeSubj = new Subject<any>();
  private readonly openSubj = new Subject<void>();
  private readonly isOpen = new BehaviorSubject<boolean>(false);

  readonly close$ = this.closeSubj.asObservable();
  readonly open$ = this.openSubj.asObservable();
  readonly isOpen$ = this.isOpen.asObservable();

  open(): void {
    this.closeEmitted = false;
    this.openSubj.next();
    this.isOpen.next(true);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  close(data?: any): void {
    if (this.closeEmitted) {
      return;
    }

    this.closeEmitted = true;
    this.closeSubj.next(data);
    this.isOpen.next(false);
  }

  ngOnDestroy(): void {
    this.closeSubj.complete();
    this.openSubj.complete();
    this.isOpen.complete();
  }
}
