import { Component, Input } from '@angular/core';

@Component({
  selector: 'gc-authentication-two-fa-qr',
  templateUrl: './two-fa-qr.component.html',
  styleUrls: ['./two-fa-qr.component.scss'],
})
export class TwoFaQrComponent {
  @Input() qrCode?: string;
  @Input() key?: string;

  get imageSource(): string {
    return `data:image/png;base64,${this.qrCode}`;
  }
}
