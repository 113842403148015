import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { SvgIconName } from '@guardicore-ui/shared/data';

import { ALERT_STATUS_ICON, AlertStatus, CallToActionId, InlineAlert, InlineAlertCallToAction } from '../entities';

@Component({
  selector: 'gc-inline-alert',
  templateUrl: './inline-alert.component.html',
  styleUrls: ['./inline-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineAlertComponent implements InlineAlert {
  private _status!: AlertStatus;
  @Input() body? = '';
  @Input() id?: CallToActionId;
  @Input() callToAction?: Partial<InlineAlertCallToAction>;
  @Input()
  set status(status: AlertStatus) {
    this.icon = ALERT_STATUS_ICON[status];
    this._status = status;
  }

  get status(): AlertStatus {
    return this._status;
  }

  @Input() title?: string;
  @Input() comment?: string;

  @Output() alert = new EventEmitter<null>();

  icon!: SvgIconName;

  action(): void {
    if (this.callToAction?.icon !== 'spinner') {
      this.alert.emit();
    }
  }
}
