import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'snakeToTitleCase',
})
export class SnakeToTitleCasePipe implements PipeTransform {
  transform(str?: string): string {
    return !str ? '' : (str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()).split('_').join(' ');
  }
}
