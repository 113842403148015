<gc-dialog>
  <div *ngIf="bulkContent" [innerHTML]="bulkContent"></div>
  <div class="inline-alerts-container" *ngIf="inlineAlerts?.length">
    <div class="inline-alert" *ngFor="let inlineAlert of inlineAlerts">
      <gc-inline-alert
        [title]="inlineAlert.title"
        [body]="inlineAlert.body"
        [callToAction]="inlineAlert.callToAction"
        [comment]="inlineAlert.comment"
        [status]="inlineAlert.status"
        (alert)="alert.emit()"
      ></gc-inline-alert>
    </div>
  </div>


</gc-dialog>
