import { Directive, ElementRef, EventEmitter, Output, OnDestroy, AfterViewInit } from '@angular/core';

import { ElementResizeObserver } from './element-resize-observer';

@Directive({
  selector: '[gcHasCssEllipsis]',
})
export class HasCssEllipsisDirective implements AfterViewInit, OnDestroy {
  @Output('gcHasCssEllipsis') hasEllipsis = new EventEmitter<boolean>();
  ellipsisObserver = this.setResizeObserver();
  constructor(public elRef: ElementRef) {}

  private setResizeObserver(): ElementResizeObserver {
    if (window.ResizeObserver) {
      return new window.ResizeObserver(() => this.render());
    }

    return {
      disconnect: (): void => undefined,
      observe: (): void => undefined,
      unobserve: (): void => undefined,
    };
  }

  ngAfterViewInit(): void {
    if (this.elRef) {
      this.ellipsisObserver.observe(this.elRef?.nativeElement);
    }
  }

  checkIfEllipsis(): void {
    this.hasEllipsis.emit(this.elRef?.nativeElement?.offsetWidth < this.elRef?.nativeElement?.scrollWidth);
  }

  private render(): void {
    this.checkIfEllipsis();
  }

  ngOnDestroy(): void {
    if (this.elRef) {
      this.ellipsisObserver.unobserve(this.elRef?.nativeElement);
    }
  }
}
