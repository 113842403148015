export function vectorInclude(arr1: unknown[], arr2: unknown[]): boolean {
  if (!arr1 || !arr2 || arr1.length === 0 || arr2.length === 0) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr2.includes(arr1[i])) {
      return true;
    }
  }

  return false;
}
