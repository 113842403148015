import { HttpParams } from '@angular/common/http';
import { FeatureWithPaginationAndFiltersState, sortStateToSortParam } from '@guardicore-ui/shared/data';

import { filterStateToFilterParams } from './filter-state-to-filter-params';

export function processRequestParams(requestParams?: FeatureWithPaginationAndFiltersState): HttpParams {
  let params = new HttpParams();

  if (!requestParams) {
    return params;
  }

  params = params.set('offset', `${requestParams.pageState.offset}`);
  params = params.set('limit', `${requestParams.pageState.limit}`);

  filterStateToFilterParams(requestParams.filtersState).forEach(f => (params = params.set(f.name, `${f.value}`)));
  const sortParams = sortStateToSortParam(requestParams.sortState);

  if (sortParams) {
    params = params.set('sort', `${sortStateToSortParam(requestParams.sortState)}`);
  }

  return params;
}
