import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { QuickLink } from '@guardicore-ui/shared/api';
import { isObjectsEmpty } from '@guardicore-ui/shared/utils';
import { filter } from 'rxjs/operators';

import { QuickLinksState, QuickLinksStore } from './quick-links.store';

@Injectable({ providedIn: 'root' })
export class QuickLinksQuery extends Query<QuickLinksState> {
  readonly hasData$ = this.select('quickLinks').pipe(filter(data => !isObjectsEmpty(data)));

  constructor(store: QuickLinksStore) {
    super(store);
  }

  getQuickLinks(viewName: string): QuickLink[] {
    return this.store.getValue().quickLinks[viewName?.toLocaleLowerCase()] || [];
  }
}
