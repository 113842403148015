/* eslint-disable @angular-eslint/no-inputs-metadata-property */
import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { Label } from '@guardicore-ui/shared/data';
import { mixinDisabled } from '@guardicore-ui/shared/utils';

const dropdownSelectionButtonComponentBase = mixinDisabled(class {});

@Component({
  selector: 'gc-dropdown-selection-button',
  templateUrl: './dropdown-selection-button.component.html',
  styleUrls: ['./dropdown-selection-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  inputs: ['disabled'],
})
export class DropdownSelectionButtonComponent extends dropdownSelectionButtonComponentBase {
  private _labelValue?: string | Label[];

  @Input() label?: string;

  get text(): string | undefined {
    return typeof this._labelValue === 'string' ? this._labelValue : undefined;
  }

  get labels(): Label[] {
    return Array.isArray(this._labelValue) ? this._labelValue : [];
  }

  @Input()
  set labelText(value: string | Label[] | undefined) {
    this._labelValue = value;
  }

  @Output() gcClick = new EventEmitter<void>();
}
