<button gc-outline-button [chevron]="'down'" panelCssClass="dropdown-filter" [disabled]="disabled" (click)="togglePopover()">
  <ng-container *ngIf="(mssp.error$ | async) as error; else defaultIconTemplate">
    <gc-icon class="icon error-icon" svgIcon="warning"></gc-icon>
    <span class="title default-title">{{ invalidText }}</span>
  </ng-container>
  <ng-template #defaultIconTemplate>
    <gc-icon svgIcon="folder" class="icon"></gc-icon>
    <ng-container *ngIf="(mssp.selected$ | async) as selected; else defaultTitle">
      <span class="title"
            [ngClass]="selected?.value === valueUndefined ? 'default-title' : ''">{{ selected?.text }}</span>
    </ng-container>
    <ng-template #defaultTitle>
      <span class="title default-title">{{ defaultText }}</span>
    </ng-template>
  </ng-template>
</button>
