import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FieldArrayType } from '@ngx-formly/core';
import { BehaviorSubject } from 'rxjs';

import { ConfigurationArrayEditDialogComponent } from '../configuration-array-edit-dialog/configuration-array-edit-dialog.component';
import { CONFLICTS } from '../constants';

type ArrayValue = string | number;

@Component({
  selector: 'gc-configuration-array',
  templateUrl: './configuration-array.component.html',
  styleUrls: ['../configuration-widget-base/configuration-widget-base.component.scss', './configuration-array.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigurationArrayComponent extends FieldArrayType implements OnInit {
  value: BehaviorSubject<ArrayValue[]> = new BehaviorSubject<ArrayValue[]>([]);
  overrideConflict = false;

  constructor(private dialogService: MatDialog) {
    super();
  }

  ngOnInit(): void {
    this.overrideConflict = this.model[this.key as string] !== CONFLICTS;
    this.value.next(this.model || []);
  }

  get control(): FormArray {
    return this.formControl;
  }

  edit(): void {
    this.dialogService
      .open(ConfigurationArrayEditDialogComponent, {
        data: {
          title: this.to.label,
          value: this.value.getValue(),
          field: this.field,
        },
      })
      .afterClosed()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .subscribe((result: any[] | undefined) => {
        if (result !== undefined) {
          this.update(result);
        }
      });
  }

  toggleOverrideConflict(): void {
    this.overrideConflict = !this.overrideConflict;
    this.form.markAsDirty();
    this.update(this.overrideConflict ? this.field.defaultValue : [CONFLICTS]);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private update(data: any[]): void {
    this.form.markAsDirty();
    while (this.control.controls.length) {
      this.remove(this.control.controls.length - 1);
    }

    data.forEach(() => this.add());
    this.model.splice(0, this.model.length);
    data.forEach(d => this.model.push(d));
    this.control.setValue(data, { emitEvent: false });
    this.value.next(data === null ? [] : data);
  }
}
