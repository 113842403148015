/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { AbstractConstructor, Constructor, CanBeUncommonConstructor } from '@guardicore-ui/shared/data';

export function mixinCanBeUncommon<T extends AbstractConstructor<{}>>(base: T): CanBeUncommonConstructor & T;
export function mixinCanBeUncommon<T extends Constructor<{}>>(base: T): CanBeUncommonConstructor & T {
  return class extends base {
    protected _common = true;

    get common(): boolean {
      return this._common;
    }

    /** Input */
    set unCommon(value: BooleanInput) {
      this._common = !coerceBooleanProperty(value);
    }

    constructor(...args: any[]) {
      super(...args);
    }
  };
}
