<ng-content select="gc-column"></ng-content>
<div class="wrapper" *ngIf="query.state$ | async as state">
  <ag-grid-angular
    #agGrid
    class="ag-theme-balham gc-grid"
    rowModelType="serverSide"
    [suppressServerSideInfiniteScroll]="true"
    [serverSideDatasource]="dataSource"
    [columnDefs]="colDef"
    [rowHeight]="rowHeight"
    [headerHeight]="headerHeight"
    [components]="frameworkComponents"
    [loadingCellRenderer]="loadingCellRenderer"
    [suppressContextMenu]="true"
    [masterDetail]="true"
    [detailCellRenderer]="detailCellRenderer"
    [detailCellRendererParams]="detailCellRendererParams"
    [detailRowAutoHeight]="true"
    [enableCellChangeFlash]="false"
    [suppressRowClickSelection]="true"
    [cacheBlockSize]="cacheBlockSize"
    [icons]="icons"
    [getRowId]="getRowId"
    [rowClassRules]="rowClassRules"
    (rowClicked)="onRowClicked($event)"
    (rowGroupOpened)="onRowGroupOpened($event)"
  ></ag-grid-angular>
  <div class="overlay" *ngIf="query.busy$ | async">
    <gc-loading-overlay></gc-loading-overlay>
  </div>
  <div class="overlay" *ngIf="query.selectError() | async as error">
    <gc-loading-overlay [error]="error"></gc-loading-overlay>
  </div>
  <div class="overlay" *ngIf="state.isEmpty && !state.isNewRowOpen">
    <gc-no-rows-overlay></gc-no-rows-overlay>
  </div>
</div>
