/* eslint-disable @angular-eslint/component-selector */
import { Component, ChangeDetectionStrategy, ElementRef, Renderer2, ViewEncapsulation, Input, OnChanges } from '@angular/core';

export type ChevronPosition = 'up' | 'down' | 'none';

const BUTTON_ATTRIBUTES = ['gc-button', 'gc-outline-button'] as const;

@Component({
  selector: 'button[gc-button], button[gc-outline-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent implements OnChanges {
  @Input() chevron?: ChevronPosition;

  constructor(private host: ElementRef, private readonly renderer: Renderer2) {
    BUTTON_ATTRIBUTES.forEach(attr => {
      if (this.getHostElement().hasAttribute(attr)) {
        renderer.addClass(this.getHostElement(), attr);
      }
    });
  }

  ngOnChanges(): void {
    if (this.chevron === 'up') {
      this.renderer.addClass(this.getHostElement(), 'gc-button-active');
    } else {
      this.renderer.removeClass(this.getHostElement(), 'gc-button-active');
    }
  }

  private getHostElement(): HTMLButtonElement {
    return this.host.nativeElement as HTMLButtonElement;
  }
}
