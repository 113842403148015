<div class="gc-button-wrapper">
  <ng-content></ng-content>
  <div class="caret" *ngIf="chevron === 'down'">
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 11.056L12 7.056L11.056 6.112L8 9.184L4.944 6.112L4 7.056L8 11.056Z"/>
    </svg>
  </div>
  <div class="caret" *ngIf="chevron === 'up'">
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.056 10.688L12 9.74399L8 5.74399L4 9.74399L4.944 10.688L8 7.61599L11.056 10.688Z"/>
    </svg>
  </div>
</div>
