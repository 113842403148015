import { Injectable } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { QuickLink, QuickLinkApiResponse, QuickLinkResponse } from '@guardicore-ui/shared/api';
import { ApplicationViewName } from '@guardicore-ui/shared/data';
import { RbacService } from '@guardicore-ui/shared/rbac';
import { SystemStatusQuery } from '@guardicore-ui/shared/system-status';
import { checkViewFeatureFlag, extractInfoFromRouterConfig } from '@guardicore-ui/shared/utils';
import { Observable, of } from 'rxjs';

import { QuickLinksStore } from './quick-links.store';

@Injectable({
  providedIn: 'root',
})
export class QuickLinksService {
  constructor(
    private readonly router: Router,
    private store: QuickLinksStore,
    private rbac: RbacService,
    private systemStatus: SystemStatusQuery,
  ) {}

  createQuickLinksFromRouterConfig(): Observable<QuickLinkResponse> {
    const quickLinksFromConfig = this.extractQuickLinksFromConfig(this.router.config);
    const quickLinks = this.createQuickLinks(quickLinksFromConfig);

    this.store.update({ quickLinks });

    return of(quickLinks);
  }

  private extractQuickLinksFromConfig(routes: Routes, quickLinkResponse: QuickLinkApiResponse = {}): QuickLinkApiResponse {
    for (const route of routes) {
      const { quickLinks, viewName } = route.data || {};

      if (quickLinks?.length && viewName) {
        quickLinkResponse[viewName] = quickLinks as string[];
      }

      if (route.children) {
        quickLinkResponse = this.extractQuickLinksFromConfig(route.children as Routes, quickLinkResponse);
      }
    }

    return quickLinkResponse;
  }

  private createQuickLinks(_quickLinksApiData: QuickLinkApiResponse): QuickLinkResponse {
    return Object.entries(_quickLinksApiData).reduce((state, [currentViewName, viewNames]) => {
      const links: QuickLink[] = viewNames
        .filter(viewName => this.rbac.isAllowed(viewName))
        .map(viewName => this.createQuickLinkFromViewName(viewName));

      return { ...state, [currentViewName.toLocaleLowerCase()]: links };
    }, {});
  }

  private createQuickLinkFromViewName(viewName: ApplicationViewName): QuickLink {
    const route = extractInfoFromRouterConfig(
      this.router.config,
      _route => _route?.data?.['viewName'] === viewName && checkViewFeatureFlag(viewName, _route, this.systemStatus?.featureFlags()),
      _routes => _routes,
      undefined,
    );

    const link =
      route
        ?.map(r => r.path)
        .reverse()
        .join('/') || '';
    const label = route?.[0]?.data?.['label'] || viewName || '';

    return {
      link,
      viewName: viewName,
      label,
    };
  }
}
