import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gc-section-message',
  templateUrl: './section-message.component.html',
  styleUrls: ['./section-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionMessageComponent {
  @Input() type: 'info' | 'change' | 'success' | 'warning' | 'error' | string = 'info';
  @Input() title?: string;
  @Input() message?: string;
  @Input() moreInfoLink?: string;
  @Input() set moreInfo(value: string) {
    this.moreInfoText = value === undefined ? 'More Info' : value;
  }

  @Output() moreInfoClick = new EventEmitter();
  @Output() closeMsg = new EventEmitter();

  moreInfoText = '';

  clickMoreInfo($event: Event): void {
    if (this.moreInfoLink !== 'modal') {
      this.closeMessage();

      return;
    }

    $event.preventDefault();
    this.moreInfoClick?.emit();
    this.closeMessage();
  }

  closeMessage(): void {
    this.closeMsg?.emit();
    this.message = '';
  }
}
