import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  API_URL,
  REQUEST_SCOPE,
  COMMON_REQUEST,
  CLUSTER_MANAGER_ALLOWED_VIEWS,
  HttpStatusCodes,
  skipCasingConverters,
} from '@guardicore-ui/shared/data';
import { setUnderlineColors, toCamel } from '@guardicore-ui/shared/utils';
import { EMPTY, Observable, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

import { SystemStatusStore } from './system-status.store';
import { SystemStatusState, SystemStatusUserPreferences } from '../entities';

@Injectable({
  providedIn: 'root',
})
export class SystemStatusService {
  constructor(
    @Inject(API_URL) private readonly apiUrl: string,
    @Inject(DOCUMENT) private readonly doc: Document,
    private readonly http: HttpClient,
    private readonly store: SystemStatusStore,
  ) {}

  read(): Observable<SystemStatusState> {
    this.store.setLoading(true);
    const context = skipCasingConverters();

    context.set(REQUEST_SCOPE, COMMON_REQUEST);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.get<{ [key: string]: any }>(`${this.apiUrl}/system-status`, { context }).pipe(
      map(resp => {
        let permissions = resp['login']['allowed_views_permissions'];
        const state = toCamel<SystemStatusState>(resp) as SystemStatusState;

        if (state?.featureFlags?.centraCluster?.isManager && state.featureFlags?.centraCluster?.centraClusterEnabled) {
          permissions = Object.keys(permissions).reduce(
            (acc, viewName) => (CLUSTER_MANAGER_ALLOWED_VIEWS.includes(viewName) ? { ...acc, [viewName]: permissions[viewName] } : acc),
            {},
          );

          resp['login']['allowed_views_permissions'] = permissions;
        }

        if (state.login) {
          state.login.allowedViewsPermissions = permissions as { [key: string]: string[] };
        }

        state.originalSystemStatus = resp;

        return state;
      }),
      tap((resp: SystemStatusState) => {
        this.store.update({ ...resp });
        this.store.setLoading(false);
        const labelForGrouping = resp.configuration?.defaultLabelForGrouping
          ?.split(',')
          .map(key => key.trim().toLowerCase())
          .filter(Boolean)
          .map((key, colorIndex) => ({ key, colorIndex }))
          .slice(0, 5);

        if (!labelForGrouping) {
          return;
        }

        setUnderlineColors(this.doc.documentElement, labelForGrouping);
      }),
    );
  }

  acceptEula(): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/system/user/eula`, { action: 'accept_eula' });
  }

  acceptEvaluation(): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/system/user/evaluation`, { action: 'accept_evaluation' });
  }

  saveUserPreferences(preferences: SystemStatusUserPreferences, username: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/system/user/preferences`, { ...preferences, username });
  }

  confirmNewNavigation(): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/system/user/confirm_v45_navbar`, {});
  }

  openLink(url: string): Observable<string | unknown> {
    return this.http
      .get(`${this.apiUrl}/${url}`)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          error.status === HttpStatusCodes.NOT_ACCEPTABLE && error.headers.get('location')
            ? of(error.headers.get('location') as string)
            : EMPTY,
        ),
      );
  }
}
