<div class="configuration-widget-wrapper">
  <p class="title">{{ to.label }}</p>
  <div class="input">
    <div *ngIf="!to['conflict'] || (to['conflict'] && overrideConflict)" class="checkbox-input">
      <input type="checkbox" [formlyAttributes]="field" [formControl]="control" />
      <label [innerHTML]="to.description"></label>
    </div>
    <gc-configuration-widget-warnings
      (toggleOverrideConflict)="toggleOverrideConflict()"
      [hasConflict]="!!to['conflict']"
      [overrideConflict]="overrideConflict"
      [customWarning]="to['custom_warning']"
    ></gc-configuration-widget-warnings>
  </div>
</div>
