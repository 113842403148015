import { InjectionToken } from '@angular/core';

interface TokenDuration {
  [key: string]: number;
}

export class ToastrConfig {
  duration?: TokenDuration;
  disableTimeout?: boolean;
  data?: {
    state: string;
    title: string | undefined;
    message: string;
  };

  constructor() {
    this.duration = { success: 5000 };
  }
}

export function createDefaultToastrConfig(): ToastrConfig {
  return {
    duration: {
      error: 10000,
      warning: 7000,
      success: 5000,
    },
    disableTimeout: false,
  };
}

export const TOASTR_AFTER_MOUSELEAVE_DURATION = 2000;

export const TOASTR_DATA = new InjectionToken<ToastrConfig>('__TOASTR_DATA__');
