export const guardicoreTitle = `<svg viewBox="0 0 64 10" xmlns="http://www.w3.org/2000/svg">
<path d="M0 5.31267V5.20877C0 2.46298 1.80053 0.501709 4.34676 0.501709C6.20987 0.501709 7.86565 1.41093 8.09755 3.60987H6.47716C6.2824 2.38506 5.50335 1.85641 4.37016 1.85641C2.66643 1.85641 1.67916 3.19684 1.67916 5.22173V5.32567C1.67916 7.2986 2.54361 8.6533 4.37016 8.6533C6.00254 8.6533 6.67221 7.60775 6.72134 6.33096H4.52837V5.03991H8.316V5.89195C8.316 8.44549 6.80586 9.99243 4.34676 9.99243C1.55751 9.99243 0 8.03247 0 5.31267Z"/>
<path d="M9.63916 7.43897V3.11768H11.111V7.25842C11.111 8.27801 11.5134 8.74042 12.3658 8.74042C13.1569 8.74042 13.8511 8.2001 13.8511 7.12855V3.11768H15.3229V9.86261H13.8511V8.80666C13.5347 9.45609 12.87 9.99121 11.8298 9.99121C10.5764 9.99251 9.63916 9.23268 9.63916 7.43897Z"/>
<path d="M16.5486 7.99323C16.5486 6.42032 17.9874 5.85271 19.8136 5.85271H20.605V5.50593C20.605 4.60321 20.3128 4.15123 19.4481 4.15123C18.6804 4.15123 18.3026 4.54089 18.2304 5.20848H16.8051C16.9276 3.63557 18.1076 2.97705 19.5329 2.97705C20.9582 2.97705 22.078 3.59662 22.078 5.44488V9.87135H20.6292V9.04658C20.2149 9.62588 19.6554 9.99995 18.6804 9.99995C17.5116 9.99215 16.5486 9.40118 16.5486 7.99323ZM20.605 7.50356V6.83334H19.8493C18.7281 6.83334 18.0096 7.1035 18.0096 7.92828C18.0096 8.49849 18.3014 8.87255 19.0325 8.87255C19.9353 8.87255 20.605 8.36729 20.605 7.50356Z"/>
<path d="M23.7151 3.11701H25.1869V4.3821C25.5881 3.53006 26.1853 3.0287 27.2574 3.0144V4.47171C25.966 4.48601 25.1869 4.92242 25.1869 6.35633V9.86325H23.7151V3.11701Z"/>
<path d="M27.9551 6.60078V6.49427C27.9551 4.34076 29.1608 2.97309 30.8177 2.97309C31.8518 2.97309 32.4981 3.47703 32.8502 4.14204V0H34.322V9.87129H32.8502V8.7374C32.5092 9.40888 31.6936 9.99986 30.7563 9.99986C29.1608 9.99206 27.9551 8.79322 27.9551 6.60078ZM32.8993 6.52413V6.42023C32.8993 4.93823 32.2542 4.20309 31.1821 4.20309C30.11 4.20309 29.4649 5.00188 29.4649 6.45918V6.56312C29.4649 8.04639 30.1714 8.75426 31.1219 8.75426C32.1448 8.75426 32.8993 8.03209 32.8993 6.52413Z"/>
<path d="M35.9019 1.12097C35.8946 0.937423 35.9388 0.755697 36.0291 0.598756C36.1192 0.441815 36.2514 0.316713 36.4087 0.239295C36.566 0.161876 36.7415 0.135637 36.9129 0.163857C37.0842 0.192077 37.2439 0.273518 37.3717 0.397845C37.4995 0.522204 37.5896 0.683848 37.6305 0.862387C37.6714 1.04089 37.6612 1.22828 37.6015 1.40079C37.5419 1.57332 37.4354 1.72321 37.2954 1.83154C37.1553 1.93987 36.9877 2.00179 36.8146 2.0094H36.7778C36.5526 2.01432 36.3344 1.9253 36.1707 1.76155C36.0069 1.59777 35.9104 1.37238 35.9019 1.13397V1.12097ZM36.0601 3.1173H37.5319V9.86224H36.0601V3.1173Z"/>
<path d="M38.8384 6.57488V6.47357C38.8384 4.33305 40.276 2.97705 42.0531 2.97705C43.4662 2.97705 44.7933 3.64727 44.9637 5.50593H43.5387C43.4159 4.60321 42.8567 4.22915 42.0777 4.22915C41.067 4.22915 40.3482 5.05392 40.3482 6.44628V6.55018C40.3482 8.02049 41.0302 8.79331 42.1134 8.79331C42.8933 8.79331 43.563 8.30234 43.649 7.349H44.9983C44.8877 8.89591 43.7803 9.99215 42.0543 9.99215C40.2269 9.99215 38.8384 8.72966 38.8384 6.57488Z"/>
<path d="M45.7856 6.56318V6.45927C45.7856 4.37074 47.1864 2.97705 49.0606 2.97705C50.9348 2.97705 52.3257 4.35774 52.3257 6.42032V6.52422C52.3257 8.62575 50.9249 9.99215 49.0495 9.99215C47.1741 9.99215 45.7856 8.61409 45.7856 6.56318ZM50.8147 6.53592V6.44628C50.8147 5.05392 50.1573 4.20318 49.0606 4.20318C47.964 4.20318 47.2958 5.03963 47.2958 6.43461V6.53592C47.2958 7.91658 47.9409 8.78031 49.0606 8.78031C50.1573 8.78031 50.8147 7.92308 50.8147 6.53592Z"/>
<path d="M53.5959 3.11701H55.0678V4.3821C55.469 3.53006 56.0662 3.0287 57.1382 3.0144V4.47171C55.8468 4.48601 55.0678 4.92242 55.0678 6.35633V9.86325H53.5959V3.11701Z"/>
<path d="M57.8254 6.56318V6.45927C57.8254 4.37074 59.1747 2.97705 60.9922 2.97705C62.5758 2.97705 63.9999 3.97068 63.9999 6.38136V6.83334H59.3391C59.3818 8.11013 59.9953 8.83227 61.0893 8.83227C61.9786 8.83227 62.4383 8.4582 62.55 7.79968H63.9754C63.768 9.21803 62.6852 9.99085 61.0536 9.99085C59.1783 9.99215 57.8254 8.6907 57.8254 6.56318ZM62.5378 5.78389C62.4655 4.624 61.9061 4.10964 60.9922 4.10964C60.1032 4.10964 59.5058 4.74091 59.3599 5.78389H62.5378Z"/>
</svg>
`;
