import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserPreferences } from '@guardicore-ui/shared/data';

@Component({
  selector: 'gc-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPreferencesComponent implements OnInit {
  blindMode = false;

  form = this.fb.group({
    isColorBlind: [this.blindMode],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { blindMode?: boolean },
    protected readonly ref: MatDialogRef<UserPreferencesComponent>,
    private readonly fb: FormBuilder,
  ) {
    this.blindMode = data.blindMode ?? this.blindMode;
  }

  ngOnInit(): void {
    this.form.get('isColorBlind')?.setValue(this.blindMode);
  }

  save(): void {
    this.ref.close(<UserPreferences>{
      isColorBlind: this.form.get('isColorBlind')?.value === true,
    });
  }

  cancel(): void {
    this.ref.close();
  }
}
