import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FiltersUiModule } from '@guardicore-ui/filters/ui';
import { UiButtonModule } from '@guardicore-ui/ui/button';
import { UiCommonModule } from '@guardicore-ui/ui/common';
import { UiFormComponentsModule } from '@guardicore-ui/ui/form-components';
import { UiIconModule } from '@guardicore-ui/ui/icon';

import { SelectionFilterComponent } from './selection-filter.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, UiCommonModule, FiltersUiModule, UiFormComponentsModule, UiButtonModule, UiIconModule],
  declarations: [SelectionFilterComponent],
  exports: [SelectionFilterComponent],
})
export class SelectionFilterModule {}
