/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { AbstractConstructor, Constructor } from '@guardicore-ui/shared/data';

export interface CanBeSelected {
  selected: boolean;
}

type CanBeSelectedConstructor = AbstractConstructor<CanBeSelected>;

export function mixinSelected<T extends AbstractConstructor<{}>>(base: T): CanBeSelectedConstructor & T;
export function mixinSelected<T extends Constructor<{}>>(base: T): CanBeSelectedConstructor & T {
  return class extends base {
    protected _selected = false;

    get selected(): boolean {
      return this._selected;
    }

    set selected(value: BooleanInput) {
      this._selected = coerceBooleanProperty(value);
    }

    constructor(...args: any[]) {
      super(...args);
    }
  };
}
