import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewChildren, OnDestroy, QueryList } from '@angular/core';
import { AuthenticationFacade } from '@guardicore-ui/authentication/domain';
import { HybridMainFacade, HybridDraftsFacade } from '@guardicore-ui/hybrid/domain';
import { MsspDropdownComponent } from '@guardicore-ui/mssp/ui';
import { NotificationsActionEvent, NotificationsFacade } from '@guardicore-ui/notifications/domain';
import { QuickLinksFacadeService } from '@guardicore-ui/quick-links/domain';
import { SystemLogPermission, MenuActionWithIcon } from '@guardicore-ui/shared/data';
import { PollingService } from '@guardicore-ui/shared/polling';
import { SystemStatusQuery } from '@guardicore-ui/shared/system-status';
import { PopoverAnchorService, PopoverClickAnchorDirective } from '@guardicore-ui/ui/popovers/popover';
import { UserMenuAction } from '@guardicore-ui/ui/user-menu';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, shareReplay, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { CoreQuery, CoreStore } from '../../state';

@Component({
  selector: 'gc-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PopoverAnchorService],
})
export class MainHeaderComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private notificationsClosed$ = new Subject<void>();

  @ViewChildren(PopoverClickAnchorDirective) readonly popoverTemplates?: QueryList<PopoverClickAnchorDirective>;
  @ViewChildren(MsspDropdownComponent) readonly msspDropdownComponent?: QueryList<MsspDropdownComponent>;
  @Output() userMenuAction = new EventEmitter<UserMenuAction>();

  readonly systemStatusLogUrl = `${location.origin}/${environment.apiUrl}/system-status/log;`;
  readonly user$ = this.systemStatus.user$;
  readonly userMenuSet$ = this.systemStatus.userMenuSet$;
  readonly installingPatch$ = this.systemStatus.installingPatch$;
  readonly notificationsItems$ = this.notifications.items$;
  readonly notifications$ = this.notifications.state$;

  readonly notificationsPermissions$: Observable<SystemLogPermission> = this.systemStatus.allowedViewsPermissions$.pipe(
    map(userPermission => {
      const logPermissions = userPermission?.['Log'] || [];

      return {
        view: logPermissions.includes('View'),
        edit: logPermissions.includes('Edit'),
      };
    }),
  );

  drafts$: Observable<MenuActionWithIcon<string>[]> = this.hybridDraftsFacade.drafts$.pipe(shareReplay(1));

  readonly closeNotifications$ = new Subject<number>();

  constructor(
    private readonly auth: AuthenticationFacade,
    private readonly coreStore: CoreStore,
    readonly systemStatus: SystemStatusQuery,
    private readonly hybridFacade: HybridMainFacade,
    private readonly hybridDraftsFacade: HybridDraftsFacade,
    public readonly notifications: NotificationsFacade,
    readonly coreQuery: CoreQuery,
    readonly pollingService: PollingService,
    private readonly quickLinksFacadeService: QuickLinksFacadeService,
  ) {}

  ngOnInit(): void {
    this.quickLinksFacadeService.read().subscribe();
    this.hybridFacade.clickOnIframe$.pipe(tap(() => this.closeNotifications$.next(Date.now()))).subscribe(() => this.hidePopovers());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.notificationsClosed$.next();
  }

  onUserMenuAction(action: UserMenuAction): void {
    this.hidePopovers();
    this.userMenuAction.next(action);
  }

  onNotificationsAction(notificationsAction: NotificationsActionEvent): void {
    const { action, notification } = notificationsAction;

    switch (action) {
      case 'dismissAll':
        this.closeNotifications$.next(Date.now());
        this.notifications.dismissAll().pipe(takeUntil(this.destroy$)).subscribe();
        break;
      case 'dismissOne':
        if (notification) {
          this.notifications.dismissOne(notification).pipe(takeUntil(this.destroy$)).subscribe();
        }

        break;
      default:
        this.closeNotifications$.next(Date.now());
        break;
    }
  }

  hidePopovers(): void {
    if (!this.popoverTemplates?.length) {
      return;
    }

    if (this.msspDropdownComponent?.get(0)) {
      this.msspDropdownComponent.get(0)?.closePopover();
    }

    this.popoverTemplates.forEach(t => t.close());
  }
}
