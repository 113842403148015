import { FilterOptionsEntity } from '@guardicore-ui/shared/data';

export interface FilterOptions {
  availableOptions: FilterOptionsEntity[];
  displayMore: boolean;
  filterValue: string;
  isElasticDb: boolean;
  limit: number;
  offset: number;
  selectedOptions: FilterOptionsEntity[];
  totalCount: number;
}
