import { FilterOptionsEntity } from '@guardicore-ui/shared/data';
import { FilterOptions } from '@guardicore-ui/ui/filters/common';

export const VALUE_NO_TENANT = 'no-tenant';

export interface MsspDropdownConfig {
  title: string;
  titleValue?: string;
  isTitleValueShown?: boolean;
  iconName: string;
}

export interface TenantsAssignment {
  selectedTenant?: FilterOptionsEntity;
  apiSegmentUrl?: string;
}

export interface MsspState {
  options?: FilterOptions;
  selected?: FilterOptionsEntity;
  tenantsAssignment?: TenantsAssignment;
}

export function createInitialState(): MsspState {
  return {};
}

export const TENANT_FILTER_ID = 'tenant';
export const TENNANTS_ASSIGNMENT_FILTER_ID = 'cluster_id';
