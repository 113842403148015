import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiIconModule } from '@guardicore-ui/ui/icon';

import { InlineAlertComponent } from './inline-alert/inline-alert.component';

@NgModule({
  imports: [CommonModule, UiIconModule],
  declarations: [InlineAlertComponent],
  exports: [InlineAlertComponent],
})
export class UiInlineAlertModule {}
