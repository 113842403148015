import { Injectable } from '@angular/core';
import { FilterOptionsEntity } from '@guardicore-ui/shared/data';

import { MsspStore, MsspQuery } from '../infrastructure';

@Injectable({
  providedIn: 'root',
})
export class MsspUiFacade {
  readonly options$ = this.query.options$;
  readonly selected$ = this.query.selected$;
  readonly error$ = this.query.selectError();

  constructor(private readonly query: MsspQuery, private readonly store: MsspStore) {}

  resetOptions(): void {
    this.store.update({ options: undefined });
  }

  select(tenant: FilterOptionsEntity): void {
    this.store.update({ selected: tenant });
  }

  unselect(): void {
    this.store.update({ selected: undefined });
  }
}
