import { ComponentType } from '@angular/cdk/overlay';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PartialRecord } from '@guardicore-ui/shared/api';
import { isObjectsEmpty, isUndefined } from '@guardicore-ui/shared/utils';
import { BulkOperationDialogComponent, BulkOperationDialogData, DialogData } from '@guardicore-ui/ui/dialogs';
import { ICellRendererParams, ColDef, GridApi } from 'ag-grid-enterprise';
import { BehaviorSubject, distinctUntilChanged, filter, Observable, shareReplay, Subject, take } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CHECKBOX_SELECTION_CELL_ID } from './grid-constant.constant';
import { GridDataSource } from './grid-data-source.service';
import { ConfirmActionCellComponent } from '../confirm-action-cell/confirm-action-cell.component';
import { EmptyCellComponent } from '../empty-cell/empty-cell.component';
import { GRID_DATA_SOURCE } from '../entities';

export const CREATE_NEW_ROW_SERVICE = new InjectionToken('CREATE_NEW_ROW_SERVICE');
export const NEW_ROW_ID = -1;
export const CONFIRM_ACTION_COLUMN_ID = '__CONFIRM_ACTION_COLUMN_ID__';

export interface NewRowColumnOption {
  cellRenderer: ComponentType<unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validations?: (data: any) => boolean;
  isRequire?: boolean;
  value?: unknown;
}

export interface NewRowOption<T = object> {
  columns: PartialRecord<keyof T, NewRowColumnOption>;
  discardDialogData: (rowData: T) => BulkOperationDialogData | DialogData;
}

export interface CreateNewRow {
  readonly isRowValid$: Observable<boolean>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly confirmAction$: PartialRecord<keyof unknown, any> | undefined;
  readonly isRowOpen$: Observable<boolean>;

  isNewRowOpen: boolean;
  isDiscardModalOpen: boolean;
  isNewRowDirty: boolean;
  rowData: Partial<unknown>;
  getRowData(params: ICellRendererParams): unknown;
  updateRowData(params: ICellRendererParams, value: unknown): void;
  getColumn(params: ICellRendererParams): ComponentType<unknown> | undefined;

  addRow<T>(option: NewRowOption<T>): void;
  removeRow(): void;
  confirmActionClick(condition: boolean): void;
}

@Injectable()
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class CreateNewRowService implements CreateNewRow {
  private _rowData = {};
  private _isNewRowDirty = false;
  private columnDefs: Map<ColDef['field'], ColDef> = new Map();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private rowOptions?: NewRowOption<any> = undefined;
  private readonly _isRowValid$ = new BehaviorSubject<boolean>(false);
  private readonly _confirmAction$ = new Subject();
  private readonly _isOpen$ = new BehaviorSubject<boolean>(false);
  isDiscardModalOpen = false;

  readonly isRowValid$ = this._isRowValid$.asObservable().pipe(distinctUntilChanged());
  readonly isRowOpen$ = this._isOpen$.asObservable().pipe(distinctUntilChanged(), shareReplay({ refCount: true, bufferSize: 1 }));
  readonly confirmAction$ = this._confirmAction$.asObservable();

  static isNewRow(params: ICellRendererParams): boolean {
    return params?.data.id === NEW_ROW_ID || !!params?.node?.rowPinned;
  }

  get isNewRowOpen(): boolean {
    return this._isOpen$.getValue();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get rowData(): any {
    return this._rowData;
  }

  get isNewRowDirty(): boolean {
    return this._isNewRowDirty;
  }

  get confirmActionColumn(): ColDef {
    return {
      colId: CONFIRM_ACTION_COLUMN_ID,
      field: '',
      minWidth: 150,
      maxWidth: 150,
      cellRenderer: ConfirmActionCellComponent,
      suppressMenu: true,
      menuTabs: [],
    };
  }

  constructor(@Inject(GRID_DATA_SOURCE) readonly dataSource: GridDataSource, private dialog: MatDialog) {}

  getRowData(params: ICellRendererParams): unknown {
    const column = params?.colDef?.field as string;

    return this.rowData[column];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateRowData(params: ICellRendererParams, value: any): void {
    const column = params?.colDef?.field;

    if (isUndefined(column)) {
      return;
    }

    // noinspection TypeScriptValidateTypes
    this.rowData[column] = value;
    this._isNewRowDirty = true;
    this.validateData();
  }

  getColumn(params: ICellRendererParams): ComponentType<unknown> | undefined {
    const field = params?.colDef?.field;

    if (isUndefined(field) || isUndefined(this.rowOptions?.columns[field])) {
      return;
    }

    const column = this.rowOptions?.columns[field] as NewRowColumnOption;

    return column?.cellRenderer || EmptyCellComponent;
  }

  addRow<C>(options: NewRowOption<C>): void {
    if (this.isNewRowOpen) {
      return;
    }

    this.toggleOpen();
    this.resetRowData();

    this.rowOptions = options as NewRowOption<C>;

    if (this.columnDefs.size === 0) {
      ((this.dataSource.gridApi?.getColumnDefs() as ColDef[]) || []).forEach((col: ColDef) => {
        this.columnDefs.set(col.colId || col.field, col);
      });
    }

    const selectColumn = {
      ...(this.columnDefs.get(CHECKBOX_SELECTION_CELL_ID) && {
        ...this.columnDefs.get(CHECKBOX_SELECTION_CELL_ID),
        headerComponent: EmptyCellComponent,
        cellRenderer: EmptyCellComponent,
      }),
    };

    const columnDefs = Object.entries((this.rowOptions as NewRowOption<C>).columns).map(([colId, option]) => ({
      ...this.columnDefs.get(colId),
      cellRenderer: (option as NewRowColumnOption).cellRenderer,
    }));

    const columnDefsWithSelection = isObjectsEmpty(selectColumn) ? columnDefs : [selectColumn, ...columnDefs];
    const newRow = Object.entries((this.rowOptions as NewRowOption<C>).columns).reduce(
      (state, [requireColumnName, requireColumn]) => ({ ...state, [requireColumnName]: (requireColumn as NewRowColumnOption).value }),
      {
        id: NEW_ROW_ID,
      },
    );

    (<GridApi>this.dataSource.gridApi).applyServerSideTransaction({
      add: [newRow],
      addIndex: 0,
    });

    // set new row columns
    (<GridApi>this.dataSource.gridApi).setColumnDefs([...columnDefsWithSelection, this.confirmActionColumn]);
    this.validateData();
  }

  removeRow(): void {
    if (!this.isNewRowOpen) {
      return;
    }

    this.toggleOpen();

    const newRow = (<GridApi>this.dataSource.gridApi).getDisplayedRowAtIndex(0)?.data;

    (<GridApi>this.dataSource.gridApi).applyServerSideTransaction({
      remove: [newRow],
    });
    // return to init columns
    (<GridApi>this.dataSource.gridApi).setColumnDefs([...this.columnDefs.values()]);
    this.dataSource.preventDataRefresh = false;
  }

  confirmActionClick(condition: boolean): void {
    if (condition) {
      this._confirmAction$.next(this.rowData);

      return;
    }

    if (!this.isNewRowDirty) {
      this.removeRow();

      return;
    }

    this.isDiscardModalOpen = true;

    this.dialog
      .open(BulkOperationDialogComponent, {
        data: this.rowOptions?.discardDialogData(this.rowData) || {},
      })
      .afterClosed()
      .pipe(
        take(1),
        tap(() => (this.isDiscardModalOpen = false)),
        filter(res => res?.confirmed === true),
        tap(() => {
          this.removeRow();
          this.dataSource.preventDataRefresh = false;
        }),
      )
      .subscribe();
  }

  private resetRowData(): void {
    this._rowData = {};
    this._isNewRowDirty = false;
    this.rowOptions = undefined;
  }

  private validateData(): void {
    if (isUndefined(this.rowOptions)) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isDataValid = Object.keys(this.rowOptions.columns).every(key => {
      const column = this.rowOptions?.columns[key];

      if (column?.isRequire && column.validations) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return column.validations(this.rowData[key]);
      }

      return true;
    });

    this._isRowValid$.next(isDataValid);
  }

  private toggleOpen(): void {
    this._isOpen$.next(!this.isNewRowOpen);
    this.dataSource.store.update({ isNewRowOpen: this.isNewRowOpen });
  }
}
