/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { ComponentType } from '@angular/cdk/overlay';
import { AbstractConstructor, Constructor, HasElementRef, HasZone } from '@guardicore-ui/shared/data';
import { Observable, Subject, Subscription } from 'rxjs';

import { OpenPopoverOptions } from '../entities';
import { PopoverRefV2 } from '../popover-ref';
import { PopoverService } from '../popover.service';

export interface PopoverAnchor<C, InputData = unknown, OutputData = unknown> {
  popoverContentComponent?: ComponentType<C>;
  popoverRef?: PopoverRefV2<C, InputData, OutputData>;
  closeOnClickOutside: boolean;
  openPopoverOptions?: OpenPopoverOptions;
  isPopoverOpen: boolean;
  popoverOpen$: Observable<void>;
  popoverClose$: Observable<void>;
  togglePopover(): void;
  closePopover(): void;
}

export interface HasPopoverService {
  popoverService: PopoverService;
}

export type PopoverBaseAbstract = AbstractConstructor<HasPopoverService & HasElementRef & HasZone>;
export type PopoverBase = Constructor<HasPopoverService & HasElementRef & HasZone>;

export type PopoverAnchorConstructor<C, InputData = unknown, OutputData = unknown> = AbstractConstructor<
  PopoverAnchor<C, InputData, OutputData>
> &
  Constructor<PopoverAnchor<C, InputData, OutputData>>;

export function mixinPopoverAnchor<
  T extends AbstractConstructor<HasPopoverService & HasElementRef & HasZone>,
  C,
  InputData = unknown,
  OutputData = unknown,
>(base: T): PopoverAnchorConstructor<C, InputData, OutputData> & T;
export function mixinPopoverAnchor<
  T extends Constructor<HasPopoverService & HasElementRef & HasZone>,
  C,
  InputData = unknown,
  OutputData = unknown,
>(base: T): PopoverAnchorConstructor<C, InputData, OutputData> & T {
  return class extends base implements PopoverAnchor<C, InputData, OutputData> {
    protected readonly isPopoverOpenSubj = new Subject<void>();
    protected readonly isPopoverClosedSubj = new Subject<void>();
    protected clickStateSubscription?: Subscription;

    popoverContentComponent?: ComponentType<C>;
    popoverRef?: PopoverRefV2<C, InputData, OutputData>;
    closeOnClickOutside = false;
    openPopoverOptions?: OpenPopoverOptions;

    get isPopoverOpen(): boolean {
      return !!this.popoverRef;
    }

    readonly popoverOpen$ = this.isPopoverOpenSubj.asObservable();
    readonly popoverClose$ = this.isPopoverClosedSubj.asObservable();

    constructor(...args: any[]) {
      super(...args);
    }

    togglePopover(): void {
      if (!this.popoverContentComponent) {
        throw new Error('No content component provided.');
      }

      if (this.isPopoverOpen) {
        this.closePopover();

        return;
      }

      this.popoverRef = this.popoverService.open<C, InputData, OutputData>(this.popoverContentComponent, this.openPopoverOptions);
      this.isPopoverOpenSubj.next();
      this.clickStateSubscription = this.popoverRef.clickState$?.subscribe(
        clickState => this.closeOnClickOutside && !clickState.isInsideHost && this.closePopover(),
      );
      const afterDismissSubscription = this.popoverRef.afterDismiss$.subscribe(() => {
        afterDismissSubscription.unsubscribe();
        this.popoverRef = undefined;
      });
    }

    closePopover(): void {
      this.clickStateSubscription?.unsubscribe();
      this.popoverRef?.dismiss();
      this.popoverRef = undefined;
      this.isPopoverClosedSubj.next();
    }
  };
}
