import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FeatureWithPaginationAndFiltersState } from '@guardicore-ui/shared/data';

import { CsvExportDialogComponent } from '../csv-export-dialog/csv-export-dialog.component';
import { CSVExportConfig, CSVExportConfigBase } from '../types';

@Component({
  selector: 'gc-csv-export-button, [gcCsvExportButton]',
  templateUrl: './csv-export-button.component.html',
  styleUrls: ['./csv-export-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CsvExportButtonComponent {
  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled || !this.featureState?.pageState.totalCount;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }

  @Input() featureState?: FeatureWithPaginationAndFiltersState | null;
  @Input() config?: CSVExportConfigBase;

  constructor(private readonly dialogService: MatDialog) {}

  openCsvExportDialog(): void {
    if (!this.config || !this.featureState) {
      throw new Error('CSV export configuration was not provided.');
    }

    if (!this.featureState.pageState.totalCount) {
      return;
    }

    const data: CSVExportConfig = {
      ...this.config,
      totalItems: this.featureState.pageState.totalCount,
      filters: this.featureState.filtersState,
    };

    this.dialogService.open(CsvExportDialogComponent, { data });
  }
}
