export interface DialogData {
  icon?: string;
  content?: string;
  title?: string;
  primaryButtonLabel?: string;
  primaryButtonDisabled?: boolean;
  secondaryButtonLabel?: string;
  secondaryButtonsGhost?: boolean;
  showConfirmAsDanger?: boolean;
  closeIcon?: boolean;
  helpLink?: string;
  keepOpenAfterConfirm?: boolean;
  keepOpenAfterDismiss?: boolean;
  readOnly?: boolean;
}

export enum DialogUserActions {
  primaryButtonClicked = 'primaryButtonClicked',
  secondaryButtonClicked = 'secondaryButtonClicked',
}
