import { Component, ChangeDetectionStrategy } from '@angular/core';

import { ConfigurationWidgetBaseComponent } from '../configuration-widget-base/configuration-widget-base.component';

@Component({
  selector: 'gc-configuration-boolean',
  templateUrl: './configuration-boolean.component.html',
  styleUrls: ['../configuration-widget-base/configuration-widget-base.component.scss', './configuration-boolean.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigurationBooleanComponent extends ConfigurationWidgetBaseComponent {}
