import { InjectionToken } from '@angular/core';
import { GlobalError, SortingOrder, RowDataObject, FeatureWithPaginationAndFiltersState } from '@guardicore-ui/shared/data';
import { RowOperations } from '@guardicore-ui/shared/data';

import { PageStatus, SelectedRowsState } from '../entities';

export const DEFAULT_PAGE_SIZE = 20;

export interface SortData {
  colId: string;
  sort: SortingOrder;
}

export const GRID_COMPONENT_STORE_NAME = new InjectionToken<string>('__GRID_COMPONENT_STORE_NAME__');

export const DEFINED_QUERY_PARAMS = ['offset', 'limit', 'sort'];

export interface GridComponentState<T extends RowDataObject = RowDataObject> {
  error?: GlobalError;
  showPageWarning?: boolean;
  busy: boolean;
  isEmpty: boolean;
  isNewRowOpen: boolean;
  pageStatus: PageStatus;
  newRowVisibleColumnIds?: string[];
  visibleColumnIds?: string[];
  scrollToRowId?: string;
  highlightRowIds?: string[];
  selectedRows: SelectedRowsState<T>;
  rowData?: T[];
  rowOperations?: RowOperations<string>[];
  maxSortingFields: number;
  featureState?: FeatureWithPaginationAndFiltersState;
}

export function createInitialState(): GridComponentState {
  return {
    error: undefined,
    busy: false,
    isEmpty: false,
    isNewRowOpen: false,
    pageStatus: 'initial-loading',
    selectedRows: {
      selected: 0,
      selectedData: [],
      selectedRowIds: [],
      unselectedRowIds: [],
      isUnselectionMode: false,
    },
    maxSortingFields: 1,
  };
}
