import { Label } from '../labels';

export type FiltersListValue = string | number | boolean | null;

export interface FilterOptionsEntity {
  text?: string | Label[];
  value: FiltersListValue;
  count?: number;
  isDisabled?: boolean;
  isSelected?: boolean;
  type?: string;
}
