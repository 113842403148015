export enum AlarmState {
  unknown = 'UNKNOWN',
  noAlarm = 'NO_ALARM',
  newAlarm = 'ALARM_NEW',
  ackedAlarm = 'ALARM_ACKED',
}

export enum Status {
  completed = 'COMPLETED',
  info = 'INFO',
  warning = 'WARNING',
  error = 'ERROR',
}

export interface NotificationQueryParams {
  search: string;
  fromTime: number;
  toTime: number;
}

export interface SystemLogPermission {
  view: boolean;
  edit: boolean;
}

export interface SystemLogItem {
  id: string;
  alarmExpiry: string;
  alarmState: AlarmState;
  allOccurrencesDescriptions: string[];
  cause: string;
  count: number;
  description: string;
  errorCode: string;
  eventSource: string;
  eventSourceHostname: string;
  errorType: string;
  expireAt: string;
  isExperimental: boolean;
  lastSeenTime: string;
  origin: string;
  processedTime: string;
  receivedTime: string;
  shortDescription: string;
  status: Status;
  time: string;
  uuid: string;
}
