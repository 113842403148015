import { Route, Routes } from '@angular/router';

// tries to find a route (in the given router config) by the given condition
// if found, returns the chain of routes as an array (from that route up to the root route)
export function findRouteChain(routerConfig: Routes, condition: (route: Route) => boolean): Routes {
  if (!routerConfig) {
    return [];
  }

  return findRouteChainRecurse(routerConfig, condition);
}

function findRouteChainRecurse(routes: Routes, condition: (route: Route) => boolean): Routes {
  for (const route of routes) {
    if (condition(route)) {
      return [route];
    } else if (route.children?.length) {
      const recursiveFind: Routes = findRouteChainRecurse(route?.children, condition);

      if (recursiveFind && recursiveFind.length) {
        recursiveFind.push(route);

        return recursiveFind;
      }
    }
  }

  return [];
}
