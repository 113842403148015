import { SvgIconName } from '@guardicore-ui/shared/data';

export const ALERT_STATUS = ['info', 'success', 'warning', 'error'] as const;
export type AlertStatus = typeof ALERT_STATUS[number];

export const ALERT_STATUS_ICON: Record<AlertStatus, SvgIconName> = {
  info: 'action-info',
  success: 'action-allow',
  warning: 'action-alert',
  error: 'action-block-alert',
};

export const enum CallToActionId {
  PreviousResultsExist = 'PreviousResultsExist',
  Success = 'Success',
  InProgress = 'InProgress',
  Unsupported = 'Unsupported',
  Discarded = 'Discarded',
  Failed = 'Failed',
  Skipped = 'Skipped',
}

export interface InlineAlertCallToAction {
  text: string;
  icon: SvgIconName;
}

export interface InlineAlert {
  status: AlertStatus;
  title?: string;
  body?: string;
  comment?: string;
  callToAction?: Partial<InlineAlertCallToAction>;
  id?: CallToActionId;
}
