import { AfterContentInit, ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ViewStates } from '@guardicore-ui/shared/data';
import { RbacService } from '@guardicore-ui/shared/rbac';
import { SystemStatusQuery } from '@guardicore-ui/shared/system-status';
import { getRouteData } from '@guardicore-ui/shared/utils';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { NavbarComponentQuery } from '../infrastructure/navbar-component-query.service';
import { NavbarComponentStore } from '../infrastructure/navbar-component-store.service';

@Component({
  selector: 'gc-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NavbarComponentQuery, NavbarComponentStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements AfterContentInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  @Input() set viewStates(newViewStates: ViewStates | null) {
    if (newViewStates === null) {
      return;
    }

    this.store.updateFromViewStates(newViewStates);
  }

  readonly isAdminModeAllowed$ = this.rbacService.isAdminModeAllowedAsync();

  constructor(
    private store: NavbarComponentStore,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly rbacService: RbacService,
    readonly systemStatus: SystemStatusQuery,
  ) {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.updateActiveView());
  }

  ngAfterContentInit(): void {
    this.updateActiveView();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private updateActiveView(): void {
    const routeData = getRouteData(this.route);

    this.store.update({ activeItemViewName: routeData.viewName });
  }
}
