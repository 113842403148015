import { Location } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  ErrorHandler,
  AfterViewChecked,
  OnDestroy,
  HostBinding,
} from '@angular/core';
import { SvgIconName } from '@guardicore-ui/shared/data';

import { IconBaseComponent } from '../icon-base';
import { SvgService } from '../infrastructure';

@Component({
  selector: 'gc-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent extends IconBaseComponent implements AfterViewChecked, OnDestroy {
  private _svgIcon!: SvgIconName;

  private elementsWithExternalReferences = new Map();
  private previousPath = '';
  private svgNamespace: string | null = null;
  private svgName: string | null = null;

  @HostBinding('attr.icon-namespace') namespace = this.svgNamespace;
  @HostBinding('attr.icon-type') fontType = 'svg';
  @HostBinding('attr.gc-icon')
  @HostBinding('class.gc-icon')
  icon = '';

  get svgIcon(): SvgIconName {
    return this._svgIcon;
  }

  @Input() set svgIcon(value: SvgIconName | string) {
    if (value !== this._svgIcon) {
      if (value) {
        this.updateSvgIcon(value as SvgIconName);
      } else if (this._svgIcon) {
        this.clearSvgElement();
      }

      this._svgIcon = value as SvgIconName;
    }
  }

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private errorHandler: ErrorHandler,
    private location: Location,
    private svgService: SvgService,
  ) {
    super();
  }

  ngAfterViewChecked(): void {
    const cachedElements = this.elementsWithExternalReferences;

    if (cachedElements && cachedElements.size) {
      const newPath = this.location.path();

      if (newPath !== this.previousPath) {
        this.previousPath = newPath;
        this.prependPathToReferences(newPath);
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    if (this.elementsWithExternalReferences) {
      this.elementsWithExternalReferences.clear();
    }
  }

  private setSvgElement(svg: HTMLElement): void {
    this.clearSvgElement();
    const styleTags = svg.querySelectorAll('style');

    for (let i = 0; i < styleTags.length; i++) {
      styleTags[i].textContent += ' ';
    }

    const path = this.location.path();

    this.previousPath = path;
    this.prependPathToReferences(path);
    this.elementRef.nativeElement.appendChild(svg);
  }

  private clearSvgElement(): void {
    const layoutElement = this.elementRef.nativeElement;
    let childCount = layoutElement.childNodes.length;

    if (this.elementsWithExternalReferences) {
      this.elementsWithExternalReferences.clear();
    }

    while (childCount--) {
      const child = layoutElement.childNodes[childCount];

      if (child.nodeType !== 1 || child.nodeName.toLowerCase() === 'svg') {
        layoutElement.removeChild(child);
      }
    }
  }

  private prependPathToReferences(path: string): void {
    const elements = this.elementsWithExternalReferences;

    if (elements) {
      elements.forEach((attrs, element) => {
        attrs.forEach((attr: Attr) => {
          element.setAttribute(attr.name, `url('${path}#${attr.value}')`);
        });
      });
    }
  }

  private updateSvgIcon(rawName: SvgIconName): void {
    this.svgNamespace = null;
    this.svgName = null;

    if (rawName) {
      const [namespace, iconName] = this.splitIconName(rawName);

      if (namespace) {
        this.svgNamespace = namespace;
      }

      if (iconName) {
        this.svgName = iconName;
      }

      const svg = this.svgService.getNamedSvgIcon(rawName);

      this.setSvgElement(svg as HTMLElement);
    }
  }
}
