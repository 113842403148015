import { SortingOrder } from '../../interfaces';

export type SortState = Record<string, SortingOrder>;
export interface CanBeSorted {
  sortState: SortState;
}

function orderToSign(order: SortingOrder): string {
  return order === 'desc' ? '-' : '';
}

export function sortStateToSortParam(sortState: SortState): string {
  return Object.keys(sortState)
    .filter(key => sortState[key] !== null)
    .map(key => `${orderToSign(sortState[key])}${key}`)
    .join(',');
}

export function parseSortParam(sortParam: string | string[] | null): SortState {
  if (!sortParam) {
    return {};
  }

  const sortParams = (Array.isArray(sortParam) ? sortParam : sortParam.trim().split(',')).map(param => param.toLowerCase());

  return sortParams.reduce<SortState>(
    (prev, curr) => ({ ...prev, [curr.charAt(0) === '-' ? curr.slice(1) : curr]: curr.charAt(0) === '-' ? 'desc' : 'asc' }),
    {},
  );
}
