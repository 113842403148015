<ng-container *ngIf="notificationsTemplate">
  <button
    class="button"
    [ngClass]="{ opened: isShown$ | async }"
    *ngIf="viewModel$ | async as viewModel"
    [gcPopoverClickAnchor]="notificationsTemplate"
    [closeTransition]="'0ms'"
    (opened)="onPopoverStateChange(true)"
    (closed)="onPopoverStateChange(false)"
    panelCssClass="header-dropdown-overlay"
  >
    <gc-icon svgIcon="bell" class="icon"></gc-icon>
    <span class="badge" *ngIf="viewModel.count">{{ viewModel.count }}</span>
  </button>
</ng-container>
