import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationFacade } from '@guardicore-ui/authentication/domain';
import { DIALOG_TOP_POSITION } from '@guardicore-ui/shared/data';
import { PasswordRequirements } from '@guardicore-ui/shared/system-status';

import { ChangePasswordComponent } from './change-password/change-password.component';
import { GenericAuthenticationModalComponent } from './generic-authentication-modal/generic-authentication-modal.component';
import { ManageTwoFaComponent } from './manage-two-fa/manage-two-fa.component';
import { RecoveryCodesComponent } from './recovery-codes/recovery-codes.component';
import { SessionExpirationDialogComponent } from './session-expiration-dialog/session-expiration-dialog.component';

const SECURE_DIALOG_CONFIG: Partial<MatDialogConfig> = {
  disableClose: true,
  autoFocus: true,
  position: DIALOG_TOP_POSITION,
};

@Injectable({
  providedIn: 'root',
})
export class AuthenticationDialogsService {
  private isModalOpened = false;

  constructor(private readonly dialogService: MatDialog, private readonly auth: AuthenticationFacade) {}

  openSetPassword(context?: { username?: string; passwordRequirements?: PasswordRequirements }): MatDialogRef<ChangePasswordComponent> {
    return this.openChangePassword({
      ...SECURE_DIALOG_CONFIG,
      data: {
        dismissButtonLabel: 'Log Out',
        submitButtonLabel: 'Set Password',
        ...context,
      },
    });
  }

  openChangePassword(config?: Partial<MatDialogConfig>): MatDialogRef<ChangePasswordComponent> {
    return this.dialogService.open(ChangePasswordComponent, config);
  }

  public openManageTwoFA(twoFactorAuthPolicyEnforced?: boolean): MatDialogRef<ManageTwoFaComponent> {
    const dialogRef = this.dialogService.open(ManageTwoFaComponent, { data: { twoFactorAuthPolicyEnforced } });

    dialogRef.componentInstance.showRecoveryCodes.subscribe(() => this.openRecoveryCodes());

    return dialogRef;
  }

  public openRecoveryCodes(): MatDialogRef<RecoveryCodesComponent> {
    return this.dialogService.open(RecoveryCodesComponent);
  }

  openSetNewPasswordDemand(content = 'In order to continue you must set a new password'): void {
    if (this.isModalOpened) {
      return;
    }

    this.isModalOpened = true;
    this.dialogService
      .open(GenericAuthenticationModalComponent, {
        ...SECURE_DIALOG_CONFIG,
        data: {
          title: 'Set New Password',
          content,
          primaryButtonLabel: 'Set new password',
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.isModalOpened = false;
        this.auth.logout();
      });
  }

  openSessionExpired(content = 'User token expired'): void {
    if (this.isModalOpened) {
      return;
    }

    this.isModalOpened = true;
    this.dialogService
      .open(GenericAuthenticationModalComponent, {
        ...SECURE_DIALOG_CONFIG,
        data: {
          title: 'Session Expired',
          content,
          primaryButtonLabel: 'Back to login screen',
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.isModalOpened = false;
        this.auth.logout('expired');
      });
  }

  openPermissionDenied(content = 'No permission to access this resource'): void {
    if (this.isModalOpened) {
      return;
    }

    this.isModalOpened = true;
    this.dialogService
      .open(GenericAuthenticationModalComponent, {
        ...SECURE_DIALOG_CONFIG,
        data: {
          title: 'Permission Denied',
          content,
          primaryButtonLabel: 'Dismiss',
          secondaryButtonLabel: 'Log in as another user',
        },
      })
      .afterClosed()
      .subscribe(result => {
        this.isModalOpened = false;
        if (result?.dismissed) {
          this.auth.logout();
        }
      });
  }

  openSessionExpirationTimeout(counterDuration: number): MatDialogRef<SessionExpirationDialogComponent> {
    return this.dialogService.open(SessionExpirationDialogComponent, {
      ...SECURE_DIALOG_CONFIG,
      data: {
        counterDuration,
      },
    });
  }
}
