<div class="gc-dialog-content">
  <div class="gc-dialog-content-header">
    <h3>{{ config.title | titlecase }}</h3>
    <button class="header-close-button" type="button" (click)="closeWithoutChanges()">
      <gc-icon svgIcon="close"></gc-icon>
    </button>
  </div>
  <div class="gc-dialog-content-body">
    <div class="textarea-wrapper">
      <div class="line-number-wrapper" [scrollTop]="scrollTop">
        <div class="line-numbers" [class.invalid-lines]="invalidLines.includes(codeLine)" *ngFor="let codeLine of codeLines">{{codeLine}}</div>
      </div>
      <textarea [rows]="codeLines.length" [disabled]="readOnly" [value]="arrayString" (input)="inputChanged($event)" #textArea></textarea>
    </div>
    <button type="button" class="default-button clear-button" [disabled]="readOnly" (click)="clear()">
      <gc-icon svgIcon="clear"></gc-icon>
      <span>Clear</span>
    </button>
  </div>
  <div class="gc-dialog-content-footer">
    <div class="error-message" *ngIf="invalidLines.length">
      <gc-icon svgIcon="failed"></gc-icon>
      <p>
        <span class="title"
          >{{ 'Item' | pluralize: invalidLines.length }} {{ invalidLines.join(', ') }}
          {{ invalidLines.length === 1 ? 'is' : 'are' }} invalid</span
        >
        <span>Should be {{ requirementsText }}</span>
      </p>
    </div>
    <div class="spacer"></div>
    <button type="button" class="default-button" (click)="closeWithoutChanges()">Cancel</button>
    <button type="button" class="primary-button" [disabled]="readOnly" (click)="apply()">OK</button>
  </div>
</div>
