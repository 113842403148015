import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AnalyticsModule } from '@guardicore-ui/analytics';
import { UiIconModule } from '@guardicore-ui/ui/icon';

import { QuickLinksComponent } from './quick-links/quick-links.component';

@NgModule({
  imports: [CommonModule, RouterModule, UiIconModule, AnalyticsModule],
  declarations: [QuickLinksComponent],
  exports: [QuickLinksComponent],
})
export class QuickLinksUiModule {}
