import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { API_URL } from '@guardicore-ui/shared/data';
import { camelToSnakeCaseKeys } from '@guardicore-ui/shared/utils';
import { Observable, of, EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AuthenticationStore } from './authentication.store';
import {
  LoginCredentials,
  LoginSettings,
  AuthenticationResponse,
  SSOAuthenticationResponse,
  UserTwoFAStatus,
  UserTwoFAQRCode,
  UserTwoFAStatusUpdate,
  UserTwoFAStatusUpdateResponse,
  NewRecoveryCodes,
  LogoutAction,
  SSOAuthenticationParams,
  ChangePasswordRequest,
} from '../entities';
import { checkLocalStorageAccessible } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private isLocalStorageAccessible = checkLocalStorageAccessible();

  constructor(
    private readonly http: HttpClient,
    private readonly authenticationStore: AuthenticationStore,
    private readonly router: Router,
    @Inject(API_URL) private readonly apiUrl: string,
  ) {
    this.authenticationStore.setLoading(false);
  }

  /**
   * Login with username and password.
   */
  login(credentials: LoginCredentials, authPhase?: number): Observable<void | AuthenticationResponse> {
    this.authenticationStore.setLoading(true);

    return this.http.post<AuthenticationResponse>(`${this.apiUrl}/authenticate`, { ...credentials, twoFactorAuthPhase: authPhase }).pipe(
      tap(resp => this.processSuccessLogin(resp)),
      catchError((err: HttpErrorResponse) => {
        this.authenticationStore.setLoading(false);
        if (credentials.isImplicitAuthentication) {
          return of(undefined);
        }

        this.authenticationStore.update({ isLoggedIn: false });

        return of(this.authenticationStore.setError(err));
      }),
    );
  }

  /**
   * Logout from the system. Cleans local storage, state
   * and redirects to '/login' page.
   */
  logout(logoutAction?: LogoutAction): void {
    const params: NavigationExtras = {};

    if (logoutAction === 'expired') {
      params.queryParams = {
        expired: '',
        backlink: `${location.pathname}${location.search}`,
      };
    }

    this.http
      .post<void>(`${this.apiUrl}/logout`, EMPTY)
      .pipe(catchError(() => EMPTY))
      .subscribe();
    this.authenticationStore.reset();
    this.router.navigate(['login'], params);
  }

  loginMessage(): Observable<unknown | string> {
    return this.http.get('/login-message', { responseType: 'text' }).pipe(catchError(() => EMPTY));
  }

  private processSuccessLogin(resp: AuthenticationResponse): void {
    if (!resp) {
      throw new Error('Authentication response error');
    }

    if (resp['2faRequired']) {
      return;
    }

    if (!resp.accessToken || resp.accessToken === 'undefined') {
      throw new Error('Access token not granted');
    }

    this.authenticationStore.setError(undefined);
    this.authenticationStore.setLoading(false);
    this.authenticationStore.update({ isLoggedIn: true, id: resp.id });
  }

  public getSSOProvider(): Observable<string> {
    return this.http.get('/sso-integration', { responseType: 'text' });
  }

  public getSSOAuthenticationLocation(): Observable<void | SSOAuthenticationResponse> {
    this.authenticationStore.setLoading(true);

    return this.http.post<SSOAuthenticationResponse>(`${this.apiUrl}/authenticate`, { isSsoAuthentication: true }).pipe(
      catchError((err: HttpErrorResponse) => {
        this.authenticationStore.setLoading(false);

        return of(this.authenticationStore.setError(err));
      }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public SSOLogin(ssoAuthenticationParams: SSOAuthenticationParams): void {
    this.authenticationStore.setError(undefined);
    this.authenticationStore.setLoading(false);
    this.authenticationStore.update({ id: ssoAuthenticationParams.id, isLoggedIn: true });
  }

  public getUserTwoFAStatus(): Observable<UserTwoFAStatus> {
    return this.http.get<UserTwoFAStatus>(`${this.apiUrl}/system/user/2fa/status`);
  }

  public setUserTwoFAStatus(status: UserTwoFAStatusUpdate): Observable<UserTwoFAStatusUpdateResponse> {
    return this.http.post<UserTwoFAStatusUpdateResponse>(`${this.apiUrl}/system/user/2fa/status`, status);
  }

  public getUserTwoFAQRCode(): Observable<UserTwoFAQRCode> {
    return this.http.get<UserTwoFAQRCode>(`${this.apiUrl}/system/user/2fa/qr`);
  }

  public getUserRecoveryCodes(): Observable<NewRecoveryCodes> {
    return this.http.get<NewRecoveryCodes>(`${this.apiUrl}/system/user/2fa/new-recovery-codes`);
  }

  getLoginPageSettings(): Observable<LoginSettings> {
    return this.http.get<LoginSettings>(`${this.apiUrl}/login-settings`).pipe(catchError(() => EMPTY));
  }

  changePassword(request: ChangePasswordRequest): Observable<void> {
    const snakeCaseRequest = camelToSnakeCaseKeys(request);

    return this.http.post<void>(`${this.apiUrl}/system/user/password`, snakeCaseRequest);
  }
}
