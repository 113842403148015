export const overlayInitializing = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11" fill="none">
<rect x="0.620361" y="0.335693" width="10" height="10" rx="5" fill="#F9FBFF"/>
<path d="M2.42653 5.21259C2.43626 5.06873 2.45764 4.92511 2.49086 4.78313C2.63613 4.16223 2.99818 3.61356 3.51187 3.2358L3.51512 3.23338L3.51977 3.22998C3.89437 2.95681 4.33599 2.78381 4.80246 2.73207C5.02802 2.70705 5.19059 2.50392 5.16556 2.27836C5.14053 2.0528 4.93739 1.89023 4.71183 1.91525C4.51107 1.93751 4.31383 1.97697 4.12208 2.03263L4.11362 2.03501C3.52505 2.20744 2.99599 2.53028 2.57589 2.96681L2.56591 2.97692C1.99049 3.5797 1.64904 4.36803 1.60298 5.20012C1.55692 6.0322 1.80927 6.85343 2.31463 7.51609C2.46683 7.71567 2.63897 7.89691 2.82749 8.05757C3.27286 8.43717 3.80941 8.70164 4.38995 8.82156L4.39823 8.82318C4.59397 8.86312 4.79387 8.88645 4.99588 8.89234L5.00207 8.89243C5.16179 8.89679 5.32135 8.89021 5.47965 8.87283C5.52157 8.86823 5.56347 8.86287 5.60533 8.85674L5.6066 8.85654C6.00682 8.79772 6.39692 8.66953 6.75857 8.47456L6.76378 8.4717C6.85068 8.42467 6.93576 8.37388 7.01875 8.31939C7.10436 8.26319 7.18776 8.20306 7.26866 8.1391L7.27015 8.13791C7.42871 8.01247 7.577 7.87281 7.71299 7.72L7.71544 7.71723C7.98374 7.41518 8.19981 7.06662 8.35132 6.68527C8.43512 6.47436 8.33207 6.23545 8.12117 6.15165C7.91026 6.06784 7.67136 6.17087 7.58756 6.38178C7.35211 6.97439 6.91293 7.46353 6.34903 7.76121C5.78512 8.0589 5.1335 8.14561 4.51136 8.00573C3.88921 7.86585 3.33738 7.50858 2.95512 6.99816C2.57287 6.48775 2.38526 5.85769 2.42606 5.22132C2.42625 5.21841 2.42641 5.2155 2.42653 5.21259Z" fill="#4B70D0"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.70637 4.93332C9.34057 4.68569 9.65392 3.97082 9.40626 3.33663L9.39585 3.31021C9.14421 2.67758 8.42737 2.36872 7.79474 2.62034C7.16211 2.87195 6.85325 3.58877 7.10489 4.2214L7.10962 4.23341C7.35728 4.8676 8.07217 5.18096 8.70637 4.93332Z" fill="#4B70D0"/>
<defs>
  <radialGradient id="paint0_angular_502_60852" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(5.62019 5.33586) rotate(10.2992) scale(4.20134 3.79332)">
    <stop stop-color="#4B70D0"/>
    <stop offset="0.297836" stop-color="#4B70D0"/>
    <stop offset="0.739583" stop-color="#D7DAE4"/>
  </radialGradient>
</defs>
</svg>`;
