import { Route, Routes } from '@angular/router';
import { ApplicationViewName, isAdminView, SystemStatusFeatureFlags, UserPermissions } from '@guardicore-ui/shared/data';

import { checkViewFeatureFlag } from './check-view-feature-flag';

const FALLBACK_DEFAULT_VIEW = 'Dashboard';

function findRouteByViewName(
  viewName: string,
  routerConfig: Routes,
  paths: string[],
  featureFlags: SystemStatusFeatureFlags | undefined,
): string[] {
  const pathsInternal = paths;

  for (let i = 0; i < routerConfig.length; i++) {
    const data = routerConfig[i].data;

    if (data && data['viewName'] === viewName && checkViewFeatureFlag(viewName, routerConfig[i], featureFlags)) {
      return pathsInternal.concat([routerConfig[i].path as string]);
    } else if (routerConfig[i].children) {
      const childRoute = findRouteByViewName(
        viewName,
        routerConfig[i].children as Routes,
        [...pathsInternal, routerConfig[i].path as string],
        featureFlags,
      );

      if (childRoute.length) {
        return childRoute;
      }
    }
  }

  return [];
}

export function getRouteByViewName(
  viewName: string,
  routerConfig: Routes,
  featureFlags: SystemStatusFeatureFlags | undefined,
  fallbackToDefault = true,
): string[] {
  const routeDefaultView = findRouteByViewName(viewName, routerConfig, [], featureFlags);

  if (fallbackToDefault && !routeDefaultView.length) {
    return findRouteByViewName(FALLBACK_DEFAULT_VIEW, routerConfig, [], featureFlags);
  }

  return routeDefaultView;
}

function getNextAvailableRoute(routes: Routes, allowedViewsKeys: string[], paths?: string[]): string[] {
  const pathsInternal = paths || [];

  for (let i = 0; i < routes.length; i++) {
    const data = routes?.[i]?.data;
    const path = routes?.[i]?.path as string;

    if (data?.['viewName'] && allowedViewsKeys.includes(data['viewName'])) {
      return [...pathsInternal, path];
    } else if (routes?.[i]?.children) {
      const routeData = getNextAvailableRoute(routes[i].children as Routes, allowedViewsKeys, [...pathsInternal, path]);

      if (routeData.length) {
        return routeData;
      }
    }
  }

  return [];
}

function getAvailableRoute(currentViewName: ApplicationViewName, routerConfig: Routes, allowedViewsKeys: string[]): string[] {
  const isAdminViewName = isAdminView(currentViewName);
  const configPath = isAdminViewName ? 'administration' : 'overview';
  const route = routerConfig.find(_route => _route.path === configPath) as Route;

  return getNextAvailableRoute([route], allowedViewsKeys);
}

export function getRouteByViewNameOrNextAvailableRoute(
  currentViewName: ApplicationViewName = '',
  routerConfig: Routes,
  userPermissions: UserPermissions = {},
  isAllowed: (string: string) => boolean,
  featureFlags: SystemStatusFeatureFlags | undefined,
): string[] {
  const allowedViewsPermissions = Object.keys(userPermissions).filter(name => isAllowed(name));

  if (allowedViewsPermissions.includes(currentViewName) && isAllowed(currentViewName)) {
    return getRouteByViewName(currentViewName, routerConfig, featureFlags, false);
  }

  return getAvailableRoute(currentViewName, routerConfig, allowedViewsPermissions);
}
