import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiIconModule } from '@guardicore-ui/ui/icon';

import { SectionMessageComponent } from './section-message.component';

@NgModule({
  imports: [CommonModule, UiIconModule],
  declarations: [SectionMessageComponent],
  exports: [SectionMessageComponent],
})
export class UiSectionMessageModule {}
