import { Directive } from '@angular/core';
import { Observable } from 'rxjs';

@Directive()
export abstract class FormFieldControl {
  readonly type!: string;
  value: unknown | null;
  readonly disabled!: boolean;
  readonly isInErrorState!: boolean;
  readonly hasValue!: boolean;
  readonly isFocused!: boolean;
  readonly stateChanges!: Observable<void>;
  readonly isTextArea!: boolean;
  abstract focus: () => void;
  abstract containerClick: () => void;
}
