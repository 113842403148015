import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { filterStateToFilterParams } from '@guardicore-ui/shared/api';
import { API_URL, FiltersState } from '@guardicore-ui/shared/data';
import { SystemStatusFacade } from '@guardicore-ui/shared/system-status';
import { isObjectsEmpty } from '@guardicore-ui/shared/utils';
import { interval, Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { CsvExportState, CSVExportTask, MaxRecordsNumResponse, NewCsvExportTaskId } from './types';

@Injectable({
  providedIn: 'root',
})
export class CsvExportService {
  private stopPolling$ = new Subject<void>();
  private exportCSVTaskStatusURL = `${this.apiUrl}/export_csv_task_status`;

  private static isExportTaskResolved(exportTask: CSVExportTask): boolean {
    return [CsvExportState.canceled, CsvExportState.resolved, CsvExportState.error, CsvExportState.ready].includes(exportTask.state);
  }

  private static csvExportTaskError(error: HttpErrorResponse): CSVExportTask {
    return {
      id: '',
      recordsWritten: 0,
      totalRecords: 0,
      exportedCsvFileId: '',
      state: CsvExportState.error,
      creationTime: 0,
      author: { description: '', username: '' },
      reason: error.error?.error_dump || error.error?.description,
    };
  }

  constructor(
    @Inject(API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
    private readonly systemStatus: SystemStatusFacade,
  ) {}

  getMaxRecordsNum(): Observable<MaxRecordsNumResponse> {
    return this.http.get<MaxRecordsNumResponse>(`${this.exportCSVTaskStatusURL}/max-records-num`);
  }

  startPolling(exportTaskId?: string, viewName?: string): Observable<CSVExportTask | undefined> {
    if (!viewName && !exportTaskId) {
      throw new Error('One of [exportTaskId, viewName] must be provided');
    }

    const params = new HttpParams({ fromObject: viewName ? { view_name: viewName } : { task_id: exportTaskId ?? '' } });

    return this.http.get<CSVExportTask>(this.exportCSVTaskStatusURL, { params }).pipe(
      takeUntil(this.stopPolling$),
      switchMap((exportTask: CSVExportTask) => {
        if (isObjectsEmpty(exportTask)) {
          return of(undefined);
        }

        return CsvExportService.isExportTaskResolved(exportTask) ? of(exportTask) : this.startTaskPolling(exportTask.id);
      }),
      catchError((error: HttpErrorResponse) => {
        return of(CsvExportService.csvExportTaskError(error));
      }),
    );
  }

  private startTaskPolling(exportTaskStatusId: string, pollingInterval = 1000): Observable<CSVExportTask> {
    return interval(pollingInterval).pipe(
      takeUntil(this.stopPolling$),
      concatMap(() => {
        return this.http
          .get<CSVExportTask>(this.exportCSVTaskStatusURL, {
            params: new HttpParams({ fromObject: { task_id: exportTaskStatusId } }),
          })
          .pipe(take(1));
      }),
      tap((exportTask: CSVExportTask) => {
        if (CsvExportService.isExportTaskResolved(exportTask)) {
          this.stopPolling(exportTask.id, false);
        }
      }),
    );
  }

  stopPolling(exportTaskStatusId?: string, cancel?: boolean): void {
    this.stopPolling$.next();
    if (cancel && exportTaskStatusId) {
      this.http.post(`${this.exportCSVTaskStatusURL}/${exportTaskStatusId}`, null).subscribe();
    }
  }

  newExport(
    exportUrl: string,
    offset: number,
    limit: number,
    compressResult: boolean,
    filters: FiltersState,
  ): Observable<CSVExportTask | undefined> {
    const exportFilters = {
      ...{ limit, offset, compress_result: compressResult },
      ...filterStateToFilterParams(filters).reduce((prev, curr) => ({ ...prev, ...{ [curr.name]: curr.value } }), {}),
    };

    return this.http
      .get<NewCsvExportTaskId>(`${this.apiUrl}/${exportUrl}/export`, {
        params: new HttpParams({ fromObject: exportFilters }),
      })
      .pipe(
        switchMap((taskId: NewCsvExportTaskId) => {
          return this.startPolling(taskId.exportTaskStatusId);
        }),
        catchError((error: HttpErrorResponse) => {
          return of(CsvExportService.csvExportTaskError(error));
        }),
      );
  }

  download(exportedCsvFileId: string): void {
    window.open(`${this.apiUrl}/exported_csv_files/${exportedCsvFileId}`, '_parent');
  }
}
