import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiIconModule } from '@guardicore-ui/ui/icon';
import { UiTextModule } from '@guardicore-ui/ui/text';

import { UserMenuComponent } from './user-menu.component';

@NgModule({
  imports: [CommonModule, UiIconModule, UiTextModule],
  declarations: [UserMenuComponent],
  exports: [UserMenuComponent],
})
export class UiUserMenuModule {}
