import { ComponentHarness } from '@angular/cdk/testing';

function getLocatorString(selector: string): string {
  const locatorPrefix = 'text__';

  return `[automation-id=${locatorPrefix}${selector}]`;
}

export class TextComponentHarness extends ComponentHarness {
  static hostSelector = 'gc-text';

  protected text = this.locatorFor('div');
  protected toolTip = this.locatorForOptional(getLocatorString('tooltip'));
  protected noToolTip = this.locatorForOptional(getLocatorString('no-tooltip'));
  protected copyToolTip = this.locatorForOptional(getLocatorString('copy-tooltip'));
  protected ellipsisTypeEnd = this.locatorForOptional(getLocatorString('end-ellipsis'));
  protected ellipsisTypeMiddle = this.locatorForOptional(getLocatorString('middle-ellipsis'));

  async getText(): Promise<string> {
    return await (await this.text()).text();
  }

  async hoverCell(): Promise<void> {
    await (await this.text()).dispatchEvent('mouseenter');
  }

  async mouseLeave(): Promise<void> {
    await (await this.text()).dispatchEvent('mouseleave');
  }

  public async hasToolTip(): Promise<boolean> {
    return !!(await this.toolTip());
  }

  public async hasNoToolTip(): Promise<boolean> {
    return !!(await this.noToolTip());
  }

  public async hasCopyToolTip(): Promise<boolean> {
    return !!(await this.copyToolTip());
  }

  public async hasEndEllipsis(): Promise<boolean> {
    return !!(await this.ellipsisTypeEnd());
  }

  public async hasMiddleEllipsis(): Promise<boolean> {
    return !!(await this.ellipsisTypeMiddle());
  }
}
