/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { AbstractConstructor, Constructor } from '@guardicore-ui/shared/data';
import { mixinCanBeUncommon, mixinDisabled, mixinHasId } from '@guardicore-ui/shared/utils';

import { FilterControlConstructor } from './filter-control';
import { HasFilterDataSource } from './filter-data-source';

export function mixinFilterControl<T extends AbstractConstructor<{} & HasFilterDataSource>>(base: T): FilterControlConstructor & T;
export function mixinFilterControl<T extends Constructor<{} & HasFilterDataSource>>(base: T): FilterControlConstructor & T {
  return mixinHasId(
    mixinCanBeUncommon(
      mixinDisabled(
        class extends base {
          constructor(...args: any[]) {
            super(...args);
          }
        },
      ),
    ),
    { required: true },
  );
}
