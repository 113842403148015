import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

import { CONFLICTS } from '../constants';

@Component({
  selector: 'gc-configuration-widget-base',
  template: '<ng-content></ng-content>',
  styleUrls: ['./configuration-widget-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigurationWidgetBaseComponent extends FieldType implements OnInit {
  overrideConflict = false;

  get control(): FormControl {
    return this.formControl as FormControl;
  }

  ngOnInit(): void {
    this.overrideConflict = this.model[this.key as string] !== CONFLICTS;
  }

  toggleOverrideConflict(): void {
    this.overrideConflict = !this.overrideConflict;
    this.form.markAsDirty();
    this.control.setValue(this.overrideConflict ? this.field.defaultValue : CONFLICTS);
  }
}
