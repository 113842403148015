import { SystemStatusFeatureFlags, User, VersionSpecification } from '@guardicore-ui/shared/data';

import { SystemStatusConfiguration } from './system-status-configuration';

export interface ReleaseInfo {
  tag: string;
  appVersion: string;
  buildTime: number;
  buildJobId: string;
}

export interface SystemStatusStateValidation {
  labelNameProhibitedCharacters: string[];
  tenantValidNameRegex: string;
  validDomainRegex?: string;
}

export interface SystemStatusUserPreferences {
  isColorBlind: boolean;
}

export interface SystemStatusStateVersion {
  build: string;
  creationtime: bigint;
  debug: string;
  fullVersion: { major: string; minor?: string };
  machine: string;
  version: string;
}

export interface SystemStatusState {
  configuration?: SystemStatusConfiguration;
  environmentCustomerName?: string;
  environmentIsProduction?: boolean;
  evaluationStartDate?: number;
  featureFlags?: SystemStatusFeatureFlags;
  installingPatch?: boolean;
  integration?: unknown;
  login?: User;
  systemTime?: string;
  version?: SystemStatusStateVersion;
  release?: VersionSpecification;
  validation?: SystemStatusStateValidation;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  originalSystemStatus?: { [key: string]: any };
  evaluationDaysPassed?: number;
}

export function createInitialState(): SystemStatusState {
  return {
    configuration: undefined,
    environmentCustomerName: undefined,
    environmentIsProduction: undefined,
    evaluationStartDate: undefined,
    featureFlags: undefined,
    installingPatch: false,
    integration: undefined,
    login: undefined,
    systemTime: undefined,
    version: undefined,
    validation: undefined,
    originalSystemStatus: undefined,
    evaluationDaysPassed: undefined,
    release: undefined,
  };
}
