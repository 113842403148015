export const guardicoreG = `<svg viewBox="0 0 40 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.6" d="M0 22.641L9.95956 28.1256V16.8752L0 22.641Z" fill="#E5E7FA"/>
<path opacity="0.6" d="M20.1702 22.4999L30.0232 28.1261L30.0231 16.8757L20.1702 22.4999Z" fill="#E5E7FA"/>
<path opacity="0.7" d="M9.95955 16.8754L0 11.2502V22.6413L9.95955 16.8754Z" fill="#E5E7FA"/>
<path opacity="0.5" d="M9.95955 28.1262L0 22.6416V33.7513L9.95955 28.1262Z" fill="#E5E7FA"/>
<path opacity="0.42" d="M9.95955 28.126L0 33.7512L10.1643 39.4765L20.0634 33.6094L9.95955 28.126Z" fill="#E5E7FA"/>
<path opacity="0.8" d="M10.2293 5.41479L0 11.2504L9.95955 16.8755L20.1889 11.1795L10.2293 5.41479Z" fill="#E5E7FA"/>
<path d="M30.0234 5.625L20.0007 11.2752L30.0234 16.8754L39.983 11.2502L30.0234 5.625Z" fill="#E5E7FA"/>
<path opacity="0.9" d="M20.0639 0L10.1045 5.48455L20.064 11.2496L30.0235 5.62518L20.0639 0Z" fill="#E5E7FA"/>
<path opacity="0.35" d="M20.0632 33.6091L10.1638 39.4762L19.9828 45L30.0089 39.3749L20.0632 33.6091Z" fill="#E5E7FA"/>
<path opacity="0.27" d="M30.0223 28.126L20.0627 33.6093L30.0085 39.3751L40 33.7504L30.0223 28.126Z" fill="#E5E7FA"/>
</svg>`;
