export const info = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 20">
    <g id="info-v3">
      <g id="ic">
        <path d="M8.58073 14.463C8.35977 14.463 8.18073 14.2838 8.18073 14.063V9.37207C8.18073 9.15111 8.35977 8.97207 8.58073 8.97207H9.41448C9.63545 8.97207 9.81449 9.15111 9.81449 9.37207V14.063C9.81449 14.2838 9.63545 14.463 9.41448 14.463H8.58073Z"/>
        <path d="M9.00144 7.81265C8.75856 7.81265 8.55016 7.73211 8.37632 7.57103C8.20496 7.4074 8.11936 7.2118 8.11936 6.98424C8.11936 6.75924 8.20496 6.5662 8.37632 6.40512C8.55016 6.24148 8.75856 6.15967 9.00144 6.15967C9.24432 6.15967 9.45144 6.24148 9.62272 6.40512C9.79664 6.5662 9.88352 6.75924 9.88352 6.98424C9.88352 7.2118 9.79664 7.4074 9.62272 7.57103C9.45144 7.73211 9.24432 7.81265 9.00144 7.81265Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17 10.5029C17 14.9212 13.4182 18.5029 9 18.5029C4.58172 18.5029 1 14.9212 1 10.5029C1 6.08465 4.58172 2.50293 9 2.50293C13.4182 2.50293 17 6.08465 17 10.5029ZM15.4 10.5029C15.4 14.0376 12.5346 16.9029 9 16.9029C5.46538 16.9029 2.6 14.0376 2.6 10.5029C2.6 6.96831 5.46538 4.10293 9 4.10293C12.5346 4.10293 15.4 6.96831 15.4 10.5029Z"/>
      </g>
    </g>
  </svg>
`;
