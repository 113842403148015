import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DIALOG_TOP_POSITION, UserAgreementAction } from '@guardicore-ui/shared/data';
import { CSVExportConfig, CsvExportDialogComponent } from '@guardicore-ui/ui/csv';

import { EvalEulaDialogComponent } from './eval-eula-dialog';
import { GenericModalComponent } from './generic-modal';
import { NewNavbarIntroductionComponent } from './new-navbar-introduction';
import { UserPreferencesComponent } from './user-preferences';

/**
 * System wide dialogs only!!!
 */
@Injectable({
  providedIn: 'root',
})
export class DialogsService {
  constructor(private readonly dialogService: MatDialog) {}

  generic(data: { [key: string]: string }): MatDialogRef<GenericModalComponent> {
    return this.dialogService.open(GenericModalComponent, { data });
  }

  openEvalEula(actions: UserAgreementAction[]): MatDialogRef<EvalEulaDialogComponent> {
    return this.dialogService.open(EvalEulaDialogComponent, {
      position: DIALOG_TOP_POSITION,
      disableClose: true,
      data: {
        demandedSteps: actions,
      },
    });
  }

  openUserPreferences(isColorBlind: boolean): MatDialogRef<UserPreferencesComponent> {
    return this.dialogService.open(UserPreferencesComponent, {
      position: DIALOG_TOP_POSITION,
      data: {
        blindMode: isColorBlind,
      },
    });
  }

  openExportCSV(config: CSVExportConfig): MatDialogRef<CsvExportDialogComponent> {
    return this.dialogService.open(CsvExportDialogComponent, {
      data: config,
    });
  }

  openNewNavbarIntroduction(): MatDialogRef<NewNavbarIntroductionComponent> {
    return this.dialogService.open(NewNavbarIntroductionComponent, { maxWidth: '100vw' });
  }
}
