import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnalyticsService } from '@guardicore-ui/analytics';
import { ViolationItem, ProjectsService } from '@guardicore-ui/projects/domain';
import { ViolationsDialogComponent } from '@guardicore-ui/projects/ui';
import { calculateDateString } from '@guardicore-ui/shared/data';
import { getAmountOfDays } from '@guardicore-ui/shared/utils';
import { ToastrService } from '@guardicore-ui/ui/toastr';

@Injectable({
  providedIn: 'root',
})
export class ProjectsDialogsService {
  constructor(
    private readonly dialogService: MatDialog,
    private analyticsService: AnalyticsService,
    private readonly router: Router,
    protected readonly projectsService: ProjectsService,
    private readonly toastrService: ToastrService,
  ) {}

  openViolationsDialog(projectId: string, maxRange?: number, projectCreationTime?: number): void {
    this.dialogService
      .open(ViolationsDialogComponent, {
        data: {
          maxRange: maxRange,
          projectId: projectId,
          projectCreationTime: projectCreationTime,
          service: this.projectsService,
        },
        panelClass: 'violation-dialog',
      })
      .afterClosed()
      .subscribe(data => {
        if (data) {
          this.createNewMap(data, projectId);
          this.sendAnalytics(data);
        }
      });
  }

  private sendAnalytics({ item, value }: ViolationItem): void {
    this.analyticsService.track('Projects-Violations', {
      selection: value,
      currentSelectionDate: calculateDateString(new Date().getTime()),
      startSelectionDate: calculateDateString(item.startTime),
      endSelectionDate: calculateDateString(item.endTime),
      days: getAmountOfDays(item.startTime, item.endTime).toString(),
    });
  }

  private createNewMap({ item, value }: ViolationItem, id: string): void {
    value === 'timeline'
      ? this.projectsService.createNewMap(id, item?.startTime, item?.endTime).subscribe(
          ({ savedMapPermalinkId }: { savedMapPermalinkId: string }) => {
            this.router.navigate(['/overview/reveal/explore'], {
              queryParams: { link_id: savedMapPermalinkId, policy_mode: true, project_id: id },
            });
          },
          (err: HttpErrorResponse) => {
            this.toastrService.open(err?.error.error_dump, 'Error', 'error');
          },
        )
      : this.router.navigate(['/overview/reveal/explore'], {
          queryParams: {
            saved_map_id: item.id,
            start_time: item.startTime,
            end_time: item.endTime,
            policy_mode: true,
            project_id: id,
            open_all_k8s: item.openAllK8s,
          },
        });
  }
}
