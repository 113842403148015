import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UiButtonModule } from '@guardicore-ui/ui/button';
import { UiLabelsModule } from '@guardicore-ui/ui/labels';

import { DropdownCalendarButtonComponent } from './dropdown-calendar-button/dropdown-calendar-button.component';
import { DropdownSelectionButtonComponent } from './dropdown-selection-button/dropdown-selection-button.component';
import { FiltersContentRowComponent } from './filters-content-row/filters-content-row.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, UiButtonModule, UiLabelsModule],
  declarations: [FiltersContentRowComponent, DropdownSelectionButtonComponent, DropdownCalendarButtonComponent],
  exports: [FiltersContentRowComponent, DropdownSelectionButtonComponent, DropdownCalendarButtonComponent],
})
export class FiltersUiModule {}
