import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';

import { DatetimePickerComponent } from './datetime-picker.component';

@NgModule({
  imports: [CommonModule, CalendarModule, ReactiveFormsModule],
  declarations: [DatetimePickerComponent],
  exports: [DatetimePickerComponent],
})
export class UiDatetimePickerModule {}
