/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { AbstractConstructor, CanHaveIdConstructor, Constructor } from '@guardicore-ui/shared/data';

import { toSnakeCase } from '../operators';

export interface MixinHasIdOptions {
  required?: boolean;
}

export function mixinHasId<T extends AbstractConstructor<{}>>(base: T, options?: MixinHasIdOptions): CanHaveIdConstructor & T;
export function mixinHasId<T extends Constructor<{}>>(base: T, options?: MixinHasIdOptions): CanHaveIdConstructor & T {
  return class extends base {
    protected _id = '';

    get id(): string {
      return this._id;
    }

    /** Input */
    set id(value: string | number | null | undefined) {
      if (!value && options?.required) {
        throw new Error('No ID provided.');
      }

      this._id = toSnakeCase(String(value));
    }

    constructor(...args: any[]) {
      super(...args);
    }

    ngOnInit(): void {
      if (!this.id && options?.required) {
        throw new Error('Filter control must have an ID.');
      }
    }
  };
}
