import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ILoadingCellRendererAngularComp } from 'ag-grid-angular';
import { ILoadingCellRendererParams } from 'ag-grid-enterprise';

@Component({
  selector: 'gc-loading-cell',
  templateUrl: './loading-cell.component.html',
  styleUrls: ['./loading-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingCellComponent implements ILoadingCellRendererAngularComp {
  params?: ILoadingCellRendererParams;
  agInit(params: ILoadingCellRendererParams): void {
    this.params = params;
  }
}
