<section class="quick-links" *ngIf="links && links.length > 0">
  <div class="quick-links-text">Quick Links</div>
  <div class="quick-links-divider"></div>
  <a class="quick-links-link" *ngFor="let link of links"
     [routerLink]="link.link"
     [state]="{ navigationReference: 'Quick Link', navigationLinkText: link.label }"
  >
    <gc-icon svgIcon="tab"></gc-icon>
    <div>{{link.label}}</div>
  </a>
</section>
