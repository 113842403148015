<div class="configuration-widget-wrapper">
  <p class="title">{{ to.label }}</p>
  <div class="input">
    <ng-container *ngIf="!to['conflict'] || (to['conflict'] && overrideConflict)">
      <button
        class="default-button options-button"
        type="button"
        [disabled]="control.disabled"
        [gcPopoverClickAnchor]="optionsTemplate"
        [closeOnClickInside]="true"
        [panelCssClass]="'dropdown-actions-list'"
        [aligns]="{ vertical: 'below', horizontal: 'start' }"
        [margin]="4"
      >
        <span *ngIf="value | async as currentValue">{{ currentValue.label }}</span>
        <gc-icon svgIcon="expand-more"></gc-icon>
      </button>
      <p class="description" [innerHTML]="to.description"></p>
    </ng-container>
    <gc-configuration-widget-warnings
      (toggleOverrideConflict)="toggleOverrideConflict()"
      [hasConflict]="!!to['conflict']"
      [overrideConflict]="overrideConflict"
      [customWarning]="to['custom_warning']"
    ></gc-configuration-widget-warnings>
  </div>
</div>

<ng-template #optionsTemplate>
  <gc-action-list (itemClick)="chooseOption($event)">
    <ng-container *ngFor="let option of selectOptions; let last = last">
      <button type="button" action-list-item [showDivider]="false" [itemId]="option.value">
        <span class="text">{{ option.label }}</span>
      </button>
      <div *ngIf="!last" class="action-list-divider"></div>
    </ng-container>
  </gc-action-list>
</ng-template>
