import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_URL } from '@guardicore-ui/shared/data';
import { Observable } from 'rxjs';

import { FeederStatus } from './entities';

@Injectable({
  providedIn: 'root',
})
export class FeederService {
  constructor(@Inject(API_URL) private readonly apiUrl: string, private readonly http: HttpClient) {}

  getFeederStatus(url: string): Observable<FeederStatus> {
    return this.http.get<FeederStatus>(`${this.apiUrl}${url}`);
  }
}
