import { AnimationEvent } from '@angular/animations';
import { CdkPortalOutlet, ComponentPortal } from '@angular/cdk/portal';
import { Component, ChangeDetectionStrategy, ViewChild, ComponentRef, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription, Subject, timer } from 'rxjs';
import { delay, filter, takeUntil } from 'rxjs/operators';

import { TOASTR_ANIMATIONS } from './toastr-container.animations';
import { ToastrConfig, TOASTR_AFTER_MOUSELEAVE_DURATION } from '../toastr-config';

@Component({
  selector: 'gc-toastr-container',
  templateUrl: './toastr-container.component.html',
  styleUrls: ['./toastr-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: TOASTR_ANIMATIONS,
})
export class ToastrContainerComponent implements OnDestroy {
  @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet?: CdkPortalOutlet;
  afterClose = new Subject<void>();
  clicked = new Subject<void>();

  private readonly destroy$ = new Subject<void>();
  readonly animationDone$ = new Subject<AnimationEvent>();
  private timerSubscription: Subscription = new Subscription();

  state: BehaviorSubject<'hide' | 'show'> = new BehaviorSubject<'hide' | 'show'>('show');

  constructor(private config: ToastrConfig) {
    const duration = config.duration && config.data?.state ? config.duration[config.data.state] : 0;

    this.timerSubscription = this.animationDone$
      .pipe(
        takeUntil(this.destroy$),
        filter((event: AnimationEvent) => !config.disableTimeout && !!config.duration && event.fromState === 'void'),
        delay(duration),
      )
      .subscribe(() => this.close());

    this.animationDone$
      .pipe(
        takeUntil(this.destroy$),
        filter((event: AnimationEvent) => event.toState === 'hide'),
      )
      .subscribe(() => this.afterClose.next());

    this.clicked.pipe(takeUntil(this.destroy$)).subscribe(() => this.close());
  }

  attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> | undefined {
    return this.portalOutlet?.attachComponentPortal(portal);
  }

  close(): void {
    this.state.next('hide');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  stopDelay(): void {
    this.timerSubscription?.unsubscribe();
  }

  startDelay(): void {
    this.timerSubscription = timer(TOASTR_AFTER_MOUSELEAVE_DURATION)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.close());
  }
}
