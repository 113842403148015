import { Route, Routes } from '@angular/router';

import { findRouteChain } from './find-route-chain';

// tries to find a route (in the given router config) by a condition
// if found, uses the given buildInfo method (with the found route chain) to create the required value
// if not found, returns the given default value
export function extractInfoFromRouterConfig<T>(
  routerConfig: Routes,
  condition: (route: Route) => boolean,
  buildInfo: (routes: Routes) => T,
  defaultValue: T,
): T {
  const foundChain: Routes = findRouteChain(routerConfig, condition);

  if (!foundChain || !foundChain.length) {
    return defaultValue;
  }

  return buildInfo(foundChain) || defaultValue;
}
