import { Injectable } from '@angular/core';
import { EntityState, EntityStore } from '@datorama/akita';

import { Asset } from '../entities';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AssetsState extends EntityState<Asset> {}

@Injectable({ providedIn: 'root' })
export class AssetsStore extends EntityStore<AssetsState, Asset> {
  constructor() {
    super(undefined, { name: 'assets', resettable: true });
  }
}
