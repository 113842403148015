import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[gcCellParams]',
})
export class CellParamsDirective {
  constructor(private readonly templateRef: TemplateRef<unknown>, private readonly viewContainer: ViewContainerRef) {}

  @Input() set gcCellParams(params: unknown) {
    this.viewContainer.createEmbeddedView(this.templateRef);
  }
}
