import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationQuery } from './authentication.query';
import { AuthenticationStore } from './authentication.store';
import { PUBLIC_ROUTES, LOGIN_ROUTE } from './public-routes';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(
    private readonly authenticationQuery: AuthenticationQuery,
    private readonly authenticationStore: AuthenticationStore,
    private readonly router: Router,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const requestUrl = request.url;

    if (PUBLIC_ROUTES.find(r => requestUrl.includes(r))) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (error.status === 403 && !window.location.pathname.startsWith(LOGIN_ROUTE)) {
          const urlToRedirect = window.location.pathname
            ? LOGIN_ROUTE + `?backlink=${encodeURIComponent(window.location.pathname || '')}`
            : LOGIN_ROUTE;

          this.authenticationStore.update({ isLoggedIn: false });
          this.router.navigate([urlToRedirect]);
        }

        if (error.status === 504 && window.location.pathname.startsWith(LOGIN_ROUTE)) {
          this.authenticationStore.update({ isLoggedIn: false });
        }

        return throwError(error);
      }),
    );
  }
}
