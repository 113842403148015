import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AlarmState, NotificationQueryParams, SystemLogItem, SystemLogPermission } from '@guardicore-ui/shared/data';
import { camelToSnakeCaseKeys, capitalizeFirstLetters } from '@guardicore-ui/shared/utils';

const systemLogRangeBuffer = 5 * 60000;

@Component({
  selector: 'gc-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent implements OnChanges {
  @Input() notification: SystemLogItem | undefined;
  @Input() notificationsPermissions: SystemLogPermission | null = null;

  @Output() dismiss: EventEmitter<SystemLogItem> = new EventEmitter();
  @Output() notificationClick: EventEmitter<void> = new EventEmitter();

  private queryParams: NotificationQueryParams | null = null;

  statusColor = '';
  descriptionCauseNgClass = '';
  statusTooltip = '';
  snakeCaseQueryParams = camelToSnakeCaseKeys(this.queryParams || {});

  ngOnChanges(): void {
    if (this.notification) {
      const { status, alarmState, uuid, lastSeenTime } = this.notification;

      this.statusColor = `status-${status.toLowerCase().replace(/[_ ]+/g, '-')}`;
      this.descriptionCauseNgClass = alarmState === AlarmState.newAlarm ? 'bold' : '';
      this.statusTooltip = capitalizeFirstLetters(status.toLowerCase());
      this.queryParams = {
        search: uuid,
        fromTime: Math.floor(+lastSeenTime - systemLogRangeBuffer),
        toTime: Math.ceil(+lastSeenTime + systemLogRangeBuffer),
      };
      this.snakeCaseQueryParams = camelToSnakeCaseKeys(this.queryParams);
    }
  }

  public handleDismiss(): void {
    this.dismiss.emit(this.notification as SystemLogItem);
  }

  public handleClick(): void {
    this.notificationClick.emit();
  }
}
