import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gc-configuration-widget-warnings',
  templateUrl: './configuration-widget-warnings.component.html',
  styleUrls: ['./configuration-widget-warnings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigurationWidgetWarningsComponent {
  @Input() customWarning?: string;
  @Input() hasConflict?: boolean;
  @Input() overrideConflict?: boolean;
  @Output() toggleOverrideConflict = new EventEmitter<string>();
}
