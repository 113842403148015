import { FilterParam, FiltersState } from '@guardicore-ui/shared/data';

export function filterStateToFilterParams(filtersState: FiltersState): FilterParam[] {
  return Object.keys(filtersState)
    .map<FilterParam>(name => {
      return {
        name,
        value: filtersState[name].map(v => v.value).join(','),
      };
    })
    .filter(param => !!param.value);
}
