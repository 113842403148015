import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@datorama/akita';
import { HybridMainFacade } from '@guardicore-ui/hybrid/domain';
import { RouteData } from '@guardicore-ui/shared/data';
import { SystemStatusQuery, SystemStatusState } from '@guardicore-ui/shared/system-status';
import { getRouteByViewName, getRouteByViewNameOrNextAvailableRoute } from '@guardicore-ui/shared/utils';

import { CoreState, CoreStateFlags, createInitialState } from './core-state';

@Injectable({ providedIn: 'root' })
export class CoreStore extends Store<CoreState> {
  constructor(hybridCommunication: HybridMainFacade, private readonly router: Router, private readonly systemStatus: SystemStatusQuery) {
    super(createInitialState(), { name: 'core', resettable: true });
    systemStatus.state$.subscribe(state => this.updateSystemStatus(state));
    hybridCommunication.isOldAppLoading$.subscribe(isOldAppLoading => this.setFlag('isOldAppLoading', isOldAppLoading));
  }

  navigationEnd(routeData: RouteData | null, urlPath: string): void {
    urlPath =
      urlPath === '/'
        ? `/${getRouteByViewName(
            this.systemStatus.defaultView()?.viewName || '',
            this.router.config,
            this.systemStatus.featureFlags(),
            true,
          ).join('/')}`
        : urlPath;
    urlPath = urlPath.split('?')[0];

    if (this.getValue()?.urlPath !== urlPath) {
      this.update({ savedUrlPath: urlPath });
    }

    this.update(state => ({
      ...state,
      routeData,
      flags: {
        ...state.flags,
        isIframeCollapsed: !routeData?.navState,
        isMsspApplicable: routeData?.useMssp === true,
      },
      urlPath,
    }));
  }

  toggleDebugMode(): void {
    this.toggleFlag('isInDebugMode');
  }

  updateSystemStatus(systemStatus: SystemStatusState): void {
    this.setFlag('isPasswordTemporary', systemStatus.login?.temporaryPassword || false);
    this.setFlag('isPasswordExpired', systemStatus.login?.passwordExpired || false);
    this.setFlag('isEulaAccepted', systemStatus.login?.acceptedEula || false);
    this.setFlag('isEvaluationAgreementAccepted', systemStatus.login?.acceptedEvaluation || false);
    this.setFlag('isEvaluationMode', systemStatus.configuration?.evaluationModeState || false);
    this.setFlag(
      'isManagerCentra',
      (systemStatus.featureFlags?.centraCluster?.isManager && systemStatus.featureFlags?.centraCluster?.centraClusterEnabled) || false,
    );
    this.apiConnectionRestored();
    this.setDefaultRoute();
  }

  login(systemStatus?: SystemStatusState): void {
    this.setFlag('isLoggedIn', true);
    if (systemStatus) {
      this.updateSystemStatus(systemStatus);
    }
  }

  logout(): void {
    this.setFlag('isLoggedIn', false);
  }

  apiConnectionFailed(): void {
    this.update(state => ({ ...state, isApiConnectionFailed: Date.now() }));
  }

  apiConnectionRestored(): void {
    this.update(state => ({ ...state, isApiConnectionFailed: null }));
  }

  updatePassword(): void {
    this.setFlag('isPasswordExpired', false);
    this.setFlag('isPasswordTemporary', false);
  }

  private setDefaultRoute(): void {
    const { viewName: defaultView } = this.systemStatus.defaultView() || {};

    if (defaultView === undefined) {
      return;
    }

    const defaultUrl = getRouteByViewNameOrNextAvailableRoute(
      defaultView,
      this.router.config,
      this.systemStatus?.allowedViewsPermissions(),
      this.systemStatus.isAllowed.bind(this.systemStatus),
      this.systemStatus?.featureFlags(),
    ).join('/');

    this.update(state => ({ ...state, defaultUrl }));
  }

  private toggleFlag(key: keyof CoreStateFlags): void {
    const localValue = this.getValue()?.flags[key];

    this.setFlag(key, !localValue);
  }

  private setFlag(key: keyof CoreStateFlags, value: boolean): void {
    const localValue = this.getValue()?.flags[key];

    if (typeof localValue !== 'boolean') {
      return;
    }

    this.update(state => {
      const flags = { ...state.flags };

      flags[key] = value;

      return { ...state, flags };
    });
  }
}
