import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { GlobalError } from '@guardicore-ui/shared/data';

@Component({
  selector: 'gc-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingOverlayComponent {
  @Input() error?: GlobalError;
}
