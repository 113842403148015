import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ConfigurationWidgetBaseComponent } from '../configuration-widget-base/configuration-widget-base.component';

interface SelectOption {
  value: string;
  label: string;
}

@Component({
  selector: 'gc-configuration-select',
  templateUrl: './configuration-select.component.html',
  styleUrls: ['../configuration-widget-base/configuration-widget-base.component.scss', './configuration-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigurationSelectComponent extends ConfigurationWidgetBaseComponent implements OnInit {
  value: BehaviorSubject<SelectOption | null> = new BehaviorSubject<SelectOption | null>(null);
  selectOptions: SelectOption[] = [];

  override ngOnInit(): void {
    super.ngOnInit();
    this.to.options?.forEach(option => {
      this.selectOptions.push(option);
    });

    this.value.next(this.getOptionFromValue(this.model[this.key as string]));
  }

  private getOptionFromValue(optionValue: string): SelectOption | null {
    return this.selectOptions.find(option => option.value === optionValue) || null;
  }

  chooseOption(optionValue: string): void {
    this.value.next(this.getOptionFromValue(optionValue));
    this.form.markAsDirty();
    this.control.setValue(optionValue);
  }

  override toggleOverrideConflict(): void {
    super.toggleOverrideConflict();
    this.value.next(this.getOptionFromValue(this.model[this.key as string]));
  }
}
