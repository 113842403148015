import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SystemLogItem, POLLING_INTERVAL, SimpleToastrState, HybridMessagePayload, Toast } from '@guardicore-ui/shared/data';
import { PollingService } from '@guardicore-ui/shared/polling';
import { RbacService } from '@guardicore-ui/shared/rbac';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ToastrService } from '@guardicore-ui/ui/toastr';
import { Observable, timer, EMPTY, Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';

import { NotificationsState } from '../entities';
import { NotificationsQuery, NotificationsService } from '../infrastructure';

@Injectable({
  providedIn: 'root',
})
export class NotificationsFacade {
  private isPolling = false;
  private toasts: Subject<HybridMessagePayload> = new Subject();

  toasts$: Observable<HybridMessagePayload> = this.toasts.asObservable();

  constructor(
    private readonly notificationsService: NotificationsService,
    private readonly query: NotificationsQuery,
    private readonly toastrService: ToastrService,
    private readonly pollingService: PollingService,
    private readonly rbacService: RbacService,
    @Inject(POLLING_INTERVAL) private readonly pollingInterval: number,
  ) {
    pollingService.reloadAll$.pipe(switchMap(() => this.read())).subscribe();
    pollingService.start$.subscribe(() => this.startPolling());
    pollingService.stop$.subscribe(() => (this.isPolling = false));
    this.query.toasts$.subscribe(toasts => this.doToasts(toasts));
  }

  read(): Observable<NotificationsState> {
    if (!this.rbacService.isAllowed('Log') || !this.pollingService.arePollingsAllowed) {
      return EMPTY;
    }

    return this.notificationsService.read().pipe(take(1));
  }

  startPolling(): void {
    if (!this.rbacService.isAllowed('Log') || this.isPolling || !this.pollingService.arePollingsAllowed) {
      return;
    }

    this.isPolling = true;
    this.notificationsErrorHandler();

    timer(0, this.pollingInterval)
      .pipe(
        switchMap(() => this.notificationsService.read()),
        takeUntil(this.pollingService.stop$),
      )
      .subscribe();
  }

  dismissAll(): Observable<void> {
    return this.notificationsService.dismissAll();
  }

  ackAll(): Observable<void> {
    return this.notificationsService.ackAll();
  }

  dismissOne(notification: SystemLogItem): Observable<void> {
    return this.notificationsService.dismissOne(notification);
  }

  get state$(): Observable<NotificationsState> {
    return this.query.state$;
  }

  get items$(): Observable<SystemLogItem[]> {
    return this.query.selectAll();
  }

  private notificationsErrorHandler(): void {
    this.query
      .selectError()
      .pipe(takeUntil(this.pollingService.stop$))
      .subscribe((error: HttpErrorResponse) => {
        error && this.toastrService.open(error.error, 'Error', 'error');
      });
  }

  private doToasts(toasts: Toast[]): void {
    if (!toasts?.length) {
      return;
    }

    toasts.forEach((toastItem: Toast) => {
      const title = toastItem?.status === 'SUCCESS' ? 'Success' : 'Error';
      const type = title.toLowerCase() as SimpleToastrState;
      const message = toastItem?.toastType === 'DNSSecurityFeed' ? this.buildCustomNotification(toastItem?.message) : toastItem?.message;

      this.toasts.next({ title, type, message });
    });
  }

  private buildCustomNotification(msg: string, redirectLink = '/administration/system/audit-logs'): string {
    return `<p class="message">${msg}</p>
      <div class="link">
        <a href="${redirectLink}">More info</a>
        </div>
      </div>`;
  }
}
