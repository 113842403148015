<div *ngIf="env$ | async as env" [attr.gc-env-cust-name]="env?.name" [attr.gc-env-is-prod]="env?.isProduction"></div>
<ng-container *ngIf="viewModel$ | async as viewModel">
  <ng-container *ngIf="isLoggedin$ | async; else notLoggedIn">
    <div class="layout-wrapper">
      <div class="navbar-wrapper" *ngIf="viewModel.viewStates">
        <gc-navbar [viewStates]="viewModel.viewStates"></gc-navbar>
      </div>
      <div class="main-content-wrapper">
        <gc-main-header (userMenuAction)="onUserMenuAction($event)"></gc-main-header>
        <main>
          <div class="main-content-border"></div>
          <div class="main-content-view" [ngClass]="(mainContentClasses | async) || {}">
            <ng-container *ngIf="sectionMessage?.['title'] && !sectionMessage['closed']">
              <gc-section-message
                [type]="sectionMessage['type']"
                [title]="sectionMessage['title']"
                [message]="sectionMessage['message']"
                [moreInfo]="sectionMessage['moreInfo']"
                [moreInfoLink]="sectionMessage['moreInfoLink']"
                (closeMsg)="closeSectionMessage()">
              </gc-section-message>
            </ng-container>
            <div class="main-content"
              [ngClass]="{
                'with-message': sectionMessage?.['title'] && !sectionMessage['closed']
              }">
              <div class="iframe-wrapper" [hidden]="query.isIframeCollapsed$ | async">
                <div *ngIf="query.isOldAppLoading$ | async" class="iframe-overlay">
                  <div class="iframe-overlay__wrapper">
                    <gc-icon svgIcon="loading-animation"></gc-icon>
                    <p class="iframe-overlay__wrapper__title">Connecting to Guardicore...</p>
                  </div>
                </div>
                <iframe
                  *ngIf="query.isIframeCanBeLoaded$ | async"
                  id="hybridiframe"
                  gcHybridIframe
                  automation-id="hybrid-iframe"
                  (locationChange)="onLocationChange($event)"
                ></iframe>
              </div>
              <router-outlet></router-outlet>
              <div class="full-screen-overlay" *ngIf="query.isApiConnectionFailed$ | async as isApiConnectionFailed">
                <gc-full-screen-error [restartHash]="isApiConnectionFailed" (retryNow)="startSystemStatusPolling()"></gc-full-screen-error>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <div
      class="modal-backdrop"
      (click)="onModalBackdropClick()"
      [ngClass]="(modalBackdropExtraClassesSubject | async) || {}">
    </div>
  </ng-container>
  <ng-template #notLoggedIn>
    <router-outlet></router-outlet>
  </ng-template>
</ng-container>
