const pluralEnding = /^[a-zA-Z0-9]+[ss|ch|sh|x]$/;

export function pluralize(value: string, count: number): string {
  return count === 1 ? value : pluralEnding.test(value) ? `${value}es` : `${value}s`;
}

export function pluralizeOperator(count: number): string {
  return count === 1 ? 'is' : 'are';
}

export function pluralizeHaveOperator(count: number): string {
  return count === 1 ? 'has' : 'have';
}
