import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiIconModule } from '@guardicore-ui/ui/icon';

import { SimpleToastrComponent } from './simple-toastr/simple-toastr.component';
import { ToastrContainerComponent } from './toastr-container/toastr-container.component';
import { ToastrStackComponent } from './toastr-stack/toastr-stack.component';

@NgModule({
  imports: [CommonModule, PortalModule, UiIconModule],
  declarations: [SimpleToastrComponent, ToastrContainerComponent, ToastrStackComponent],
})
export class UiToastrModule {}
