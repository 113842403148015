import { Inject, Injectable } from '@angular/core';
import { Store } from '@datorama/akita';
import { RowDataObject } from '@guardicore-ui/shared/data';

import { GridComponentState, createInitialState, GRID_COMPONENT_STORE_NAME } from './grid-component-state';

@Injectable()
export abstract class GridComponentStore<T extends RowDataObject = RowDataObject> extends Store<GridComponentState<T>> {
  constructor(@Inject(GRID_COMPONENT_STORE_NAME) name: string) {
    super(createInitialState(), { name, resettable: true });
  }

  setIsNewRow(isNewRowOpen: boolean): void {
    this.update({ isNewRowOpen });
  }
}
