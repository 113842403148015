import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Constructor } from '@guardicore-ui/shared/data';
import { EllipsisType } from '@guardicore-ui/ui/common';
import { PopoverConfigurationAlign } from '@guardicore-ui/ui/popovers/popover';
import { SatPopoverHorizontalAlign, SatPopoverVerticalAlign } from '@ncstate/sat-popover';
import { BehaviorSubject } from 'rxjs';

import { HasNestedEllipsis, mixinHasNestedEllipsis } from './has-ellipsis.mixin';

const defaultVerticalAlign: SatPopoverVerticalAlign = 'above';
const defaultHorizontalAlign: SatPopoverHorizontalAlign = 'center';

const base: Constructor<HasNestedEllipsis> = mixinHasNestedEllipsis(class {});

@Component({
  selector: 'gc-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextComponent extends base {
  showToolTip$ = new BehaviorSubject<boolean>(false);
  private _isToolTipForced = false;
  private _ellipsisSplitRatio = 0.5;
  public _value = '';
  public leftText = '';
  public rightText = '';
  @Input() ellipsisType: EllipsisType = 'end';
  @Input() useCopyTextTooltip = false;
  @Input() tooltipText?: string | null;
  @Input() set forceToolTip(isForced: boolean) {
    this._isToolTipForced = isForced;
    if (isForced) {
      this.showToolTip$.next(true);
    }
  }

  @Input() set value(value: string | undefined | null) {
    this._value = value || '';
    this.calculateEllipsisText();
  }

  @Input() set ellipsisSplitRatio(splitRatio: number) {
    if (splitRatio >= 0 || splitRatio <= 1) {
      this._ellipsisSplitRatio = splitRatio;
      this.calculateEllipsisText();
    } else {
      throw new Error('Text can only be split in a range between 1 and 0');
    }
  }

  @Input() set tooltipVerticalAlign(verticalAlign: SatPopoverVerticalAlign) {
    this.aligns = { ...this.aligns, vertical: verticalAlign };
  }

  copiedToClipboard = false;
  margin = 0;
  aligns: PopoverConfigurationAlign = {
    vertical: defaultVerticalAlign,
    horizontal: defaultHorizontalAlign,
  };

  calculateEllipsisText(): void {
    const splitIndex = Math.floor(this._value.length * this._ellipsisSplitRatio);

    this.tooltipText = this.tooltipText?.length ? this.tooltipText : this._value;
    this.leftText = this._value.slice(0, splitIndex);
    this.rightText = this._value.slice(splitIndex);
  }

  onEllipsisChange(hasEllipsis: boolean): void {
    if (!this._isToolTipForced) {
      this.showToolTip$.next(hasEllipsis);
    }

    this.customEllipsisFlag$.next(hasEllipsis);
  }
}
