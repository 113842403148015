<div class="container"
     [@toast]="state | async"
     (@toast.done)="animationDone$.next($event)"
     (click)="clicked.next()"
     [class.subscribed]="clicked.observers.length"
     automation-id="toastr"
     (mouseenter)="stopDelay()" 
     (mouseleave)="startDelay()"
>
  <ng-template [cdkPortalOutlet]></ng-template>
</div>
