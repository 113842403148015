import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AnalyticsModule } from '@guardicore-ui/analytics';
import { UiIconModule } from '@guardicore-ui/ui/icon';
import { UiInlineAlertModule } from '@guardicore-ui/ui/inline-alert';
import { UiStepperStepperModule } from '@guardicore-ui/ui/stepper/stepper';

import { BulkOperationDialogComponent } from './bulk-operation-dialog/bulk-operation-dialog.component';
import { DialogComponent } from './dialog/dialog.component';
import { EvalEulaDialogComponent } from './eval-eula-dialog';
import { GenericModalComponent } from './generic-modal';
import { MultiStateDialogBaseComponent, TemplateStateIndexDirective } from './multi-state-dialog-base';
import { NewNavbarIntroductionComponent } from './new-navbar-introduction';
import { QuitWizardDialogComponent } from './quit-wizard-dialog/quit-wizard-dialog.component';
import { UserPreferencesComponent } from './user-preferences';
import { WizardDialogComponent } from './wizard-dialog';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, UiIconModule, AnalyticsModule, UiInlineAlertModule, UiStepperStepperModule],
  declarations: [
    EvalEulaDialogComponent,
    UserPreferencesComponent,
    GenericModalComponent,
    NewNavbarIntroductionComponent,
    TemplateStateIndexDirective,
    MultiStateDialogBaseComponent,
    BulkOperationDialogComponent,
    DialogComponent,
    WizardDialogComponent,
    QuitWizardDialogComponent,
  ],
  exports: [
    EvalEulaDialogComponent,
    UserPreferencesComponent,
    GenericModalComponent,
    NewNavbarIntroductionComponent,
    BulkOperationDialogComponent,
    DialogComponent,
    WizardDialogComponent,
  ],
})
export class UiDialogsModule {}
