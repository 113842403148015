import { ComponentType } from '@angular/cdk/portal';
import { Provider } from '@angular/core';
import { RowDataObject } from '@guardicore-ui/shared/data';

import { GRID_COMPONENT_STORE, GRID_DATA_SOURCE, GRID_FEATURE_QUERY } from '../entities';
import { GridComponentStore, GridDataSource, GridFeatureQuery } from '../infrastructure';

export function setGridProviders<T extends RowDataObject = RowDataObject>(
  DataSource: ComponentType<GridDataSource<T>>,
  Store: ComponentType<GridComponentStore<T>>,
  Query: ComponentType<GridFeatureQuery<T>>,
): Provider[] {
  return [
    { provide: GRID_DATA_SOURCE, useClass: DataSource },
    { provide: GRID_COMPONENT_STORE, useClass: Store },
    { provide: GRID_FEATURE_QUERY, useClass: Query },
  ];
}
