import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { akitaConfig } from '@datorama/akita';
import { AnalyticsModule } from '@guardicore-ui/analytics';
import { AuthenticationDomainModule } from '@guardicore-ui/authentication/domain';
import { FORMLY_CONFIG } from '@guardicore-ui/configuration/ui/widgets';
import { ErrorHandlingDomainModule } from '@guardicore-ui/error-handling/domain';
import { HybridDomainModule } from '@guardicore-ui/hybrid/domain';
import { HybridUiModule } from '@guardicore-ui/hybrid/ui';
import { MsspUiModule } from '@guardicore-ui/mssp/ui';
import { NavbarUiModule } from '@guardicore-ui/navbar/ui';
import { NotificationsDomainModule } from '@guardicore-ui/notifications/domain';
import { NotificationsUiModule } from '@guardicore-ui/notifications/ui';
import { QuickLinksUiModule } from '@guardicore-ui/quick-links/ui';
import { API_URL } from '@guardicore-ui/shared/data';
import { RbacModule } from '@guardicore-ui/shared/rbac';
import { UiButtonModule } from '@guardicore-ui/ui/button';
import { UiCommonModule } from '@guardicore-ui/ui/common';
import { UiErrorScreenModule } from '@guardicore-ui/ui/error-screen';
import { UiIconModule } from '@guardicore-ui/ui/icon';
import { UiPopoverModule } from '@guardicore-ui/ui/popovers/popover';
import { UiTooltipModule } from '@guardicore-ui/ui/popovers/tooltip';
import { UiSectionMessageModule } from '@guardicore-ui/ui/section-message';
import { UiToastrModule } from '@guardicore-ui/ui/toastr';
import { UiUnpublishedChangesMenuModule } from '@guardicore-ui/ui/unpublished-changes-menu';
import { UiUserMenuModule } from '@guardicore-ui/ui/user-menu';
import { FormlyModule } from '@ngx-formly/core';
import { HotkeyModule } from 'angular2-hotkeys';

import { CoreRoutingModule } from './core-routing.module';
import { MainHeaderComponent } from './layouts/main-header/main-header.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { NotFoundComponent } from './layouts/not-found/not-found.component';
import { OldAppContainerComponent } from './old-app-container/old-app-container.component';
import { environment } from '../../environments/environment';

akitaConfig({ resettable: true });

@NgModule({
  declarations: [MainHeaderComponent, MainLayoutComponent, NotFoundComponent, OldAppContainerComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HotkeyModule.forRoot(),
    ErrorHandlingDomainModule,
    MatDialogModule,
    AuthenticationDomainModule,
    UiButtonModule,
    UiUserMenuModule,
    HybridDomainModule.forRoot({
      iframeUrl: environment.iframeUrl,
    }),
    HybridUiModule,
    NavbarUiModule,
    UiErrorScreenModule,
    CoreRoutingModule,
    NotificationsDomainModule,
    NotificationsUiModule,
    UiCommonModule,
    RbacModule,
    MsspUiModule,
    UiPopoverModule,
    UiTooltipModule,
    UiSectionMessageModule,
    UiUnpublishedChangesMenuModule,
    AnalyticsModule.forRoot({
      analyticsKey: environment.analyticsKey,
      analyticsStagingKey: environment.analyticsStagingKey,
      production: environment.production,
      iframeUrl: environment.iframeUrl,
    }),
    UiIconModule,
    MatIconModule,
    UiToastrModule,
    FormlyModule.forRoot(FORMLY_CONFIG),
    QuickLinksUiModule,
  ],
  providers: [{ provide: API_URL, useValue: environment.apiUrl }],
  exports: [MainLayoutComponent],
})
export class CoreModule {}
