import { ConfigOption } from '@ngx-formly/core';

import { ConfigurationArrayComponent } from './configuration-array/configuration-array.component';
import { ConfigurationBooleanComponent } from './configuration-boolean/configuration-boolean.component';
import { ConfigurationInputComponent } from './configuration-input/configuration-input.component';
import { ConfigurationObjectComponent } from './configuration-object/configuration-object.component';
import { ConfigurationSelectComponent } from './configuration-select/configuration-select.component';

export const FORMLY_CONFIG: ConfigOption = {
  types: [
    {
      name: 'formly-group',
      defaultOptions: {
        defaultValue: {},
      },
    },
    { name: 'object', component: ConfigurationObjectComponent },
    { name: 'boolean', component: ConfigurationBooleanComponent },
    {
      name: 'string',
      component: ConfigurationInputComponent,
      defaultOptions: {
        templateOptions: {
          type: 'text',
        },
      },
    },
    {
      name: 'number',
      component: ConfigurationInputComponent,
      defaultOptions: {
        templateOptions: {
          type: 'number',
        },
      },
    },
    {
      name: 'integer',
      component: ConfigurationInputComponent,
      defaultOptions: {
        templateOptions: {
          type: 'number',
        },
      },
    },
    { name: 'array', component: ConfigurationArrayComponent },
    { name: 'enum', component: ConfigurationSelectComponent },
  ],
};
