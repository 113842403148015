<ng-container *ngIf="message">
  <div class="section-message {{type}}">
    <div class="icon"></div>
    <strong class="title" *ngIf="title">{{ title }}</strong>
    <span class="message">{{ message }}</span>
    <a *ngIf="moreInfoLink && moreInfoText"
      class="more-info"
      href="#"
      [href]="moreInfoLink"
      target="_blank"
      rel="nofollow noopener"
      (click)="clickMoreInfo($event)">{{ moreInfoText }}</a>
    <a class="close" (click)="closeMessage()">
      <div class="icon"></div>
    </a>
  </div>
</ng-container>
