import { ID } from '@datorama/akita';

export interface RowExpansion {
  rowId: ID;
  rowData: unknown;
  expand: boolean;
}

export interface GcGridIcons {
  [key: string]: string | (() => HTMLElement | string | Error);
}
