export function capitalizeFirstLetters(string: string): string {
  // returns the given string - while setting the first letter of every word in it to be capital
  const stringsArr = string.split(' ');

  return stringsArr.length > 1
    ? stringsArr.reduce(
        (accumulator, currentValue) =>
          !accumulator.length
            ? `${currentValue[0].toUpperCase()}${currentValue.substring(1)}`
            : `${accumulator} ${currentValue[0].toUpperCase()}${currentValue.substring(1)}`,
        '',
      )
    : string[0].toUpperCase() + string.substring(1);
}
