import { EventEmitter } from '@angular/core';
import { Constructor } from '@guardicore-ui/shared/data';
import { InlineAlert } from '@guardicore-ui/ui/inline-alert';
import { BehaviorSubject, Observable } from 'rxjs';

import { BulkDialogUserActions } from './bulk-operation-dialog';
import { DialogUserActions } from './dialog';

export interface Communication<D> {
  updateData(data: Partial<D>): void;
  data$: Observable<D>;
  output$: EventEmitter<DialogOutput<Partial<D>>>;
  getData(): D;
}

export interface DialogOutput<Out> {
  id: UserActions;
  outputData: Out;
  inlineAlert?: InlineAlert;
}

export type UserActions = DialogUserActions | BulkDialogUserActions;

// eslint-disable-next-line @typescript-eslint/ban-types
export function mixinTwoWayCommunication<D, T extends Constructor<{}> = Constructor<{}>>(base: T): Constructor<Communication<D>> & T {
  return class extends base {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(...args: any[]) {
      super(...args);
    }

    protected _data$ = new BehaviorSubject<D>({} as D);
    data$ = this._data$.asObservable();
    output$ = new EventEmitter<DialogOutput<Partial<D>>>();

    updateData(data: Partial<D>): void {
      this._data$.next({ ...this._data$.getValue(), ...data });
    }

    getData(): D {
      return this._data$.getValue();
    }
  };
}
