<ng-container *ngIf="rowOperations$ | async as operations">
  <button
    #rowOperationsTrigger
    class="row-operations-trigger show-on-row-hover"
    [gcPopoverClickAnchor]="rowOperationsContent"
    [aligns]="{ horizontal: 'start' }"
    [margin]="1"
  >
    <gc-icon svgIcon="caret-down"></gc-icon>
  </button>

  <ng-template #rowOperationsContent let-ref>
    <gc-action-list (itemClick)="emitOperation($event)" [popoverRef]="ref">
      <ng-container *ngFor="let operation of operations">
        <div *ngIf="operation.id === 'divider'; else listItem" action-list-divider></div>
        <ng-template #listItem>
          <div
            class="button"
            action-list-item
            [showDivider]="false"
            *ngIf="!operation.isHidden"
            [disabled]="operation.isDisabled"
            [itemId]="operation.id"
            [gcTooltip]="operation.tooltip"
            [aligns]="{ vertical: 'center', horizontal: 'after' }"
          >
            <gc-icon *ngIf="operation.icon" class="icon" [svgIcon]="operation.icon.icon" [ngClass]="operation.icon.iconExtraCssClass || ''"></gc-icon>
            <span class="text">{{ operation.title }}</span>
          </div>
        </ng-template>
      </ng-container>
    </gc-action-list>
  </ng-template>
</ng-container>
