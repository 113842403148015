import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { mixinDisabled } from '@guardicore-ui/shared/utils';

const dropdownCalendarButtonComponentBase = mixinDisabled(class {});

@Component({
  selector: 'gc-dropdown-calendar-button',
  templateUrl: './dropdown-calendar-button.component.html',
  styleUrls: ['./dropdown-calendar-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  inputs: ['disabled'],
})
export class DropdownCalendarButtonComponent extends dropdownCalendarButtonComponentBase {
  private _labelValue?: string[];

  @Input() label?: string;

  get text(): string[] | undefined {
    return Array.isArray(this._labelValue) ? this._labelValue : undefined;
  }

  @Input()
  set labelText(value: string | undefined) {
    this._labelValue = value?.split(',');
  }

  @Output() gcClick = new EventEmitter<void>();
}
