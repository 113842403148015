import { Injectable } from '@angular/core';
import { Store } from '@datorama/akita';

import { createInitialState, SystemStatusState } from '../entities';

@Injectable({ providedIn: 'root' })
export class SystemStatusStore extends Store<SystemStatusState> {
  constructor() {
    super(createInitialState(), { name: 'system-status', resettable: true });
    this.setLoading(true);
  }
}
