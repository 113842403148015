import { Directive, ElementRef, HostBinding, Input, Renderer2, RendererStyleFlags2 } from '@angular/core';
import { UNDERLINE_COLOR_CSS_VAR, UNDERLINE_DEFAULT_COLOR } from '@guardicore-ui/shared/data';

@Directive({
  selector: '[gcUnderline]',
})
export class UnderlineDirective {
  @HostBinding('class.gc-underlined')
  get classGcUnderlined(): boolean {
    return true;
  }

  @Input('gcUnderline')
  set key(key: string) {
    if (!key) {
      return;
    }

    this.renderer.setStyle(
      this.el.nativeElement,
      `border-bottom-color`,
      `var(--${UNDERLINE_COLOR_CSS_VAR}-${key.toLowerCase()}, ${UNDERLINE_DEFAULT_COLOR})`,
      RendererStyleFlags2.DashCase,
    );
  }

  constructor(private readonly el: ElementRef, private readonly renderer: Renderer2) {}
}
