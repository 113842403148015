import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { ApplicationViewName } from '@guardicore-ui/shared/data';
import { SystemStatusQuery } from '@guardicore-ui/shared/system-status';
import { getRouteByViewName } from '@guardicore-ui/shared/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RbacService } from './rbac.service';

@Injectable({
  providedIn: 'root',
})
export class RbacGuard {
  constructor(private readonly rbac: RbacService, private readonly systemStatus: SystemStatusQuery, private readonly router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const viewName = route?.data?.['viewName'] as ApplicationViewName;

    return this.rbac.isAllowedAsync(viewName).pipe(
      map(rbacIsAllowed => {
        if (rbacIsAllowed && this.systemStatus.isAllowed(viewName)) {
          return true;
        }

        return this.router.parseUrl(
          `/${getRouteByViewName(
            this.systemStatus.defaultView()?.viewName || '',
            this.router.config,
            this.systemStatus.featureFlags(),
          ).join('/')}`,
        );
      }),
    );
  }
}
