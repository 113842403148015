<ng-container *ngIf="_value">
  <div *ngIf="showToolTip$ | async else noEllipsis">
    <div
    automation-id="text__copy-tooltip"
      *ngIf="useCopyTextTooltip else notUseCopyTextTooltip"
      [gcPopoverHoverAnchor]="popoverContent"
      panelCssClass='tooltip copy-tooltip'
      withLeaf
      [aligns]="aligns"
      [margin]="margin"
      disableOtherClickOutside
    ><ng-template [ngTemplateOutlet]="textValue"></ng-template></div>
  <ng-template #notUseCopyTextTooltip>
    <div
      automation-id="text__tooltip"
      [gcTooltip]="tooltipText || undefined"
      [aligns]="aligns"
      disableOtherClickOutside
    ><ng-template [ngTemplateOutlet]="textValue"></ng-template></div>
  </ng-template>
  </div>
  <div automation-id="text__no-tooltip" >
    <ng-template #noEllipsis>
      <ng-template [ngTemplateOutlet]="textValue"></ng-template>
    </ng-template>
  </div>
</ng-container>
<ng-template #textValue>
 <div automation-id="text__middle-ellipsis" *ngIf="ellipsisType === 'middle'" class="middle-ellipsis-container">
  <span class="left" (gcHasCssEllipsis)="onEllipsisChange($event)">{{leftText}}</span>
  <span class="right">{{rightText}}</span>
</div>
<div
 automation-id="text__end-ellipsis"
 *ngIf="ellipsisType === 'end'"
 class="ellipsis-css"
 (gcHasCssEllipsis)="onEllipsisChange($event)">{{ _value }}
</div>
</ng-template>
<ng-template #popoverContent>
 <div class="tooltip-container">
  <span class="tooltip-text"><span></span>{{ _value }}</span>
  <gc-text-copy [textToCopy]="_value"></gc-text-copy>
 </div>
</ng-template>
