import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { HybridDraftsFacade } from '@guardicore-ui/hybrid/domain';
import { MenuActionWithIcon } from '@guardicore-ui/shared/data';
import { PopoverRef } from '@guardicore-ui/ui/popovers/popover';
import { Observable } from 'rxjs';

@Component({
  selector: 'gc-unpublished-changes-menu',
  templateUrl: './unpublished-changes-menu.component.html',
  styleUrls: ['./unpublished-changes-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnpublishedChangesMenuComponent {
  @Input() ref?: PopoverRef;
  @Input() drafts$?: Observable<MenuActionWithIcon<string>[]>;

  constructor(private readonly hybridDraftsFacade: HybridDraftsFacade) {}

  onPublishAction(key: string): void {
    this.hybridDraftsFacade.publishDraft(key);
  }
}
