import { Injectable } from '@angular/core';
import { HotkeysService as HotkeysService3rdParty, Hotkey } from 'angular2-hotkeys';
import { Subject } from 'rxjs';

import { HotkeyCombination, HOTKEY_COMBINATIONS } from './hotkey-combination';

@Injectable({
  providedIn: 'root',
})
export class HotkeysService {
  private readonly actionSubj = new Subject<string>();
  readonly action$ = this.actionSubj.asObservable();

  constructor(private readonly hkService: HotkeysService3rdParty) {}

  set(actionName: string, combination: HotkeyCombination): void {
    this.hkService.add(
      new Hotkey(
        HOTKEY_COMBINATIONS[combination],
        () => {
          this.actionSubj.next(actionName);

          return false;
        },
        undefined,
        combination,
      ),
    );
  }
}
