import { ActivatedRoute } from '@angular/router';
import { RouteData } from '@guardicore-ui/shared/data';

export function getRouteData(route: ActivatedRoute, oldAppOrigin?: boolean | null): RouteData {
  let child = route.firstChild;

  while (child) {
    if (child.firstChild) {
      child = child.firstChild;
    } else {
      return {
        ...child.snapshot.data,
        params: child.snapshot.params,
        oldAppOrigin: oldAppOrigin === true,
      };
    }
  }

  return {};
}
