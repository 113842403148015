<ng-template>
  <div
    *ngIf="template"
    class="sat-popover-container"
    #focusTrapElement
    [ngClass]="_classList"
    [ngStyle]="wrapperStyle || null"
    [@transformPopover]="{ value: state, params: params }"
    (@transformPopover.done)="animationDone$.next($event)"
  >
    <ng-container *ngTemplateOutlet="template; context: { $implicit: ref }"></ng-container>
  </div>
</ng-template>
