import { Injectable } from '@angular/core';
import { Observable, skip } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { CreateNewRowService } from './create-new-row.service';
import { GridDataSource } from './grid-data-source.service';

@Injectable()
export class NewRowRef {
  destroy$ = this.addNewRowService.isRowOpen$.pipe(
    skip(1),
    filter(isOpen => !isOpen),
  );

  readonly confirmAction$: Observable<unknown> = this.addNewRowService.confirmAction$.pipe(
    takeUntil(this.destroy$),
    tap(() => this.gridDataSource.store.setLoading(true)),
  );

  constructor(private addNewRowService: CreateNewRowService, private gridDataSource: GridDataSource) {}

  closeRow(): void {
    this.gridDataSource.preventDataRefresh = false;
    this.gridDataSource.store.setLoading(false);
    this.addNewRowService.removeRow();
  }
}
