<div class="wrapper">
  <div class="logo-wrapper">
    <gc-icon svgIcon="akamai-wave"></gc-icon>
  </div>
  <h1 class="error-title">Could not connect to Guardicore</h1>
  <div class="progressbar-container" *ngIf="viewModel$ | async as vm">
    <p class="progress-bar-text">{{ vm.progressBarText }}</p>
    <div class="progressbar">
      <div
        class="progressbar-value {{ 'duration-' + vm.retryTime }}"
        [ngClass]="{ reset: vm.progressBarValue >= 99 }">
      </div>
    </div>
    <div class="retry">
      <a class="retry-button" *ngIf="vm.secondsLeft > 0" (click)="onRetryNowClick()">Retry now</a>
    </div>
  </div>
</div>
