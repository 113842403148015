/**
 * Compares URLs ignoring query params.
 * @param url1
 * @param url2
 * @returns
 */
export function areRoutesEqual(url1: string, url2: string): boolean {
  if (!url1 || !url2) {
    return false;
  }

  const url1Splitted = url1.split('?');
  const url2Splitted = url2.split('?');

  if (url1Splitted.length === 0 || url2Splitted.length === 0) {
    return false;
  }

  return url1Splitted[0] === url2Splitted[0];
}
