import { Pipe, PipeTransform, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ApplicationViewName } from '@guardicore-ui/shared/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { RbacAction } from '../entities';
import { RbacService } from '../infrastructure';

@Pipe({
  name: 'rbac',
})
export class RbacPipe implements PipeTransform, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private latestValue = false;

  constructor(private readonly cd: ChangeDetectorRef, private readonly rbac: RbacService) {}

  transform(viewName: ApplicationViewName | null, ...actions: RbacAction[]): boolean {
    if (viewName === null) {
      this.updateLatestValue(false);

      return this.latestValue;
    }

    this.rbac
      .isAllowedAsync(viewName, actions)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: res => this.updateLatestValue(res),
        error: err => {
          throw err;
        },
      });

    return this.latestValue;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private updateLatestValue(value: boolean): void {
    this.latestValue = value;
    this.cd.markForCheck();
  }
}
