import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Injectable } from '@angular/core';

export type AnchorType = number | string | undefined;
let ID = 0;

@Injectable()
export class PopoverAnchorService extends Map<AnchorType, boolean> {
  static createUniqueId(): number {
    return ID++;
  }

  setAnchorValue(anchor: AnchorType, value: boolean): void {
    if (!coerceBooleanProperty(anchor)) {
      return undefined;
    }

    this.set(anchor, value);
  }

  initAnchor(anchor: AnchorType): void {
    if (!coerceBooleanProperty(anchor)) {
      return undefined;
    }

    this.set(anchor, false);
  }

  removeAnchor(anchor: AnchorType): void {
    if (!coerceBooleanProperty(anchor)) {
      return undefined;
    }

    this.delete(anchor);
  }

  isAllowClose(): boolean {
    return Array.from(this.values()).every(value => !value);
  }
}
