import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SKIP_CASING_CONVERTERS } from '@guardicore-ui/shared/data';
import { camelToSnakeCase, httpParamsCamelToSnakeCase } from '@guardicore-ui/shared/utils';
import { Observable } from 'rxjs';

@Injectable()
export class ToSnakeCaseInterceptor implements HttpInterceptor {
  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (request.context.get(SKIP_CASING_CONVERTERS)) {
      return next.handle(request);
    }

    return next.handle(
      request.clone({
        body: camelToSnakeCase(request.body),
        params: httpParamsCamelToSnakeCase(request.params),
      }),
    );
  }
}
