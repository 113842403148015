<div class="wrapper" [ngClass]="mode">
  <div class="step-wrapper" *ngFor="let step of _steps">
    <ng-container *ngIf="stepCssClass(step) as stepCssClass">
      <div class="step" [ngClass]="stepCssClass">
        <span *ngIf="!(stepCssClass === stepStatus.complete)">{{step}}</span>
        <gc-icon svgIcon="check-circle-filled" *ngIf="stepCssClass === stepStatus.complete"></gc-icon>
      </div>
      <div *ngIf="step !== _steps.length" class="line" [ngClass]="stepCssClass"></div>
    </ng-container>
  </div>
</div>
