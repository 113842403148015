/* eslint-disable max-lines */
import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router';
import { AnalyticsService } from '@guardicore-ui/analytics';
import { AuthenticationDialogsService } from '@guardicore-ui/authentication/dialogs';
import {
  AuthenticationFacade,
  AuthenticationQuery,
  AuthenticationStore,
  LogoutAction,
  offsetMilliseconds,
  SessionExpirationFacade,
} from '@guardicore-ui/authentication/domain';
import { HttpErrorHandlerService, AuthErrorResponse } from '@guardicore-ui/error-handling/domain';
import { HybridMainFacade, HybridSectionMessageFacade } from '@guardicore-ui/hybrid/domain';
import { HybridIframeDatasource } from '@guardicore-ui/hybrid/ui';
import { MsspMainFacade } from '@guardicore-ui/mssp/domain';
import { NotificationsFacade } from '@guardicore-ui/notifications/domain';
import { ProjectsDialogsService } from '@guardicore-ui/projects/features';
import { ViewStates, HybridMessagePayload } from '@guardicore-ui/shared/data';
import { FeederFacade } from '@guardicore-ui/shared/feeder';
import { HotkeysService, DOUBLE_PRESS_THRESOLD_MILLISECONDS } from '@guardicore-ui/shared/hotkeys';
import { PollingService } from '@guardicore-ui/shared/polling';
import { RbacService } from '@guardicore-ui/shared/rbac';
import { SystemStatusFacade, SystemStatusQuery, SystemStatusUserPreferences } from '@guardicore-ui/shared/system-status';
import { isObjectsEmpty } from '@guardicore-ui/shared/utils';
import { DialogsService } from '@guardicore-ui/ui/dialogs';
import { ToastrService } from '@guardicore-ui/ui/toastr';
import { UserMenuAction } from '@guardicore-ui/ui/user-menu';
import { BehaviorSubject, combineLatest, merge, Observable, of } from 'rxjs';
import { switchMap, tap, filter, buffer, debounceTime, map, delay, startWith } from 'rxjs/operators';

import { CoreStore, CoreQuery } from '../../state';

const ACTIVATE_DEBUG_MODE_ACTION = '__ACTIVATE_DEBUG_MODE_ACTION__';
const notificationDurationInSec = offsetMilliseconds / 1000;

interface MainLayoutViewModel {
  isLoggedIn: boolean;
  viewStates: ViewStates | null;
}

function createMainLayoutViewModel(): MainLayoutViewModel {
  return {
    isLoggedIn: false,
    viewStates: null,
  };
}

@Component({
  selector: 'gc-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  providers: [{ provide: HybridIframeDatasource, useClass: CoreQuery }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayoutComponent implements OnInit, AfterViewInit {
  readonly viewModel$ = new BehaviorSubject<MainLayoutViewModel>(createMainLayoutViewModel());

  mainContentClasses: BehaviorSubject<{ [key: string]: boolean }> = new BehaviorSubject({});
  modalBackdropExtraClassesSubject: BehaviorSubject<{ [key: string]: boolean }> = new BehaviorSubject({});
  isModalBackdropOpen$: Observable<boolean> = of(false);
  sectionMessage: HybridMessagePayload = {};

  readonly hotkeyInIframe$ = this.hybridCommunication.hotkey$;
  readonly env$ = this.systemStatus.env$;
  readonly systemStatus$ = this.systemStatus.state$;
  readonly isLoggedin$ = combineLatest({ isLoggedIn: this.auth.isLoggedIn$, systemStatus: this.systemStatus.state$ }).pipe(
    map(({ isLoggedIn, systemStatus }) => isLoggedIn && !!systemStatus.login),
  );

  constructor(
    httpErrorHandler: HttpErrorHandlerService,
    private location: Location,
    private readonly sessionExpirationFacade: SessionExpirationFacade,
    private readonly authStore: AuthenticationStore,
    private readonly authQuery: AuthenticationQuery,
    private readonly store: CoreStore,
    private readonly authDialogService: AuthenticationDialogsService,
    private readonly dialogs: DialogsService,
    private readonly systemStatusService: SystemStatusFacade,
    private readonly systemStatus: SystemStatusQuery,
    private readonly hybridCommunication: HybridMainFacade,
    private readonly hybridSectionMessage: HybridSectionMessageFacade,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly hotkeysService: HotkeysService,
    private readonly pollingService: PollingService,
    private readonly feeder: FeederFacade,
    private readonly notifications: NotificationsFacade,
    private readonly mssp: MsspMainFacade,
    private readonly rbacService: RbacService,
    private readonly toastrService: ToastrService,
    private breakpoint: BreakpointObserver,
    private cdr: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    readonly auth: AuthenticationFacade,
    readonly query: CoreQuery,
    readonly projectsDialogsService: ProjectsDialogsService,
  ) {
    combineLatest([this.router.events, this.hybridSectionMessage.sectionMessages$.pipe(startWith({}))])
      .pipe(
        filter(([e]) => e instanceof NavigationEnd || 'routerEvent' in e),
        map(([e]) => {
          const url = e instanceof Scroll ? e?.routerEvent?.url : (e as NavigationEnd).url;
          const page = this.hybridSectionMessage.getSectionNameFromUrl(url);
          const message = this.hybridSectionMessage.getMessage(page);

          this.sectionMessage = message;
          this.cdr.markForCheck();
        }),
      )
      .subscribe();

    combineLatest([query.isLoggedIn$, query.isInDebugMode$, rbacService.allPermissions$])
      .pipe(
        map(([isLoggedIn, isInDebugMode, viewStates]) => {
          const isHidden = viewStates['Labs']?.isHidden || !isInDebugMode;

          if (isHidden !== viewStates['Labs']?.isHidden) {
            viewStates = { ...viewStates, Labs: { ...viewStates['Labs'], isHidden: isHidden } };
          }

          return { isLoggedIn, viewStates };
        }),
      )
      .subscribe(vm => this.viewModel$.next(vm));
    query.isInDebugMode$.subscribe(isInDebugMode => this.auth.setDebugMode(isInDebugMode));
    query.isIframeCollapsed$.subscribe(isIframeCollapsed => hybridCommunication.setIsIframeCollapsed(isIframeCollapsed));
    // TODO delete after implementing Projects page V3
    this.hybridCommunication.violationsDialog$.subscribe(({ projectId, projectCreationTime }) => {
      const maxTimeRange = systemStatus.featureFlags()?.savedMaps?.projectViolationsMapMaxTimeRangeDays;

      this.projectsDialogsService.openViolationsDialog(projectId, maxTimeRange, projectCreationTime);
    });

    merge(httpErrorHandler.connectivityError$, hybridCommunication.isOldAppServerConnectivityLost$).subscribe(() => {
      store.apiConnectionFailed();
    });
    httpErrorHandler.authError$.pipe(tap(resp => this.processAuthError(resp))).subscribe();
  }

  ngOnInit(): void {
    this.isModalBackdropOpen$ = this.hybridCommunication.isModalBackdropOpen$;
    this.activateDebugModeAction();
    this.feeder.init();

    combineLatest([this.hybridCommunication.matDialogModal$, this.hybridCommunication.isModalBackdropOpen$])
      .pipe(
        tap(([isMatDialogModal, isModalBackdropOpen]: [boolean, boolean]) => {
          const modalBackdropExtraClasses = this.modalBackdropExtraClassesSubject.getValue();
          const mainContentClasses = this.mainContentClasses.getValue();

          this.modalBackdropExtraClassesSubject.next({
            ...modalBackdropExtraClasses,
            ['show-backdrop']: isModalBackdropOpen,
            ['show-mat-dialog-backdrop mat-dialog']: isMatDialogModal,
          });

          this.mainContentClasses.next({
            ...mainContentClasses,
            ['z-index-1050']: isModalBackdropOpen,
          });
        }),
      )
      .subscribe();

    this.mssp.selected$
      .pipe(
        tap(selected => {
          if (!this.query.isMsspApplicable()) {
            return;
          }

          const id = selected?.value?.toString() || null;

          this.hybridCommunication.sendTenantId(id);
          this.updateTenantQueryParam(id);
        }),
      )
      .subscribe();

    merge(this.hybridCommunication.toastr$, this.notifications.toasts$).subscribe(({ message, title, type }: HybridMessagePayload) => {
      if (message) {
        this.toastrService.open(message, title, type);
      }
    });

    this.hybridCommunication.dialog$.subscribe((message: HybridMessagePayload) => this.dialogs.generic({ ...message['payload'] }));

    this.feeder.sectionMessages$.subscribe(({ message }: HybridMessagePayload) => {
      if (message) {
        this.sectionMessage = message;
      }
    });

    this.hybridCommunication.analytics$.subscribe((message: HybridMessagePayload) =>
      this.analyticsService.track(message['eventName'], message['props']['props'] || message['props']),
    );
  }

  // eslint-disable-next-line max-lines-per-function
  ngAfterViewInit(): void {
    this.query.isResetPasswordRequired$
      .pipe(
        filter(Boolean),
        delay(1),
        switchMap(() => {
          const username = this.systemStatus.user()?.username;

          if (!username) {
            return of();
          }

          return this.authDialogService
            .openSetPassword({
              username,
              passwordRequirements: this.systemStatus.configuration()?.passwordRequirements,
            })
            .afterClosed();
        }),
        switchMap(res => (res?.success ? this.systemStatusService.readSystemStatus() : of(undefined))),
      )
      .subscribe(systemStatusData => {
        if (!systemStatusData) {
          this.auth.logout();

          return;
        }

        if (this.router.url === '/') {
          const url = this.query.defaultUrl() as string;

          this.router.navigateByUrl(url);
        } else {
          this.router.navigateByUrl(this.location.path());
        }

        if (this.query.arePollingsAllowed()) {
          this.pollingService.startPollings();
        }
      });
    this.query.areRecoveryCodesRequired$.pipe(filter(Boolean), delay(1)).subscribe(() => this.authDialogService.openRecoveryCodes());
    this.setupNewNavbarIntroduction();
    this.query.areAgreementsRequired$
      .pipe(
        delay(1),
        switchMap(actions => {
          return this.dialogs
            .openEvalEula(actions)
            .afterClosed()
            .pipe(map(({ dismissed }) => ({ dismissed, actions })));
        }),
        switchMap(({ dismissed, actions }) => {
          return dismissed ? of({}) : this.systemStatusService.acceptAgreements(actions);
        }),
      )
      .subscribe(systemStatusData => {
        if (isObjectsEmpty(systemStatusData)) {
          this.logout();
        } else {
          this.store.updateSystemStatus(systemStatusData);
        }
      });

    this.query.arePollingsAllowed$.subscribe(allowed => {
      if (allowed) {
        this.pollingService.startPollings();
      } else {
        this.pollingService.stopPollings();
      }
    });

    this.sessionExpirationFacade.sessionExpired$.subscribe(() => {
      this.authDialogService
        .openSessionExpirationTimeout(notificationDurationInSec)
        .afterClosed()
        .subscribe(isExpired =>
          isExpired
            ? this.logout('expired')
            : this.sessionExpirationFacade.startSessionExpirationCheck(this.systemStatusService.systemStatus),
        );
    });
  }

  private setupNewNavbarIntroduction(): void {
    this.query.isNewNavbarIntroductionRequired$
      .pipe(
        filter(Boolean),
        delay(1000),
        switchMap(() => this.dialogs.openNewNavbarIntroduction().afterClosed()),
        switchMap(() => this.systemStatusService.confirmNewNavigation()),
      )
      .subscribe();
  }

  startSystemStatusPolling(): void {
    this.systemStatusService.readSystemStatus().subscribe();
  }

  onUserMenuAction(action: UserMenuAction): void {
    switch (action) {
      case 'changePassword':
        this.authDialogService.openChangePassword({
          data: {
            username: this.systemStatus.user()?.username,
            passwordRequirements: this.systemStatus.configuration()?.passwordRequirements,
          },
        });
        break;
      case 'manage2StepVerification':
        this.authDialogService.openManageTwoFA(this.systemStatus.configuration()?.twoFactorAuthPolicyEnforced);
        break;
      case 'logout':
        this.logout();
        break;
      case 'userPreferences':
        this.dialogs
          .openUserPreferences(!!this.systemStatus.user()?.isColorBlind)
          .afterClosed()
          .pipe(
            filter(Boolean),
            switchMap(preferences => this.systemStatusService.saveUserPreferences(preferences as SystemStatusUserPreferences)),
          )
          .subscribe();
        break;
      case 'help':
        window.open('https://techdocs.akamai.com/segmentation-home/docs/welcome-to-guardicore-segmentation', '_blank');
        break;
      case 'eula':
        window.open('/assets/html/eula.html', '_blank');
        break;
      case 'relNotes':
        window.open('https://techdocs.akamai.com/segmentation-home/docs/welcome-to-guardicore-segmentation#release-notes ', '_blank');
        break;
      case 'evaluation':
        window.open('/assets/html/evaluation_agreement.html', '_blank');
        break;
      case 'apiDocsV3':
        window.open('api/v3.0/docs/rest-api-docs', '_blank');
        break;
      case 'apiDocsV4':
        window.open('api/v3.0/docs/automation-rest-api-docs', '_blank');
        break;
      case 'apiDocsAgent':
        window.open('api/v3.0/docs/agent-sdk-api-docs', '_blank');
        break;
      default:
        break;
    }
  }

  logout(logoutAction?: LogoutAction): void {
    this.hybridCommunication.logout();
    this.auth.logout(logoutAction);
    this.pollingService.stopPollings();
  }

  onLocationChange(url: string): void {
    if (this.query.isIframeCollapsed()) {
      return;
    }

    if (this.query.isMsspApplicable()) {
      const tenant = this.store.getValue().routeData?.queryParamMap?.get('tenant');

      if (tenant) {
        this.mssp.setSelected(tenant);
      }

      if (this.mssp.selected()) {
        const tenantId = this.mssp.selected()?.value || null;

        if (tenantId && !tenant) {
          url += url.includes('?') ? `&tenant=${tenantId}` : `?tenant=${tenantId}`;
        }

        this.hybridCommunication.sendTenantId(tenantId?.toString() || null);
      }
    }

    this.router.navigateByUrl(url, { state: { oldAppOrigin: true } });
  }

  onModalBackdropClick(): void {
    this.hybridCommunication.closeModalBackdrop();
  }

  closeSectionMessage(): void {
    const url = this.hybridSectionMessage.getSectionNameFromUrl(this.router.url);

    this.hybridSectionMessage.closeMessage(url);
  }

  private processAuthError(authErrorResponse: AuthErrorResponse): void {
    if (!this.isLoggedin$) {
      return;
    }

    const { error } = authErrorResponse;
    const description = authErrorResponse.description?.trim() || undefined;

    switch (error) {
      case 'Set New Password':
        this.authDialogService.openSetNewPasswordDemand(description);
        break;
      case 'JWT Token Revoked':
        this.authDialogService.openSessionExpired(description);
        break;
      case 'Unauthorized Request':
        this.authDialogService.openPermissionDenied(description);
        break;
      case 'Invalid JWT':
        this.logout();
        break;
      default:
        this.logout();
        break;
    }
  }

  private activateDebugModeAction(): void {
    this.hotkeysService.set(ACTIVATE_DEBUG_MODE_ACTION, 'debugMode');

    const activateDebugModeAction$ = this.hotkeysService.action$.pipe(filter(action => action === ACTIVATE_DEBUG_MODE_ACTION));
    const activateDebugModeActionInIframe$ = this.hotkeyInIframe$.pipe(
      filter(comb => comb === 'debugMode'),
      map(() => ACTIVATE_DEBUG_MODE_ACTION),
    );

    const debugMode$ = activateDebugModeAction$.pipe(
      buffer(activateDebugModeAction$.pipe(debounceTime(DOUBLE_PRESS_THRESOLD_MILLISECONDS))),
      filter(actions => actions.length === 2),
    );
    const debugModeInIframe$ = activateDebugModeActionInIframe$.pipe(
      buffer(activateDebugModeActionInIframe$.pipe(debounceTime(DOUBLE_PRESS_THRESOLD_MILLISECONDS))),
      filter(actions => actions.length === 2),
    );

    merge(debugMode$, debugModeInIframe$).subscribe(() => this.store.toggleDebugMode());
  }

  private updateTenantQueryParam(id: string | null): void {
    this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: { tenant: id },
      relativeTo: this.route,
    });
  }
}
