import { PasswordRequirements } from './system-status-configuration-password-requirements';

export interface SystemStatusConfiguration {
  visibilityEnabled: boolean;
  showUiLabs: boolean;
  showDebugLinks?: boolean;
  showAgentSdkDocs: boolean;
  showInternalDocumentation: boolean;
  mitigationAgentsEnabled: boolean;
  showUncommonDomainUsage: boolean;
  showFileReputationChecks: boolean;
  showUnresolvedDomains: boolean;
  showNetworkMisconfigurations: boolean;
  showCloudInventory: boolean;
  twoFactorAuthPolicyEnforced: boolean;
  hideEmailReportConfig: boolean;
  visibilityPolicyEnforcementEnabled: boolean;
  azureThreatFeedIntegrationEnabled: boolean;
  interflowIntegrationEnabled: boolean;
  paloaltoIntegrationEnabled: boolean;
  disableMitigationButtons: boolean;
  defaultLabelForGrouping?: string | null;
  requireAcceptingEula: boolean;
  evaluationModeState: boolean;
  saveDnsToElastic: boolean;
  isQEnabled: boolean;
  revealShortDateRangeOptions: boolean;
  agentsDiagnosticsVisibleTtlHours: number;
  agentsUpgradeVisibleTtlHours: number;
  agentsStartedVisibleTtlMinutes: number;
  maxAgentsForRemoteDiagnostics: number;
  maxAgentsForRemoteUpgrade: number;
  enableKoUpgradeEnforcement: boolean;
  maxAgentsForSettingEnforcementState: number;
  maxAgentsForBulkLabelOperation: number;
  maxPolicyRulesForBulkOperation: number;
  showManagementClusterHealthInUi: boolean;
  savedMapDropdownRecordsLimit: number;
  permissionSchemeAllowAllUsersEnabled: boolean;
  hidePolicyViolationIncidentSummary: boolean;
  visibilityIncidentLastOccurrenceCheckMaxTimeframeDays: number;
  pluginsApiVersion: string;
  pluginsEnabled: boolean;
  maxAdGroupsToDisplay: number;
  defaultPodGroupsFieldNames: string;
  isKubernetesDefined: boolean;
  passwordRequirements: PasswordRequirements;
  labelsMatchingAssetsCountIncludeDeletedAssets: boolean;
  hideAuditFilters: boolean;
  keylinesLegacyMapMode: boolean;
  userSessionIdleTimeoutMins: number;
}
