import { InjectionToken, OnInit } from '@angular/core';

import { AbstractConstructor, Constructor } from './constructor';

export interface HasId extends OnInit {
  id: string;
}

export const ID = new InjectionToken<string>('__ID__');

export type CanHaveIdConstructor = AbstractConstructor<HasId> & Constructor<HasId>;
