import { Injectable } from '@angular/core';
import { SystemStatusState } from '@guardicore-ui/shared/system-status';

import { SessionExpirationService } from '../infrastructure';

@Injectable({
  providedIn: 'root',
})
export class SessionExpirationFacade {
  constructor(private readonly sessionExpirationService: SessionExpirationService) {}
  sessionExpired$ = this.sessionExpirationService.sessionExpired$;

  public startSessionExpirationCheck(value: SystemStatusState): void {
    return this.sessionExpirationService.run(value);
  }

  public stopSessionExpirationCheck(): void {
    return this.sessionExpirationService.stop();
  }
}
