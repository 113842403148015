import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gc-selection-indicator',
  templateUrl: './selection-indicator.component.html',
  styleUrls: ['./selection-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectionIndicatorComponent {
  @Output() closed = new EventEmitter<void>();

  total = 0;

  @Input() set selection(total: number | undefined) {
    this.total = total ?? 0;
  }
}
