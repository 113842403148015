export interface Context {
  context: {
    page: StrippedPage;
  };
}

export interface StrippedPage {
  search: string;
  url: string;
  referrer: string;
  title: string;
  path?: string;
  reference?: string;
}
