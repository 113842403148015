import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@datorama/akita';

export interface AuthenticationState {
  id?: string;
  isLoggedIn?: boolean;
  error?: HttpErrorResponse;
}

export function createInitialState(): AuthenticationState {
  return {
    id: undefined,
    isLoggedIn: false,
  };
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationStore extends Store<AuthenticationState> {
  constructor() {
    super(createInitialState(), { name: 'authentication', resettable: true });
  }
}
