import { Injectable } from '@angular/core';
import { EntityStore } from '@datorama/akita';

import { NotificationsState, createInitialState, NotificationsResponse } from '../entities';

@Injectable({ providedIn: 'root' })
export class NotificationsStore extends EntityStore<NotificationsState> {
  constructor() {
    super(createInitialState(), { name: 'notifications' });
  }

  updateNotifications(newState?: NotificationsResponse): void {
    if (!newState) {
      return;
    }

    const { items, newCount, totalCount, toasts } = newState;

    this.setError(undefined);
    this.set(items);
    this.update({
      toasts,
      newCount,
      totalCount,
    });
  }
}
