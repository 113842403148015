import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gc-violations',
  templateUrl: './violations.component.html',
  styleUrls: ['./violations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViolationsComponent {
  @Output() openViolationsDialog = new EventEmitter();

  openDialog(): void {
    this.openViolationsDialog.emit();
  }
}
