import { FilterOptionsEntity, Label } from '@guardicore-ui/shared/data';
import { Observable, map } from 'rxjs';

export interface FilterValueChangesEvent {
  id?: string;
  value: FilterOptionsEntity[];
}

export abstract class FilterDataSource {
  abstract readonly filtersValue$: Observable<Record<string, FilterOptionsEntity[] | undefined>>;
  abstract readonly filterValueChanges$: Observable<FilterValueChangesEvent>;

  abstract updateFilterValue(value: FilterOptionsEntity[], id?: string, isQuery?: boolean): void;
  abstract emitFilterValueChanges(value: FilterOptionsEntity[], id?: string): void;
  abstract getFilterValue(id?: string): FilterOptionsEntity[];

  getLabelText(id: string): Observable<string | Label[]> {
    return this.filtersValue$.pipe(
      map(value => value[id]),
      map(value => {
        if (!value?.length) {
          return '';
        }

        const isTextString = value.map(entity => entity.text).some(t => typeof t === 'string' || typeof t === 'undefined');

        return isTextString
          ? value.map(entity => entity.text || entity.value).join(',')
          : value.map(entity => entity.text as Label[]).flat();
      }),
    );
  }
}

export interface HasFilterDataSource {
  dataSource: FilterDataSource;
}
