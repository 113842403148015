export const ICON_PACKS = ['icons8-regular', 'font-awesome'] as const;

export type IconPacks = typeof ICON_PACKS[number];

import { InjectionToken } from '@angular/core';

export enum IconTypes {
  SVG = 'SVG',
}

export const SVG_NAMES = [
  'default',
  'close',
  'alert',
  'spinner',
  'info',
  'download',
  'confirm',
  'done',
  'done-circle',
  'chevron-right',
  'chevron-left',
  'expand-more',
  'expand-less',
  'wrench',
  'refresh',
  'delete',
  'caret-up',
  'caret-down',
  'plus',
  'minus',
  'stop',
  'sort',
  'sortable',
  'edit',
  'search',
  'list',
  'dot',
  'side-line',
  'failed',
  'clear',
  'turned-off',
  'turned-on',
  'setting',
  'cancel',
  'warning',
  'folder',
  'error',
  'success',
  'key',
  'bell',
  'api',
  'logOut',
  'license',
  'bug',
  'valid',
  'asset',
  'userGroups',
  'labelGroups',
  'navbar-dashboard',
  'navbar-cluster',
  'reveal',
  'navbar-enforce',
  'navbar-inventory',
  'navbar-security',
  'navbar-system',
  'guardicore-g',
  'guardicore-title',
  'space-dots',
  'akamai-wave',
  'loading-animation',
  'invalid',
  'tab',
  'price-tag',
  'price-tag-filled',
  'shield',
  'shield-filled',
  'circle',
  'link',
  'clipboard',
  'external-link',
  'cloud-slash',
  'warning-transparent',
  'clock',
  'label',
  'check-circle',
  'check-circle-filled',
  'close-circle',
  'locked',
  'unix',
  'linux',
  'windows',
  'diagnostics',
  'upload-alt',
  'play',
  'circle-arrow-right',
  'circle-arrow-up',
  'thin-arrow-left',
  'global-alt',
  'deception-module',
  'detection-module',
  'enforcement-module',
  'reveal-module',
  'overlay-errors',
  'overlay-stopped',
  'overlay-not-installed',
  'overlay-initializing',
  'access-module',
  'restart',
  'debug',
  'edit-v3',
  'action-allow',
  'action-alert',
  'action-block-alert',
  'action-info',
  'error-filled',
  'block-alert',
  'file',
  'tenant',
  'key-colored',
  'visibility',
  'enforcement-colored',
  'done-circle-filled',
  'pencil',
] as const;

export type IconColor = 'basic' | 'warning' | 'grey' | 'white' | null;
export type SvgIconName = typeof SVG_NAMES[number];
export const DEFAULT_ICON_SET = new InjectionToken<SvgIconName>('DEFAULT_ICON_SET');

export const MATERIAL_DEFAULT_FONT_SET_CLASS = 'material-icons';

export interface Icon {
  icon: SvgIconName;
  iconExtraCssClass?: string;
}

export interface IconFont {
  iconCssClass: string;
  iconPack: IconPacks;
  iconExtraCssClass?: string;
}
