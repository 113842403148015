<div *ngIf="qrCode" class="qr-wrapper">
  <div class="qr-code-wrapper">
    <img [src]="imageSource" alt="QR Code" class="qr-code" />
    <p>{{ key }}</p>
  </div>
  <p class="instructions">
    Scan this QR code using an Authentication app (such as
    <a href="https://support.google.com/accounts/answer/1066447" target="_blank">Google Authenticator</a>,
    <a href="https://www.authy.com/" target="_blank">Authy</a>, or <a href="https://duo.com/" target="_blank">Duo</a>) in order to 6-digit
    verification codes.
  </p>
</div>
