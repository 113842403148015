export const keyColored = `<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
  <rect width="32" height="32" rx="16" fill="#EBE8FB"/>
  <path d="M16.6207 15.6815C14.4198 17.7818 10.8515 17.7818 8.65065 15.6815C6.44978 13.5811 6.44978 10.1757 8.65065 8.07529C10.8515 5.9749 14.4198 5.9749 16.6207 8.07529C18.8216 10.1757 18.8216 13.5811 16.6207 15.6815Z" fill="#654CDB"/>
  <path d="M15.5638 14.6724C15.7867 14.8851 16.1511 14.8869 16.3454 14.6501C16.6082 14.3297 16.8248 13.9762 16.9886 13.5988C17.2254 13.0533 17.3473 12.4686 17.3473 11.8781C17.3473 11.2876 17.2254 10.703 16.9886 10.1574C16.7519 9.61191 16.4048 9.11624 15.9673 8.69871C15.5298 8.28119 15.0104 7.94999 14.4388 7.72403C13.8672 7.49807 13.2545 7.38177 12.6358 7.38177C12.0171 7.38177 11.4044 7.49807 10.8328 7.72403C10.4374 7.88036 10.0669 8.08706 9.73124 8.33786C9.4831 8.52328 9.48497 8.87112 9.70782 9.0838L15.5638 14.6724Z" fill="#4934B7"/>
  <path d="M12.6357 9.53502C12.6357 10.4564 11.8531 11.2032 10.8877 11.2032C9.92226 11.2032 9.13963 10.4564 9.13963 9.53502C9.13963 8.61368 9.92226 7.86679 10.8877 7.86679C11.8531 7.86679 12.6357 8.61368 12.6357 9.53502Z" fill="#F1EEFB"/>
  <path d="M23.8765 25.3145L11.6973 13.6912C11.5631 13.5631 11.5068 13.382 11.543 13.2042L11.9833 11.0472C12.0707 10.6191 12.6237 10.4578 12.9469 10.7663L18.6439 16.2034C18.7908 16.3436 19.0059 16.3965 19.2054 16.3416L20.745 15.9182C20.9445 15.8633 21.1594 15.9162 21.3063 16.0562L22.7192 17.403C22.8674 17.5442 22.9226 17.7515 22.863 17.9431L22.1148 20.3505C22.0063 20.6996 22.2803 21.05 22.6618 21.05H23.8249C23.9763 21.05 24.1214 21.1074 24.2284 21.2095L24.8329 21.7863C24.9473 21.8955 25.0078 22.046 24.9992 22.2002L24.8463 24.9549C24.8202 25.4256 24.2258 25.6479 23.8765 25.3145Z" fill="#4934B7"/>
  <path d="M24.0175 25.4662L13.3668 15.2508C13.3435 15.2284 13.3315 15.1974 13.3339 15.166L13.4008 14.2977C13.408 14.2044 13.5269 14.1626 13.5958 14.2291L24.7867 25.0426C24.8149 25.0698 24.8259 25.1092 24.8089 25.1438C24.777 25.2088 24.7066 25.317 24.5646 25.4055C24.3915 25.5134 24.1651 25.5037 24.0787 25.4951C24.0553 25.4927 24.0341 25.4821 24.0175 25.4662Z" fill="#A68DFF"/>
</svg>

`;
