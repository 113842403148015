<div class="gc-dialog-content">
  <section class="side-content">
    <gc-stepper [currentStep]="currentStep" [steps]="steps"></gc-stepper>
  </section>
  <section class="main-content">
    <div class="header">
      <p class="title">{{ data.title | uppercase }}</p>
      <button class="control-button" type="button" (click)="close()">
        <gc-icon svgIcon="close"></gc-icon>
      </button>
    </div>
    <div class="gc-dialog-content-body">
      <ng-content></ng-content>
    </div>
    <div class="footer" >
      <button class="control-button" *ngIf="backButtonVisible" (click)="action('back')">
        <gc-icon svgIcon="thin-arrow-left"></gc-icon>
      </button>
      <div class="action-buttons">
        <button class="ghost-button" *ngIf="secondaryButtonVisible" (click)="action('save')">{{ secondaryButtonLabel }}</button>
        <button class="primary-button next-button" (click)="action('next')" [disabled]="primaryButtonDisabled">{{ primaryButtonLabel }}
          <gc-icon *ngIf="!lastStep && !primaryButtonDisabled" svgIcon="chevron-left"></gc-icon>
        </button>
      </div>
    </div>
  </section>
</div>
