import { SortingOrder } from '@guardicore-ui/shared/data';

import { SortParam } from '../entities';

function orderToSign(order: SortingOrder): string {
  return order === 'desc' ? '-' : '';
}

export function sortToQueryParam(sort: SortParam[]): string {
  return sort.map(s => `${orderToSign(s.order)}${s.fieldName}`).join(',');
}
