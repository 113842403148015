import { HybridMessagePayload } from '@guardicore-ui/shared/data';

export enum HybridMessageSubject {
  Loaded = 1,
  Token,
  Navigation,
  SystemStatusUpdate,
  Click,
  HotKey,
  DialogModal,
  ServerConnectivityLost,
  TokenExpired,
  Draft,
  WindowTitle,
  PollingsStop,
  PollingsResume,
  ReloadAllApiCalls,
  Logout,
  TenantId,
  Track,
  Minify,
  SectionMessage,
  ToastrMessage,
  GenericModal,
  NavigationLink,
  ViolationsDialog,
}

export interface HybridMessage {
  subject: HybridMessageSubject;
  sessionId: string;
  payload: HybridMessagePayload;
}
