import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiIconModule } from '@guardicore-ui/ui/icon';

import { PasswordRequirementsStatusComponent } from './password-requirements-status/password-requirements-status.component';

@NgModule({
  imports: [CommonModule, UiIconModule],
  declarations: [PasswordRequirementsStatusComponent],
  exports: [PasswordRequirementsStatusComponent],
})
export class AuthenticationUiModule {}
