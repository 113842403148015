import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { UserAgreementAction } from '@guardicore-ui/shared/data';
import { BehaviorSubject } from 'rxjs';

import { steps, EvalEulaStep } from './steps';

@Component({
  selector: 'gc-eval-eula-dialog',
  templateUrl: './eval-eula-dialog.component.html',
  styleUrls: ['./eval-eula-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EvalEulaDialogComponent implements OnInit {
  @Input() demandedSteps: UserAgreementAction[] = [];

  readonly steps$ = new BehaviorSubject<EvalEulaStep[]>([]);
  currentStepIndex = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { demandedSteps: UserAgreementAction[] },
    protected readonly ref: MatDialogRef<EvalEulaDialogComponent>,
    private readonly sanitizer: DomSanitizer,
  ) {
    this.demandedSteps = data.demandedSteps || this.demandedSteps;
  }

  ngOnInit(): void {
    const st = this.demandedSteps
      .map(ds => steps.get(ds) as EvalEulaStep)
      .map(s => ({ ...s, resourceUrl: this.sanitizer.bypassSecurityTrustResourceUrl(`/assets/html/${s.html}.html`) }));

    this.steps$.next(st);
  }

  onAccept(): void {
    if (this.currentStepIndex === this.demandedSteps.length - 1) {
      this.ref.close({});
    } else {
      this.currentStepIndex++;
    }
  }

  onCancel(): void {
    this.ref.close({ dismissed: true });
  }
}
