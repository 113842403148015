import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ManageTwoFaFacade, NewRecoveryCodes } from '@guardicore-ui/authentication/domain';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { delay, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gc-recovery-codes',
  templateUrl: './recovery-codes.component.html',
  styleUrls: ['./recovery-codes.component.scss'],
})
export class RecoveryCodesComponent implements OnInit, OnDestroy {
  readonly errorMessage$ = new BehaviorSubject<string>('');
  private readonly destroy$ = new Subject<void>();
  private copyDelay = of(undefined).pipe(delay(3000), take(1), takeUntil(this.destroy$));
  recoveryCodes: string[] = [];
  loading = true;
  codesCopied = false;

  constructor(public readonly dialogRef: MatDialogRef<RecoveryCodesComponent>, private readonly manageTwoFAFacade: ManageTwoFaFacade) {}

  ngOnInit(): void {
    this.manageTwoFAFacade
      .getRecoveryCodes()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(
        (codes: NewRecoveryCodes) => {
          this.recoveryCodes = codes.newRecoveryCodes;
          this.loading = false;
        },
        () => {
          this.errorMessage$.next('Failed to get recovery codes');
        },
      );
  }

  public close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.dialogRef.close();
  }

  copyCodes(): void {
    navigator.clipboard.writeText(this.recoveryCodes.join()).then(() => {
      this.codesCopied = true;
      this.copyDelay.subscribe(() => (this.codesCopied = false));
    });
  }
}
