import { ElementRef, InjectionToken, Injector } from '@angular/core';

import { HorizontalAlign, VerticalAlign } from './alignment';

export type Direction = 'top' | 'bottom' | 'left' | 'right';

export interface OpenPopoverOptionsAlignment {
  horizontal?: HorizontalAlign;
  vertical?: VerticalAlign;
}

export interface OpenPopoverOptions {
  injector?: Injector;
  anchor?: ElementRef<HTMLElement>;
  alignment?: OpenPopoverOptionsAlignment;
  offsetX?: number;
  offsetY?: number;
  withLeaf?: boolean;
  direction?: Direction;
  /** The width of the overlay panel. If a number is provided, pixel units are assumed. */
  width?: number | string;
  /** The height of the overlay panel. If a number is provided, pixel units are assumed. */
  height?: number | string;
  /** The min-width of the overlay panel. If a number is provided, pixel units are assumed. */
  minWidth?: number | string;
  /** The min-height of the overlay panel. If a number is provided, pixel units are assumed. */
  minHeight?: number | string;
  /** The max-width of the overlay panel. If a number is provided, pixel units are assumed. */
  maxWidth?: number | string;
  /** The max-height of the overlay panel. If a number is provided, pixel units are assumed. */
  maxHeight?: number | string;
  minWidthEqAnchor?: boolean;
}

export const LEEF_SIZE = 8;

export const POPOVER_OPTIONS = new InjectionToken<OpenPopoverOptions>('POPOVER_OPTIONS');
