import { Injectable } from '@angular/core';
import { FilterDataSource, FilterValueChangesEvent } from '@guardicore-ui/filters/shared';
import { FilterOptionsEntity } from '@guardicore-ui/shared/data';
import { ReplaySubject, map } from 'rxjs';

import { TENNANTS_ASSIGNMENT_FILTER_ID } from '../entities';

@Injectable()
export class TenantsAssignmentDataSource extends FilterDataSource {
  private filtersValue?: FilterOptionsEntity;
  private readonly filtersValueSubject = new ReplaySubject<FilterOptionsEntity | undefined>(1);
  protected readonly filterValueChangesEventEmitter = new ReplaySubject<FilterValueChangesEvent>(1);

  override readonly filtersValue$ = this.filtersValueSubject.pipe(
    map(value => ({ [TENNANTS_ASSIGNMENT_FILTER_ID]: value ? [value] : [] })),
  );

  override filterValueChanges$ = this.filterValueChangesEventEmitter.asObservable();

  override updateFilterValue(value: FilterOptionsEntity[]): void {
    this.filtersValue = value.length ? value[0] : undefined;
    this.filtersValueSubject.next(this.filtersValue);
  }

  override emitFilterValueChanges(value: FilterOptionsEntity[]): void {
    this.updateFilterValue(value);
    this.filterValueChangesEventEmitter.next({ value });
  }

  override getFilterValue(): FilterOptionsEntity[] {
    return this.filtersValue ? [{ ...this.filtersValue }] : [];
  }
}
