import { Injectable } from '@angular/core';
import { FilterDataSource, FilterValueChangesEvent } from '@guardicore-ui/filters/shared';
import { FilterOptionsEntity } from '@guardicore-ui/shared/data';
import { ReplaySubject } from 'rxjs';

import { FiltersComponentStore, FiltersComponentQuery } from '../infrastructure';

@Injectable()
export class FiltersDataSource extends FilterDataSource {
  protected readonly filterValueChangesEventEmitter = new ReplaySubject<FilterValueChangesEvent>(1);

  override readonly filtersValue$ = this.filtersComponentQuery.filtersValue$;
  override filterValueChanges$ = this.filterValueChangesEventEmitter.asObservable();

  constructor(
    private readonly filtersComponentStore: FiltersComponentStore,
    private readonly filtersComponentQuery: FiltersComponentQuery,
  ) {
    super();
  }

  override updateFilterValue(value: FilterOptionsEntity[], id: string, isQuery: boolean): void {
    this.filtersComponentStore.updateFilterValue(id, value, isQuery);
  }

  override emitFilterValueChanges(value: FilterOptionsEntity[], id: string): void {
    this.filterValueChangesEventEmitter.next({ id, value });
  }

  override getFilterValue(id: string): FilterOptionsEntity[] {
    return this.filtersComponentStore.getValue().filtersValue[id];
  }
}
