import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpStatusCodes } from '@guardicore-ui/shared/data';
import { Subject } from 'rxjs';

import { AuthErrorResponse } from '../entities';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorHandlerService {
  private readonly httpErrorSubj = new Subject<HttpErrorResponse>();
  private readonly requestFinishedSubj = new Subject<void>();
  private readonly authErrorSubj = new Subject<AuthErrorResponse>();
  private readonly connectivityErrorSubj = new Subject<void>();

  readonly httpError$ = this.httpErrorSubj.asObservable();
  readonly requestFinished$ = this.requestFinishedSubj.asObservable();
  readonly authError$ = this.authErrorSubj.asObservable();
  readonly connectivityError$ = this.connectivityErrorSubj.asObservable();

  newHttpError(error: HttpErrorResponse): void {
    switch (error.status) {
      case HttpStatusCodes.FORBIDDEN:
        this.authErrorSubj.next(error.error);
        break;

      case 0:
      case HttpStatusCodes.INTERNAL_SERVER_ERROR:
      case HttpStatusCodes.BAD_GATEWAY:
      case HttpStatusCodes.SERVICE_UNAVAILABLE:
      case HttpStatusCodes.GATEWAY_TIMEOUT:
        this.connectivityErrorSubj.next();
        break;

      default:
        break;
    }

    this.httpErrorSubj.next(error);
  }

  requestFinished(): void {
    this.requestFinishedSubj.next();
  }
}
