export interface UserData {
  buildtag: string;
  hostname: string;
  userid: string;
  isgcuser: boolean;
  release: string;
  isproduction: boolean;
  windowwidth: number;
  windowheight: number;
}
