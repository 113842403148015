import { ApplicationViewName } from '@guardicore-ui/shared/data';

export type QuickLinkResponse = Record<ApplicationViewName, QuickLink[]>;
export type QuickLinkApiResponse = Record<ApplicationViewName, ApplicationViewName[]>;

export interface QuickLink {
  link: string;
  viewName: string;
  label: string;
}
