import { ActivatedRoute } from '@angular/router';
import { BreadcrumbItem } from '@guardicore-ui/shared/data';

export function createBreadcrumbs(route: ActivatedRoute, url = '', breadcrumbs: BreadcrumbItem[] = []): BreadcrumbItem[] {
  const children: ActivatedRoute[] = route.children;

  if (children.length === 0) {
    return breadcrumbs;
  }

  for (const child of children) {
    const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

    if (routeURL !== '') {
      url += `/${routeURL}`;
    }

    const label = child.snapshot.data['breadcrumb']?.label;

    if (label) {
      breadcrumbs.push({ label, url });
    }

    return createBreadcrumbs(child, url, breadcrumbs);
  }

  return [];
}
