export const ON_GOING_STATUSES = {
  COMPLETED: 'COMPLETED',
  INFO: 'INFO',
  WARNING: 'WARNING',
  SKIPPED: 'SKIPPED',
  TIMEOUT: 'TIMEOUT',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  FAILURE: 'FAILURE',
  RUNNING: 'RUNNING',
  DONE: 'DONE',
  ABORTED: 'ABORTED',
  WAITFORKO: 'WAIT_FOR_KO',
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  NONE: 'NONE',
  ABORTING: 'ABORTING',
} as const;

type StatusKeys = keyof typeof ON_GOING_STATUSES;

export type OnGoingStatusValues = typeof ON_GOING_STATUSES[StatusKeys];
