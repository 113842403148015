import { SystemStatusState } from '@guardicore-ui/shared/system-status';
import { from, Observable } from 'rxjs';

// TODO should be provided from backend GC-37847
export function getUserId(status: SystemStatusState): Observable<string> {
  return from(hashCode(`${status.environmentCustomerName}/${status?.login?.username}`));
}

// TOREMOVE when userId will be provided from backend GC-37847
async function hashCode(str: string): Promise<string> {
  const msgUint8 = new TextEncoder().encode(str);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0'));

  hashHex.splice(20, 0, '-');
  hashHex.splice(16, 0, '-');
  hashHex.splice(12, 0, '-');
  hashHex.splice(8, 0, '-');

  return hashHex.join('').substr(0, 32);
}
