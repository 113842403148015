import { HttpParams } from '@angular/common/http';

import { toSnakeCase } from './object-serializers';

export function httpParamsCamelToSnakeCase(httpParams: HttpParams): HttpParams {
  let params = new HttpParams();

  httpParams.keys().forEach(key => {
    params = params.set(toSnakeCase(key), httpParams.get(key) as string);
  });

  return params;
}
