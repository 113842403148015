import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_URL, SystemLogItem } from '@guardicore-ui/shared/data';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { NotificationsStore } from './notifications.store';
import { NotificationsResponse, NotificationsState } from '../entities';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(
    @Inject(API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
    private notificationsStore: NotificationsStore,
  ) {}

  read(): Observable<NotificationsState> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.get<{ [key: string]: any }>(`${this.apiUrl}/system-notifications`).pipe(
      map(resp => resp as NotificationsResponse),
      tap(resp => {
        this.notificationsStore.setError(undefined);
        this.notificationsStore.updateNotifications(resp);
      }),
      catchError(() => {
        return EMPTY;
      }),
    );
  }

  dismissAll(): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/system-notifications`, { action: 'hide_all' }).pipe(
      switchMap(() => this.read()),
      switchMap(() => EMPTY),
      catchError((err: HttpErrorResponse) => of(this.notificationsStore.setError(err))),
    );
  }

  ackAll(): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/system-notifications`, { action: 'ack_all' }).pipe(
      switchMap(() => this.read()),
      switchMap(() => EMPTY),
      catchError((err: HttpErrorResponse) => of(this.notificationsStore.setError(err))),
    );
  }

  dismissOne(notification: SystemLogItem): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/system-notifications/${notification.uuid}`, { action: 'hide' }).pipe(
      switchMap(() => this.read()),
      switchMap(() => EMPTY),
      catchError((err: HttpErrorResponse) => of(this.notificationsStore.setError(err))),
    );
  }
}
