import { Component, ChangeDetectionStrategy, OnDestroy, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, debounceTime, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CsvExportService } from '../csv-export.service';
import { CSVExportConfig, CsvExportState, CSVExportTask, MaxRecordsNumResponse } from '../types';

const DEFAULT_LIMIT = 1000;

enum ExportChoice {
  all,
  range,
}

@Component({
  selector: 'gc-csv-export-dialog',
  templateUrl: './csv-export-dialog.component.html',
  styleUrls: ['./csv-export-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CsvExportDialogComponent implements OnInit, OnDestroy {
  private stopPolling$ = new Subject<void>();
  private destroy$ = new Subject<void>();
  private readonly exportUrl: string;
  readonly exportChoice = ExportChoice;
  readonly exportState = CsvExportState;
  totalItems;
  exportTaskStatus$ = new BehaviorSubject<CSVExportTask | undefined>(undefined);
  totalRecords$ = new BehaviorSubject<number>(DEFAULT_LIMIT);
  form: FormGroup;
  initialStateLoading = true;
  pendingTask = false;
  maxRecords = DEFAULT_LIMIT;
  exportAllMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public config: CSVExportConfig,
    protected readonly ref: MatDialogRef<CsvExportDialogComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly csvExportService: CsvExportService,
  ) {
    this.config = { ...{ showFormattingOptions: true, showRangeOptions: true, filters: {} }, ...config };
    this.exportUrl = config.exportUrl || config.viewName;
    this.totalItems = config.totalItems;
    this.exportAllMessage = `Export all ${this.totalItems} records`;

    this.form = this.formBuilder.group({
      exportChoice: ExportChoice.all,
      exportRangeStart: 1,
      exportRangeEnd: Math.min(DEFAULT_LIMIT, this.totalItems, this.maxRecords),
      compress: false,
    });
  }

  ngOnInit(): void {
    this.form.disable();
    this.form.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500)).subscribe(() => {
      this.correctRages();
      this.updateTotalRecordsCount();
    });

    this.csvExportService
      .startPolling(undefined, this.config.viewName)
      .pipe(takeUntil(this.stopPolling$))
      .subscribe((csvExport: CSVExportTask | undefined) => {
        this.initialStateLoading = false;
        this.form.enable();
        if (csvExport) {
          this.handleExportUpdate(csvExport);
        }
      });

    this.csvExportService.getMaxRecordsNum().subscribe((maxRecordsResponse: MaxRecordsNumResponse) => {
      this.maxRecords = maxRecordsResponse.maxRecords;
      this.updateExportRangeEnd();
      this.exportAllMessage = this.totalItems <= this.maxRecords ? this.exportAllMessage : `Export first ${this.maxRecords} records`;
    });
  }

  apply(): void {
    if (this.pendingTask) {
      this.cancelExport();
      this.exportTaskStatus$.next(undefined);
      this.pendingTask = false;
      this.stopPolling$.next();
      this.form.enable();
    } else {
      this.form.disable();
      this.newExport();
    }
  }

  close(): void {
    this.ref.close();
  }

  private updateExportRangeEnd(): void {
    this.form.get('exportRangeEnd')?.setValue(Math.min(DEFAULT_LIMIT, this.totalItems, this.maxRecords));
    this.updateTotalRecordsCount();
  }

  private correctRages(): void {
    const exportRangeEnd = this.form.get('exportRangeEnd');
    const exportRangeStart = this.form.get('exportRangeStart');
    const max = this.totalItems;
    const min = 1;

    if (exportRangeEnd?.value < exportRangeStart?.value) {
      exportRangeEnd?.setValue(exportRangeStart?.value);
    }

    if (exportRangeEnd?.value > max) {
      exportRangeEnd?.setValue(max);
    }

    if (exportRangeStart?.value < min) {
      exportRangeStart?.setValue(min);
    }

    if (exportRangeStart?.value > exportRangeEnd?.value) {
      exportRangeStart?.setValue(exportRangeEnd?.value);
    }
  }

  private updateTotalRecordsCount(): void {
    const exportRangeEnd = this.form.get('exportRangeEnd')?.value;
    const exportRangeStart = this.form.get('exportRangeStart')?.value;

    if (!exportRangeEnd || !exportRangeStart) {
      this.totalRecords$.next(0);

      return;
    }

    this.totalRecords$.next(Math.max(this.form.get('exportRangeEnd')?.value - this.form.get('exportRangeStart')?.value + 1, 0));
  }

  newExport(): void {
    let limit = this.totalRecords$.getValue();
    let offset = this.form.get('exportRangeStart')?.value - 1;
    const compress = !!this.form.get('compress')?.value;

    if (this.form.get('exportChoice')?.value === ExportChoice.all) {
      limit = Math.min(this.totalItems, this.maxRecords);
      offset = 0;
    }

    this.initialStateLoading = true;
    this.csvExportService
      .newExport(this.exportUrl, offset, limit, compress, { ...this.config.filters })
      .pipe(takeUntil(this.stopPolling$))
      .subscribe((csvExport: CSVExportTask | undefined) => {
        this.initialStateLoading = false;
        if (csvExport) {
          this.handleExportUpdate(csvExport);
        }
      });
  }

  private handleExportUpdate(csvExport: CSVExportTask): void {
    if (![CsvExportState.canceled, CsvExportState.resolved].includes(csvExport.state)) {
      this.pendingTask = true;
      this.form.disable();
      this.exportTaskStatus$.next(csvExport);
    } else {
      this.pendingTask = false;
      this.form.enable();
      this.exportTaskStatus$.next(undefined);
    }
  }

  cancelExport(): void {
    this.csvExportService.stopPolling(this.exportTaskStatus$?.getValue()?.id, true);
  }

  download(): void {
    const exportedCsvFileId = this.exportTaskStatus$?.getValue()?.exportedCsvFileId;

    if (exportedCsvFileId) {
      this.csvExportService.download(exportedCsvFileId);
    }
  }

  ngOnDestroy(): void {
    this.stopPolling$.next();
    this.destroy$.next();
  }
}
