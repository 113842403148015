/* eslint-disable @typescript-eslint/no-explicit-any */

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { AbstractConstructor, CanBeDisabled, CanBeDisabledConstructor, Constructor } from '@guardicore-ui/shared/data';

/* eslint-disable @typescript-eslint/ban-types */
export function mixinDisabled<T extends AbstractConstructor<{}>>(base: T): CanBeDisabledConstructor & T;
export function mixinDisabled<T extends Constructor<{}>>(base: T): CanBeDisabledConstructor & T {
  return class extends base implements CanBeDisabled {
    protected _disabled = false;

    get disabled(): boolean {
      return this._disabled;
    }

    set disabled(value: BooleanInput) {
      this._disabled = coerceBooleanProperty(value);
    }

    constructor(...args: any[]) {
      super(...args);
    }
  };
}
