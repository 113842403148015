import { Injectable } from '@angular/core';
import { SystemStatusQuery } from '@guardicore-ui/shared/system-status';
import { Observable } from 'rxjs';

import { ChangePasswordRequest } from '../entities/change-password-request';
import { ChangePasswordService } from '../infrastructure/change-password.service';

@Injectable({ providedIn: 'root' })
export class ChangePasswordFacade {
  constructor(private readonly service: ChangePasswordService, private readonly systemStatus: SystemStatusQuery) {}

  public changePassword(username: string, currentPassword: string, password: string, passwordVerification: string): Observable<void> {
    const changePasswordRequest: ChangePasswordRequest = {
      username,
      currPassword: currentPassword,
      password,
      passwordConfirm: passwordVerification,
    };

    return this.service.changePassword(changePasswordRequest);
  }
}
