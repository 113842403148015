<div class="wrapper" *ngIf="{ index: state$ | async } as stateIndex">
  <button
    class="close-button"
    (click)="ref.close()"
    gcAnalytics="NavBar v2.0 Intro - Close Clicked"
    [gcAnalyticsProps]="analyticsProps()"
  ><gc-icon svgIcon="close"></gc-icon>
  </button>
  <div class="content">
    <ng-container *ngTemplateOutlet="stateTemplate$ | async"></ng-container>
    <img class="navbar-mock state-{{ stateIndex.index }}" src="assets/images/new-navbar-popup-mock.svg"/>
    <img class="background state-{{ stateIndex.index }}" src="assets/images/new-navbar-popup-illustration.png"/>
    <img class="quick-links-mock state-{{ stateIndex.index }}" src="assets/images/new-navbar-quick-links-mock.png"/>
    <img class="system-mock state-{{ stateIndex.index }}" src="assets/images/new-navbar-system-mock.png"/>
  </div>
  <div class="footer">
    <div class="state-selector">
      <div
        class="state"
        [class.current]="state.index === stateIndex.index"
        *ngFor="let state of statesTemplates" (click)="state$.next(state.index || 0)"
      ></div>
    </div>
    <div class="spacer"></div>
    <ng-container *ngIf="!lastState else last">
      <p class="skip-intro"
         (click)="ref.close()"
         gcAnalytics="NavBar v2.0 Intro - Skip Clicked"
         [gcAnalyticsProps]="analyticsProps()">Skip Intro</p>
      <button
        class="primary-button"
        (click)="nextState()"
        gcAnalytics="NavBar v2.0 Intro - Next Clicked"
        [gcAnalyticsProps]="analyticsProps()">{{ stateIndex.index === 0 ? "So, what's new?" : 'Next' }}</button>
    </ng-container>

    <ng-template #last>
<!--      TODO: Add blog link when provided -->
<!--      <a class="blog-link"-->
<!--         href="https://www.google.com"-->
<!--         target="_blank"-->
<!--         gcAnalytics="NavBar v2.0 Intro - Read Blog Clicked">Read the Blog</a>-->
      <button class="primary-button" (click)="ref.close()">Try it now!</button>
    </ng-template>
  </div>
</div>

<ng-template [gcTemplateStateIndex]="0">
  <div>
    <div class="state-title initial-state">
      <p class="state-subtitle">WELCOME TO OUR NEW</p>
      <p>NAVIGATION BAR!</p>
    </div>

    <div class="state-text">
      <p>Based on <u>your</u> feedback, we’re excited to<br>announce our <b>new navigation bar!</b></p>
      <p>This is part of our commitment to continuously<br>improve our system and enhance the experience of our users.</p>
    </div>
  </div>
</ng-template>

<ng-template [gcTemplateStateIndex]="1">
  <div>
    <div class="state-title">
      <p>SIMPLER & EASY TO USE</p>
    </div>

    <div class="state-text">
      <p>A simpler, more organized navigation experience,<br>with only 6 core functionality menu categories.</p>
      <p>Get faster access to the information that matters<br>the most to you!</p>
    </div>
  </div>
</ng-template>

<ng-template [gcTemplateStateIndex]="2">
  <div>
    <div class="state-title">
      <p>DESIGNED TO FIT<br>THE WAY YOU WORK</p>
    </div>

    <div class="state-text">
      <p>The Admin features are now integrated with the<br>main navigation, based on how they are used.</p>
      <p>Agents are part of the Inventory menu,<br>Deception is now part of Security, and so on.</p>
    </div>
  </div>
</ng-template>

<ng-template [gcTemplateStateIndex]="3">
  <div>
    <div class="state-title">
      <p>QUICK LINKS</p>
    </div>

    <div class="state-text">
      <p>At the top of each page you will find<br><b>Quick Links</b>, for quicker access to the most used<br>screens and user workflows.</p>
    </div>
  </div>
</ng-template>
