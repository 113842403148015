import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiIconModule } from '@guardicore-ui/ui/icon';
import { UiTooltipModule } from '@guardicore-ui/ui/popovers/tooltip';

import { TextCopyComponent } from './text-copy.component';

@NgModule({
  imports: [CommonModule, ClipboardModule, UiIconModule, UiTooltipModule],
  declarations: [TextCopyComponent],
  providers: [],
  exports: [TextCopyComponent],
})
export class UiTextCopyModule {}
