import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_URL } from '@guardicore-ui/shared/data';
import { camelToSnakeCaseKeys } from '@guardicore-ui/shared/utils';
import { Observable } from 'rxjs';

import { ChangePasswordRequest } from '../entities/change-password-request';

@Injectable({ providedIn: 'root' })
export class ChangePasswordService {
  constructor(private readonly http: HttpClient, @Inject(API_URL) private readonly apiUrl: string) {}
  changePassword(request: ChangePasswordRequest): Observable<void> {
    const snakeCaseRequest = camelToSnakeCaseKeys(request);

    return this.http.post<void>(`${this.apiUrl}/system/user/password`, snakeCaseRequest);
  }
}
