import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FiltersService } from './infrastructure';

@NgModule({
  imports: [CommonModule],
  providers: [FiltersService],
})
export class FiltersDomainModule {}
