import { HttpClient, HttpParams } from '@angular/common/http';
import { ResolvedFilterOptions, FiltersState, FeatureWithPaginationAndFiltersState, RowDataObject } from '@guardicore-ui/shared/data';
import { FeatureWithPaginationAndFilters, camelToSnakeCaseKeys, isObjectsEmpty } from '@guardicore-ui/shared/utils';
import { map, Observable, of, switchMap, tap } from 'rxjs';

import { ApiResponse, ApiResponseFilter } from '../entities';
import { apiResponseToFeatureState, processRequestParams } from '../utils';

const HAS_DATA_FLAGS_ARRAY = '__HAS_DATA_FLAGS';

/**
 * Extend this class in your features domain infrastructure service.
 */
export abstract class ApiService<T extends RowDataObject> {
  constructor(
    protected readonly apiUrl: string,
    protected readonly http: HttpClient,
    protected readonly apiSegment?: string,
    protected featureStore?: FeatureWithPaginationAndFilters,
  ) {}

  /**
   * Feel free to override this method in you extensions to extend or provide
   * different data reading functionaity. Until you don't want to completely
   * change the behavior of this class, call `this._read(requestParams);`
   * in your overriden method.
   *
   * @param requestParams Request params of standard Guardicore API with sort, filters and pagination
   * @returns Observable of standard Guardicore API response with pagination data
   */
  read(requestParams?: FeatureWithPaginationAndFiltersState): Observable<ApiResponse<T>> {
    return this._read(requestParams);
  }

  protected _read<R extends RowDataObject = T>(requestParams?: FeatureWithPaginationAndFiltersState): Observable<ApiResponse<R>> {
    requestParams = requestParams ?? this.featureStore?.getValue();

    const isFiltered = !isObjectsEmpty(requestParams?.filtersState);

    return this.http.get<ApiResponse<R>>(`${this.apiUrl}/${this.apiSegment}`, { params: processRequestParams(requestParams) }).pipe(
      switchMap(res =>
        !isObjectsEmpty(res?.filter)
          ? this.filterOptionsResolve(res.filter).pipe(
              map(resolvedFilterOptions => {
                const filter = camelToSnakeCaseKeys(res.filter);
                let resolvedFilters: FiltersState = Object.assign(
                  {},
                  ...Object.keys(filter).map(key => ({
                    [key]:
                      typeof filter[key] === 'string' && filter[key]
                        ? (filter[key] as string).split(',').map(value => ({ value }))
                        : [{ value: filter[key] }],
                  })),
                );

                resolvedFilters = Object.assign(
                  resolvedFilters,
                  ...resolvedFilterOptions.map(o => ({ [o.filterName]: o.selectedOptions })),
                );

                return {
                  ...res,
                  resolvedFilters,
                };
              }),
            )
          : of({
              ...res,
              resolvedFilters: {},
            }),
      ),
      tap(res => {
        this.featureStore?.updateState(apiResponseToFeatureState(res));

        if (isFiltered) {
          res.objects.length && this.setHasDataFlag();

          return;
        }

        res.objects.length ? this.setHasDataFlag() : this.removeHasDataFlag();
      }),
    );
  }

  getSelected<R>(requestParams?: FeatureWithPaginationAndFiltersState, limit = 10, unselectedRowIds?: string[]): Observable<R[]> {
    requestParams = requestParams ?? this.featureStore?.getValue();

    if (requestParams?.pageState) {
      requestParams.pageState.offset = 0;
      requestParams.pageState.limit = limit;
    }

    let params = processRequestParams(requestParams);

    params = params.set('unselected', unselectedRowIds?.length ? unselectedRowIds.join(',') : '');

    return this.http.get<R[]>(`${this.apiUrl}/${this.apiSegment}/selected`, { params });
  }

  getHasDataFlagsDictionary(): Record<string, boolean> | null {
    if (!this.isLSDefined()) {
      return null;
    }

    const flagsString = localStorage.getItem(HAS_DATA_FLAGS_ARRAY);

    if (!flagsString) {
      return null;
    }

    try {
      return JSON.parse(flagsString);
    } catch {
      return null;
    }
  }

  hasDataFlag(): boolean | undefined {
    const flags = this.getHasDataFlagsDictionary();

    if (!flags || !this.apiSegment || flags[this.apiSegment] === undefined) {
      return undefined;
    }

    return flags[this.apiSegment];
  }

  filterOptionsResolve(filter?: ApiResponseFilter): Observable<ResolvedFilterOptions[]> {
    const params = new HttpParams({ fromObject: filter ? camelToSnakeCaseKeys(filter) : undefined });

    return this.http.get<ResolvedFilterOptions[]>(`${this.apiUrl}/${this.apiSegment}/filter-options-resolve`, { params });
  }

  private setHasDataFlag(): void {
    if (!this.isLSDefined() || !this.apiSegment) {
      return;
    }

    let flags = this.getHasDataFlagsDictionary();

    if (!flags) {
      flags = {};
    }

    flags[this.apiSegment] = true;
    localStorage.setItem(HAS_DATA_FLAGS_ARRAY, JSON.stringify(flags));
  }

  private removeHasDataFlag(): void {
    if (!this.isLSDefined() || !this.apiSegment) {
      return;
    }

    let flags = this.getHasDataFlagsDictionary();

    if (!flags) {
      flags = {};
    }

    flags[this.apiSegment] = false;
    localStorage.setItem(HAS_DATA_FLAGS_ARRAY, JSON.stringify(flags));
  }

  private isLSDefined(): boolean {
    return typeof localStorage === 'object' && localStorage !== null;
  }
}
