import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HybridMessagePayload } from '@guardicore-ui/shared/data';
import { SystemStatusQuery } from '@guardicore-ui/shared/system-status';
import { capitalizeFirstLetters } from '@guardicore-ui/shared/utils';
import { map, Observable, Subject } from 'rxjs';
import { switchMap, filter } from 'rxjs/operators';

import { FeederStatus, FeederPages, feederPages } from '../entities';
import { FeederService } from '../feeder.service';

@Injectable({
  providedIn: 'root',
})
export class FeederFacade {
  private sectionMessages: Subject<HybridMessagePayload> = new Subject();

  sectionMessages$: Observable<HybridMessagePayload> = this.sectionMessages.asObservable();
  page = '';

  constructor(private feederService: FeederService, private readonly systemStatus: SystemStatusQuery, private router: Router) {}

  init(): void {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        map(e => this.getFeederStatusURL((e as NavigationEnd).url)),
        filter(Boolean),
        switchMap(url => this.feederService.getFeederStatus(url)),
      )
      .subscribe((status: FeederStatus) => {
        if (!status?.isOutdated) {
          return;
        }

        this.sectionMessages.next({ message: this.getFeedMessage(this.page as string) });
      });
  }

  private getFeederStatusURL(url: string): string {
    this.page = url.split('?')[0]?.split('/').pop() || '';
    const feedObj = (feederPages as FeederPages)[this.page];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const flag = (this.systemStatus.featureFlags() as any)?.[feedObj?.parentKey]?.[feedObj?.key];

    return flag ? feedObj.apiUrl : '';
  }

  private getFeedMessage(page: string): { [key: string]: unknown } {
    const pageName = (feederPages as FeederPages)[page]?.name || capitalizeFirstLetters(page.replace('/_/g', ' '));

    return {
      type: 'warning',
      title: `Guardicore ${pageName} Feed is not up to date`,
      message: 'Check for details in the System Log',
      moreInfoLink: '/administration/system/log',
      moreInfo: 'View in System Log',
    };
  }
}
