import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiIconModule } from '@guardicore-ui/ui/icon';

import { FullScreenErrorComponent } from './full-screen-error.component';

@NgModule({
  imports: [CommonModule, UiIconModule],
  declarations: [FullScreenErrorComponent],
  exports: [FullScreenErrorComponent],
})
export class UiErrorScreenModule {}
