import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, HostBinding, Input } from '@angular/core';
import { IconColor } from '@guardicore-ui/shared/data';
import { Subject } from 'rxjs';

@Component({
  template: ``,
})
export class IconBaseComponent {
  protected destroy$ = new Subject<void>();
  protected _inline = true;

  @HostBinding(' attr.icon-color')
  @Input()
  color!: IconColor;

  @HostBinding('class.icon-inline')
  @Input()
  set inline(inline: unknown) {
    this._inline = coerceBooleanProperty(inline);
  }

  get inline(): boolean {
    return this._inline;
  }

  protected splitIconName(iconName: string): string[] {
    if (!iconName) {
      return ['', ''];
    }

    const parts = iconName.split(':');

    switch (parts.length) {
      case 1:
        return ['', parts[0]];
      case 2:
        return parts;
      default:
        throw Error(`Invalid icon name: "${iconName}"`);
    }
  }
}
