import { SafeResourceUrl } from '@angular/platform-browser';
import { UserAgreementAction } from '@guardicore-ui/shared/data';

export interface EvalEulaStep {
  title: string;
  html: string;
  resourceUrl?: SafeResourceUrl;
}

export const steps = new Map<UserAgreementAction, EvalEulaStep>()
  .set('accept_evaluation', {
    title: 'Evaluation Agreement',
    html: 'evaluation_agreement',
  })
  .set('accept_eula', {
    title: 'End User License Agreement',
    html: 'eula',
  });
