export const labelGroups = `<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.34739 1H3.64334V5.8085L9.896 12.2L14.6001 7.39149L8.34739 1Z" fill="white"/>
<path d="M8.34739 1H3.64334V5.8085L9.896 12.2L14.6001 7.39149L8.34739 1ZM3.99275 5.66079V1.35717H8.20289L14.104 7.39149L9.896 11.6929L3.99275 5.66079Z" fill="#09011D"/>
<path d="M7.17446 2.68018H2V7.96953L8.87792 15.0002L14.0524 9.71082L7.17446 2.68018Z" fill="white"/>
<path d="M7.17446 2.68018H2V7.96953L8.87792 15.0002L14.0524 9.71082L7.17446 2.68018ZM2.38435 7.80704V3.07306H7.0155L13.5067 9.71082L8.87792 14.4424L2.38435 7.80704Z" fill="#09011D"/>
<path d="M3.91192 3.84668C3.48724 3.84668 3.14085 4.20076 3.14085 4.63487C3.14085 5.06898 3.48724 5.42306 3.91192 5.42306C4.3366 5.42306 4.68299 5.06898 4.68299 4.63487C4.68299 4.20076 4.3366 3.84668 3.91192 3.84668ZM3.91192 5.03017C3.6984 5.03017 3.52757 4.85314 3.52757 4.63729C3.52757 4.42145 3.70077 4.24441 3.91192 4.24441C4.12308 4.24441 4.29627 4.42145 4.29627 4.63729C4.29627 4.85314 4.12545 5.03017 3.91192 5.03017Z" fill="#09011D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.41489 4.27385C4.59487 4.55715 4.50941 4.94142 4.22435 5.13066C3.93929 5.31989 3.5611 5.24339 3.38112 4.96009C3.20114 4.6768 3.2866 4.29252 3.57166 4.10329C3.85672 3.91405 4.23491 3.99055 4.41489 4.27385ZM3.20788 5.0751C3.44823 5.45343 3.95159 5.55517 4.33219 5.30251C4.71279 5.04986 4.82659 4.53843 4.58624 4.1601C4.34589 3.78177 3.84253 3.68003 3.46193 3.93269C3.08133 4.18534 2.96753 4.69677 3.20788 5.0751Z" fill="#09011D"/>
<g opacity="0.936849" filter="url(#filter0_i_4187_33471)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.4149 4.27395C4.59488 4.55724 4.50942 4.94152 4.22436 5.13075C3.9393 5.31999 3.56111 5.24349 3.38113 4.96019C3.20115 4.67689 3.28661 4.29262 3.57167 4.10338C3.85673 3.91415 4.23492 3.99065 4.4149 4.27395Z" fill="white"/>
</g>
<defs>
<filter id="filter0_i_4187_33471" x="3.28687" y="3.99951" width="1.22231" height="1.23511" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.163871 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_4187_33471"/>
</filter>
</defs>
</svg>`;
