<div class="gc-dialog-content">
  <div class="gc-dialog-content-body">
    <h2 class="title">{{ title }}</h2>
    <p class="content">{{ content }}</p>
  </div>
  <div class="gc-dialog-content-footer footer" *ngIf="primaryButtonLabel || secondaryButtonLabel">
    <button class="default-button" *ngIf="secondaryButtonLabel" (click)="dismiss(true)">{{ secondaryButtonLabel }}</button>
    <button class="primary-button" *ngIf="primaryButtonLabel" (click)="dismiss()">{{ primaryButtonLabel }}</button>
  </div>
</div>
