import { Inject, Injectable } from '@angular/core';
import { Project } from '@guardicore-ui/projects/domain';
import { GridFeatureQuery, GRID_COMPONENT_STORE } from '@guardicore-ui/ui/grid';

import { ProjectsFeatureStore } from './projects.store';

@Injectable()
export class ProjectsFeatureQuery extends GridFeatureQuery<Project> {
  constructor(@Inject(GRID_COMPONENT_STORE) protected override readonly store: ProjectsFeatureStore) {
    super(store);
  }
}
