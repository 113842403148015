import { ComponentHarness } from '@angular/cdk/testing';

const BASE_CLASS = '.error-block';

export class ErrorBlockHarness extends ComponentHarness {
  static hostSelector = 'gc-error-block';

  protected title = this.locatorFor(`${BASE_CLASS}__title`);
  protected description = this.locatorForOptional(`${BASE_CLASS}__description`);

  public async getTitleText(): Promise<string> {
    return (await (await this.title()).text()).trim();
  }

  public async isDescriptionVisible(): Promise<boolean> {
    return !!(await this.description());
  }

  public async getDescriptionText(): Promise<string> {
    const descriptionLabel = await this.description();

    if (!descriptionLabel) {
      return Promise.reject('Description element is not present in the DOM');
    }

    return (await descriptionLabel.text()).trim();
  }
}
