export const turnedOff = `<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14 4L6 4C3.79086 4 2 5.79086 2 8C2 10.2091 3.79086 12 6 12L14 12C16.2091 12 18 10.2091 18 8C18 5.79086 16.2091 4 14 4Z" fill="#D8D8D8"/>
<g filter="url(#filter0_d_3649_29611)">
<path d="M5.79993 11C7.45678 11 8.79993 9.65685 8.79993 8C8.79993 6.34315 7.45678 5 5.79993 5C4.14307 5 2.79993 6.34315 2.79993 8C2.79993 9.65685 4.14307 11 5.79993 11Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d_3649_29611" x="0.799927" y="5" width="8" height="8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-1" dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3649_29611"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3649_29611" result="shape"/>
</filter>
</defs>
</svg>
`;
