import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

@Component({
  selector: 'gc-empty-cell',
  templateUrl: './empty-cell.component.html',
  styleUrls: ['./empty-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyCellComponent implements ICellRendererAngularComp {
  params?: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
