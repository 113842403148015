import { ChangeDetectionStrategy, Input } from '@angular/core';
import { Component } from '@angular/core';

enum StepStatus {
  inProgress = 'in-progress',
  toDo = 'to-do',
  complete = 'complete',
}

@Component({
  selector: 'gc-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent {
  stepStatus = StepStatus;
  _steps: number[] = [];
  @Input() set steps(steps: number) {
    this._steps = Array.from(Array(steps).keys()).map(step => step + 1);
  }

  @Input() currentStep = 1;
  @Input() mode: 'column' | 'row' = 'column';

  stepCssClass(step: number): string {
    if (step === this.currentStep) {
      return StepStatus.inProgress;
    } else if (step > this.currentStep) {
      return StepStatus.toDo;
    } else {
      return StepStatus.complete;
    }
  }
}
