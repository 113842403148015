import { Component, ChangeDetectionStrategy, ElementRef, NgZone } from '@angular/core';
import { REPLACE_VALUE_FROM, REPLACE_VALUE_TO } from '@guardicore-ui/filters/domain';
import { SelectionFilterComponent } from '@guardicore-ui/filters/features/selection-filter';
import { FilterControlConstructor, FilterDataSource, mixinFilterControl, provideFilters } from '@guardicore-ui/filters/shared';
import { MsspUiFacade, TENANT_FILTER_ID, VALUE_NO_TENANT } from '@guardicore-ui/mssp/domain';
import { MsspDataSource, MsspOptionsSource } from '@guardicore-ui/mssp/domain';
import { API_SEGMENT_URL } from '@guardicore-ui/shared/data';
import { VALUE_UNDEFINED } from '@guardicore-ui/ui/filters/common';
import { PopoverBase, PopoverService, mixinPopoverAnchor } from '@guardicore-ui/ui/popovers/popover';
import { map, takeUntil } from 'rxjs';

const msspDropdownComponentBase = mixinPopoverAnchor<PopoverBase & FilterControlConstructor, SelectionFilterComponent>(
  mixinFilterControl(
    class {
      constructor(
        readonly dataSource: FilterDataSource,
        readonly elementRef: ElementRef,
        readonly popoverService: PopoverService,
        readonly ngZone?: NgZone,
      ) {}
    },
  ),
);

@Component({
  selector: 'gc-mssp-dropdown',
  templateUrl: './mssp-dropdown.component.html',
  styleUrls: ['./mssp-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    PopoverService,
    provideFilters(MsspDataSource, MsspOptionsSource),
    { provide: API_SEGMENT_URL, useValue: 'mssp-tenants' },
    { provide: REPLACE_VALUE_FROM, useValue: VALUE_NO_TENANT },
    { provide: REPLACE_VALUE_TO, useValue: VALUE_UNDEFINED },
  ],
  inputs: ['disabled'],
})
export class MsspDropdownComponent extends msspDropdownComponentBase {
  filtersState$ = this.mssp.selected$.pipe(map(res => ({ tenant: res ? [res] : [] })));
  override popoverContentComponent = SelectionFilterComponent;
  readonly defaultText = 'All Tenants';
  readonly invalidText = 'Invalid Tenant';
  readonly valueUndefined = VALUE_UNDEFINED;

  constructor(
    readonly mssp: MsspUiFacade,
    override readonly dataSource: FilterDataSource,
    override readonly elementRef: ElementRef,
    override readonly popoverService: PopoverService,
    override readonly ngZone?: NgZone,
  ) {
    super(elementRef, popoverService, ngZone);
    this.id = TENANT_FILTER_ID;
    this.closeOnClickOutside = true;
    this.openPopoverOptions = {
      alignment: {
        horizontal: 'start',
        vertical: 'below',
      },
      offsetY: 6,
      minWidthEqAnchor: true,
      minWidth: 358,
    };
    dataSource.filterValueChanges$.subscribe(({ value }) => (value?.length ? mssp.select(value[0]) : mssp.unselect()));
    mssp.selected$.subscribe(value => this.dataSource.updateFilterValue(value ? [value] : []));
  }

  override togglePopover(): void {
    super.togglePopover();
    if (!this.popoverRef?.componentInstance) {
      return;
    }

    this.popoverRef.componentInstance.withActions = true;
    this.popoverRef.componentInstance.withCount = true;
    this.popoverRef.componentInstance.withSearchBox = true;
    this.popoverRef.componentInstance.searchBoxPlaceholder = 'Select tenant...';

    this.popoverRef.componentInstance.id = this.id;
    this.popoverRef.componentInstance.value = this.dataSource.getFilterValue(this.id);
    this.popoverRef.componentInstance.valueChanges.pipe(takeUntil(this.popoverRef.afterDismiss$)).subscribe(value => {
      this.dataSource.emitFilterValueChanges(value, this.id);
      this.popoverRef?.dismiss();
    });
  }
}
