import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_URL, FilterOptionsEntity } from '@guardicore-ui/shared/data';
import { FilterOptions, VALUE_UNDEFINED, replaceValue } from '@guardicore-ui/ui/filters/common';
import { Observable, EMPTY, map, catchError } from 'rxjs';

import { MsspStore } from './mssp.store';
import { TENANT_FILTER_ID, TENNANTS_ASSIGNMENT_FILTER_ID, VALUE_NO_TENANT } from '../entities';

const TENANT_NOT_FOUND_ERROR = 'tenant 404';

@Injectable({
  providedIn: 'root',
})
export class MsspService {
  constructor(@Inject(API_URL) private readonly apiUrl: string, private readonly http: HttpClient, private readonly store: MsspStore) {
    this.store.setError(undefined);
    this.store.setLoading(false);
  }

  read(id: string): Observable<FilterOptionsEntity> {
    const params = new HttpParams().set('tenant', id);

    return this.http
      .get<{ selectedOptions: FilterOptionsEntity[] }[]>(`${this.apiUrl}/mssp-tenants/filter-options-resolve`, { params })
      .pipe(
        map(res => {
          if (!res?.[0]?.selectedOptions.length) {
            this.store.update({ selected: undefined });
            throw new Error(TENANT_NOT_FOUND_ERROR);
          }

          let selected = res[0].selectedOptions[0];

          selected = { ...selected, isSelected: true };
          this.store.update({ selected });
          this.store.setError(undefined);

          return selected;
        }),
        catchError(err => {
          this.store.setError({ text: err });

          return EMPTY;
        }),
      );
  }

  resolveTenantClustersFilterOptions(tenantId: string): Observable<FilterOptionsEntity[]> {
    const params = new HttpParams().set('tenant', tenantId);

    return this.http
      .get<{ selectedOptions: FilterOptionsEntity[] }[]>(`${this.apiUrl}/mssp-tenants/clusters/filter-options-resolve`, { params })
      .pipe(map(res => res?.[0].selectedOptions.map(opt => ({ ...opt, isSelected: true }))));
  }

  assignOrUpdateClusters(tenantName: string, clusters: string[], tenantId?: string): Observable<{ description: string }> {
    return this.http.put<{ description: string }>(`${this.apiUrl}/mssp-tenants/tenant${tenantId ? `/${tenantId}` : ''}`, {
      tenantName,
      clusters,
      tenantId,
    });
  }

  getFilterOptions(additionalParams?: Record<string, string | number | boolean>): Observable<FilterOptions> {
    return this._getFilterOptions(additionalParams);
  }

  search(search: string): Observable<FilterOptions> {
    return this._getFilterOptions({ filter_value: search });
  }

  loadMore(offset: number): Observable<FilterOptions> {
    return this._getFilterOptions({ offset });
  }

  getTenantClustersFilterOptions(additionalParams?: Record<string, string | number | boolean>): Observable<FilterOptions> {
    const tenantsAssignment = this.store.getValue().tenantsAssignment;

    if (!tenantsAssignment?.selectedTenant?.value) {
      throw new Error(`Can't get cluster filter options for undefined tenant`);
    }

    const { selectedTenant } = tenantsAssignment;

    return this._getTenantClustersFilterOptions({ ...additionalParams, tenant: `${selectedTenant.value}` });
  }

  private _getFilterOptions(additionalParams?: Record<string, string | number | boolean>): Observable<FilterOptions> {
    const params = new HttpParams({
      fromObject: {
        ...(additionalParams && { ...additionalParams }),
      },
    }).set('filter_name', TENANT_FILTER_ID);

    return this.http.get<FilterOptions>(`${this.apiUrl}/mssp-tenants/filter-options`, { params }).pipe(
      map(options => ({
        ...options,
        availableOptions: replaceValue(options.availableOptions, VALUE_NO_TENANT, VALUE_UNDEFINED),
        selectedOptions: replaceValue(options.selectedOptions, VALUE_NO_TENANT, VALUE_UNDEFINED),
      })),
    );
  }

  private _getTenantClustersFilterOptions(additionalParams?: Record<string, string | number | boolean>): Observable<FilterOptions> {
    const params = new HttpParams({
      fromObject: {
        ...(additionalParams && { ...additionalParams }),
      },
    }).set('filter_name', TENNANTS_ASSIGNMENT_FILTER_ID);

    return this.http.get<FilterOptions>(`${this.apiUrl}/mssp-tenants/clusters/filter-options`, { params }).pipe(
      map(options => ({
        ...options,
        availableOptions: replaceValue(options.availableOptions, VALUE_NO_TENANT, VALUE_UNDEFINED),
        selectedOptions: replaceValue(options.selectedOptions, VALUE_NO_TENANT, VALUE_UNDEFINED),
      })),
      catchError(() => EMPTY),
    );
  }
}
