import {
  Component,
  AfterViewInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ViewChildren,
  ElementRef,
  QueryList,
  ChangeDetectorRef,
  OnChanges,
  NgZone,
  OnDestroy,
  ViewContainerRef,
  Renderer2,
  ComponentFactoryResolver,
  Injector,
  TemplateRef,
} from '@angular/core';
import { CombinedLabel, Labels } from '@guardicore-ui/shared/data';
import { CanHaveKey, mixinWithKey, CanHaveIcon, mixinWithIcon, detectHover } from '@guardicore-ui/ui/common';
import { CanBePopoverAnchor, PopoverAnchorBase } from '@guardicore-ui/ui/popovers/popover';
import { Subject, combineLatest, debounceTime, delay, distinctUntilChanged, map, of, switchMap, takeUntil } from 'rxjs';

import { LabelComponent } from '../label/label.component';

interface ObservedComponent {
  el: HTMLElement;
  intersectionRatio: number;
  label?: CombinedLabel;
}

const MINIMAL_INTERSECTION = 0.1;
const RESIZE_DEBOUNCE = 10;
const LABEL_COMPONENT_MARGIN = 4;

// eslint-disable-next-line @typescript-eslint/naming-convention
const LabelHorizontalListMixin = mixinWithKey(mixinWithIcon(PopoverAnchorBase, 'label', 'grey'));

@Component({
  selector: 'gc-label-horizontal-list',
  templateUrl: './label-horizontal-list.component.html',
  styleUrls: ['./label-horizontal-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  inputs: ['withKey', 'withIcon', 'iconName', 'iconColor', 'aligns'],
})
export class LabelHorizontalListComponent
  extends LabelHorizontalListMixin
  implements AfterViewInit, OnChanges, OnDestroy, CanHaveKey, CanHaveIcon, CanBePopoverAnchor
{
  private _labels?: Labels;
  private resizeObserver?: ResizeObserver;
  private labelsSectionWidthSubject = new Subject<number>();
  private labelsSectionWidth$ = this.labelsSectionWidthSubject.pipe(debounceTime(RESIZE_DEBOUNCE));
  private observedLabelComponents: ObservedComponent[] = [];
  private readonly destroyHoverDetection$ = new Subject<void>();

  get hiddenAmount(): number {
    return this.observedLabelComponents.filter(c => c.intersectionRatio < MINIMAL_INTERSECTION).length;
  }

  get showEllipsis(): boolean {
    return this.observedLabelComponents[this.observedLabelComponents.length - 1]?.intersectionRatio < 1;
  }

  get labels(): Labels {
    return this._labels || [];
  }

  @Input()
  set labels(value: Labels | undefined) {
    this._labels = value;
    this.setObservedLabelComponents();
  }

  @ViewChild('labelsSection') private labelsSection?: ElementRef;
  @ViewChild('popover') private popoverTemplate?: TemplateRef<unknown>;
  @ViewChildren(LabelComponent) private labelComponentsList?: QueryList<LabelComponent>;

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly zone: NgZone,
    host: ElementRef,
    viewContainerRef: ViewContainerRef,
    renderer: Renderer2,
    componentFactoryResolver: ComponentFactoryResolver,
    injector: Injector,
  ) {
    super(host, viewContainerRef, renderer, componentFactoryResolver, injector, 'hover');
    this.withLeaf = true;
    this.labelsSectionWidth$.subscribe(width => this.widthChangeHandle(width));
    this.configurationSubj
      .pipe(
        switchMap(configuration => {
          this.destroyHoverDetection$.next();
          const isAnchorHovered$ = detectHover(this.host.nativeElement, this.destroyHoverDetection$).pipe(
            takeUntil(this.destroyHoverDetection$),
          );

          return combineLatest({ isAnchorHovered: isAnchorHovered$, isHovered: this.popover.isHovered$ }).pipe(
            map(({ isAnchorHovered, isHovered }) => (isAnchorHovered || isHovered) && this.showEllipsis),
            switchMap(value => of(value).pipe(delay(value ? configuration.delays.show : configuration.delays.hide))),
            debounceTime(1),
            distinctUntilChanged(),
          );
        }),
      )
      .subscribe(isHovered => this._toggle(isHovered));
  }

  ngAfterViewInit(): void {
    if (!this.labelsSection) {
      return;
    }

    this.setObservedLabelComponents();
    this.zone.runOutsideAngular(() => {
      this.resizeObserver = new ResizeObserver(entries => {
        if (!entries.length) {
          return;
        }

        const target = entries[0].target as HTMLElement;

        this.labelsSectionWidthSubject.next(target.offsetWidth);
      });
      this.resizeObserver.observe(this.labelsSection?.nativeElement);
    });

    this.popover.template = this.popoverTemplate;
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.labelsSection) {
      this.resizeObserver?.unobserve(this.labelsSection.nativeElement);
    }

    this.labelsSectionWidthSubject.complete();
    this.destroyHoverDetection$.next();
  }

  private setObservedLabelComponents(): void {
    if (!this.labelComponentsList) {
      return;
    }

    if (this.observedLabelComponents.length) {
      this.observedLabelComponents = [];
    }

    for (const labelComponent of this.labelComponentsList) {
      const el = labelComponent.labelsHost?.nativeElement;

      this.observedLabelComponents.push({ el, intersectionRatio: 0, label: labelComponent.label });
    }
  }

  private widthChangeHandle(labelSectionWidth: number): void {
    let spaceLeft = labelSectionWidth;
    let counter = 0;

    while (spaceLeft > 0 && counter < this.observedLabelComponents.length) {
      const labelComponentWidth = this.observedLabelComponents[counter].el.offsetWidth;
      const margin = counter < this.observedLabelComponents.length - 1 ? LABEL_COMPONENT_MARGIN : 0;
      const width = labelComponentWidth + margin;
      let intersectionRatio = 1;

      if (spaceLeft < width) {
        intersectionRatio = spaceLeft / width;
      }

      this.observedLabelComponents[counter].intersectionRatio = intersectionRatio;
      spaceLeft -= width;
      counter++;
    }

    for (let i = counter; i < this.observedLabelComponents.length; i++) {
      this.observedLabelComponents[i].intersectionRatio = 0;
    }

    this.cd.detectChanges();
  }
}
