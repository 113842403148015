import { Component, ChangeDetectionStrategy, Input, TemplateRef, OnChanges, ViewChild } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { NotificationsQuery, NotificationsFacade } from '@guardicore-ui/notifications/domain';
import { RbacService } from '@guardicore-ui/shared/rbac';
import { PopoverClickAnchorDirective } from '@guardicore-ui/ui/popovers/popover';
import { EMPTY, timer, BehaviorSubject } from 'rxjs';
import { map, switchMap, takeUntil, filter } from 'rxjs/operators';

const TRIGGER_ACK_ALL_THRESHOLD_SECONDS = 3;

@Component({
  selector: 'gc-notifications-bell',
  templateUrl: './notifications-bell.component.html',
  styleUrls: ['./notifications-bell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsBellComponent implements OnChanges {
  @Input() notificationsTemplate?: TemplateRef<unknown>;
  @Input() close?: number;
  @ViewChild(PopoverClickAnchorDirective) popover?: PopoverClickAnchorDirective;
  isShown = false;
  isAllowedToAck: boolean | null = false;
  readonly isShown$ = new BehaviorSubject(false);
  viewModel$ = this.query.newCount$.pipe(map(newCount => ({ count: newCount > 0 ? (newCount > 9 ? '9+' : `${newCount}`) : '' })));

  constructor(
    private readonly query: NotificationsQuery,
    private readonly notificationsService: NotificationsFacade,
    private readonly rbacService: RbacService,
  ) {}

  ngOnChanges(): void {
    if (this.popover && this.isShown && this.close) {
      this.popover.close();
    }
  }

  onPopoverStateChange(isShown: boolean): void {
    this.isAllowedToAck = this.rbacService.isAllowed('Syslog', 'Edit');
    this.isShown = isShown;
    this.isShown$.next(isShown);

    if (!this.isAllowedToAck) {
      return;
    }

    timer(TRIGGER_ACK_ALL_THRESHOLD_SECONDS * 1000)
      .pipe(
        switchMap(() => (this.isShown ? this.notificationsService.ackAll() : EMPTY)),
        takeUntil(this.isShown$.pipe(filter(isShownState => !isShownState))),
      )
      .subscribe();
  }
}
