import { Injectable } from '@angular/core';
import { SystemStatusQuery } from '@guardicore-ui/shared/system-status';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { LoginCredentials, AuthenticationResponse, SSOAuthenticationResponse, SSOAuthenticationParams, LoginSettings } from '../entities';
import { AuthenticationService, AuthenticationQuery } from '../infrastructure';

@Injectable({ providedIn: 'root' })
export class LoginPageFacade {
  readonly loading$ = this.authenticationQuery.selectLoading();
  readonly error$ = this.authenticationQuery.selectError();
  readonly errorStatus = this.authenticationQuery.errorStatus;
  readonly release$ = this.systemStatusQuery.release$;
  readonly allowLoginRedirect$ = this.systemStatusQuery.state$.pipe(
    filter(systemStatus => !!systemStatus?.login && !!systemStatus.configuration && !!systemStatus.login.allowedViewsPermissions),
  );

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly authenticationQuery: AuthenticationQuery,
    private readonly systemStatusQuery: SystemStatusQuery,
  ) {}

  /**
   * User Password Login
   */
  login(credentials: LoginCredentials, authPhase?: number): Observable<void | AuthenticationResponse> {
    return this.authenticationService.login(credentials, authPhase);
  }

  /**
   * Get the name of SSO Provider, if any is configured
   */
  getSSOProvider(): Observable<string> {
    return this.authenticationService.getSSOProvider();
  }

  /**
   * Get SSO authentication location with login values
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  getSSOAuthenticationLocation(): Observable<void | SSOAuthenticationResponse> {
    return this.authenticationService.getSSOAuthenticationLocation();
  }

  /**
   * Login using SSO
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  SSOLogin(ssoAuthenticationParams: SSOAuthenticationParams): void {
    return this.authenticationService.SSOLogin(ssoAuthenticationParams);
  }

  loginMessage(): Observable<unknown | string> {
    return this.authenticationService.loginMessage();
  }

  /**
   * Get login page settings
   */
  getLoginPageSettings(): Observable<LoginSettings> {
    return this.authenticationService.getLoginPageSettings();
  }
}
