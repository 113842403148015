const RBAC_ACTIONS = [
  'View',
  'Edit',
  'Suggest Changes',
  'Revert Policy Revisions',
  'Label Asset',
  'Collect Debug Logs',
  'Create Private',
  'Create',
  'Explore',
  'Explore All Scoped',
  'Explore Scheduled',
  'Publish Changes',
  'Discard Changes',
  'Cleanup Stale Hashes',
  'Add',
  'Delete',
] as const;

export type RbacAction = typeof RBAC_ACTIONS[number];

export const VIEW_PERMISSIONS: RbacAction[] = ['View', 'Explore', 'Edit'];
