export const enforcementColored = `<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15.7468 3.05841L14.1258 2.04399L8.17512 4.93104L7.05058 15.9379C7.33172 16.0281 8.49373 16.0101 10.8927 15.2162C14.6412 13.0509 16 11.5171 16 9.71271V3.49924C16 3.32143 15.905 3.15743 15.7468 3.05841Z" fill="#4934B7"/>
  <path d="M13.8915 1.91865L13.8917 1.91745L7.26404 0.017973C7.17096 -0.00599101 7.07225 -0.00599101 6.97918 0.017973L0.347282 1.90266C0.244152 1.93472 0.154997 1.9953 0.0926215 2.07565C0.0302459 2.156 -0.00214096 2.25198 0.000109856 2.34988V9.24182C0.0319389 10.2476 0.432411 11.216 1.13955 11.997C1.82089 12.7868 2.62074 13.4882 3.51635 14.0814C4.55771 14.7961 5.67016 15.4233 6.83959 15.9551C6.89084 15.9784 6.94516 15.9971 7.00213 15.9988L7.00448 15.9989C7.0823 16.0008 7.16099 16 7.23881 15.9982C7.29722 15.9968 7.3531 15.9781 7.4057 15.9544C8.57205 15.4283 9.68355 14.8095 10.7269 14.1053C11.6243 13.5048 12.4242 12.7953 13.1037 11.997C13.8035 11.2246 14.2036 10.2686 14.2431 9.2738V2.38185C14.2502 2.28002 14.219 2.17895 14.1544 2.09495C14.0907 2.01207 13.9982 1.95054 13.8914 1.91987L13.8915 1.91865Z" fill="#ECE2F9"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.244 2.34527C14.2422 2.25602 14.2112 2.16888 14.1544 2.09495C14.0907 2.01207 13.9982 1.95054 13.8914 1.91987L13.8915 1.91865L13.8917 1.91745L7.26404 0.017973C7.17096 -0.00599101 7.07225 -0.00599101 6.97918 0.017973L0.347282 1.90266C0.244152 1.93472 0.154997 1.9953 0.0926215 2.07565C0.0302459 2.156 -0.00214096 2.25198 0.000109856 2.34988V9.24182C0.0319389 10.2476 0.432411 11.216 1.13955 11.997C1.82089 12.7868 2.62074 13.4882 3.51635 14.0814C4.55771 14.7961 5.67016 15.4233 6.83959 15.9551C6.89084 15.9784 6.94516 15.9971 7.00213 15.9988L7.00448 15.9989C7.0823 16.0008 7.16099 16 7.23881 15.9982C7.29722 15.9968 7.3531 15.9781 7.4057 15.9544C8.57205 15.4283 9.68355 14.8095 10.7269 14.1053C11.6243 13.5048 12.4242 12.7953 13.1037 11.997C13.8035 11.2246 14.2036 10.2686 14.2431 9.2738V2.38185C14.2439 2.37097 14.2442 2.36011 14.2441 2.34926C14.229 2.35988 14.2131 2.36983 14.1964 2.37902C14.1947 2.37997 14.193 2.38092 14.1912 2.38185C14.1094 2.42587 14.0093 2.45195 13.8915 2.45195C14.0056 2.45195 14.1041 2.42588 14.1856 2.38185C14.1875 2.38082 14.1894 2.37977 14.1914 2.37871C14.2098 2.36846 14.2274 2.35728 14.244 2.34527ZM13.6717 9.26358V2.41247L13.6695 2.41163L7.1215 0.535005L0.571545 2.39641V9.2337C0.601268 10.1141 0.95263 10.9643 1.57603 11.6528L1.5804 11.6576L1.58465 11.6626C2.23263 12.4137 2.99387 13.0814 3.84705 13.6464L3.851 13.649L3.85491 13.6517C4.86244 14.3433 5.93861 14.9508 7.06999 15.4666C7.10384 15.4669 7.13885 15.4666 7.17526 15.466C8.30497 14.9552 9.38147 14.3552 10.392 13.6732L10.3939 13.6719C11.2488 13.0999 12.0101 12.4244 12.6562 11.6653L12.6617 11.6589L12.6673 11.6527C13.2838 10.9722 13.6348 10.1336 13.6717 9.26358ZM13.5063 2.31682C13.3748 2.21012 13.3155 2.05724 13.3206 1.9061C13.3181 2.05727 13.3789 2.21017 13.5063 2.31682Z" fill="#4934B7"/>
  <path d="M4.575 12.5039C5.38515 13.054 6.24736 13.5394 7.15179 13.9547V2.04375L1.94531 3.5216V9.07371C1.94531 10.4228 3.37351 11.6837 4.575 12.5039Z" fill="#D2C2FF"/>
  <g style="mix-blend-mode:multiply">
  <path d="M9.66963 12.5032C8.85948 13.0532 7.99727 13.5386 7.09284 13.9539V2.04297L12.2993 3.52081V9.07293C12.2993 10.422 10.8711 11.6829 9.66963 12.5032Z" fill="#D2C2FF"/>
  </g>
</svg>
`;
