/* eslint-disable @angular-eslint/directive-class-suffix */
import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  ViewContainerRef,
  Renderer2,
  ComponentFactoryResolver,
  Injector,
  Inject,
  Output,
  EventEmitter,
  Optional,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ANCHOR_CATEGORY } from './anchor-category';
import { PopoverComponent } from './popover/popover.component';
import { AnchorType, PopoverAnchorService } from './popover-anchor.service';
import {
  createDefaultConfiguration,
  PopoverConfiguration,
  PopoverConfigurationAlign,
  PopoverConfigurationHoverDelays,
  PopoverConfigurationLeaf,
  AnchorCategory,
} from './popover-configuration';
import { POPOVER_REF } from './popover-ref';
import { PopoverRef } from './popover-ref.service';

const DEFAULT_LEAF_SIZE = 10;
const DEFAULT_LEAF_POSITION = 50;

export interface CanBePopoverAnchor {
  delays?: Partial<PopoverConfigurationHoverDelays>;
  aligns?: Partial<PopoverConfigurationAlign>;
  margin?: number;
  closeTransition?: string;
  openTransition?: string;
  withLeaf?: boolean | string | PopoverConfigurationLeaf;
  panelCssClass?: string;
}

@Directive({ selector: '[gcPopoverAnchorBase]' })
export abstract class PopoverAnchorBase implements OnChanges, OnDestroy {
  private readonly openSubj = new Subject<void>();
  private readonly closeSubj = new Subject<void>();

  protected readonly destroy$ = new Subject<void>();
  protected popover: PopoverComponent;
  protected configuration = createDefaultConfiguration();
  protected readonly configurationSubj = new BehaviorSubject<PopoverConfiguration>(this.configuration);
  protected readonly _isOpen$ = new BehaviorSubject<boolean>(false);
  protected preventOpening = false;
  protected _name?: AnchorType;
  @Input() delays?: Partial<PopoverConfigurationHoverDelays>;
  @Input() aligns?: Partial<PopoverConfigurationAlign>;
  @Input() margin?: number;
  @Input() closeTransition?: string;
  @Input() openTransition?: string;
  @Input() withLeaf?: boolean | string | PopoverConfigurationLeaf;
  @Input() panelCssClass?: string;
  @Input()
  set disableOtherClickOutside(_: unknown) {
    this._name = PopoverAnchorService.createUniqueId();
    this.popoverAnchorService?.initAnchor(this._name);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() readonly closed = new EventEmitter<any>();
  @Output() readonly opened = new EventEmitter<void>();

  constructor(
    protected readonly host: ElementRef,
    protected readonly viewContainerRef: ViewContainerRef,
    renderer: Renderer2,
    protected readonly componentFactoryResolver: ComponentFactoryResolver,
    protected readonly injector: Injector,
    @Inject(ANCHOR_CATEGORY) protected readonly category: AnchorCategory,
    @Optional() @Inject(POPOVER_REF) protected readonly ref?: PopoverRef,
    @Optional() @Inject(PopoverAnchorService) protected popoverAnchorService?: PopoverAnchorService,
  ) {
    const componentFactory = componentFactoryResolver.resolveComponentFactory(PopoverComponent);
    const componentRef = viewContainerRef.createComponent<PopoverComponent>(componentFactory, undefined, injector);

    this.popover = componentRef.instance;
    this.popover.anchor = host.nativeElement;
    this.popover.configuration$ = this.configurationSubj.asObservable();

    this._isOpen$
      .pipe(takeUntil(this.destroy$))
      .subscribe(isOpen =>
        isOpen ? renderer.addClass(host.nativeElement, 'gc-popover-open') : renderer.removeClass(host.nativeElement, 'gc-popover-open'),
      );

    this.ref?.close$.pipe(takeUntil(this.destroy$)).subscribe(data => this.closed.emit(data));
    this.ref?.open$.pipe(takeUntil(this.destroy$)).subscribe(() => this.opened.emit());
    this.openSubj.pipe(takeUntil(this.destroy$)).subscribe(() => this._toggle(true));
    this.closeSubj.pipe(takeUntil(this.destroy$)).subscribe(() => this._toggle(false));
    this.popover.isOpen$.pipe(takeUntil(this.destroy$)).subscribe(isOpen => this._isOpen$.next(isOpen));
  }

  ngOnChanges(): void {
    let aligns: PopoverConfigurationAlign | undefined;
    let delays: PopoverConfigurationHoverDelays | undefined;
    let withLeaf = false;
    const leafConfig: PopoverConfigurationLeaf = { position: DEFAULT_LEAF_POSITION, size: DEFAULT_LEAF_SIZE };

    if (this.aligns) {
      aligns = {
        vertical: this.aligns.vertical || this.configuration.aligns.vertical,
        horizontal: this.aligns.horizontal || this.configuration.aligns.horizontal,
      };
    }

    if (this.delays) {
      delays = {
        show: this.delays.show !== undefined ? this.delays.show : this.configuration.delays.show,
        hide: this.delays.hide !== undefined ? this.delays.hide : this.configuration.delays.hide,
      };
    }

    if (this.withLeaf !== undefined) {
      withLeaf =
        this.withLeaf === true ||
        (typeof this.withLeaf === 'string' && (this.withLeaf === '' || this.withLeaf?.trim().toLowerCase() === 'true'));
      if (typeof this.withLeaf === 'object') {
        withLeaf = true;
        leafConfig.position = this.withLeaf.position ?? DEFAULT_LEAF_POSITION;
        leafConfig.size = this.withLeaf.size ?? DEFAULT_LEAF_SIZE;
      }
    }

    this._updateConfiguration({
      aligns: aligns || this.configuration.aligns,
      delays: delays || this.configuration.delays,
      margin: this.margin !== undefined ? this.margin : this.configuration.margin,
      closeTransition: this.closeTransition ? this.closeTransition : this.configuration.closeTransition,
      openTransition: this.openTransition ? this.openTransition : this.configuration.openTransition,
      withLeaf: withLeaf,
      leafConfig: leafConfig,
      panelCssClass: this.panelCssClass,
      category: this.category,
    });
  }

  open(): void {
    this.openSubj.next();
  }

  close(): void {
    this.closeSubj.next();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.configurationSubj.complete();
    this.popoverAnchorService?.removeAnchor(this._name);
  }

  protected _updateConfiguration(configuration: Partial<PopoverConfiguration>): void {
    this.configuration = { ...this.configuration, ...configuration };

    this.configurationSubj.next(this.configuration);
  }

  protected _toggle(open?: boolean): void {
    if (this.preventOpening) {
      return;
    }

    let isOpen = false;

    if (open === undefined) {
      isOpen = !this.popover?.isOpen();
      this.popover?.isOpen() ? this.popover?.close() : this.popover?.open({ autoFocus: true });
    } else {
      isOpen = open;
      open ? this.popover?.open({ autoFocus: true }) : this.popover?.close();
    }

    this.popoverAnchorService?.setAnchorValue(this._name, isOpen);
    this.popover?.realign();
  }
}
