/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { AbstractConstructor, Constructor, IconColor, SvgIconName } from '@guardicore-ui/shared/data';

export interface CanHaveIcon {
  iconName?: SvgIconName;
  iconColor?: IconColor;
}

type CanHaveIconConstructor = AbstractConstructor<CanHaveIcon>;

export function mixinWithIcon<T extends AbstractConstructor<{}>>(
  base: T,
  defaultIcon?: SvgIconName,
  defaultIconColor?: IconColor,
): CanHaveIconConstructor & T;
export function mixinWithIcon<T extends Constructor<{}>>(
  base: T,
  defaultIcon?: SvgIconName,
  defaultIconColor?: IconColor,
): CanHaveIconConstructor & T {
  return class extends base {
    protected _iconName?: SvgIconName;
    protected _iconColor?: IconColor;

    get iconName(): SvgIconName | undefined {
      return this._iconName;
    }

    set iconName(value: SvgIconName | undefined) {
      this._iconName = value;
    }

    get iconColor(): IconColor | undefined {
      return this._iconColor;
    }

    set iconColor(value: IconColor | undefined) {
      this._iconColor = value;
    }

    set withIcon(value: BooleanInput) {
      if (coerceBooleanProperty(value)) {
        if (!defaultIcon) {
          throw new Error('You must provide default icon name in order to use "withIcon" attribute.');
        }

        this._iconName = defaultIcon;
        this._iconColor = defaultIconColor;

        return;
      }

      this._iconName = undefined;
      this._iconColor = undefined;
    }

    constructor(...args: any[]) {
      super(...args);
    }
  };
}
