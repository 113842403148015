export interface ViolationsData {
  name?: string;
  id: string;
  storageSize?: number;
  lastUsed?: number;
  startTime: number;
  endTime: number;
  openAllK8s?: boolean;
}

export interface ViolationItem {
  item: ViolationsData;
  value: string;
  currentDate: string;
}

export enum ViolationsMap {
  lastLoaded = 'lastLoaded',
  timeline = 'timeline',
}
