import { Component, ChangeDetectionStrategy } from '@angular/core';

import { ConfigurationWidgetBaseComponent } from '../configuration-widget-base/configuration-widget-base.component';

@Component({
  selector: 'gc-configuration-input',
  templateUrl: './configuration-input.component.html',
  styleUrls: ['../configuration-widget-base/configuration-widget-base.component.scss', './configuration-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigurationInputComponent extends ConfigurationWidgetBaseComponent {}
