import { Inject, Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import {
  UserPermissions,
  User,
  ApplicationViewName,
  APPLICATION_VIEWS,
  CLUSTER_MANAGER_ALLOWED_VIEWS,
  ViewState,
  ViewStates,
  POLLING_INTERVAL,
  CLUSTER_INSTANCE_VIEWS_TO_HIDE,
  SystemStatusFeatureFlags,
} from '@guardicore-ui/shared/data';
import { isObjectsEmpty } from '@guardicore-ui/shared/utils';
import { of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

import { SystemStatusStore } from './system-status.store';
import { SystemStatusState, SystemStatusConfiguration, SystemStatusStateValidation } from '../entities';

@Injectable({ providedIn: 'root' })
export class SystemStatusQuery extends Query<SystemStatusState> {
  readonly state$ = this.select();
  readonly user$ = this.select(state => state.login || null);
  readonly configuration$ = this.select(state => state.configuration);
  readonly featureFlags$ = this.select(state => state.featureFlags);
  readonly release$ = this.select('release');
  readonly extendedConfiguration$ = this.select(['configuration', 'featureFlags']);
  readonly areRecoveryCodesRequired$ = this.select(
    state => state.login?.twoFactorAuthEnabled && !state.login?.isRadius2fa && state.login.recoveryCodesNum === 0,
  );

  readonly isNewNavbarConfirmed$ = this.select(state => state.login?.confirmedV45Navbar);
  readonly isMsspUser$ = this.select(state => state.login?.isMsspUser);
  readonly allowedViewsPermissions$ = this.select(state => state.login?.allowedViewsPermissions).pipe(
    filter((permissions: UserPermissions | undefined): permissions is UserPermissions => !isObjectsEmpty(permissions)),
  );

  readonly env$ = this.select(state => ({ name: state.environmentCustomerName, isProduction: state.environmentIsProduction }));
  readonly installingPatch$ = this.select(state => state.installingPatch || false);
  readonly userMenuSet$ = this.select(state => ({
    evaluationDaysPassed: this.getDaysPassed(state.evaluationStartDate),
    version: state.version?.build,
    release: state.release,
    systemTime: state.systemTime,
    evaluationModeState: state.configuration?.evaluationModeState || false,
    showAgentSdkDocs: state.configuration?.showAgentSdkDocs || false,
    showInternalDocumentation: state.configuration?.showInternalDocumentation || true,
  }));

  readonly isLoaded$ = this.selectLoading().pipe(
    filter(isLoading => !isLoading),
    map(() => true),
  );

  readonly allPermissions$ = this.state$.pipe(
    distinctUntilChanged((a, b) => a.systemTime === b.systemTime),
    switchMap((state: SystemStatusState) => {
      return of(
        APPLICATION_VIEWS.reduce(
          (acc, viewName) => ({ ...acc, [viewName]: getViewState(viewName, state.featureFlags, state.configuration) }),
          <ViewStates>{},
        ),
      );
    }),
  );

  readonly allowedViewsPermissions = (): UserPermissions | undefined => this.getValue()?.login?.allowedViewsPermissions;
  readonly user = (): User | undefined => this.getValue()?.login;
  readonly defaultView = (): { viewName: string } | undefined => {
    const viewName = this.getValue()?.login?.defaultView;

    return viewName ? { viewName } : undefined;
  };

  readonly configuration = (): SystemStatusConfiguration | undefined => this.getValue()?.configuration;
  readonly featureFlags = (): SystemStatusFeatureFlags | undefined => this.getValue()?.featureFlags;
  readonly validation = (): SystemStatusStateValidation | undefined => this.getValue()?.validation;
  readonly systemTime = (): Date | undefined => {
    return this.getValue()?.systemTime ? new Date(`${this.getValue().systemTime} UTC`) : undefined;
  };

  readonly wasSystemStatusJustRead = (): boolean =>
    !isNaN(Number(this.systemTime()?.getTime())) && Date.now() - Number(this.systemTime()?.getTime()) < this.pollingInterval;

  constructor(protected override store: SystemStatusStore, @Inject(POLLING_INTERVAL) private readonly pollingInterval: number) {
    super(store);
  }

  private getDaysPassed(timestamp: number | undefined): number | undefined {
    return timestamp ? Math.floor((Date.now() / 1000 - timestamp) / 3600 / 24) : timestamp;
  }

  isAllowed(viewName: string): boolean {
    const configuration = this.configuration();
    const featureFlags = this.featureFlags();
    const viewState = getViewState(viewName, featureFlags, configuration);

    return !viewState.isDisabled && !viewState.isHidden;
  }
}

// eslint-disable-next-line max-lines-per-function
const getViewState = (
  viewOrLabelName: ApplicationViewName,
  featureFlags?: SystemStatusFeatureFlags,
  configuration?: SystemStatusConfiguration,
): ViewState => {
  const viewState = <ViewState>{ isDisabled: false, isHidden: false };

  featureFlags = featureFlags ? { ...featureFlags, labs: { enableLabs: configuration?.showUiLabs } } : featureFlags;

  switch (viewOrLabelName) {
    case 'Integrity Monitoring':
    case 'Integrity Log':
    case 'Integrity Violations':
      viewState.isHidden = !featureFlags?.fim?.enableFim;
      break;
    case 'Security Events':
      viewState.isHidden =
        !featureFlags?.genericIncidents?.exposeGenericIncidents ||
        featureFlags?.genericIncidents?.excludedGenericIncidentsTypes?.includes('Hunt');
      break;
    case 'Security Event Preview':
      viewState.isHidden = !featureFlags?.genericIncidents?.exposeIncidentPreview;
      break;
    case 'Guardicore Query':
      viewState.isDisabled = !featureFlags?.insight?.enableInsight;
      viewState.isHidden = !featureFlags?.insight?.enableInsight;
      break;
    case 'Scheduled Queries':
      viewState.isDisabled = !featureFlags?.insight?.enableScheduledQueries;
      viewState.isHidden = !featureFlags?.insight?.enableInsight;
      break;
    case 'Cloud':
      viewState.isHidden = !configuration?.showCloudInventory;
      break;
    case 'Azure Threat Feed':
      viewState.isHidden = !configuration?.azureThreatFeedIntegrationEnabled;
      break;
    case 'Interflow':
      viewState.isHidden = !configuration?.interflowIntegrationEnabled;
      break;
    case 'Firewalls':
      viewState.isHidden = !configuration?.paloaltoIntegrationEnabled;
      break;
    case 'Create Policy':
    case 'Projects':
    case 'Revisions':
    case 'Segmentation Rules':
    case 'Label Groups':
    case 'User Groups':
      viewState.isDisabled = !featureFlags?.reveal20.visibilityPolicyEnabled;
      break;
    case 'Explore':
    case 'Labels':
    case 'Saved Maps':
      viewState.isDisabled = !configuration?.visibilityEnabled;
      break;
    case 'Plugins':
      viewState.isHidden = !configuration?.pluginsEnabled;
      break;
    case 'DNS Requests':
      viewState.isDisabled = !featureFlags?.labs?.enableLabs;
      break;
    case 'DNS Security':
      viewState.isHidden = !featureFlags?.dnsSecurity?.enableDnsSecurity;
      break;
    case 'Kubernetes Clusters':
      viewState.isHidden = !featureFlags?.k8sEnforcement?.exposeClustersView;
      break;
    case 'Repo Key':
      viewState.isHidden = !featureFlags?.navigation?.enableRepoKey;
      break;
    case 'Mitigation':
      viewState.isHidden = !featureFlags?.navigation?.enableMitigationAndIocs;
      break;
    case 'Cloud App':
      viewState.isHidden = !featureFlags?.cloudApp?.enabled;
      viewState.isDisabled = !featureFlags?.cloudApp?.enabled;
      break;
    case 'Centra Cluster':
      viewState.isHidden = !featureFlags?.centraCluster?.isManager || !featureFlags?.centraCluster?.centraClusterEnabled;
      viewState.isDisabled = !featureFlags?.centraCluster?.isManager || !featureFlags?.centraCluster?.centraClusterEnabled;
      break;
    default:
      break;
  }

  if (featureFlags?.centraCluster?.centraClusterEnabled) {
    if (featureFlags?.centraCluster?.isManager) {
      viewState.isHidden = CLUSTER_MANAGER_ALLOWED_VIEWS.includes(viewOrLabelName) ? viewState.isHidden : true;
      viewState.isDisabled = CLUSTER_MANAGER_ALLOWED_VIEWS.includes(viewOrLabelName) ? viewState.isDisabled : true;
    } else {
      viewState.isHidden = CLUSTER_INSTANCE_VIEWS_TO_HIDE.includes(viewOrLabelName) ? true : viewState.isHidden;
      viewState.isDisabled = CLUSTER_INSTANCE_VIEWS_TO_HIDE.includes(viewOrLabelName) ? true : viewState.isDisabled;
    }
  }

  return viewState;
};
