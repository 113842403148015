import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InlineAlert } from '@guardicore-ui/ui/inline-alert';

import { DialogData } from '../dialog';

export interface BulkOperationDialogData extends Omit<DialogData, 'title'> {
  bulkContent?: string;
  dialogTitle?: string;
  inlineAlerts?: InlineAlert[];
}

export enum BulkDialogUserActions {
  InlineAlertAction = 'InlineAlertAction',
}

@Component({
  selector: 'gc-bulk-operation-dialog',
  templateUrl: './bulk-operation-dialog.component.html',
  styleUrls: ['./bulk-operation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkOperationDialogComponent {
  @Input() inlineAlerts: InlineAlert[] = [];
  @Input() bulkContent?: string;
  @Output() alert = new EventEmitter<null>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: BulkOperationDialogData = {},
  ) {
    this.bulkContent = data?.bulkContent;
    this.inlineAlerts = data?.inlineAlerts || [];
  }
}
