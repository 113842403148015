import { Component, ChangeDetectionStrategy, Inject, EventEmitter, Output, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filter, tap } from 'rxjs';

import { DialogData } from '../dialog/dialog-data';
import { QuitWizardDialogComponent } from '../quit-wizard-dialog/quit-wizard-dialog.component';

@Component({
  selector: 'gc-wizard-dialog',
  templateUrl: './wizard-dialog.component.html',
  styleUrls: ['./wizard-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WizardDialogComponent {
  @Input() primaryButtonDisabled = false;
  @Input() primaryButtonLabel = 'Next';
  @Input() secondaryButtonLabel = 'Save';
  @Input() backButtonVisible = true;
  @Input() secondaryButtonVisible = true;
  @Input() page = 'page';
  @Input() lastStep = false;
  @Input() currentStep = 1;
  @Input() steps = 0;

  @Output() actionEvent = new EventEmitter<string>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: DialogData = {},
    private readonly ref: MatDialogRef<WizardDialogComponent>,
    private dialog: MatDialog,
  ) {}

  close(): void {
    this.dialog
      .open(QuitWizardDialogComponent, {
        data: {
          primaryButtonLabel: 'Quit',
          secondaryButtonLabel: 'Cancel',
          secondaryButtonsGhost: true,
          showConfirmAsDanger: true,
          title: `Quit ${this.page} onboarding`,
          closeIcon: true,
        },
      })
      .afterClosed()
      .pipe(
        filter(res => res?.confirmed),
        tap(() => this.ref.close()),
      )
      .subscribe();
  }

  action(action: 'back' | 'next' | 'save'): void {
    this.actionEvent.emit(action);
  }
}
