import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiActionListModule } from '@guardicore-ui/ui/action-list';
import { UiCommonModule } from '@guardicore-ui/ui/common';
import { UiIconModule } from '@guardicore-ui/ui/icon';
import { UiPopoverModule } from '@guardicore-ui/ui/popovers/popover';
import { UiTooltipModule } from '@guardicore-ui/ui/popovers/tooltip';
import { AgGridModule } from 'ag-grid-angular';

import { CellComponent } from './cell/cell.component';
import { CellParamsDirective } from './cell-params.directive';
import { ColumnComponent } from './column.component';
import { ConfirmActionCellComponent } from './confirm-action-cell/confirm-action-cell.component';
import { DetailCellRendererComponent } from './detail-cell-renderer/detail-cell-renderer.component';
import { EmptyCellComponent } from './empty-cell/empty-cell.component';
import { GridComponent } from './grid.component';
import { HeaderComponent } from './header/header.component';
import { RowExpansionService } from './infrastructure';
import { LoadingCellComponent } from './loading-cell/loading-cell.component';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';
import { NoRowsOverlayComponent } from './no-rows-overlay/no-rows-overlay.component';
import { PageWarningComponent } from './page-warning';
import { RowOperationsComponent } from './row-operations/row-operations.component';
import { SelectHeaderComponent } from './select-header/select-header.component';
import { SelectRowComponent } from './select-row/select-row.component';
import { SelectionIndicatorComponent } from './selection-indicator/selection-indicator.component';

import 'ag-grid-enterprise';

@NgModule({
  imports: [CommonModule, UiIconModule, UiCommonModule, UiPopoverModule, UiTooltipModule, UiActionListModule, AgGridModule],
  declarations: [
    GridComponent,
    ColumnComponent,
    CellComponent,
    EmptyCellComponent,
    CellParamsDirective,
    LoadingOverlayComponent,
    NoRowsOverlayComponent,
    HeaderComponent,
    LoadingCellComponent,
    RowOperationsComponent,
    SelectHeaderComponent,
    SelectRowComponent,
    DetailCellRendererComponent,
    PageWarningComponent,
    SelectionIndicatorComponent,
    ConfirmActionCellComponent,
  ],
  exports: [GridComponent, ColumnComponent, CellParamsDirective, PageWarningComponent, SelectionIndicatorComponent],
  providers: [RowExpansionService],
})
export class GridModule {}
