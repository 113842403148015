import { Component, OnChanges, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FilterOptionsEntity } from '@guardicore-ui/shared/data';

import { InputType, VALUE_UNDEFINED } from '../data';

@Component({
  selector: 'gc-filters-content-row',
  templateUrl: './filters-content-row.component.html',
  styleUrls: ['./filters-content-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersContentRowComponent implements OnChanges {
  @Input() entity?: FilterOptionsEntity;
  @Input() withCount = true;
  @Input() inputType: InputType | undefined = 'radio';
  @Input() noInput = false;
  @Output() private readonly selected = new EventEmitter<void>();

  readonly ctrl = new FormControl();
  isMuted = false;

  ngOnChanges(): void {
    if (this.entity?.isSelected) {
      this.ctrl.setValue(this.entity?.value);
    }

    if (this.entity?.isDisabled) {
      this.ctrl.disable();
    }

    this.isMuted = this.entity?.value === VALUE_UNDEFINED;
  }

  onClick(): void {
    if (this.entity?.isDisabled) {
      return;
    }

    this.selected.emit();
  }
}
