import { Injectable } from '@angular/core';
import { PageState } from '@guardicore-ui/shared/data';
import { GridApi } from 'ag-grid-enterprise';

import { ExcludeSelectionMode } from './exclude-selection-mode';
import { GridComponentStore } from './grid-component.store';
import { CHECKBOX_SELECTION_CELL_ID } from './grid-constant.constant';
import { NormalSelectionMode } from './normal-selection-mode';
import {
  RowSelectType,
  SelectionState,
  RowData,
  HeaderSelectEventType,
  RowDataId,
  RowSelection,
  SelectionModeState,
  SelectedRowsState,
} from '../entities';

@Injectable()
export class GridSelectionService {
  private _lastPageState: RowSelectType = 'none';
  private _shouldRefreshCells = false;
  private _selectedSaveKeys: string[] = [];
  private store: GridComponentStore | undefined;
  private selectionState: SelectionState = {
    selectedRowIds: new Set(),
    unselectedRowIds: new Set(),
    isUnselectionMode: false,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedRowData: new Map<string, RowData<any>[]>(),
    currentPageState: 'none',
    totalItems: 0,
    itemsInPage: 0,
  };

  private selectionModeState: SelectionModeState = new NormalSelectionMode(this);
  rowSelectedEventParams:
    | {
        rowSelection: RowSelection<RowSelectType> | undefined;
        pageState?: PageState | undefined;
        gridApi: GridApi;
        rowData?: Required<RowDataId>[];
      }
    | undefined;

  get selectedSaveKeys(): string[] {
    return this._selectedSaveKeys;
  }

  set selectedSaveKeys(selectedSaveKeys: string[]) {
    this._selectedSaveKeys = selectedSaveKeys;
  }

  get lastPageState(): RowSelectType {
    return this._lastPageState;
  }

  set lastPageState(lastPageState: RowSelectType) {
    this._lastPageState = lastPageState;
  }

  set gridComponentStore(store: GridComponentStore) {
    this.store = store;
  }

  shouldRefreshCells(): boolean {
    return (
      (this._shouldRefreshCells &&
        (this.lastPageState === 'pagination' || this.lastPageState === 'none' || this.lastPageState === 'deselectPage')) ||
      (this.lastPageState === 'some' && ['none', 'allGrid'].includes(this.selectionState.currentPageState)) ||
      (['allGrid', 'allPage', 'some'].includes(this.lastPageState) &&
        ['none', 'deselectPage', 'some'].includes(this.selectionState.currentPageState)) ||
      this.isAllPageToAllGrid()
    );
  }

  shouldRefreshHeaders(): boolean {
    return this._shouldRefreshCells;
  }

  getSelectionState(): SelectionState {
    return this.selectionState;
  }

  isAllPageToAllGrid(): boolean {
    return this.lastPageState === 'allPage' && this.selectionState.currentPageState === 'allGrid';
  }

  rowSelectedEvent({
    rowSelection,
    pageState,
    gridApi,
    rowData,
  }: {
    rowSelection: RowSelection<RowSelectType> | undefined;
    pageState?: PageState | undefined;
    gridApi: GridApi;
    rowData?: Required<RowDataId>[];
  }): void {
    this._shouldRefreshCells = this.selectionState.currentPageState !== rowSelection?.selectedRowType;
    this.lastPageState = this.selectionState.currentPageState;

    if (pageState) {
      this.selectionState.totalItems = pageState.totalCount || 0;
      this.selectionState.itemsInPage = pageState.resultsInPage || 0;
    }

    this.rowSelectedEventParams = { rowSelection, pageState, gridApi, rowData };
    this.selectionModeState.handleRowSelectedEvent();
    this.updateSelectedRowIdStore();
  }

  clearState(pageState: HeaderSelectEventType): void {
    if (!['allGrid', 'none'].includes(pageState)) {
      return;
    }

    this.selectionState.currentPageState = pageState;
    this.selectionState.isUnselectionMode = pageState === 'allGrid';
    this.selectionState.selectedRowIds.clear();
    this.selectionState.selectedRowData.clear();
    this.selectionState.unselectedRowIds.clear();
  }

  countSelectedRowsInPage(data: GridApi | Required<RowDataId>[]): { selectedInPage: number; itemsInPage: number } {
    let selectedInPage = 0;
    let itemsInPage = 0;

    if (Array.isArray(data)) {
      itemsInPage = data.length;

      selectedInPage = data.reduce((sum, current) => {
        return (this.selectionState.isUnselectionMode && !this.selectionState.unselectedRowIds.has(current.id)) ||
          (!this.selectionState.isUnselectionMode && this.selectionState.selectedRowIds.has(current.id))
          ? sum + 1
          : sum;
      }, 0);
    } else {
      data.forEachNode(current => {
        itemsInPage++;
        if (
          (this.selectionState.isUnselectionMode && !this.selectionState.unselectedRowIds.has(current.data.id)) ||
          (!this.selectionState.isUnselectionMode && this.selectionState.selectedRowIds.has(current.data.id))
        ) {
          selectedInPage++;
        }
      });
    }

    return { selectedInPage: selectedInPage, itemsInPage: itemsInPage };
  }

  changeSelectionModeState(newPageState: HeaderSelectEventType): void {
    if (!['allGrid', 'none'].includes(newPageState)) {
      return;
    }

    this.selectionModeState = newPageState === 'allGrid' ? new ExcludeSelectionMode(this) : new NormalSelectionMode(this);
  }

  getCurrentSelectionState(data: GridApi | Required<RowDataId>[]): RowSelectType {
    const selectionState = this.getSelectionState();
    const { selectedInPage, itemsInPage } = this.countSelectedRowsInPage(data);

    if (selectionState.isUnselectionMode) {
      return selectionState.unselectedRowIds.size === 0
        ? 'allGrid'
        : itemsInPage === selectedInPage
        ? 'allPage'
        : selectedInPage > 0
        ? 'some'
        : selectionState.unselectedRowIds.size === selectionState.totalItems
        ? 'none'
        : 'deselectPage';
    } else {
      return selectionState.totalItems > 0 && selectionState.selectedRowIds.size === selectionState.totalItems
        ? 'allPage' // we don't go to all-grid state in this case, but we do need the all-page UI logic
        : itemsInPage > 0 && itemsInPage === selectedInPage
        ? 'allPage'
        : selectedInPage > 0
        ? 'some'
        : selectionState.selectedRowIds.size > 0
        ? 'deselectPage'
        : 'none';
    }
  }

  addAllPageRowsToSelection(gridApi: GridApi): void {
    const selectionState = this.getSelectionState();

    gridApi.forEachNode(node => {
      this.addRowSelectedData(node.data);
      selectionState.isUnselectionMode
        ? selectionState.unselectedRowIds.add(node.data.id)
        : selectionState.selectedRowIds.add(node.data.id);
    });
  }

  removeRowNodeFromSelectedRowIds(gridApi: GridApi): void {
    const selectionState = this.getSelectionState();

    gridApi.forEachNode(node => {
      selectionState.selectedRowData.delete(node.data.id);
      selectionState.isUnselectionMode
        ? selectionState.unselectedRowIds.delete(node.data.id)
        : selectionState.selectedRowIds.delete(node.data.id);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addRowSelectedData(data: RowData<any>): void {
    if (!this.selectedSaveKeys.length) {
      return;
    }

    const selectionState = this.getSelectionState();

    if (!selectionState.selectedRowData.has(data.id)) {
      selectionState.selectedRowData.set(data.id, this.getRowSelectedData(data));
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getRowSelectedData(data: RowData<any>): RowData<any> {
    const extraData = this.selectedSaveKeys.reduce((state, currentKey) => ({ ...state, [currentKey]: data[currentKey] }), {});

    return { id: data.id, ...extraData };
  }

  rowSelection(rowSelection: RowSelection<RowSelectType>, gridApi: GridApi): void {
    this.rowSelectedEvent({
      rowSelection: rowSelection,
      gridApi: gridApi,
    });

    if (this.shouldRefreshCells()) {
      gridApi.refreshCells({ force: true, columns: [CHECKBOX_SELECTION_CELL_ID] });
    }

    if (this.shouldRefreshHeaders()) {
      gridApi.refreshHeader();
    }
  }

  private updateSelectedRowIdStore(): void {
    const configuration = this.getSelectionState();
    const isAllGridSelected = configuration.currentPageState === 'allGrid';
    const isUnselectionMode = configuration.isUnselectionMode;
    const unselectedRowIds = [...configuration.unselectedRowIds.values()];
    const selectedRowIds = [...configuration.selectedRowIds.values()];
    const selectedRowData = [...configuration.selectedRowData.values()];
    const selectedRows: SelectedRowsState = {
      ...(this.selectedSaveKeys.length ? { selectedData: isAllGridSelected ? [] : selectedRowData } : { selectedData: [] }),
      selectedRowIds: isAllGridSelected ? [] : isUnselectionMode ? [] : selectedRowIds,
      unselectedRowIds: isAllGridSelected ? [] : isUnselectionMode ? unselectedRowIds : [],
      isUnselectionMode: isUnselectionMode,
      selected:
        !isUnselectionMode && configuration.currentPageState === 'none' && !selectedRowIds.length
          ? 0
          : isAllGridSelected
          ? configuration.totalItems
          : isUnselectionMode
          ? configuration.totalItems - unselectedRowIds.length
          : selectedRowIds.length,
    };

    this.store?.update(state => ({ ...state, selectedRows }));
  }
}
