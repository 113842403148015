import { Injectable } from '@angular/core';
import { ApplicationViewName, APPLICATION_ADMIN_VIEWS, UserPermissions, ViewStates } from '@guardicore-ui/shared/data';
import { SystemStatusQuery } from '@guardicore-ui/shared/system-status';
import { vectorInclude } from '@guardicore-ui/shared/utils';
import { of, Observable, zip } from 'rxjs';
import { concatMap, map, switchMap } from 'rxjs/operators';

import { RbacAction, VIEW_PERMISSIONS } from '../entities';

export const DEFAULT_DISABLED_MESSAGE = 'Disabled for current user';

@Injectable({
  providedIn: 'root',
})
export class RbacService {
  readonly allPermissions$ = this.systemStatus.allPermissions$.pipe(
    switchMap(systemStatusViewStates => {
      const viewNames = Object.keys(systemStatusViewStates);

      return zip(of(viewNames), of(systemStatusViewStates), this.isAllowedMultipleAsync(viewNames));
    }),
    switchMap(([viewNames, systemStatusViewStates, itemsRbacPermission]) => {
      const viewStates: ViewStates = {};

      for (let i = 0; i < viewNames.length; i++) {
        const viewName = viewNames[i];
        const systemStatusViewState = systemStatusViewStates[viewName];
        const viewState = { ...systemStatusViewState };
        const itemRbacPermission = itemsRbacPermission[i];

        if (systemStatusViewState?.isDisabled || systemStatusViewState?.isHidden) {
          viewState.tooltip = systemStatusViewState.isDisabled
            ? systemStatusViewState.tooltip !== undefined
              ? systemStatusViewState.tooltip
              : DEFAULT_DISABLED_MESSAGE
            : undefined;
        } else if (itemRbacPermission !== undefined && !itemRbacPermission) {
          viewState.isDisabled = true;
          viewState.tooltip = DEFAULT_DISABLED_MESSAGE;
        }

        viewStates[viewName] = viewState;
      }

      return of(viewStates);
    }),
  );

  constructor(private readonly systemStatus: SystemStatusQuery) {}

  isAllowedAsync(viewName: ApplicationViewName, action?: RbacAction | RbacAction[]): Observable<boolean> {
    return this.isAllowedMultipleAsync([viewName], action).pipe(map(([permission]) => permission));
  }

  isAllowedMultipleAsync(viewNames: ApplicationViewName[], action?: RbacAction | RbacAction[]): Observable<boolean[]> {
    if (!viewNames.length) {
      return of([false]);
    }

    return this.systemStatus.isLoaded$.pipe(
      concatMap(() => this.systemStatus.allowedViewsPermissions$),
      switchMap(viewsPermissions => of(this.calculatePermissions(viewNames, viewsPermissions, action))),
    );
  }

  isAllowedMultiple(viewNames: ApplicationViewName[], action?: RbacAction | RbacAction[]): boolean[] | null {
    if (!viewNames.length) {
      return null;
    }

    const viewsPermissions = this.systemStatus.allowedViewsPermissions();

    if (!viewsPermissions) {
      return null;
    }

    return this.calculatePermissions(viewNames, viewsPermissions, action);
  }

  isAllowed(viewName: ApplicationViewName, action?: RbacAction | RbacAction[]): boolean | null {
    const permission = this.isAllowedMultiple([viewName], action);

    return permission?.length ? permission[0] : null;
  }

  isAdminModeAllowedAsync(): Observable<boolean> {
    return this.systemStatus.isLoaded$.pipe(
      concatMap(() => this.systemStatus.allowedViewsPermissions$),
      switchMap(permissions => of(vectorInclude([...APPLICATION_ADMIN_VIEWS], Object.keys(permissions)))),
    );
  }

  private calculatePermissions(
    viewNames: ApplicationViewName[],
    viewsPermissions: UserPermissions,
    action?: RbacAction | RbacAction[],
  ): boolean[] {
    return viewNames.map(viewName => {
      const userPermissions = viewsPermissions[viewName];

      if (userPermissions && action && Array.isArray(action)) {
        return vectorInclude(userPermissions, action);
      } else if (userPermissions && action && !Array.isArray(action)) {
        return userPermissions.includes(action);
      } else {
        return vectorInclude(userPermissions, VIEW_PERMISSIONS);
      }
    });
  }
}
