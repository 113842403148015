import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { REQUEST_SCOPE, COMMON_REQUEST } from '@guardicore-ui/shared/data';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { HttpErrorHandlerService } from './http-error-handler.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private readonly handler: HttpErrorHandlerService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isCommonRequest = request.context.get(REQUEST_SCOPE) === COMMON_REQUEST;

    return isCommonRequest
      ? (next.handle(request).pipe(
          catchError((error: HttpErrorResponse) => {
            this.handler.newHttpError(error);

            return throwError(error);
          }),
          finalize(() => this.handler.requestFinished()),
        ) as Observable<HttpEvent<unknown>>)
      : next.handle(request);
  }
}
