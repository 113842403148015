<div class="notification">
  <div class="notification-body" [routerLink]="['/administration/system/log']" [queryParams]="snakeCaseQueryParams" (click)="handleClick()">
    <span class="notification-body-status" [gcTooltip]="statusTooltip" [aligns]="{ vertical: 'center', horizontal: 'before' }">
      <gc-icon class="notification-status-icon" svgIcon="circle" [ngClass]="statusColor"></gc-icon>

    </span>
    <div class="notification-body-description">
      <div class="notification-body-description-header">
        <span class="notification-body-description-header-hostname">{{ notification?.eventSourceHostname }}</span>
        <span class="notification-body-description-header-date">{{ notification?.lastSeenTime | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
      </div>
      <span class="notification-body-description-cause" [ngClass]="descriptionCauseNgClass">{{ notification?.cause }}</span>
    </div>
  </div>
  <span
    *ngIf="notificationsPermissions?.edit"
    class="notification-dismiss"
    gcTooltip="Dismiss"
    [aligns]="{ vertical: 'center', horizontal: 'before' }"
    (click)="handleDismiss()"
  >
    <gc-icon class="notification-dismiss-icon" svgIcon="close"></gc-icon>
  </span>
</div>
