import { ParamMap } from '@angular/router';
import { QuickLink } from '@guardicore-ui/shared/api';
import { ApplicationViewName, RouteData } from '@guardicore-ui/shared/data';

export interface CoreStateCurrentView {
  url?: string;
  queryParamsMap?: ParamMap;
  name: ApplicationViewName | null;
  isMsspApplicable: boolean;
  isOldAppOrigin?: boolean;
}

export interface CoreStateFlags {
  isLoggedIn: boolean;
  isInDebugMode: boolean;
  isOldAppLoading: boolean;
  isIframeCollapsed: boolean;
  isMsspApplicable: boolean;
  isEvaluationMode: boolean;
  isEvaluationAgreementAccepted: boolean;
  isEulaAccepted: boolean;
  isPasswordExpired: boolean;
  isPasswordTemporary: boolean;
  isManagerCentra: boolean;
}

export interface CoreState {
  flags: CoreStateFlags;
  viewQuickLinks: QuickLink[];
  currentView: CoreStateCurrentView | null;
  routeData?: RouteData | null;
  urlPath?: string;
  savedUrlPath?: string;
  defaultUrl?: string;
  isApiConnectionFailed: number | null;
}

export function createInitialState(): CoreState {
  return {
    flags: {
      isLoggedIn: false,
      isInDebugMode: false,
      isOldAppLoading: true,
      isIframeCollapsed: true,
      isMsspApplicable: false,
      isEvaluationMode: false,
      isEvaluationAgreementAccepted: false,
      isEulaAccepted: false,
      isPasswordExpired: false,
      isPasswordTemporary: false,
      isManagerCentra: false,
    },
    viewQuickLinks: [],
    currentView: null,
    isApiConnectionFailed: null,
  };
}
