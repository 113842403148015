import { Injectable, OnDestroy } from '@angular/core';
import { HybridMessagePayload } from '@guardicore-ui/shared/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { HybridCommunicationService } from '../infrastructure';

@Injectable({
  providedIn: 'root',
})
export class HybridSectionMessageFacade implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private messages: { [key: string]: HybridMessagePayload } = this.sectionMessageFromStorage;
  sectionMessages$ = this.communicationService.sectionMessages$;

  constructor(private readonly communicationService: HybridCommunicationService) {
    this.sectionMessages$.pipe(takeUntil(this.destroy$)).subscribe(this.initSectionMessage.bind(this));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  get sectionName(): string {
    return this.getSectionNameFromUrl(location.pathname + location.search);
  }

  get sectionMessageFromStorage(): HybridMessagePayload {
    const sectionMessageLS = sessionStorage.getItem('section_message');

    if (!sectionMessageLS) {
      return {};
    }

    try {
      return JSON.parse(sectionMessageLS);
    } catch {
      return {};
    }
  }

  getMessage(sectionName: string): HybridMessagePayload {
    return this.messages[this.sectionName.includes(sectionName) ? this.sectionName : sectionName];
  }

  closeMessage(sectionName?: string): void {
    if (!sectionName) {
      sectionName = this.sectionName;
    }

    if (!this.messages[sectionName]) {
      return;
    }

    this.messages[sectionName]['closed'] = true;
    sessionStorage.setItem('section_message', JSON.stringify(this.messages));
  }

  getSectionNameFromUrl(url: string): string {
    const projectIdMatch = url.match(/project_id=([a-z0-9-]{36,36})/);
    const projectId = projectIdMatch?.[1] ? `-${projectIdMatch[1]}` : '';
    const page = url.split('?')[0]?.split('/').pop() || '';

    return page + projectId;
  }

  private initSectionMessage(sectionMessage: HybridMessagePayload): void {
    // section name fallback
    if (!sectionMessage['section']) {
      sectionMessage['section'] = this.sectionName;
    }

    if (sectionMessage?.['moreInfoModal']) {
      sectionMessage['moreInfoLink'] = 'modal';
    }

    const storedData = this.sectionMessageFromStorage;
    const storedMessage = storedData[sectionMessage['section']];

    /**
     * no message in session storage and new message after user action (create or delete), so skip this message
     */
    if ((!storedMessage || !storedMessage['title'] || !storedMessage['message']) && !sectionMessage['cached']) {
      return;
    }

    const isMessageCached = !!sectionMessage['cached'];
    const differentContent =
      !storedMessage ||
      sectionMessage['title'] !== storedMessage['title'] ||
      sectionMessage['message'] !== storedMessage['message'] ||
      sectionMessage['moreInfoModal'] !== storedMessage['moreInfoModal'];

    // remove section message from screen
    if (differentContent && !sectionMessage['message']) {
      this.messages[sectionMessage['section']] = sectionMessage;
      sessionStorage.setItem('section_message', JSON.stringify(this.messages));

      return;
    }

    const isStoredMessageOutdated =
      !storedMessage || !storedMessage['date'] || sectionMessage['date'] - storedMessage['date'] > 1 * 60 * 1000; // older than one minute

    // updated section message content
    if ((!isMessageCached || isStoredMessageOutdated) && differentContent) {
      this.messages[sectionMessage['section']] = sectionMessage;
      sessionStorage.setItem('section_message', JSON.stringify(this.messages));
    }
  }
}
