export interface NavbarItem {
  children?: Array<NavbarItem>;
  queryParams?: QueryParams;
  expanded?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  iconName?: string;
  active?: boolean;
  tooltip?: string;
  label?: string;
  viewName?: string;
  url?: string;
  isAdmin?: boolean;
  isNotRouterLinkActive?: boolean;
  infoTooltip?: string;
  isNonCollapsible?: boolean;
  redirectTo?: string;
}

export interface QueryParams {
  [key: string]: string;
}
