import { ConnectedPosition } from '@angular/cdk/overlay';

import { getDirection } from './get-direction';
import { HorizontalAlign, LEEF_SIZE, OpenPopoverOptions, VerticalAlign } from '../entities';

const DEFAULT_CONNECTED_POSITION: ConnectedPosition = { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' };

function calculateHorizontalPosition(align?: HorizontalAlign): Pick<ConnectedPosition, 'originX' | 'overlayX'> {
  const { originX, overlayX } = DEFAULT_CONNECTED_POSITION;

  switch (align) {
    case 'before':
      return { originX: 'start', overlayX: 'end' };
    case 'start':
      return { originX: 'start', overlayX: 'start' };
    case 'center':
      return { originX: 'center', overlayX: 'center' };
    case 'end':
      return { originX: 'end', overlayX: 'end' };
    case 'after':
      return { originX: 'end', overlayX: 'start' };
    default:
      return { originX, overlayX };
  }
}

function calculateVerticalPosition(align?: VerticalAlign): Pick<ConnectedPosition, 'originY' | 'overlayY'> {
  const { originY, overlayY } = DEFAULT_CONNECTED_POSITION;

  switch (align) {
    case 'above':
      return { originY: 'top', overlayY: 'bottom' };
    case 'top':
      return { originY: 'top', overlayY: 'top' };
    case 'center':
      return { originY: 'center', overlayY: 'center' };
    case 'bottom':
      return { originY: 'bottom', overlayY: 'bottom' };
    case 'below':
      return { originY: 'bottom', overlayY: 'top' };
    default:
      return { originY, overlayY };
  }
}

export function calculateConnectedPosition(options?: OpenPopoverOptions): ConnectedPosition {
  let offsetX = options?.offsetX;
  let offsetY = options?.offsetY;

  if (options?.withLeaf) {
    options.direction = getDirection(options);

    if (['top', 'bottom'].includes(options.direction)) {
      offsetY = LEEF_SIZE;
    }

    if (['left', 'right'].includes(options.direction)) {
      offsetX = LEEF_SIZE;
    }
  }

  return {
    ...calculateHorizontalPosition(options?.alignment?.horizontal),
    ...calculateVerticalPosition(options?.alignment?.vertical),
    offsetX,
    offsetY,
  };
}
