import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UiActionListModule } from '@guardicore-ui/ui/action-list';
import { UiCommonModule } from '@guardicore-ui/ui/common';
import { UiDatetimePickerModule } from '@guardicore-ui/ui/datetime-picker';
import { UiIconModule } from '@guardicore-ui/ui/icon';
import { UiPopoverModule } from '@guardicore-ui/ui/popovers/popover';
import { UiTooltipModule } from '@guardicore-ui/ui/popovers/tooltip';
import { UiTextModule } from '@guardicore-ui/ui/text';
import { CalendarModule } from 'primeng/calendar';

import { ViolationsDialogComponent } from './violation-dialog/violations-dialog.component';
import { ViolationsComponent } from './violations/violations.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UiTextModule,
    UiIconModule,
    UiCommonModule,
    UiTooltipModule,
    ReactiveFormsModule,
    FormsModule,
    UiPopoverModule,
    UiActionListModule,
    CalendarModule,
    UiDatetimePickerModule,
  ],
  declarations: [ViolationsComponent, ViolationsDialogComponent],
  exports: [ViolationsComponent, ViolationsDialogComponent],
})
export class ProjectsUiModule {}
