import { SvgIconName } from '@guardicore-ui/shared/data';

import {
  setting,
  close,
  alert,
  spinner,
  info,
  refresh,
  deleteSvg,
  list,
  sortable,
  clear,
  doneCircle,
  doneCircleFilled,
  cancel,
  turnedOff,
  turnedOn,
  folder,
  labelGroups,
  userGroups,
  navbarDashboard,
  navbarCluster,
  navbarReveal,
  navbarEnforce,
  navbarInventory,
  navbarSecurity,
  navbarSystem,
  guardicoreG,
  guardicoreTitle,
  spaceDots,
  akamaiWave,
  loadingAnimation,
  tab,
  invalid,
  priceTag,
  shield,
  shieldFilled,
  priceTagFilled,
  circle,
  link,
  clipboard,
  chevronLeft,
  chevronRight,
  externalLink,
  expandMore,
  cloudSlash,
  warningTransparent,
  clock,
  success,
  error,
  label,
  checkCircle,
  checkCircleFilled,
  closeCircle,
  locked,
  unix,
  linux,
  windows,
  diagnostics,
  uploadAlt,
  play,
  circleArrowRight,
  circleArrowUp,
  thinArrowLeft,
  globalAlt,
  deceptionModule,
  detectionModule,
  enforcementModule,
  revealModule,
  overlayErrors,
  overlayStopped,
  overlayNotInstalled,
  overlayInitializing,
  accessModule,
  restart,
  debug,
  editV3,
  actionAllow,
  actionBlockAlert,
  actionAlert,
  actionInfo,
  errorFilled,
  blockAlert,
  file,
  tenant,
  keyColored,
  visibility,
  enforcementColored,
  pencil,
} from './svgs';
/**
 * When adding new SVG objects, make sure they DO NOT have any fill attributes (for paths as well),
 * and no width and height attributes on the <svg> tag
 */
export const SVG_DATA: Record<SvgIconName, string> = {
  default: `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path d="M7.92673 3.92973C7.6228 3.92973 7.40027 3.84324 7.25916 3.67027C7.1289 3.4973 7.06377 3.25946 7.06377 2.95676C7.06377 2.66486 7.1289 2.43243 7.25916 2.25946C7.40027 2.08649 7.6228 2 7.92673 2C8.24152 2 8.46947 2.08649 8.61058 2.25946C8.7517 2.43243 8.82225 2.66486 8.82225 2.95676C8.82225 3.25946 8.7517 3.4973 8.61058 3.67027C8.46947 3.84324 8.24152 3.92973 7.92673 3.92973ZM2.99322 13.7568L2.24423 13.0108L7.22659 7.98378L2.22795 2.97297L2.97693 2.21081L7.99186 7.22162L13.0231 2.22703L13.772 2.97297L8.75712 7.98378L13.7395 13.027L12.9905 13.773L7.99186 8.74595L2.99322 13.7568ZM2.87924 8.97297C2.61872 8.97297 2.40706 8.9027 2.24423 8.76216C2.08141 8.61081 2 8.36757 2 8.03243C2 7.6973 2.08141 7.45405 2.24423 7.3027C2.40706 7.15135 2.61872 7.07568 2.87924 7.07568C3.15061 7.07568 3.36771 7.15135 3.53053 7.3027C3.69335 7.45405 3.77476 7.6973 3.77476 8.03243C3.77476 8.36757 3.69335 8.61081 3.53053 8.76216C3.36771 8.9027 3.15061 8.97297 2.87924 8.97297ZM13.1045 8.97297C12.844 8.97297 12.6323 8.9027 12.4695 8.76216C12.3066 8.61081 12.2252 8.36757 12.2252 8.03243C12.2252 7.6973 12.3066 7.45405 12.4695 7.3027C12.6323 7.15135 12.844 7.07568 13.1045 7.07568C13.3758 7.07568 13.5929 7.15135 13.7558 7.3027C13.9186 7.45405 14 7.6973 14 8.03243C14 8.36757 13.9186 8.61081 13.7558 8.76216C13.5929 8.9027 13.3758 8.97297 13.1045 8.97297ZM7.92673 14C7.6228 14 7.40027 13.9135 7.25916 13.7405C7.1289 13.5676 7.06377 13.3297 7.06377 13.027C7.06377 12.7351 7.1289 12.5027 7.25916 12.3297C7.40027 12.1568 7.6228 12.0703 7.92673 12.0703C8.24152 12.0703 8.46947 12.1568 8.61058 12.3297C8.7517 12.5027 8.82225 12.7351 8.82225 13.027C8.82225 13.3297 8.7517 13.5676 8.61058 13.7405C8.46947 13.9135 8.24152 14 7.92673 14Z"/>
</svg>
`,
  close,
  alert,
  spinner,
  info,
  download: `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.98328 12L13 6.08303H10.1237V1H5.84281V6.08303H3L7.98328 12ZM13 14H3V15H13V14Z"/>
</svg>
`,
  confirm: `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path d="M2.5 9.03876L5.9722 12.5L13.5 5.00499L11.9884 3.5L6.0676 9.39582C6.01657 9.44661 5.93384 9.44661 5.88281 9.39582L4.01236 7.53351L2.5 9.03876Z"/>
</svg>
`,
  done: `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path d="M6.00006 10.8L3.20006 8L2.26672 8.93334L6.00006 12.6667L14.0001 4.66667L13.0667 3.73334L6.00006 10.8Z"/>
</svg>
`,
  'chevron-right': chevronRight,
  'chevron-left': chevronLeft,
  'expand-more': expandMore,
  'expand-less': `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path d="M11.056 10.688L12 9.74399L8 5.74399L4 9.74399L4.944 10.688L8 7.61599L11.056 10.688Z"/>
</svg>
`,
  wrench: `<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.02752 13.6797L8.48911 10.2181C8.89844 10.3508 9.33522 10.4225 9.78876 10.4225C12.1146 10.4225 14 8.53705 14 6.21124C14 5.55982 13.8521 4.94294 13.588 4.39236C13.4269 4.05636 12.9863 4.01933 12.7228 4.28283L10.644 6.36169L9.78876 6.21128L9.63836 5.35606L11.7172 3.27719C11.9807 3.01369 11.9437 2.57316 11.6077 2.412C11.0571 2.14792 10.4402 2 9.78876 2C7.46295 2 5.57752 3.88544 5.57752 6.21124C5.57752 6.66478 5.64921 7.10156 5.78188 7.51089L2.32029 10.9725C2.09465 11.1981 1.9551 11.5069 2.01318 11.8207C2.21633 12.9183 3.08166 13.7837 4.1793 13.9868C4.49309 14.0449 4.80187 13.9054 5.02752 13.6797ZM8.56623 4.72658C8.4275 4.86531 8.36514 5.06296 8.39912 5.25619L8.67676 6.83497C8.7206 7.08425 8.91579 7.27944 9.16508 7.32328L10.7439 7.60093C10.9371 7.63491 11.1347 7.57254 11.2735 7.43381L12.7833 5.92394C12.7922 6.01838 12.7968 6.11421 12.7968 6.21124C12.7968 7.87253 11.45 9.21927 9.78876 9.21927C9.46272 9.21927 9.15105 9.16783 8.8601 9.07353C8.42998 8.93412 7.95802 9.04761 7.63831 9.36732L4.24024 12.7654C3.75994 12.6206 3.3794 12.2401 3.23462 11.7598L6.63268 8.36169C6.95239 8.04198 7.06588 7.57001 6.92647 7.1399C6.83217 6.84895 6.78073 6.53728 6.78073 6.21124C6.78073 4.54995 8.12747 3.20321 9.78876 3.20321C9.88582 3.20321 9.98167 3.20777 10.0761 3.21668L8.56623 4.72658ZM4.17223 12.8329C4.17223 12.8329 4.1726 12.8326 4.17341 12.832C4.17264 12.8327 4.17223 12.8329 4.17223 12.8329ZM3.16705 11.8278C3.16705 11.8278 3.16733 11.8273 3.16798 11.8266C3.16739 11.8274 3.16705 11.8278 3.16705 11.8278Z" fill="#888787"/>
  </svg>
`,
  refresh,
  delete: deleteSvg,
  'caret-up': `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path d="M8.5 3.5L12 7H5L8.5 3.5Z"/>
</svg>
`,
  'caret-down': `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path d="M8.5 12.5L12 9H5L8.5 12.5Z"/>
</svg>

`,
  plus: `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7 14H9V9H14V7H9V2H7V7H2V9H7V14Z"/>
</svg>
`,
  minus: `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.83333 9H13V7H8.83333H7.16667H3V9H7.16667H8.83333Z"/>
</svg>
`,
  stop: `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.99741 3.99753C6.20519 1.78975 9.78691 1.79196 11.9974 4.00246C14.2079 6.21296 14.2101 9.79468 12.0023 12.0025C9.79456 14.2102 6.21284 14.208 4.00234 11.9975C1.79184 9.78703 1.78963 6.20531 3.99741 3.99753ZM12.4075 9.82619C13.1428 8.04528 12.7322 5.99544 11.3671 4.63284C9.49976 2.78572 6.49505 2.78525 4.63169 4.63177C3.26953 5.99394 2.86327 8.04364 3.60243 9.82478C4.34159 11.6059 6.08053 12.7676 8.00812 12.7679C9.9357 12.7682 11.6721 11.6071 12.4075 9.82619Z"/>
<rect x="3" y="7.5" width="10" height="1"/>
</svg>
`,
  sort: `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4 6.93333L8 0L12 6.93333H4Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.00014 9.06665H4L8 16L12 9.06665H11.9999L12 9.06641H4L4.00014 9.06665Z"/>
</svg>
`,
  sortable,
  edit: `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.7596 1.06025L14.9244 3.22496C14.963 3.26366 14.9847 3.31615 14.9845 3.37084C14.9844 3.42554 14.9626 3.47794 14.9238 3.51649L12.1865 6.2539L4.92411 13.516C4.91867 13.521 4.91298 13.5256 4.90705 13.53C4.90482 13.5316 4.90283 13.5332 4.90088 13.5348C4.89904 13.5363 4.89723 13.5378 4.89529 13.5392C4.88249 13.5482 4.86872 13.5557 4.85423 13.5617L2.78847 14.3764L1.28152 14.9707C1.20521 15.0008 1.11834 14.9827 1.06034 14.9247C1.00235 14.8667 0.98428 14.7799 1.01435 14.7035L1.60894 13.1966V13.1959L2.42294 11.1297C2.42884 11.1152 2.43638 11.1014 2.44541 11.0886C2.44722 11.086 2.44923 11.0837 2.45125 11.0814L2.45126 11.0814L2.45458 11.0774C2.45892 11.0714 2.46359 11.0657 2.46858 11.0603L12.4686 1.06025C12.549 0.979917 12.6793 0.979917 12.7596 1.06025ZM10.1681 3.9439L12.0412 5.81696L12.6272 5.23037L10.7542 3.35743L10.1681 3.9439ZM4.77927 13.0788L4.36751 12.6671L11.3379 5.69601L11.7496 6.10778L4.77927 13.0788ZM10.5806 4.93825L3.6094 11.909L4.0761 12.3757L11.0468 5.40448L10.5806 4.93825ZM2.90622 11.2058L9.87693 4.23507L10.2887 4.64684L3.31834 11.6179L2.90622 11.2058ZM2.34387 14.1089L1.87599 13.6411L1.57128 14.4137L2.34387 14.1089ZM2.76142 13.9442L2.04071 13.2235L2.69153 11.5732L3.17247 12.0541L3.92965 12.8113L4.41059 13.2922L2.76142 13.9442ZM11.0453 3.06672L12.9184 4.93978L14.4877 3.37049L12.6146 1.49755L11.0453 3.06672Z"/>
<path d="M1.876 13.6411L2.34388 14.1089L1.57129 14.4136L1.876 13.6411Z"/>
</svg>
`,
  search: `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.6953 1C3.54987 1 1 3.54673 1 6.68829C1 9.82984 3.54987 12.3766 6.6953 12.3766C9.84073 12.3766 12.3906 9.82984 12.3906 6.68829C12.3906 3.54673 9.84073 1 6.6953 1ZM6.69528 1.897C8.63596 1.89523 10.3865 3.06146 11.1304 4.8517C11.8743 6.64194 11.465 8.70348 10.0933 10.0747C8.7217 11.4459 6.658 11.8566 4.86487 11.1153C3.07174 10.3739 1.90247 8.62657 1.90247 6.68828C1.91444 4.0488 4.05256 1.91137 6.69528 1.897ZM11.6458 11.007L14.8746 14.2537C14.985 14.3647 15.0276 14.526 14.9866 14.677C14.9455 14.8279 14.8269 14.9455 14.6755 14.9855C14.5241 15.0254 14.3629 14.9817 14.2525 14.8707L11.0237 11.624L11.6458 11.007Z"/>
</svg>
`,
  'done-circle': doneCircle,
  'done-circle-filled': doneCircleFilled,
  list,
  dot: `
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<circle cx="5" cy="8" r="3"/>
</svg>
`,
  'side-line': `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.66667 9H7C7.55229 9 8 8.55228 8 8C8 7.44772 7.55229 7 7 7H4.66667H3.33333H0L0 9H3.33333H4.66667Z"/>
</svg>
`,
  failed: `<svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 9.00002C0 4.03741 4.03715 0 8.9998 0C13.9625 0 18 4.03741 18 9.00002C18 13.9626 13.9626 18 8.9998 18C4.03715 18 0 13.9626 0 9.00002ZM15.5 8H2.5V10H15.5V8Z"/>
</svg>
`,
  clear,
  'turned-off': turnedOff,
  'turned-on': turnedOn,
  setting,
  cancel,
  warning: `<svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.368 16h11.264a2.368 2.368 0 0 0 2.119-3.428L10.118 1.31a2.369 2.369 0 0 0-4.237 0L.25 12.573a2.369 2.369 0 0 0 2.118 3.426Zm4.78-6.29a.857.857 0 0 0 .852.795.858.858 0 0 0 .852-.795l.293-4.141a1.147 1.147 0 1 0-2.288 0l.29 4.141ZM8 13.283a.972.972 0 1 1 0-1.945.972.972 0 0 1 0 1.945Z" fill="#FDC300"/></svg>`,
  folder,
  success,
  error,
  api: `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3 2C2.44772 2 2 2.44772 2 3V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V3C14 2.44772 13.5523 2 13 2H3ZM10.7909 8.64419L7.25536 5.10865L11.1317 4.76782L10.7909 8.64419ZM4.19299 10.557L8.44827 6.30172L9.56311 7.41655L5.30782 11.6718L4.19299 10.557Z"/>
</svg>
`,
  bell: `<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
<path d="M15.4407 5.17481C15.9591 6.20301 16.075 7.21496 16.0503 8.29696C16.031 9.17735 15.9584 10.0613 16.0988 10.9378C16.4408 13.0531 17.5815 14.6323 19.4905 15.6831C19.6733 15.7837 19.8673 15.8689 19.9515 16.0781C20.1281 16.5194 19.8031 16.9546 19.2882 16.9564C18.8073 16.9571 18.3258 16.9498 17.8442 16.9459L0.705233 16.815C0.562313 16.8229 0.420612 16.7856 0.301238 16.7087C0.181863 16.6319 0.0911948 16.5196 0.0427353 16.3886C-0.0170027 16.2405 -0.0140062 16.0755 0.0510619 15.9296C0.11613 15.7837 0.237974 15.6688 0.389915 15.6101C1.29668 15.1748 2.08829 14.5423 2.70346 13.7616C3.63095 12.5869 4.1181 11.1392 4.08422 9.65831C4.0797 8.87784 4.08418 8.09478 4.11417 7.318C4.15787 6.60255 4.34452 5.90224 4.66368 5.25626C5.23821 4.05073 6.22214 3.07456 7.44985 2.49206C8.67756 1.90956 10.0741 1.7563 11.4043 2.05809C13.2477 2.48447 14.9223 4.14661 15.4407 5.17481Z"/>
<path id="tongue" d="M13 17.5073C12.9203 18.1971 12.5806 18.8337 12.0466 19.2941C11.5125 19.7544 10.822 20.0059 10.1085 19.9999C9.39495 19.9939 8.70902 19.7308 8.18324 19.2615C7.65746 18.7922 7.32916 18.15 7.26176 17.4589L13 17.5073Z"/>
<path d="M10.9049 1.29545L9.12643 1.28309C9.11118 0.94752 9.0734 0.584172 9.35555 0.289767C9.46708 0.162949 9.61541 0.0718711 9.78108 0.028479C9.94675 -0.0149132 10.122 -0.00859881 10.284 0.0465965C10.4539 0.0965639 10.6041 0.19603 10.7142 0.331583C10.8242 0.467135 10.8889 0.632257 10.8996 0.804691C10.9152 0.969529 10.9052 1.13816 10.9049 1.29545Z"/>
</svg>
`,
  bug: `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.5178 4.93726V4.4283C10.5178 3.01802 9.37459 1.87476 7.96431 1.87476C6.55402 1.87476 5.41076 3.01802 5.41076 4.4283V4.93726H4.68234L3.22714 3.47469L2.70817 3.99105L4.11769 5.4077L4.10144 5.45073C3.81939 6.19774 3.66076 7.03865 3.66076 7.9283C3.66076 8.09953 3.66664 8.26896 3.67814 8.43626L3.68077 8.47444H1.87503V9.20652H3.77275L3.7781 9.23583C3.92881 10.0599 4.21872 10.8131 4.61306 11.4509L4.64281 11.4991L2.92499 13.2169L3.44265 13.7345L5.08039 12.0968L5.13068 12.1543C5.89024 13.0224 6.88223 13.5443 7.96431 13.5443C9.02988 13.5443 10.008 13.0382 10.763 12.1938L10.8134 12.1373L12.4845 13.8169L13.0035 13.3005L11.2566 11.5448L11.287 11.4966C11.6959 10.8491 11.9962 10.0797 12.1505 9.23583L12.1559 9.20652H14.055V8.47444H12.2478L12.2505 8.43626C12.262 8.26896 12.2678 8.09953 12.2678 7.9283C12.2678 7.02689 12.105 6.17551 11.8159 5.42116L11.7993 5.37785L13.1729 4.00425L12.6553 3.48659L11.2046 4.93726H10.5178ZM6.14285 4.93726V4.4283C6.14285 3.42233 6.95834 2.60684 7.96431 2.60684C8.97027 2.60684 9.78576 3.42233 9.78576 4.4283V4.93726H6.14285ZM11.1272 5.66934L11.1446 5.71563C11.3926 6.37461 11.5358 7.12485 11.5358 7.9283C11.5358 9.33699 11.0957 10.583 10.421 11.46C9.74691 12.3363 8.87139 12.8123 7.96431 12.8123C7.05722 12.8123 6.1817 12.3363 5.50762 11.46C4.83295 10.583 4.39285 9.33699 4.39285 7.9283C4.39285 7.12485 4.53599 6.37462 4.78397 5.71563L4.80139 5.66934H11.1272Z"/>
</svg>
`,
  key: `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.3356 6.36492C11.3608 7.33967 9.78273 7.3476 8.81773 6.3826C7.85273 5.4176 7.86066 3.83949 8.83541 2.86474C9.81016 1.88999 11.3883 1.88206 12.3533 2.84706C13.3183 3.81206 13.3103 5.39017 12.3356 6.36492ZM6.14656 12.904L5.79301 13.2576L4.91797 12.3825L3.85731 13.4432L4.73235 14.3182L4.3788 14.6718L2.62871 12.9217L4.39647 11.1539L6.14656 12.904ZM8.48528 2.51472C7.37336 3.62664 7.31035 5.38444 8.2979 6.5523L1.9251 12.9251L4.37522 15.3752L5.43588 14.3146L4.56084 13.4395L4.91439 13.086L5.78944 13.961L6.8501 12.9003L4.74999 10.8002L8.64792 6.90232C9.81578 7.88987 11.5736 7.82686 12.6855 6.71493C13.857 5.54343 13.8665 3.65329 12.7067 2.49351C11.5469 1.33372 9.65678 1.34322 8.48528 2.51472Z"/>
</svg>
`,
  license: `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.0418 14.5335H2.95846V1.4668H9.37513V5.20013H13.0418V14.5335ZM12.8431 4.73331H9.83346V1.66895L12.8431 4.73331ZM13.4911 4.73333L9.83333 1.0091V1H2.5V15H13.5V4.73333H13.4911Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.79182 3.56652H8.91682V3.09985H4.79182V3.56652Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.79182 5.90002H11.2085V5.43335H4.79182V5.90002Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.79182 8.23327H11.2085V7.7666H4.79182V8.23327Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.79182 10.5665H11.2085V10.0999H4.79182V10.5665Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.79182 12.9H11.2085V12.4333H4.79182V12.9Z"/>
</svg>
`,
  logOut: `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path d="M6 9.03552C5.44772 9.03552 5 8.58778 5 8.03549C5 7.48321 5.44772 7.03547 6 7.03547H11.1138V4L15 7.99999L11.1138 12V9.03552H6Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.36339 3C7.86547 3 8.27248 3.40701 8.27248 3.90909C8.27248 4.41117 7.86547 4.81818 7.36339 4.81818L3.81799 4.81818C3.2657 4.81818 2.81799 5.2659 2.81799 5.81818L2.81799 10.1817C2.81799 10.734 3.2657 11.1817 3.81799 11.1817L7.36339 11.1817C7.86547 11.1817 8.27248 11.5887 8.27248 12.0908C8.27248 12.5929 7.86547 12.9999 7.36339 12.9999L1.90885 12.9999C1.40677 12.9999 0.999756 12.5929 0.999756 12.0908V11.1817C0.999756 11.1817 0.999767 11.1817 0.999781 11.1817C0.999795 11.1817 0.999806 11.1817 0.999806 11.1817L0.999806 4.81821C0.999806 4.81819 0.999795 4.81818 0.999781 4.81818C0.999767 4.81818 0.999756 4.81817 0.999756 4.81816V3.90909C0.999756 3.40701 1.40677 3 1.90885 3H7.36339Z"/>
</svg>
`,
  valid: `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM5.6 8L7 9.4L10.5333 5.86667L11 6.33333L7 10.3333L5.13333 8.46667L5.6 8Z"/>
</svg>
`,
  asset: `<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.15492 11.3331H14.8438V1.80933H1.15492V11.3331ZM10.8199 14.1903H5.17955L6.58976 11.8093H9.40967L10.8199 14.1903ZM15.3333 1.33325H0.666672V11.8094H6.02563L4.33334 14.6666H11.6667L9.97438 11.8094H15.3333V1.33325Z" fill="#09011D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.15492 11.3331H14.8438V1.80933H1.15492V11.3331Z" fill="white"/>
<path opacity="0.780101" fill-rule="evenodd" clip-rule="evenodd" d="M10.8199 14.1903H5.17955L6.58975 11.8093H9.40966L10.8199 14.1903Z" fill="#979797"/>
</svg>
`,
  userGroups,
  labelGroups,
  'navbar-dashboard': navbarDashboard,
  'navbar-cluster': navbarCluster,
  reveal: navbarReveal,
  'navbar-enforce': navbarEnforce,
  'navbar-inventory': navbarInventory,
  'navbar-security': navbarSecurity,
  'navbar-system': navbarSystem,
  'guardicore-g': guardicoreG,
  'guardicore-title': guardicoreTitle,
  'space-dots': spaceDots,
  'akamai-wave': akamaiWave,
  'loading-animation': loadingAnimation,
  tab,
  invalid,
  'price-tag': priceTag,
  'price-tag-filled': priceTagFilled,
  shield,
  'shield-filled': shieldFilled,
  circle,
  link,
  clipboard,
  'external-link': externalLink,
  'cloud-slash': cloudSlash,
  'warning-transparent': warningTransparent,
  clock,
  label,
  'check-circle': checkCircle,
  'check-circle-filled': checkCircleFilled,
  'close-circle': closeCircle,
  locked: locked,
  unix: unix,
  linux: linux,
  windows: windows,
  diagnostics,
  'upload-alt': uploadAlt,
  play,
  'circle-arrow-right': circleArrowRight,
  'circle-arrow-up': circleArrowUp,
  'thin-arrow-left': thinArrowLeft,
  'global-alt': globalAlt,
  'deception-module': deceptionModule,
  'detection-module': detectionModule,
  'enforcement-module': enforcementModule,
  'reveal-module': revealModule,
  'overlay-errors': overlayErrors,
  'overlay-stopped': overlayStopped,
  'overlay-not-installed': overlayNotInstalled,
  'overlay-initializing': overlayInitializing,
  'access-module': accessModule,
  restart,
  debug,
  'edit-v3': editV3,
  'action-allow': actionAllow,
  'action-alert': actionAlert,
  'action-block-alert': actionBlockAlert,
  'action-info': actionInfo,
  'error-filled': errorFilled,
  'block-alert': blockAlert,
  file,
  tenant,
  'key-colored': keyColored,
  visibility,
  'enforcement-colored': enforcementColored,
  pencil,
};
