import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { VersionSpecification } from '@guardicore-ui/shared/data';

import { UserMenuAction } from './user-menu-actions';

const BUILD_DISPLAYED_LENGTH = 8;

interface UserMenuSet {
  release?: VersionSpecification;
  build?: string;
  evaluationDaysPassed?: number;
  evaluationModeState: boolean;
  systemTime?: string;
  showAgentSdkDocs: boolean;
  showInternalDocumentation: boolean;
}

@Component({
  selector: 'gc-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent implements OnChanges {
  @Input() appTitle = 'Guardicore';
  @Input() userMenuSet: UserMenuSet | null = null;
  @Input() systemStatusLogUrl?: string;
  @Input() isInDebugMode = false;
  @Input() isEvaluationMode = false;
  @Output() readonly action = new EventEmitter<UserMenuAction>();

  ngOnChanges(): void {
    if (this.userMenuSet) {
      this.userMenuSet.build = this.userMenuSet.build?.substr(0, BUILD_DISPLAYED_LENGTH);
    }
  }
}
