<button gc-outline-button [chevron]="'down'" panelCssClass="dropdown-filter" [disabled]="disabled" (click)="gcClick.emit()">
  <span class="filter-label">{{ label }}</span>
  <ng-container *ngIf="text">  
    <span class="spaced">:</span>
    <span class="value" *ngFor="let date of text; let last = last">
      {{ date | date:"yyyy-MM-dd" }}
      <span *ngIf="!last" class="spaced">-</span>
    </span>
  </ng-container>
</button>
