import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QueryParams } from '@guardicore-ui/shared/data';
import { Observable, Subject } from 'rxjs';

import { NavbarComponentQuery } from '../infrastructure/navbar-component-query.service';
import { NavbarItemState } from '../infrastructure/navbar-state';

@Component({
  selector: 'gc-navbar-item',
  templateUrl: './navbar-item.component.html',
  styleUrls: ['./navbar-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarItemComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  @Input() queryParams: QueryParams = {};
  @Input() label?: string;
  @Input() viewName?: string;
  @Input() url?: string;
  @Input() isNew?: boolean;

  state$?: Observable<NavbarItemState | undefined>;

  constructor(private query: NavbarComponentQuery, private router: Router) {}

  ngOnInit(): void {
    if (!this.label) {
      throw new Error(`Label wasn't provided for the navbar item`);
    }

    if (!this.viewName) {
      this.viewName = this.label;
    }

    const viewName = this.viewName as string;

    this.state$ = this.query.itemState(viewName);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  analyticsProps(state: NavbarItemState): { [key: string]: string } {
    return {
      item: this.label ?? '',
      route: this.url ?? '',
      currentRoute: this.router.url.split('?')[0],
      isDisabled: (state.disabled ?? 'false').toString(),
    };
  }
}
