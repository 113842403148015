import { GridSelectionService } from './grid-selection.service';
import { SelectionModeState } from '../entities';

export class NormalSelectionMode implements SelectionModeState {
  constructor(private context: GridSelectionService) {
    this.context.clearState('none');
  }

  handleRowSelectedEvent(): void {
    const params = this.context.rowSelectedEventParams;
    const selectionState = this.context.getSelectionState();

    switch (params?.rowSelection?.selectedRowType) {
      case 'none':
        this.context.clearState(params.rowSelection.selectedRowType);
        break;
      case 'allGrid':
        this.context.changeSelectionModeState(params.rowSelection.selectedRowType);
        break;
      case 'allPage':
        this.context.addAllPageRowsToSelection(params.gridApi);
        selectionState.currentPageState = 'allPage';
        break;
      case 'deselectPage':
        this.context.removeRowNodeFromSelectedRowIds(params.gridApi);
        selectionState.currentPageState = 'deselectPage';
        break;
      case 'deselect':
        selectionState.selectedRowIds.delete(params.rowSelection.rowId);
        selectionState.selectedRowData.delete(params.rowSelection.rowId);
        selectionState.currentPageState =
          this.context.countSelectedRowsInPage(params.gridApi).selectedInPage > 0
            ? 'some'
            : selectionState.selectedRowIds.size > 0
            ? 'deselect'
            : 'none';
        if (selectionState.currentPageState === 'none') {
          this.context.clearState('none');
        }

        break;
      case 'select':
        selectionState.selectedRowIds.add(params.rowSelection.rowId);
        this.context.addRowSelectedData(params.rowSelection.rowData);

        selectionState.currentPageState = this.context.getCurrentSelectionState(params.gridApi);

        break;
      case 'pagination':
        params.rowData = params.rowData || [];
        selectionState.currentPageState = this.context.getCurrentSelectionState(params.rowData);
        if (['allGrid'].includes(selectionState.currentPageState)) {
          params.rowData.forEach((data: { id: string }) => {
            selectionState.selectedRowIds.add(data.id);
            this.context.addRowSelectedData(data);
          });
        }

        break;
    }
  }
}
