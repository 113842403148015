import { FormGroup } from '@angular/forms';

export const MATCH_VALUES_VALIDATOR_KEY = 'valuesMismatch';

export function matchValuesValidator(formControlName: string, formControlNameToMatch: string): (form: FormGroup) => void {
  return (form: FormGroup): void => {
    const control = form?.get(formControlName);
    const matchingControl = form?.get(formControlNameToMatch);

    if (matchingControl?.errors && !matchingControl?.errors?.[MATCH_VALUES_VALIDATOR_KEY]) {
      return;
    }

    if (control?.value === matchingControl?.value) {
      matchingControl?.setErrors(null);
    } else {
      matchingControl?.setErrors({ [MATCH_VALUES_VALIDATOR_KEY]: true });
      matchingControl?.markAsTouched();
    }
  };
}
