import { InjectionToken } from '@angular/core';

export interface TrackingConfig {
  analyticsKey: string;
  analyticsStagingKey: string;
  production: boolean;
  iframeUrl: string;
}

export const TRACKING_CONFIG_TOKEN = new InjectionToken<TrackingConfig>('__TRACKING_CONFIG__');
