import { ParamMap } from '@angular/router';

import { ApplicationViewName } from './application-views';
import { BreadcrumbItem } from './breadcrumb-item';

export interface RouteData {
  navState?: string;
  viewName?: ApplicationViewName;
  params?: { [key: string]: string | number };
  breadcrumb?: BreadcrumbItem;
  useMssp?: boolean;
  oldAppOrigin?: boolean;
  queryParamMap?: ParamMap;
  isPublicRoute?: boolean;
  title?: string;
}
