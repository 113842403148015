export interface NavbarItemState {
  hidden: boolean;
  disabled: boolean | undefined;
  active?: boolean;
  tooltip?: string;
}

export interface NavbarState {
  itemsState: Record<string, NavbarItemState>;
  activeItemViewName?: string;
}

export function createInitialState(): NavbarState {
  return {
    itemsState: {},
    activeItemViewName: undefined,
  };
}
