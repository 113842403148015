export interface AuthenticationResponse {
  accessToken: string;
  id: string;
  username: string;
  '2faRequired'?: boolean;
  '2faActivationRequired'?: boolean;
  '2faEnforced'?: boolean;
  '2faTempToken'?: string;
  qrCode?: string;
  secretKey?: string;
  radius2faService?: boolean;
}

export interface SSOAuthenticationResponse {
  location: string;
}

export interface SSOAuthenticationParams {
  accessToken: string;
  id: string;
}
