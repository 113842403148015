import { ComponentType, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { SimpleToastrState } from '@guardicore-ui/shared/data';

import { SimpleToastrComponent } from './simple-toastr/simple-toastr.component';
import { createDefaultToastrConfig, TOASTR_DATA, ToastrConfig } from './toastr-config';
import { ToastrRef } from './toastr-ref';
import { ToastrStackComponent } from './toastr-stack/toastr-stack.component';

@Injectable({
  providedIn: 'root',
})
export class ToastrService {
  private overlayRef?: OverlayRef;
  private toastrPortal?: ToastrStackComponent;
  private readonly overlayConfig = new OverlayConfig({
    positionStrategy: this.overlay.position().global().top('60px').right('24px'),
    scrollStrategy: this.overlay.scrollStrategies.block(),
    hasBackdrop: false,
  });

  constructor(private overlay: Overlay, private injector: Injector) {}

  open(
    message: string,
    title?: string,
    state: SimpleToastrState = 'success',
    config: ToastrConfig = createDefaultToastrConfig(),
  ): ToastrRef<SimpleToastrComponent> {
    config = { ...config, ...{ data: { message, title, state } } };

    return this.openFromComponent(SimpleToastrComponent, config);
  }

  private openFromComponent<T>(component: ComponentType<T>, config: ToastrConfig): ToastrRef<T> {
    return this.attachToasterContent<T>(component, config);
  }

  private attachToastrPortal(overlay: OverlayRef): ToastrStackComponent {
    const containerPortal = new ComponentPortal(ToastrStackComponent, null, this.injector);
    const containerRef = overlay.attach<ToastrStackComponent>(containerPortal);

    return containerRef.instance;
  }

  private attachToasterContent<T>(component: ComponentType<T>, config: ToastrConfig): ToastrRef<T> {
    if (!this.overlayRef || !this.toastrPortal) {
      this.overlayRef = this.overlay.create(this.overlayConfig);
      this.toastrPortal = this.attachToastrPortal(this.overlayRef);
    }

    const containerComponentRef = this.toastrPortal.addToast(config);
    const toastrRef = new ToastrRef<T>(containerComponentRef);
    const injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: ToastrRef, useValue: toastrRef },
        { provide: TOASTR_DATA, useValue: config.data },
      ],
    });

    const contentRef = containerComponentRef.instance.attachComponentPortal<T>(new ComponentPortal(component, null, injector));

    toastrRef.componentInstance = contentRef?.instance;

    return toastrRef;
  }
}
