import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { GridApi } from 'ag-grid-enterprise';

import { RowExpansion } from '../entities';

@Injectable()
export class RowExpansionService {
  private expandedRowIds = new Set<ID>();

  addExpandedRows(gridApi: GridApi): void {
    if (!this.expandedRowIds.size) {
      return;
    }

    gridApi.forEachNode(rowNode => {
      rowNode.setExpanded(this.expandedRowIds.has(rowNode.data.id));
    });
  }

  rowExpansion(rowExpansion: RowExpansion): void {
    this.rowExpandedEvent(rowExpansion);
  }

  private rowExpandedEvent(rowExpansion: RowExpansion): void {
    rowExpansion.expand ? this.expandedRowIds.add(rowExpansion.rowId) : this.expandedRowIds.delete(rowExpansion.rowId);
  }
}
