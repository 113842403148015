import { Injectable, NgZone } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { HybridMainFacade } from '@guardicore-ui/hybrid/domain';
import { SystemStatusState } from '@guardicore-ui/shared/system-status';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

export const lastActionKey = 'lastAction';
export const events = ['scroll', 'wheel', 'mousedown', 'keypress'];
export const offsetMilliseconds = 30_000;

@Injectable({ providedIn: 'root' })
export class SessionExpirationService {
  private checkInterval!: number;
  private sessionExpirationTimeoutModalSubject: Subject<void> = new Subject();
  private destroy$ = new Subject<void>();
  private expirationTimeInMilliseconds: number = 10 * 60_000;

  constructor(private ngZone: NgZone, private readonly hybridCommunicationFacade: HybridMainFacade) {}

  public run(systemStatus: SystemStatusState): void {
    const expirationMins = systemStatus.configuration?.userSessionIdleTimeoutMins;

    if (expirationMins) {
      this.expirationTimeInMilliseconds = expirationMins * 60_000;
      this.lastAction = Date.now();
      this.check();
      this.initListener();
      this.initInterval();
    }
  }

  public stop(): void {
    localStorage.removeItem(lastActionKey);
    clearInterval(this.checkInterval);
    this.destroy$.next();
  }

  public get sessionExpired$(): Observable<void> {
    return this.sessionExpirationTimeoutModalSubject.asObservable();
  }

  private get lastAction(): number {
    const lastAction = localStorage.getItem(lastActionKey);

    if (lastAction) {
      return parseInt(lastAction);
    }

    const resetValue = Date.now();

    this.lastAction = resetValue;

    return resetValue;
  }

  private set lastAction(value: number) {
    localStorage.setItem(lastActionKey, JSON.stringify(value));
  }

  private initListener(): void {
    this.ngZone.runOutsideAngular(() => {
      const eventStreams = events.map(ev => fromEvent(document, ev));

      merge(...eventStreams, this.hybridCommunicationFacade.hotkey$, this.hybridCommunicationFacade.clickOnIframe$)
        .pipe(debounceTime(5_000), takeUntil(this.destroy$))
        .subscribe(() => {
          this.reset();
        });
    });
  }

  private initInterval(): void {
    this.ngZone.runOutsideAngular(() => {
      clearInterval(this.checkInterval);
      this.checkInterval = window.setInterval(() => {
        this.check();
      }, 1_000);
    });
  }

  private reset(): void {
    this.lastAction = Date.now();
  }

  private check(): void {
    const now = Date.now();
    const timeLeft = this.lastAction + this.expirationTimeInMilliseconds - offsetMilliseconds;
    const diff = timeLeft - now;
    const isTimeout = diff < 0;

    this.ngZone.run(() => {
      if (isTimeout) {
        this.stop();
        this.sessionExpirationTimeoutModalSubject.next();
      }
    });
  }
}
