<div #wrapper class="wrapper" [ngClass]="{ 'disabled': disabled, 'selected': selected }">
    <gc-icon class="icon" [svgIcon]="iconName" [color]="iconColor || null" *ngIf="iconName"></gc-icon>
    <div #labels class="labels">
        <div class="label" *ngFor="let label of labelsToShow; let i = index" [gcUnderline]="label.key">
            <span *ngIf="withKey" class="key">{{ label.key }}: </span>
            <span>{{ label.value }}</span>
            <span class="union" *ngIf="labelsToShow.length - 1 > i">AND</span>
        </div>    
    </div>    
</div>
