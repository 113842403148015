import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PollingService {
  private readonly startSubj = new ReplaySubject<void>(1);
  readonly start$ = this.startSubj.asObservable();

  private readonly stopSubj = new Subject<void>();
  readonly stop$ = this.stopSubj.asObservable();

  private readonly reloadAllSubj = new Subject<void>();
  readonly reloadAll$ = this.reloadAllSubj.pipe(debounceTime(1000));

  private readonly reloadSystemStatusSubj = new Subject<void>();
  readonly reloadSystemStatus$ = this.reloadSystemStatusSubj.asObservable();

  protected _arePollingsAllowed = false;
  get arePollingsAllowed(): boolean {
    return this._arePollingsAllowed;
  }

  startPollings(): void {
    this._arePollingsAllowed = true;
    this.startSubj.next();
  }

  stopPollings(): void {
    this._arePollingsAllowed = false;
    this.stopSubj.next();
  }

  reloadAll(): void {
    this.reloadAllSubj.next();
  }

  reloadSystemStatus(): void {
    this.reloadSystemStatusSubj.next();
  }
}
