export interface EnvironmentSpecification {
  production: boolean;
  version?: VersionSpecification;
}

export interface VersionSpecification {
  appVersion: string;
  buildJobId: string;
  buildTime: number;
  tag: string;
}
