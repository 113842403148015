import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ComponentRef,
  ChangeDetectorRef,
  ViewContainerRef,
  ComponentFactoryResolver,
  Injector,
} from '@angular/core';

import { ToastrConfig } from '../toastr-config';
import { ToastrContainerComponent } from '../toastr-container/toastr-container.component';

@Component({
  selector: 'gc-toastr-portal',
  template: '<ng-container #toastsStack></ng-container>',
  styleUrls: ['./toastr-stack.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastrStackComponent {
  @ViewChild('toastsStack', { read: ViewContainerRef, static: true }) toastsStack?: ViewContainerRef;

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly injector: Injector,
    private cd: ChangeDetectorRef,
  ) {}

  addToast(config: ToastrConfig): ComponentRef<ToastrContainerComponent> {
    const injector = Injector.create({
      parent: this.injector,
      providers: [{ provide: ToastrConfig, useValue: config }],
    });
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ToastrContainerComponent);
    const componentRef = this.viewContainerRef.createComponent<ToastrContainerComponent>(componentFactory, undefined, injector);

    this.toastsStack?.insert(componentRef.hostView);
    this.cd.markForCheck();

    return componentRef;
  }
}
