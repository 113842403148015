import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TwoFaQrComponent } from './two-fa-qr.component';

@NgModule({
  imports: [CommonModule],
  declarations: [TwoFaQrComponent],
  exports: [TwoFaQrComponent],
})
export class AuthenticationUiTwoFaQrModule {}
