import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SystemLogItem, SystemLogPermission } from '@guardicore-ui/shared/data';

import { NotificationsState } from './entities';
import { NotificationsActionEvent } from './notifications-actions';

@Component({
  selector: 'gc-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent {
  @Input() notificationsPermissions: SystemLogPermission | null = null;
  @Input() items: SystemLogItem[] | null = null;
  @Input() set notifications(value: NotificationsState | null) {
    if (value) {
      const { totalCount, ids } = value;

      if (ids && totalCount > ids.length) {
        this.navigateToSystemLogText = `Show all ${totalCount} notifications`;
      }
    }
  }

  @Output() readonly action = new EventEmitter<NotificationsActionEvent>();

  navigateToSystemLogText = 'Go to System Log';

  public handleDismissAll(): void {
    this.action.emit({ action: 'dismissAll' });
  }

  public onDismissNotification(notification: SystemLogItem): void {
    this.action.emit({ action: 'dismissOne', notification });
  }
}
