import { StrippedPage, Context, UserData } from './';

declare global {
  interface Window {
    analytics: Analytics;
  }
}

export interface Analytics {
  SNIPPET_VERSION: string;
  addDestinationMiddleware: () => unknown;
  addIntegrationMiddleware: () => unknown;
  addSourceMiddleware: () => unknown;
  alias: () => unknown;
  debug: () => unknown;
  factory: (e?: unknown) => unknown;
  group: () => unknown;
  identify: (userId: string, userData: UserData, context: Context) => unknown;
  invoked: boolean;
  load: (key: string, event?: unknown) => unknown;
  methods: string[];
  off: () => unknown;
  on: () => unknown;
  once: () => unknown;
  page: (properties: StrippedPage, context: Context) => unknown;
  pageview: () => unknown;
  ready: () => unknown;
  reset: () => unknown;
  setAnonymousId: () => unknown;
  track: (eventName: string, properties: Record<string, string>, context: Context) => unknown;
  trackClick: () => unknown;
  trackForm: () => unknown;
  trackLink: () => unknown;
  trackSubmit: () => unknown;
}

export interface GeneralProperties {
  customerName?: string;
  centraVersion?: string;
  usernameOrEmail?: string;
  path?: string;
}
