import {
  ViewContainerRef,
  Renderer2,
  ElementRef,
  Input,
  Directive,
  ComponentFactoryResolver,
  Injector,
  Inject,
  TemplateRef,
  OnChanges,
  Optional,
} from '@angular/core';
import { AnchorCategory, ANCHOR_CATEGORY, PopoverAnchorService, PopoverHoverAnchorDirective } from '@guardicore-ui/ui/popovers/popover';
import { filter, takeUntil } from 'rxjs/operators';

import { TextAlign } from './text-align';
import { TooltipContentComponent } from './tooltip-content/tooltip-content.component';

const DEFAULT_TOOLTIP_SHOW_DELAY = 380;
const DEFAULT_TOOLTIP_HIDE_DELAY = 75;

@Directive({
  selector: '[gcTooltip]',
  providers: [{ provide: ANCHOR_CATEGORY, useValue: 'hover' }],
})
export class TooltipDirective extends PopoverHoverAnchorDirective implements OnChanges {
  private contentComponent: TooltipContentComponent;

  @Input('gcTooltip')
  set textInput(value: string | string[] | undefined | null) {
    if (!value) {
      this.contentComponent.text = undefined;
      this.preventOpening = true;

      return;
    }

    this.preventOpening = false;
    this.contentComponent.text = typeof value === 'string' ? value.split('\n') : value;
  }

  @Input() set textAlign(value: TextAlign) {
    this.contentComponent.textAlign = value;
  }

  constructor(
    override readonly host: ElementRef<HTMLElement>,
    override readonly viewContainerRef: ViewContainerRef,
    renderer: Renderer2,
    protected override readonly componentFactoryResolver: ComponentFactoryResolver,
    protected override readonly injector: Injector,
    @Inject(ANCHOR_CATEGORY) protected override readonly category: AnchorCategory,
    @Optional() popoverAnchorService: PopoverAnchorService,
  ) {
    super(host, viewContainerRef, renderer, componentFactoryResolver, injector, category, popoverAnchorService);
    this.withLeaf = { size: 6, position: 50 };
    this.panelCssClass = 'tooltip';
    this.delays = { show: DEFAULT_TOOLTIP_SHOW_DELAY, hide: DEFAULT_TOOLTIP_HIDE_DELAY };
    const componentFactory = componentFactoryResolver.resolveComponentFactory(TooltipContentComponent);
    const componentRef = viewContainerRef.createComponent<TooltipContentComponent>(componentFactory, undefined, injector);

    this.contentComponent = componentRef.instance;

    this.contentComponent.tooltipContentTemplate$
      .pipe(
        filter((templateRef): templateRef is TemplateRef<unknown> => !!templateRef),
        takeUntil(this.destroy$),
      )
      .subscribe(templateRef => {
        this.templateRef = templateRef;
        super.ngOnChanges();
      });
  }

  override ngOnChanges(): void {
    if (!this.contentComponent.tooltipContentTemplate) {
      return;
    }

    super.ngOnChanges();
  }
}
