import { ParamMap } from '@angular/router';

export function areParamMapsEqual(paramMap1?: ParamMap | null, paramMap2?: ParamMap | null): boolean {
  if (!paramMap1 || !paramMap2) {
    return false;
  }

  if (paramMap1.keys.length !== paramMap2.keys.length) {
    return false;
  }

  for (const key of paramMap1.keys) {
    if (!key || paramMap1.get(key) !== paramMap2.get(key)) {
      return false;
    }
  }

  return true;
}
