import { QueryList } from '@angular/core';
import { Focusable, CanDisable } from '@guardicore-ui/shared/data';

export function focusNextQueryListItem<T extends Focusable & CanDisable>(currentIndex: number, itemList: QueryList<T>): number {
  const currentItem = itemList.get(currentIndex);

  if (currentItem && currentIndex === itemList.length - 1) {
    currentItem.isFocused = true;

    return currentIndex;
  }

  if (itemList.get(currentIndex + 1)?.disabled) {
    return focusNextQueryListItem<T>(currentIndex + 1, itemList);
  }

  const item = itemList.get(currentIndex + 1);

  if (item) {
    item.isFocused = true;

    return currentIndex + 1;
  }

  return currentIndex;
}

export function focusPreviousQueryListItem<T extends Focusable & CanDisable>(currentIndex: number, itemList: QueryList<T>): number {
  const currentItem = itemList.get(currentIndex);

  if (currentItem && currentIndex === 0) {
    currentItem.isFocused = true;

    return currentIndex;
  }

  if (itemList.get(currentIndex - 1)?.disabled) {
    return focusPreviousQueryListItem<T>(currentIndex - 1, itemList);
  }

  const item = itemList.get(currentIndex - 1);

  if (item) {
    item.isFocused = true;

    return currentIndex - 1;
  }

  return currentIndex;
}
