import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogData } from '../dialog/dialog-data';

@Component({
  selector: 'gc-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericModalComponent {
  content?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: DialogData = {},
  ) {
    this.content = data.content;
  }
}
