import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiButtonModule } from '@guardicore-ui/ui/button';
import { UiIconModule } from '@guardicore-ui/ui/icon';
import { UiTooltipModule } from '@guardicore-ui/ui/popovers/tooltip';

import { MsspDropdownComponent } from './mssp-dropdown/mssp-dropdown.component';

@NgModule({
  imports: [CommonModule, UiIconModule, UiTooltipModule, UiTooltipModule, UiButtonModule],
  declarations: [MsspDropdownComponent],
  exports: [MsspDropdownComponent],
})
export class MsspUiModule {}
