import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  Inject,
  Injector,
  Renderer2,
  ViewContainerRef,
  OnDestroy,
  Input,
  TemplateRef,
  OnChanges,
  Optional,
} from '@angular/core';
import { detectHover } from '@guardicore-ui/ui/common';
import { Subject, combineLatest, of } from 'rxjs';
import { takeUntil, switchMap, map, debounceTime, distinctUntilChanged, delay } from 'rxjs/operators';

import { ANCHOR_CATEGORY } from './anchor-category';
import { PopoverAnchorBase } from './popover-anchor-base';
import { PopoverAnchorService } from './popover-anchor.service';
import { AnchorCategory } from './popover-configuration';

@Directive({
  selector: '[gcPopoverHoverAnchor]',
  providers: [{ provide: ANCHOR_CATEGORY, useValue: 'hover' }],
})
export class PopoverHoverAnchorDirective extends PopoverAnchorBase implements OnChanges, OnDestroy {
  private readonly destroyHoverDetection$ = new Subject<void>();

  @Input('gcPopoverHoverAnchor') templateRef?: TemplateRef<unknown>;

  constructor(
    protected override readonly host: ElementRef,
    protected override readonly viewContainerRef: ViewContainerRef,
    renderer: Renderer2,
    protected override readonly componentFactoryResolver: ComponentFactoryResolver,
    protected override readonly injector: Injector,
    @Inject(ANCHOR_CATEGORY) protected override readonly category: AnchorCategory,
    @Optional() popoverAnchorService: PopoverAnchorService,
  ) {
    super(host, viewContainerRef, renderer, componentFactoryResolver, injector, category, undefined, popoverAnchorService);
    this.configurationSubj
      .pipe(
        switchMap(configuration => {
          this.destroyHoverDetection$.next();
          const isAnchorHovered$ = detectHover(this.host.nativeElement, this.destroyHoverDetection$).pipe(
            takeUntil(this.destroyHoverDetection$),
          );

          return combineLatest([isAnchorHovered$, this.popover.isHovered$]).pipe(
            map(([isAnchorHovered, isPopoverHovered]) => isAnchorHovered || isPopoverHovered),
            switchMap(value => of(value).pipe(delay(value ? configuration.delays.show : configuration.delays.hide))),
            debounceTime(1),
            distinctUntilChanged(),
          );
        }),
      )
      .subscribe(isHovered => this._toggle(isHovered));
  }

  override ngOnChanges(): void {
    if (!this.templateRef) {
      throw new Error('You need to provide content template');
    }

    super.ngOnChanges();
    this.popover.template = this.templateRef;
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroyHoverDetection$.next();
  }
}
