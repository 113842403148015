import { ComponentHealthResponse, ComponentHealth, ServiceStatus } from '@guardicore-ui/shared/data';

export function formatComponentHealth(originalHealth: ComponentHealthResponse): ComponentHealth {
  const health: ComponentHealth = {};

  Object.entries(originalHealth || {}).forEach(([key, value]) => {
    if (!value) {
      return;
    }

    health[key] = {
      applicationStatus: value['application-status'],
    };

    if (value['services-status']) {
      const servicesStatus: { [key: string]: ServiceStatus } = {};

      Object.entries(value['services-status'] || {}).forEach(([serviceKey, serviceValue]) => {
        servicesStatus[serviceKey] = {
          serviceStatus: serviceValue['service-status'],
          msg: serviceValue.msg,
        };
      });

      health[key].servicesStatus = servicesStatus;
    }
  });

  return health;
}
