import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap, distinctUntilChanged } from 'rxjs/operators';

const NOISE_REDUCTION = 10;

export function detectHover(host: HTMLElement, destroyScheduler$: Subject<void>): Observable<boolean> {
  let isHovered = false;
  const isHoveredSubj = new BehaviorSubject<boolean>(isHovered);

  fromEvent<void>(host, 'mouseenter')
    .pipe(
      filter(() => !isHovered),
      tap(() => {
        isHovered = true;
        isHoveredSubj.next(isHovered);
      }),
      takeUntil(destroyScheduler$),
    )
    .subscribe({ complete: () => isHoveredSubj.complete() });

  fromEvent<void>(host, 'mouseleave')
    .pipe(
      tap(() => {
        isHovered = false;
        isHoveredSubj.next(isHovered);
      }),
      takeUntil(destroyScheduler$),
    )
    .subscribe({ complete: () => isHoveredSubj.complete() });

  return isHoveredSubj.pipe(debounceTime(NOISE_REDUCTION), distinctUntilChanged());
}
