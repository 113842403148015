import { formatDate } from '@angular/common';

export const DATE_RANGE_LABEL = [
  'Yesterday',
  'Last 3 days',
  'Last 7 days',
  'Last 30 days',
  'Last quarter',
  'Last year',
  'Custom range',
] as const;

export type DateRangeLabelTypes = typeof DATE_RANGE_LABEL[number];

export type DatePickerCallback = (val: number[] | undefined) => unknown;

export const YEAR = 365;

export const calculateTime = (days: number): number => days * 23 * 59 * 59 * 1000;

export const calculateDateString = (timestamp: number): string =>
  !timestamp ? '' : formatDate(timestamp, 'yyyy-MM-dd', 'en_US', 'Asia/Jerusalem');

export const timeZoneOffset = (): number => new Date().getTimezoneOffset() * 60 * 1000;

export const endOfDay = (): number => {
  const now = new Date().getTime();
  const dayOffset = 86400000;
  const startOfDay = now - (now % dayOffset);

  return startOfDay + dayOffset;
};

export interface DateRangeItem {
  label: DateRangeLabelTypes;
  start: number;
  end: number;
  disabled?: boolean;
}

export const DEFAULT_DATE_PICKER_RANGES: DateRangeItem[] = [
  {
    label: 'Yesterday',
    start: new Date(endOfDay() - calculateTime(1)).setHours(0, 0, 0, 0),
    end: new Date(endOfDay() - calculateTime(1)).setHours(23, 59, 59, 999),
  },
  {
    label: 'Last 3 days',
    start: new Date(endOfDay() - calculateTime(3)).setHours(0, 0, 0, 0),
    end: new Date().setHours(23, 59, 59, 999),
  },
  {
    label: 'Last 7 days',
    start: new Date(endOfDay() - calculateTime(7)).setHours(0, 0, 0, 0),
    end: new Date().setHours(23, 59, 59, 999),
  },
  {
    label: 'Last 30 days',
    start: new Date(endOfDay() - calculateTime(30)).setHours(0, 0, 0, 0),
    end: new Date().setHours(23, 59, 59, 999),
  },
  {
    label: 'Last quarter',
    start: new Date(new Date().getFullYear(), new Date().getMonth() - 3, 1).setHours(0, 0, 0, 0),
    end: new Date(new Date().getFullYear(), new Date().getMonth(), 0).setHours(23, 59, 59, 999),
  },
  {
    label: 'Last year',
    start: new Date().setFullYear(new Date().getFullYear() - 1),
    end: new Date().setHours(0, 0, 0, 0),
  },
];
