import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { HybridIframeDatasource } from '@guardicore-ui/hybrid/ui';
import { UserAgreementAction } from '@guardicore-ui/shared/data';
import { SystemStatusQuery } from '@guardicore-ui/shared/system-status';
import { distinctUntilNotEqual } from '@guardicore-ui/shared/utils';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CoreState } from './core-state';
import { CoreStore } from './core.store';

const isEvaluationAgreementPassed = (state: CoreState): boolean =>
  !state.flags.isEvaluationMode || state.flags.isEvaluationAgreementAccepted;

@Injectable({ providedIn: 'root' })
export class CoreQuery extends Query<CoreState> implements HybridIframeDatasource {
  readonly isLoggedIn$ = this.select(state => state.flags?.isLoggedIn);
  readonly isApiConnectionFailed$ = this.select(state => state.isApiConnectionFailed);
  readonly isInDebugMode$ = this.select(state => state.flags?.isInDebugMode);
  readonly currentViewName$ = this.select(state => state.routeData?.viewName);
  readonly currentViewQuickLinks$ = this.select(state => state.viewQuickLinks);
  readonly isResetPasswordRequired$ = this.select(state => state.flags.isPasswordExpired || state.flags.isPasswordTemporary);
  readonly isIframeCanBeLoaded$ = this.select(state => {
    const canLoad =
      state.flags.isLoggedIn && !state.flags.isPasswordExpired && !state.flags.isPasswordTemporary && state.flags.isEulaAccepted;

    return canLoad && isEvaluationAgreementPassed(state);
  });

  readonly isOldAppLoading$ = combineLatest([this.select(state => state.flags?.isOldAppLoading), this.isLoggedIn$]).pipe(
    map(([isOldAppLoading]) => isOldAppLoading),
  );

  readonly isIframeCollapsed$ = this.select(state => state.flags?.isIframeCollapsed);

  readonly isEvaluationMode$ = this.select(state => state.flags.isEvaluationMode);

  readonly agreementActions$ = this.select<UserAgreementAction[] | null>(state => {
    const actions: UserAgreementAction[] = [];

    if (!state.flags.isLoggedIn) {
      return null;
    }

    const flags = state.flags;

    if (flags.isEvaluationMode && flags.isEvaluationAgreementAccepted === false) {
      actions.push('accept_evaluation');
    }

    if (flags.isEulaAccepted === false) {
      actions.push('accept_eula');
    }

    return actions.length > 0 ? (actions as UserAgreementAction[]) : null;
  }).pipe(distinctUntilNotEqual());

  readonly areAgreementsRequired$ = combineLatest([this.isResetPasswordRequired$, this.agreementActions$]).pipe(
    filter(([isResetPasswordRequired, agreementActions]) => {
      if (isResetPasswordRequired || !this.getValue().flags.isLoggedIn) {
        return false;
      }

      return !!agreementActions;
    }),
    map(value => value[1] as UserAgreementAction[]),
  );

  readonly areRecoveryCodesRequired$ = combineLatest([
    this.isResetPasswordRequired$,
    this.agreementActions$,
    this.systemStatus.areRecoveryCodesRequired$,
  ]).pipe(
    filter(([isResetPasswordRequired, areAgreementsRequired, areRecoveryCodesRequired]) => {
      if (!this.getValue().flags.isLoggedIn) {
        return false;
      }

      return !isResetPasswordRequired && !areAgreementsRequired && !!areRecoveryCodesRequired;
    }),
  );

  readonly isNewNavbarIntroductionRequired$ = combineLatest([
    this.isResetPasswordRequired$,
    this.agreementActions$,
    this.systemStatus.isNewNavbarConfirmed$,
    this.isLoggedIn$,
  ]).pipe(
    filter(([isResetPasswordRequired, areAgreementsRequired, isNewNavbarConfirmed, isLoggedIn]) => {
      return !isResetPasswordRequired && !areAgreementsRequired && !isNewNavbarConfirmed && isLoggedIn;
    }),
  );

  readonly isMsspApplicable$ = this.select(state => state.flags.isMsspApplicable === true);
  readonly isMsspApplicable = (): boolean => this.getValue().flags.isMsspApplicable;
  readonly isIframeCollapsed = (): boolean => this.getValue().flags.isIframeCollapsed;
  readonly isResetPasswordRequired = (): boolean => this.getValue().flags.isPasswordExpired || this.getValue().flags.isPasswordTemporary;

  readonly isManagerCentra$ = this.select(state => state.flags.isManagerCentra);
  readonly isManagerCentra = (): boolean => this.getValue().flags.isManagerCentra;

  readonly routeData$ = this.select('routeData');

  readonly arePollingsAllowed$ = this.select(state => this.calcPollingsAllowed(state));
  readonly arePollingsAllowed = (): boolean => this.calcPollingsAllowed(this.getValue());
  readonly url = (): string | undefined => this.getValue().urlPath;
  readonly defaultUrl = (): string | undefined => this.getValue().defaultUrl;
  readonly savedUrlPath = (): string | undefined => this.getValue().savedUrlPath;
  constructor(protected override store: CoreStore, private readonly systemStatus: SystemStatusQuery) {
    super(store);
  }

  private calcPollingsAllowed(state: CoreState): boolean {
    const arePollingsAllowed =
      state.flags.isLoggedIn &&
      !state.flags.isPasswordExpired &&
      !state.flags.isPasswordTemporary &&
      state.flags.isEulaAccepted &&
      state.isApiConnectionFailed === null;

    return arePollingsAllowed && isEvaluationAgreementPassed(state);
  }
}
