import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { AuthenticationState, AuthenticationStore } from './authentication.store';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationQuery extends Query<AuthenticationState> {
  readonly authStatus$ = this.select(({ isLoggedIn, id }) => ({ isLoggedIn, id }));
  readonly isLoggedIn$ = this.select<boolean>(state => !!state.isLoggedIn);
  readonly id = (): string | undefined => this.getValue()?.id;
  readonly authData$ = this.select();
  readonly errorStatus = (): number => this.getValue()?.error?.status || 0;

  constructor(protected override store: AuthenticationStore) {
    super(store);
  }
}
