import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UiIconModule } from '@guardicore-ui/ui/icon';

import { CsvExportButtonComponent } from './csv-export-button/csv-export-button.component';
import { CsvExportDialogComponent } from './csv-export-dialog/csv-export-dialog.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, UiIconModule],
  declarations: [CsvExportDialogComponent, CsvExportButtonComponent],
  exports: [CsvExportDialogComponent, CsvExportButtonComponent],
})
export class UiCsvModule {}
