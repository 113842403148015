<gc-icon class="icon" [svgIcon]="iconName" [color]="iconColor || null" *ngIf="iconName && labels.length"></gc-icon>
<div #labelsSection class="labels-section">
  <gc-label class="label" *ngFor="let label of labels" [label]="label" [withKey]="withKey"></gc-label>
</div>
<span class="ellipsis" *ngIf="showEllipsis">...</span>
<div class="plus-section" *ngIf="hiddenAmount">+ {{ hiddenAmount }}</div>

<ng-template #popover>
  <div class="popover-wrapper">
    <gc-label class="label" *ngFor="let label of labels" [label]="label" [withKey]="withKey"></gc-label>
  </div>
</ng-template>
