export const ELLIPSIS_MAX_LENGTH = 16;
export const ELLIPSIS_CHUNK_LENGTH = 8;
const ELLIPSIS_TYPES = ['middle', 'end'] as const;

export type EllipsisType = typeof ELLIPSIS_TYPES[number];

export class EllipsisConfiguration {
  firstChunkLength: number;
  lastChunkLength: number;
  maxLength: number;

  constructor(firstChunkLength = ELLIPSIS_CHUNK_LENGTH, lastChunkLength = ELLIPSIS_CHUNK_LENGTH, maxLength = ELLIPSIS_MAX_LENGTH) {
    if (firstChunkLength < 0) {
      throw new Error('Cannot set negative values for firstChunkLength in EllipsisConfiguration');
    }

    if (lastChunkLength < 0) {
      throw new Error('Cannot set negative values for lastChunkLength in EllipsisConfiguration');
    }

    if (firstChunkLength + lastChunkLength > maxLength) {
      throw new Error('firstChunkLength + lastChunkLength exceeds maxLength in EllipsisConfiguration');
    }

    this.firstChunkLength = firstChunkLength;
    this.lastChunkLength = lastChunkLength;
    this.maxLength = maxLength;
  }

  public isEllipsisRequired(value: string): boolean {
    return value?.length > this.maxLength;
  }
}
