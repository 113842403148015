import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiCommonModule } from '@guardicore-ui/ui/common';
import { UiIconModule } from '@guardicore-ui/ui/icon';
import { UiPopoverModule } from '@guardicore-ui/ui/popovers/popover';
import { UiTooltipModule } from '@guardicore-ui/ui/popovers/tooltip';
import { UiTextCopyModule } from '@guardicore-ui/ui/text-copy';

import { TextComponent } from './text.component';
import { UnderlineDirective } from './underline.directive';

@NgModule({
  imports: [CommonModule, UiPopoverModule, UiTooltipModule, UiCommonModule, UiIconModule, UiTextCopyModule],
  declarations: [TextComponent, UnderlineDirective],
  exports: [TextComponent, UnderlineDirective],
})
export class UiTextModule {}
