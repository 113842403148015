import { OperationItem } from '@guardicore-ui/shared/data';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Subject } from 'rxjs';

import { RowOperationEvent } from './row-operation-event';

export interface GcRowOperationsParams extends ICellRendererParams {
  operations: OperationItem<string>[];
  rowOperation$: Subject<RowOperationEvent>;
}
