import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ManageTwoFaFacade, UserTwoFAQRCode, UserTwoFAStatus, UserTwoFAStatusUpdate } from '@guardicore-ui/authentication/domain';
import { BehaviorSubject, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'gc-manage-two-fa',
  templateUrl: './manage-two-fa.component.html',
  styleUrls: ['./manage-two-fa.component.scss'],
})
export class ManageTwoFaComponent implements OnInit, OnDestroy {
  showRecoveryCodes = new EventEmitter();

  readonly errorMessage$ = new BehaviorSubject<string>('');
  readonly showQRCode$ = new BehaviorSubject<boolean>(false);

  form: FormGroup = new FormGroup({});
  userTwoFAStatus?: UserTwoFAStatus;
  userTwoFAQRCode?: UserTwoFAQRCode;
  twoFactorAuthPolicyEnforced?: boolean;
  twoFactorAuthEnabled?: boolean;
  successMessageMode = false;
  loading = true;
  qrCodeLoading = true;

  private readonly destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { twoFactorAuthPolicyEnforced?: boolean },
    public readonly dialogRef: MatDialogRef<ManageTwoFaComponent>,
    private readonly manageTwoFAFacade: ManageTwoFaFacade,
    private readonly fb: FormBuilder,
  ) {
    this.twoFactorAuthPolicyEnforced = data.twoFactorAuthPolicyEnforced;
  }

  ngOnInit(): void {
    /**
     * Get value of twoFactorAuthPolicyEnforced from System Status
     */
    this.manageTwoFAFacade
      .getTwoFAStatus()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe((status: UserTwoFAStatus) => {
        this.userTwoFAStatus = status;
        this.twoFactorAuthEnabled = status.require2fa;
        this.setUpFormControls();
        this.loading = false;

        if (!this.userTwoFAStatus.require2fa && !this.userTwoFAStatus.isRadius2fa) {
          this.updateQRCode();
        }
      });

    this.dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        if (this.successMessageMode && this.twoFactorAuthEnabled) {
          this.showRecoveryCodes.emit();
        }
      });
  }

  /**
   * Set form controls according to mode
   */
  private setUpFormControls(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const controls: { [key: string]: any } = this.twoFactorAuthEnabled
      ? { password: ['', [Validators.required]] }
      : { verificationCode: ['', [Validators.required]] };

    this.form = this.fb.group(controls);
    this.form.valueChanges
      .pipe(
        tap(() => this.errorMessage$.next('')),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  /**
   * Get QR Code values
   */
  private updateQRCode(): void {
    this.qrCodeLoading = true;
    this.manageTwoFAFacade
      .getTwoFAQRCode()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(data => {
        this.userTwoFAQRCode = data;
        this.qrCodeLoading = false;
        this.showQRCode$.next(!this.userTwoFAStatus?.isRadius2fa && !!this.userTwoFAQRCode.qrCode && !!this.userTwoFAQRCode.secretKey);
      });
  }

  /**
   * Submit form with reset or according to Two-FA status
   */
  submit(reset = false): void {
    let status: UserTwoFAStatusUpdate;

    // Disable or Reset Two-FA
    if (this.twoFactorAuthEnabled) {
      if (this.form.invalid && this.form.get('password')?.errors) {
        this.errorMessage$.next('Password is required');

        return;
      }

      status = {
        password: this.getFormTrimmedValue('password'),
        verificationCode: '',
        require2fa: reset ? 'reset' : false,
      };

      // Enable Two-FA
    } else {
      if (this.form.invalid && this.form.get('verificationCode')?.errors) {
        this.errorMessage$.next('Verification code is required');

        return;
      }

      status = {
        password: '',
        verificationCode: this.getFormTrimmedValue('verificationCode'),
        require2fa: true,
      };
    }

    this.setTwoFAStatus(status, reset);
  }

  private getFormTrimmedValue(key: string): string {
    return (this.form.get(key)?.value as string).trim();
  }

  private setTwoFAStatus(status: UserTwoFAStatusUpdate, reset = false): void {
    this.manageTwoFAFacade
      .setTwoFAStatus(status)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        response => {
          if (reset) {
            this.twoFactorAuthEnabled = false;
            this.form.reset();
            this.setUpFormControls();
            this.updateQRCode();
          } else {
            this.twoFactorAuthEnabled = !!response.require2fa;
            this.successMessageMode = true;
          }
        },
        (error: HttpErrorResponse) => {
          this.errorMessage$.next(error.error);
        },
      );
  }

  public close(): void {
    this.dialogRef.close();
    this.form.reset();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.dialogRef.close();
  }

  getVerificationCodePlaceholder(): string {
    return !this.userTwoFAStatus?.isRadius2fa ? 'Enter 6-digit code' : 'Enter PIN code';
  }

  getSuccessMessageStatus(): string {
    return this.twoFactorAuthEnabled ? 'enabled' : 'disabled';
  }
}
