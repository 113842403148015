import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { AnalyticsDirective } from './analytics.directive';
import { TRACKING_CONFIG_TOKEN, TrackingConfig } from './entities';
import { AnalyticsService } from './infrastructure';

@NgModule({
  imports: [CommonModule],
  declarations: [AnalyticsDirective],
  exports: [AnalyticsDirective],
})
export class AnalyticsModule {
  static forRoot(config: TrackingConfig): ModuleWithProviders<AnalyticsModule> {
    return {
      ngModule: AnalyticsModule,
      providers: [{ provide: TRACKING_CONFIG_TOKEN, useValue: config }, AnalyticsService],
    };
  }
}
