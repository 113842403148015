/* eslint-disable @angular-eslint/no-host-metadata-property */
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { BooleanInput } from '@angular/cdk/coercion';
import {
  Component,
  ChangeDetectionStrategy,
  AfterContentInit,
  ContentChild,
  ContentChildren,
  QueryList,
  ChangeDetectorRef,
  HostBinding,
  Input,
  OnDestroy,
  ElementRef,
} from '@angular/core';
import { MAT_FORM_FIELD } from '@angular/material/form-field';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FormFieldControl } from '../form-field-control';
import { PREFIX, PrefixDirective } from '../prefix.directive';
import { SUFFIX, SuffixDirective } from '../suffix.directive';

@Component({
  selector: 'gc-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  host: {
    class: 'gc-input',
    '(click)': 'control?.containerClick()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MAT_FORM_FIELD, useExisting: FormFieldComponent }],
})
export class FormFieldComponent implements AfterContentInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private _fullWidth = false;
  private _forceHideX = false;
  private _showX = false;
  showSearch = false;

  @Input() get forceHideX(): boolean {
    return this._forceHideX;
  }

  set forceHideX(value: BooleanInput) {
    this._forceHideX = coerceBooleanProperty(value);
  }

  @Input()
  @HostBinding('class.full-width')
  get fullWidth(): boolean {
    return this._fullWidth;
  }

  set fullWidth(value: BooleanInput) {
    this._fullWidth = coerceBooleanProperty(value);
  }

  @ContentChild(FormFieldControl, { static: true }) control?: FormFieldControl;
  @ContentChildren(PREFIX, { descendants: true }) prefixes!: QueryList<PrefixDirective>;
  @ContentChildren(SUFFIX, { descendants: true }) suffixes!: QueryList<SuffixDirective>;

  get showX(): boolean {
    return this._showX && !this.forceHideX;
  }

  @HostBinding('class.gc-input-focused')
  get isFocused(): boolean {
    return !!this.control?.isFocused;
  }

  @HostBinding('class.gc-input-invalid')
  get isInErrorState(): boolean {
    return !!this.control?.isInErrorState;
  }

  @HostBinding('class.gc-input-disabled')
  get isDisabled(): boolean {
    return !!this.control?.disabled;
  }

  // For compatibility with mat-form-field
  get _control(): FormFieldControl | undefined {
    return this.control;
  }

  constructor(private readonly cd: ChangeDetectorRef, readonly _elementRef: ElementRef) {}

  ngAfterContentInit(): void {
    if (!this.control) {
      throw new Error('Form field must contain form field control.');
    }

    this.showSearch = this.control.type === 'search';
    this.setShowX(this.control.hasValue);

    this.control.stateChanges.subscribe(() => {
      this.showSearch = this.control?.type === 'search';
      this.setShowX(!!this.control?.hasValue);
    });

    merge(this.prefixes?.changes, this.suffixes?.changes)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.cd.markForCheck());
  }

  clearValue(): void {
    if (!this.control) {
      return;
    }

    this.control.value = '';
  }

  // For compatibility with mat-form-field
  getLabelId(): string | null {
    return null;
  }

  // For compatibility with mat-form-field
  getConnectedOverlayOrigin(): ElementRef {
    return this._elementRef;
  }

  private setShowX(value: boolean): void {
    this._showX = value;
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
