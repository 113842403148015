import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthenticationFacade } from '@guardicore-ui/authentication/domain';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private readonly auth: AuthenticationFacade) {}
  handleError(error: Error): void {
    // eslint-disable-next-line no-console
    console.log(error);
    if (error instanceof HttpErrorResponse && error.status === 403) {
      this.auth.logout();
    }
  }
}
