import { Injectable } from '@angular/core';
import { FilterOptionsSource, FilterOptions } from '@guardicore-ui/filters/shared';
import { Observable } from 'rxjs';

import { FiltersService } from '../infrastructure';

@Injectable()
export class FiltersOptionsSource extends FilterOptionsSource {
  constructor(private readonly filtersService: FiltersService) {
    super();
  }

  getFilterOptions(id: string, additionalParams?: Record<string, string | boolean | number>): Observable<FilterOptions> {
    return this.filtersService.getFilterOptions(id, additionalParams);
  }

  search(search: string, id: string): Observable<FilterOptions> {
    return this.filtersService.search(id, search);
  }

  loadMore(offset: number, id: string): Observable<FilterOptions> {
    return this.filtersService.loadMore(id, offset);
  }
}
