import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UserTwoFAQRCode, UserTwoFAStatus, UserTwoFAStatusUpdate, UserTwoFAStatusUpdateResponse, NewRecoveryCodes } from '../entities';
import { AuthenticationService } from '../infrastructure';

@Injectable({
  providedIn: 'root',
})
export class ManageTwoFaFacade {
  constructor(private readonly authenticationService: AuthenticationService) {}

  public getTwoFAStatus(): Observable<UserTwoFAStatus> {
    return this.authenticationService.getUserTwoFAStatus();
  }

  public setTwoFAStatus(status: UserTwoFAStatusUpdate): Observable<UserTwoFAStatusUpdateResponse> {
    return this.authenticationService.setUserTwoFAStatus(status);
  }

  public getTwoFAQRCode(): Observable<UserTwoFAQRCode> {
    return this.authenticationService.getUserTwoFAQRCode();
  }

  public getRecoveryCodes(): Observable<NewRecoveryCodes> {
    return this.authenticationService.getUserRecoveryCodes();
  }
}
