import { FilterOptionsEntity } from '@guardicore-ui/shared/data';

export function replaceValue(entities: FilterOptionsEntity[], valueFrom?: string, valueTo?: string): FilterOptionsEntity[] {
  if (!entities.length) {
    return [];
  }

  if (!valueFrom || !valueTo) {
    return entities;
  }

  const result = entities.slice(0);

  for (let i = 0; i < entities.length; i++) {
    if (result[i].value === valueFrom) {
      result[i].value = valueTo;
    }
  }

  return result;
}
