import { Constructor } from '@guardicore-ui/shared/data';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, Observable, switchMap } from 'rxjs';

export interface HasNestedEllipsis {
  hasNestedEllipsis$: Observable<boolean>;
  addNestedEllipsisElements(elements: HasNestedEllipsis[]): void;
  customEllipsisFlag$: BehaviorSubject<boolean>;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function mixinHasNestedEllipsis<T extends Constructor<{}>>(base: T): Constructor<HasNestedEllipsis> & T {
  return class extends base {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(...args: any[]) {
      super(...args);
    }

    private readonly updateSubscription$ = new BehaviorSubject<number>(0);
    protected readonly _ellipsisListeningElements: HasNestedEllipsis[] = [];
    readonly customEllipsisFlag$ = new BehaviorSubject<boolean>(false);

    readonly hasNestedEllipsis$ = this.updateSubscription$.pipe(
      switchMap(() => {
        return combineLatest([this.customEllipsisFlag$, ...this._ellipsisListeningElements.map(element => element.hasNestedEllipsis$)]);
      }),
      map(arr => arr.some(value => value)),
      distinctUntilChanged(),
    );

    addNestedEllipsisElements(elements: HasNestedEllipsis[]): void {
      this._ellipsisListeningElements.push(...elements);
      this.updateSubscription$.next(this.updateSubscription$.value + 1);
    }
  };
}
