import { Directive, ElementRef, HostListener, Input, Optional } from '@angular/core';

import { AnalyticsEventsTypes } from './entities';
import { AnalyticsService } from './infrastructure';

@Directive({
  selector: '[gcAnalytics]',
})
export class AnalyticsDirective {
  @Input('gcAnalytics') eventName!: string;
  @Input() gcAnalyticsProps?: { [key: string]: string };
  @Input() gcAnalyticsEvents: AnalyticsEventsTypes[] = ['click'];
  @Input() gcAnalyticsEventSuffix?: { [key: string]: string };

  constructor(private el: ElementRef, @Optional() private analyticsService?: AnalyticsService) {}

  @HostListener('click', ['$event'])
  handleClick(event: Event): void {
    if (this.gcAnalyticsEvents.includes('click')) {
      this.sendAnalytics(event);
    }
  }

  @HostListener('mouseenter', ['$event'])
  handleMouseenter(event: Event): void {
    if (this.gcAnalyticsEvents.includes('mouseenter')) {
      this.sendAnalytics(event);
    }
  }

  @HostListener('blur', ['$event'])
  handleBlur(event: Event): void {
    if (this.gcAnalyticsEvents.includes('blur')) {
      this.sendAnalytics(event);
    }
  }

  @HostListener('focus', ['$event'])
  handleFocus(event: Event): void {
    if (this.gcAnalyticsEvents.includes('focus')) {
      this.sendAnalytics(event);
    }
  }

  private sendAnalytics(event: Event): void {
    const eventName = this.gcAnalyticsEventSuffix ? `${this.eventName} ${this.gcAnalyticsEventSuffix[event.type]}` : this.eventName;

    this.analyticsService?.track(eventName, this.gcAnalyticsProps);
  }
}
