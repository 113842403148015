import { OverlayRef } from '@angular/cdk/overlay';
import { ClickState } from '@guardicore-ui/ui/click-observer';
import { Observable, ReplaySubject } from 'rxjs';

export class PopoverRefV2<C, InputData = unknown, OutputData = unknown> {
  protected _input?: InputData;
  protected readonly inputSubject$ = new ReplaySubject<InputData>();
  protected readonly outputSubject$ = new ReplaySubject<OutputData>();
  protected readonly afterDismissSubject$ = new ReplaySubject<void>();

  readonly afterDismiss$ = this.afterDismissSubject$.asObservable();
  clickState$?: Observable<ClickState>;

  /**
   * The instance of the component making up the content of the popover.
   */
  componentInstance?: C;
  containerSize$?: Observable<DOMRect>;

  constructor(protected readonly overlayRef: OverlayRef) {}

  dismiss(): void {
    this.destroy();
  }

  protected destroy(): void {
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.dispose();
    }

    this.inputSubject$.complete();
    this.outputSubject$.complete();
    this.afterDismissSubject$.next();
    this.afterDismissSubject$.complete();
  }
}
