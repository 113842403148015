import { FiltersListValue } from '@guardicore-ui/shared/data';
import { Observable } from 'rxjs';

import { FilterOptions } from './filter-options';

export abstract class FilterOptionsSource {
  abstract getFilterOptions(id?: string, additionalParams?: Record<string, FiltersListValue>): Observable<FilterOptions>;
  abstract search(search: string, id?: string): Observable<FilterOptions>;
  abstract loadMore(offset: number, id?: string): Observable<FilterOptions>;
}
