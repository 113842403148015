import { coerceBooleanProperty, BooleanInput } from '@angular/cdk/coercion';
import { Component, ChangeDetectionStrategy, Input, ContentChild, TemplateRef } from '@angular/core';
import { SortingOrder } from '@guardicore-ui/shared/data';

import { PinnedDirection } from './entities';

@Component({
  selector: 'gc-column',
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnComponent {
  protected _rowOperations = false;
  protected _rowExpand = false;
  protected _rowSelection: string[] | undefined = undefined;

  @Input() id?: string;
  @Input() fieldName?: string;
  @Input() sortField?: string;
  @Input() displayName?: string;
  @Input() defaultSortingOrder?: SortingOrder;
  @Input() defaultSortingPriority?: number;
  @Input() isSortable = false;
  @Input() isResizable = false;
  @Input() exactWidth?: number;
  @Input() minWidth?: number;
  @Input() maxWidth?: number;
  @Input() autoHeight = false;
  @Input() pinDirection?: PinnedDirection;
  @Input() isFlex = 1;
  @Input() headerTooltip?: string;
  @Input() removeDefaultPadding?: boolean;
  @Input()
  get rowOperations(): boolean {
    return this._rowOperations;
  }

  set rowOperations(value: BooleanInput) {
    this._rowOperations = coerceBooleanProperty(value);
  }

  @Input() // if array is given than it will add those fields to the selection data
  get rowSelection(): string[] | undefined {
    return this._rowSelection;
  }

  set rowSelection(value: string | string[] | undefined) {
    this._rowSelection = value === '' ? [] : Array.isArray(value) ? value : undefined;
  }

  @Input()
  get rowExpand(): boolean {
    return this._rowExpand;
  }

  set rowExpand(value: BooleanInput) {
    this._rowExpand = coerceBooleanProperty(value);
  }

  @ContentChild(TemplateRef) content?: TemplateRef<unknown>;
}
