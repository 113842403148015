import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiPopoverModule } from '@guardicore-ui/ui/popovers/popover';

import { TooltipContentComponent } from './tooltip-content/tooltip-content.component';
import { TooltipDirective } from './tooltip.directive';

@NgModule({
  declarations: [TooltipDirective, TooltipContentComponent],
  exports: [TooltipDirective],
  imports: [CommonModule, UiPopoverModule],
})
export class UiTooltipModule {}
