import { Query } from '@datorama/akita';
import { SelectionData } from '@guardicore-ui/shared/api';
import { FilterParameters, RowDataObject, sortStateToSortParam } from '@guardicore-ui/shared/data';
import { distinctUntilNotEqual, isObjectsEmpty } from '@guardicore-ui/shared/utils';
import { combineLatest, map } from 'rxjs';

import { GridComponentState } from './grid-component-state';
import { GridComponentStore } from './grid-component.store';
import { SelectedRowsState } from '../entities/row-selection';

export abstract class GridFeatureQuery<T extends RowDataObject = RowDataObject> extends Query<GridComponentState<T>> {
  readonly error$ = this.selectError();
  readonly busy$ = combineLatest([this.select('busy'), this.selectLoading()]).pipe(map(([busy, loading]) => busy || loading));
  readonly pageStatus$ = this.select('pageStatus');
  readonly selectedRows$ = this.select('selectedRows');
  readonly rowData$ = this.select('rowData').pipe(distinctUntilNotEqual());
  readonly isNewRowOpen$ = combineLatest([this.select('isNewRowOpen'), this.busy$]).pipe(
    map(([busy, isNewRow]) => busy || isNewRow),
    distinctUntilNotEqual(),
  );

  filterParams(): FilterParameters {
    const currentState = this.store.getValue();

    if (!currentState.featureState) {
      return {};
    }

    const { offset, limit } = currentState.featureState.pageState;

    const filterParams: FilterParameters = { offset: offset || 0, limit: limit || 0, ...currentState.featureState.filtersState };

    if (isObjectsEmpty(currentState.featureState.sortState)) {
      filterParams['sort'] = sortStateToSortParam(currentState.featureState.sortState);
    }

    return filterParams;
  }

  selectedRows(): SelectedRowsState | undefined {
    return this.store.getValue().selectedRows;
  }

  getSelection(isGlobalOperation = false): SelectionData {
    // override this method in your inheriting classes to use another field as the id field
    return this._getSelection(isGlobalOperation);
  }

  protected _getSelection(isGlobalOperation = false, idKey: keyof T = 'id'): SelectionData {
    const selectedRows = this.selectedRows();
    const selection: SelectionData = {
      selected: [],
      total: 0,
      extendedSelection: undefined,
    };

    if (!selectedRows || isGlobalOperation) {
      return selection;
    }

    selection.total = selectedRows.selected;

    if (selectedRows?.selectedData) {
      const idList = selectedRows.selectedData.map(item => item[idKey]);

      if (selectedRows?.isUnselectionMode) {
        const filters = this.filterParams();

        delete filters['offset'];
        delete filters['limit'];

        selection.extendedSelection = {
          unselected: selectedRows.unselectedRowIds?.length ? idList : [],
          filters,
          quantity: selectedRows.selected,
        };
      } else if (selectedRows.selectedRowIds?.length) {
        selection.selected = idList;
      }
    }

    return selection;
  }

  constructor(protected override readonly store: GridComponentStore<T>) {
    super(store);
  }
}
