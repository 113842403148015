import { Directive, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { HybridUiFacade } from '@guardicore-ui/hybrid/domain';
import { RouteData } from '@guardicore-ui/shared/data';
import { equals } from '@guardicore-ui/shared/utils';
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged, filter, tap } from 'rxjs/operators';

import { HybridIframeDatasource } from './hybrid-iframe-datasource';

@Directive({
  selector: '[gcHybridIframe]',
})
export class HybridIframeDirective implements OnDestroy {
  @Output() locationChange = new EventEmitter<string>();
  private readonly iframe = this.el.nativeElement as HTMLIFrameElement;
  private readonly destroy$ = new Subject<void>();

  constructor(private readonly el: ElementRef, private readonly domain: HybridUiFacade, private readonly ds: HybridIframeDatasource) {
    this.iframe.width = '100%';
    this.iframe.height = '100%';
    this.iframe.scrolling = 'no';
    this.iframe.frameBorder = '0';
    this.iframe.allow = 'clipboard-read; clipboard-write';
    this.iframe.src = `${location.origin}${this.domain.iframeUrl}/`;
    this.domain.setIframe(this.iframe);
    this.ds.routeData$
      .pipe(
        distinctUntilChanged((a, b) => a?.navState === b?.navState && equals(a?.queryParamMap, b?.queryParamMap)),
        filter(data => !data?.oldAppOrigin && !!data?.navState),
        filter((data): data is RouteData => !!data),
        tap(data => this.domain.sendNavMessage(data)),
        takeUntil(this.destroy$),
      )
      .subscribe();
    domain.location$.pipe(takeUntil(this.destroy$)).subscribe(url => this.locationChange.next(url));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
