import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { API_SEGMENT_URL, API_URL } from '@guardicore-ui/shared/data';
import { replaceValue } from '@guardicore-ui/ui/filters/common';
import { Observable, EMPTY, catchError, finalize, map } from 'rxjs';

import { FiltersComponentQuery } from './filters-component.query';
import { FiltersComponentStore } from './filters-component.store';
import { REPLACE_VALUE_FROM, REPLACE_VALUE_TO } from './replace-value';
import { FilterOptions } from '../entities';

@Injectable()
export class FiltersService {
  constructor(
    @Inject(API_URL) private readonly apiUrl: string,
    @Inject(API_SEGMENT_URL) private readonly apiSegmentUrl: string,
    @Optional() @Inject(REPLACE_VALUE_FROM) private readonly valueFrom: string,
    @Optional() @Inject(REPLACE_VALUE_TO) private readonly valueTo: string,
    private readonly http: HttpClient,
    private readonly store: FiltersComponentStore,
    private readonly query: FiltersComponentQuery,
  ) {}

  getFilterOptions(id: string, additionalParams?: Record<string, string | number | boolean>): Observable<FilterOptions> {
    return this._getFilterOptions(id, additionalParams);
  }

  search(id: string, search: string): Observable<FilterOptions> {
    return this._getFilterOptions(id, { filter_value: search });
  }

  loadMore(id: string, offset: number): Observable<FilterOptions> {
    return this._getFilterOptions(id, { offset });
  }

  private _getFilterOptions(id: string, additionalParams?: Record<string, string | number | boolean>): Observable<FilterOptions> {
    const params = new HttpParams({
      fromObject: {
        ...(additionalParams && { ...additionalParams }),
        ...this.query.getOtherSelectedFilters(id),
      },
    }).set('filter_name', id);

    this.store.setError(undefined);
    this.store.setLoading(true);

    return this.http.get<FilterOptions>(`${this.apiUrl}/${this.apiSegmentUrl}/filter-options`, { params }).pipe(
      map(options => ({
        ...options,
        availableOptions: replaceValue(options.availableOptions, this.valueFrom, this.valueTo),
        selectedOptions: replaceValue(options.selectedOptions, this.valueFrom, this.valueTo),
      })),
      catchError(err => {
        this.store.setError(err);

        return EMPTY;
      }),
      finalize(() => this.store.setLoading(false)),
    );
  }
}
