import { coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';
import {
  Component,
  ChangeDetectionStrategy,
  ContentChildren,
  QueryList,
  AfterViewInit,
  ViewChild,
  OnDestroy,
  ChangeDetectorRef,
  Input,
  Inject,
  TemplateRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { SortingOrder } from '@guardicore-ui/shared/data';
import { toSnakeCase } from '@guardicore-ui/shared/utils';
import { SvgService } from '@guardicore-ui/ui/icon';
import { AgGridAngular } from 'ag-grid-angular';
import { RowClickedEvent, RowGroupOpenedEvent } from 'ag-grid-community';
import {
  CellRendererSelectorResult,
  ColDef,
  ColumnApi,
  GetRowIdParams,
  GridApi,
  ICellRendererParams,
  RowClassParams,
} from 'ag-grid-enterprise';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { CellComponent } from './cell/cell.component';
import { ColumnComponent } from './column.component';
import { DetailCellRendererComponent } from './detail-cell-renderer/detail-cell-renderer.component';
import { EmptyCellComponent } from './empty-cell/empty-cell.component';
import {
  GRID_DATA_SOURCE,
  RowOperationEvent,
  GcSelectionState,
  RowSelectEvent,
  RowSelection,
  GcGridIcons,
  RowSelectType,
  rowSelectionNone,
} from './entities';
import { GcExpansionRendererParams } from './entities/detail-cell-renderer-params';
import { HeaderComponent } from './header/header.component';
import {
  DEFAULT_PAGE_SIZE,
  GridComponentQuery,
  GridDataSource,
  GridSelectionService,
  RowExpansionService,
  SortData,
  CreateNewRowService,
  NEW_ROW_ID,
  NewRowOption,
} from './infrastructure';
import { CHECKBOX_SELECTION_CELL_ID, ROW_EXPANSION_CELL_ID } from './infrastructure/grid-constant.constant';
import { NewRowRef } from './infrastructure/new-row-ref.service';
import { LoadingCellComponent } from './loading-cell/loading-cell.component';
import { RowOperationsComponent } from './row-operations/row-operations.component';
import { SelectHeaderComponent } from './select-header/select-header.component';
import { SelectRowComponent } from './select-row/select-row.component';

const DEFAULT_ROW_HEIGHT = 64;
const DEFAULT_HEADER_HEIGHT = 48;
const ROW_OPERATIONS_COLUMN_WIDTH = 50;
const ROW_SELECTION_COLUMN_WIDTH = 54;
const ROW_EXPAND_COLUMN_WIDTH = 28;

@Component({
  selector: 'gc-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CreateNewRowService],
})
export class GridComponent implements OnDestroy, AfterViewInit {
  private readonly destroy$ = new Subject<void>();
  private api$?: Observable<{ gridApi: GridApi; columnApi: ColumnApi }>;
  private readonly rowOperation$ = new Subject<RowOperationEvent>();
  private hasRowExpansion = false;
  private readonly rowSelection$ = new Subject<RowSelection<RowSelectType>>();
  private gridSelection?: GridSelectionService;

  @Input() set maxSortingFields(value: NumberInput) {
    this.dataSource.store.update({ maxSortingFields: coerceNumberProperty(value) });
  }

  @Output() sortEvent = new EventEmitter<SortData | SortData[]>();

  @ViewChild('agGrid') agGrid?: AgGridAngular;
  @ContentChildren(ColumnComponent) columns?: QueryList<ColumnComponent>;

  readonly rowHeight = DEFAULT_ROW_HEIGHT;
  readonly headerHeight = DEFAULT_HEADER_HEIGHT;
  readonly cacheBlockSize = DEFAULT_PAGE_SIZE;
  readonly detailCellRenderer = 'gcDetailCellRenderer';
  readonly frameworkComponents = {
    customLoadingCellRenderer: LoadingCellComponent,
    gcDetailCellRenderer: DetailCellRendererComponent,
  };

  rowClassRules = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'ag-row-disable': (params: RowClassParams): boolean => this.addNewRowService.isNewRowOpen && params.data.id !== NEW_ROW_ID,
  };

  readonly icons: GcGridIcons = {
    groupContracted: (): HTMLElement | Error => {
      return this.svgService.getNamedSvgIcon('chevron-right');
    },
    groupExpanded: (): HTMLElement | Error => {
      return this.svgService.getNamedSvgIcon('expand-more');
    },
  };

  rowSelection = 'multiple';
  loadingCellRenderer = 'customLoadingCellRenderer';
  detailCellRendererParams?: GcExpansionRendererParams;

  readonly query = new GridComponentQuery(this.dataSource.store);

  colDef?: ColDef[];

  constructor(
    @Inject(GRID_DATA_SOURCE) readonly dataSource: GridDataSource,
    private readonly rowExpansion: RowExpansionService,
    private readonly cd: ChangeDetectorRef,
    private readonly svgService: SvgService,
    private addNewRowService: CreateNewRowService,
  ) {}

  ngAfterViewInit(): void {
    if (!this.columns?.length) {
      throw new Error('You need to provide at least one column to build the grid.');
    }

    this.processColumns();
    if (!this.agGrid) {
      return;
    }

    this.api$ = this.agGrid.gridReady.pipe(
      map(gridReady => ({ gridApi: gridReady.api as GridApi, columnApi: gridReady.columnApi as ColumnApi })),
      tap(({ gridApi }) => {
        this.dataSource.gridApi = gridApi;
        this.cd.markForCheck();
      }),
    );

    combineLatest([this.api$, this.rowSelection$])
      .pipe(
        map(([api, rowSelection]) => ({ api, rowSelection })),
        filter(({ api }) => Boolean(api)),
        tap(({ api, rowSelection }) => this.gridSelection?.rowSelection(rowSelection, api.gridApi)),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.rowOperation$.pipe(takeUntil(this.destroy$)).subscribe(event => this.dataSource.rowOperation(event));

    combineLatest([this.api$, this.dataSource.triggerAdditionalRowManipulations$])
      .pipe(
        filter(([api]) => Boolean(api) && this.hasRowExpansion),
        tap(([api]) => this.rowExpansion.addExpandedRows(api.gridApi)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  resetSelection(): void {
    this.rowSelection$.next(rowSelectionNone);
  }

  getRowId(params: GetRowIdParams): string {
    return params.data.id;
  }

  private processColumns(): void {
    if (!this.columns) {
      return;
    }

    this.colDef = this.columns.toArray().map(c => {
      const def: ColDef = {
        field: c.fieldName,
        headerName: c.displayName,
        sortable: c.isSortable,
        resizable: c.isResizable,
        width: c.exactWidth,
        minWidth: c.minWidth,
        maxWidth: c.maxWidth,
        autoHeight: c.autoHeight === true,
        pinned: c.pinDirection,
        headerComponent: HeaderComponent,
        flex: c.isFlex,
        wrapText: true,
        colId: c.id,
        headerComponentParams: {
          sortField: c.sortField,
          sortEventSubject: this.sortEvent,
        },
      };

      if (c.rowExpand) {
        if (c.content) {
          this.detailCellRendererParams = {
            expansionTemplate: c.content as TemplateRef<unknown>,
          };
        }

        this.hasRowExpansion = true;

        this.processRowExpansionCell(def);

        return def;
      }

      if (c.rowOperations) {
        this.processRowOperation(def);
      } else if (c.rowSelection) {
        this.processRowSelection(def, c);
      } else {
        def.cellRendererSelector = (params: ICellRendererParams): CellRendererSelectorResult | undefined => {
          return {
            component: CreateNewRowService.isNewRow(params) ? this.addNewRowService.getColumn(params) : CellComponent,
          };
        };
      }

      if (c.content) {
        def.cellRendererParams = {
          cellTemplate: c.content,
          removeDefaultPadding: c.removeDefaultPadding,
        };
      }

      if (c.headerTooltip?.trim()?.length) {
        def.headerComponentParams = {
          tooltip: c.headerTooltip.trim(),
        };
      }

      return def;
    });

    this.setDefaultSortQuery();
    this.cd.detectChanges();
  }

  addNewRow<T>(option: NewRowOption<T>): NewRowRef {
    if (this.agGrid?.animateRows === false) {
      this.dataSource.gridApi?.setAnimateRows(true);
    }

    const newRowRef = new NewRowRef(this.addNewRowService, this.dataSource);

    this.addNewRowService.addRow(option);
    this.dataSource.preventDataRefresh = true;

    return newRowRef;
  }

  private setDefaultSortQuery(): void {
    if (!this.columns) {
      return;
    }

    const defaultSortQuery: SortData[] = this.columns
      .toArray()
      .filter(c => !!c.defaultSortingOrder)
      .sort((column1, column2) => (column1.defaultSortingPriority || 0) - (column2.defaultSortingPriority || 0))
      .map(c => {
        return {
          sort: c.defaultSortingOrder as SortingOrder,
          colId: toSnakeCase((c.sortField || c.fieldName || c.id) as string),
        };
      });

    this.sortEvent.emit(defaultSortQuery);
  }

  private processRowExpansionCell(def: ColDef): void {
    def.cellStyle = { 'background-color': 'transparent' };
    def.cellRenderer = 'agGroupCellRenderer';
    def.colId = ROW_EXPANSION_CELL_ID;
    def.width = ROW_EXPAND_COLUMN_WIDTH;
    def.minWidth = ROW_EXPAND_COLUMN_WIDTH;
    def.maxWidth = ROW_EXPAND_COLUMN_WIDTH;
    def.field = 'id';
    def.cellRendererParams = {
      innerRenderer: EmptyCellComponent,
    };
    def.headerComponent = EmptyCellComponent;
  }

  private processRowOperation(def: ColDef): void {
    def.cellRenderer = RowOperationsComponent;
    def.width = ROW_OPERATIONS_COLUMN_WIDTH;
    def.flex = 0;
    def.colId = 'rowOperations';
    def.cellRendererParams = {
      rowOperation$: this.rowOperation$,
    };
  }

  private processRowSelection(def: ColDef, c: ColumnComponent): void {
    this.gridSelection = new GridSelectionService();
    this.gridSelection.gridComponentStore = this.dataSource.store;
    this.dataSource.gridSelection = this.gridSelection;
    this.gridSelection.selectedSaveKeys = c.rowSelection || [];
    def.flex = 1;
    def.cellStyle = { 'background-color': 'transparent' };
    def.headerComponent = SelectHeaderComponent;
    def.cellRenderer = SelectRowComponent;
    def.cellRendererParams = {
      rowSelection$: this.rowSelection$,
      configuration: {
        getSelectionState: () => this.gridSelection?.getSelectionState(),
      },
    } as GcSelectionState<RowSelectEvent>;
    def.width = ROW_SELECTION_COLUMN_WIDTH;
    def.minWidth = ROW_SELECTION_COLUMN_WIDTH;
    def.maxWidth = ROW_SELECTION_COLUMN_WIDTH;
    def.colId = CHECKBOX_SELECTION_CELL_ID;
  }

  onRowClicked(event: RowClickedEvent): void {
    if (!this.hasRowExpansion) {
      return;
    }

    const target = event.event?.target as HTMLElement;

    if (target?.closest('input') || target?.closest('gc-detail-cell-renderer') || target?.closest('gc-row-operations')) {
      return;
    }

    this.agGrid?.api.setRowNodeExpanded(event.node, !event.node.expanded);
  }

  onRowGroupOpened(event: RowGroupOpenedEvent): void {
    this.rowExpansion.rowExpansion({
      rowId: event.data.id,
      rowData: event.data,
      expand: event.expanded,
    });
  }
}
