import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  OnDestroy,
  QueryList,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'gc-error-block-description',
})
export class ErrorBlockDescriptionDirective {}

@Component({
  selector: 'gc-error-block',
  templateUrl: './error-block.component.html',
  styleUrls: ['./error-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorBlockComponent implements AfterContentChecked, OnDestroy {
  @ContentChildren(ErrorBlockDescriptionDirective, { emitDistinctChangesOnly: true })
  public descriptionList: QueryList<ErrorBlockDescriptionDirective> | undefined;

  private destroy$ = new Subject<void>();

  public get hasDescription(): boolean | undefined {
    return this.descriptionList && this.descriptionList.length > 0;
  }

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngAfterContentChecked(): void {
    this.descriptionList?.changes.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
