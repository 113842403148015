import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiIconModule } from '@guardicore-ui/ui/icon';
import { UiPopoverModule } from '@guardicore-ui/ui/popovers/popover';

import { NotificationsBellComponent } from './notifications-bell/notifications-bell.component';

@NgModule({
  imports: [CommonModule, UiIconModule, UiPopoverModule],
  declarations: [NotificationsBellComponent],
  exports: [NotificationsBellComponent],
})
export class NotificationsUiModule {}
