import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { HybridMainFacade } from '@guardicore-ui/hybrid/domain';
import { SystemStatusQuery, SystemStatusState } from '@guardicore-ui/shared/system-status';
import { camelToSnakeCaseKeys, getRouteData } from '@guardicore-ui/shared/utils';
import { Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { getUserId } from './crypto-user-id';
import { Context, StrippedPage, TRACKING_CONFIG_TOKEN, TrackingConfig, GeneralProperties } from '../entities';

@Injectable()
export class AnalyticsService implements OnDestroy {
  private destroy$ = new Subject<void>();
  private navigationReference?: string;
  private generalProps?: GeneralProperties;
  private navigationPage = {
    prev: 'None',
    curr: '',
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject('Window') private readonly window: Window,
    @Inject(TRACKING_CONFIG_TOKEN) private readonly config: TrackingConfig,
    private readonly systemStatus: SystemStatusQuery,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private readonly hybridCommunication: HybridMainFacade,
  ) {
    this.hybridCommunication.navigationLink$.pipe(debounceTime(1000), takeUntil(this.destroy$)).subscribe(data => {
      this.track('Link Clicked', this.getLinkProps(data['linkText']));
    });
    this.init();
    this.listenToNavigationAndPage();
    this.listenToMatDialog();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  track(eventName: string, properties: Record<string, string | number> = {}): void {
    const extendedProperties = {
      ...camelToSnakeCaseKeys(properties || {}),
      ...camelToSnakeCaseKeys(this.generalProperties || {}),
    };

    this.window?.analytics?.track(eventName, extendedProperties, this.context);
  }

  private init(): void {
    this.systemStatus.state$
      .pipe(
        take(1),
        switchMap(status => getUserId(status).pipe(map(userId => ({ status, userId, key: this.getKey(status) })))),
        filter(({ key }) => !!key),
        tap(({ key }) => this.window?.analytics?.load(key)),
        tap(({ status }) => this.setGeneralProperties(status)),
        takeUntil(this.destroy$),
      )
      .subscribe(({ status, userId }) => this.identify(status, userId));
  }

  private page(): void {
    const properties = {
      ...this.strippedPage,
      ...camelToSnakeCaseKeys(this.generalProperties || {}),
    };

    this.window?.analytics?.page(properties, this.context);
  }

  private getKey(status: SystemStatusState): string {
    const turnOffAnalytics = status.featureFlags?.analytics?.overrideState === false;
    let key = '';

    if (!turnOffAnalytics) {
      key = status?.environmentIsProduction ? this.config.analyticsKey : this.config.analyticsStagingKey;
    }

    return key;
  }

  private identify(status: SystemStatusState, userId: string): void {
    const tag = status.release?.tag || '';
    const username = status?.login?.username || '';
    const userData = {
      buildtag: tag,
      hostname: this.window.location.hostname,
      userid: userId,
      isgcuser: username.startsWith('gc-'),
      release: tag.split('_')[1],
      isproduction: !!status.environmentIsProduction,
      windowwidth: this.window.outerWidth,
      windowheight: this.window.outerHeight,
    };

    this.window?.analytics?.identify(userId, userData, this.context);
  }

  private get strippedPage(): StrippedPage {
    return {
      search: this.window.location.search.slice(1),
      url: this.window.location.href.split('?')?.[0]?.replace(this.config.iframeUrl, ''),
      referrer: this.navigationPage.prev,
      title: this.document.title,
      reference: this.navigationPage.curr,
    };
  }

  private get context(): Context {
    return {
      context: {
        page: this.strippedPage,
      },
    };
  }

  private listenToNavigationAndPage(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd && this.router.navigated),
        tap(() => {
          this.setPage(getRouteData(this.route)?.viewName || '');
          this.page();
        }),
        tap(() => {
          const state = this.router.getCurrentNavigation()?.extras?.state;

          if (state?.['navigationReference']) {
            this.navigationReference = state?.['navigationReference'];
            this.track('Link Clicked', this.getLinkProps(state?.['navigationLinkText'], this.navigationReference));
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private setGeneralProperties(status: SystemStatusState): void {
    this.generalProps = {
      customerName: status?.environmentCustomerName,
      centraVersion: status?.version?.version,
      usernameOrEmail: status?.login?.username || status?.login?.email?.emailAddress,
    };
  }

  private get generalProperties(): GeneralProperties {
    return {
      ...this.generalProps,
      path: this.window.location.pathname,
    };
  }

  private getLinkProps(linkText = '', navigationReference = 'In-page link'): Record<string, string> {
    return {
      type: navigationReference,
      sourceRoute: this.navigationPage.prev,
      destinationRoute: this.navigationPage.curr,
      item: linkText,
    };
  }

  private setPage(page: string): void {
    this.navigationPage.prev = this.navigationPage.curr;
    this.navigationPage.curr = page;
  }

  private listenToMatDialog(): void {
    const modalData = { modalTitle: '', action: '' };

    this.dialog.afterOpened.subscribe(() => {
      const matDialogEl = this.document.querySelector('mat-dialog-container');

      matDialogEl?.addEventListener('click', (event: Event) => {
        if ((event?.target as HTMLElement)?.nodeName === 'BUTTON') {
          modalData.modalTitle = matDialogEl?.querySelector<HTMLElement>('.gc-dialog-content-header')?.innerText || '';
          modalData.action = (event?.target as HTMLElement)?.textContent || 'Quit';
        }
      });
    });

    this.dialog.afterAllClosed.subscribe(() => {
      if (modalData.action) {
        this.track('Modal Button Click', { modalTitle: modalData.modalTitle, action: modalData.action });
      }
    });
  }
}
