import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiIconModule } from '@guardicore-ui/ui/icon';
import { UiTextModule } from '@guardicore-ui/ui/text';

import { LabelComponent } from './label/label.component';
import { LabelHorizontalListComponent } from './label-horizontal-list/label-horizontal-list.component';

@NgModule({
  imports: [CommonModule, UiTextModule, UiIconModule],
  declarations: [LabelComponent, LabelHorizontalListComponent],
  exports: [LabelComponent, LabelHorizontalListComponent],
})
export class UiLabelsModule {}
