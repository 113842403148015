export interface SystemStatusFeatureFlags {
  agentsAdminLock: {
    agentPageEnabled: boolean;
  };
  dashboardRevamp: {
    showDashboard: boolean;
  };
  dnsSecurity: {
    enableDnsSecurity: boolean;
    maxDnsBlocklistSizeMb: number;
  };
  mssp: {
    enableMssp: boolean;
  };
  policy: {
    ruleHitCount: boolean;
  };
  policyLearningMode: {
    hidePolicyViolation: boolean;
  };
  reveal20: {
    visibilityDatacenterDisableUngroupingLargeNodes: boolean;
    visibilityMaxGraphFlowsToSuggest: number;
    visibilityPolicyEnabled: boolean;
    showSegmentationRulesScopeColumn: boolean;
    changeEdgesColors: boolean;
    changeManyPorts: boolean;
    removeEdgeThickness: boolean;
    changeBiDirectionalEdges: boolean;
    changeNodeSize: boolean;
    movePortToEnd: boolean;
  };
  workflow: {
    showProjectsCoverageColumn: boolean;
  };
  insight: {
    enableInsight: boolean;
    enableScheduledQueries: boolean;
  };
  k8sEnforcement: {
    exposeClustersView: boolean;
  };
  analytics?: {
    overrideState: boolean;
  };
  genericIncidents: {
    exposeGenericIncidents: boolean;
    exposeIncidentPreview?: boolean;
    excludedGenericIncidentsTypes: string[];
  };
  labs?: {
    enableLabs?: boolean;
  };
  fim: {
    enableFim: boolean;
  };
  aggregators?: {
    enableNew?: boolean;
  };
  agents?: {
    enableNew?: boolean;
  };
  navigation?: {
    enableMitigationAndIocs?: boolean;
    enableRepoKey?: boolean;
  };
  dnsSecurityFeeder?: {
    dnsFeedConfigEnable?: boolean;
    dnsFeedOn?: boolean;
  };
  centraCluster?: {
    centraClusterEnabled: boolean;
    isManager: boolean;
    hideDisasterRecoveryWidget: boolean;
  };
  savedMaps?: {
    projectViolationsMapMaxTimeRangeDays: number;
  };
  cloudApp?: {
    enabled: boolean;
  };
}
