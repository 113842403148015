export function equals(a?: unknown, b?: unknown): boolean {
  if (a === b || (a !== a && b !== b)) {
    return true;
  }

  a = a ?? undefined;
  b = b ?? undefined;

  if (a === undefined || b === undefined || typeof a !== 'object' || typeof b !== 'object') {
    return false;
  }

  if (Array.isArray(a)) {
    if (!Array.isArray(b)) {
      return false;
    }

    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; i++) {
      if (!equals(a[i], b[i])) {
        return false;
      }
    }

    return true;
  }

  if (a instanceof Map && !(b instanceof Map)) {
    return false;
  }

  if (b instanceof Map && !(a instanceof Map)) {
    return false;
  }

  if (a instanceof Map && b instanceof Map) {
    if (a.size !== b.size) {
      return false;
    }

    for (const entry of a.entries()) {
      if (entry.length !== 2 || !b.has(entry[0]) || !equals(entry[1], b.get(entry[0]))) {
        return false;
      }
    }

    return true;
  }

  const aObj = a as Record<string, unknown>;
  const bObj = b as Record<string, unknown>;

  if (typeof aObj?.valueOf === 'function' && typeof bObj?.valueOf === 'function' && aObj?.valueOf !== Object.prototype.valueOf) {
    return aObj.valueOf() === bObj.valueOf();
  }

  if (typeof aObj?.toString === 'function' && typeof bObj?.toString === 'function' && aObj?.toString !== Object.prototype.toString) {
    return aObj.toString() === bObj.toString();
  }

  if (Object.keys(aObj).length !== Object.keys(bObj).length) {
    return false;
  }

  for (const key in aObj) {
    if (!equals(aObj[key], bObj[key])) {
      return false;
    }
  }

  return true;
}
