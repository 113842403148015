import { Component, ChangeDetectionStrategy, ViewChild, AfterViewInit, ElementRef, Renderer2 } from '@angular/core';
import { trackByIndex } from '@guardicore-ui/shared/utils';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-enterprise';

import { SelectDropdownOption } from './types';
import { GcSelectionState, RowSelectEvent, RowSelectType, SelectionState } from '../entities/row-selection';

@Component({
  selector: 'gc-select-header',
  templateUrl: './select-header.component.html',
  styleUrls: ['./select-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectHeaderComponent implements AfterViewInit, IHeaderAngularComp {
  private col!: GcSelectionState<RowSelectEvent>;
  private config!: SelectionState;

  @ViewChild('container') container!: ElementRef;
  @ViewChild('arrowContainer') arrow!: ElementRef;
  @ViewChild('checkboxContainer') checkbox!: ElementRef;

  isMarked = false;
  isIndeterminate = false;
  isDisabled = false;
  options: SelectDropdownOption<RowSelectType>[] = [];
  public params!: IHeaderParams;
  trackBy = trackByIndex;
  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.setHovering();
  }

  agInit(params: IHeaderParams): void {
    this.setConfig(params);
  }

  refresh(params: IHeaderParams): boolean {
    this.setConfig(params);

    return false;
  }

  setConfig(params: IHeaderParams): void {
    this.params = params;
    this.col = params.column?.getColDef()?.cellRendererParams as GcSelectionState<RowSelectEvent>;
    this.config = this.col.configuration.getSelectionState();
    this.isIndeterminate = this.config.currentPageState === 'some';
    this.isMarked = ['allPage', 'allGrid'].includes(this.config.currentPageState) || this.isIndeterminate;
    this.isDisabled = this.config.totalItems === 0;
    this.createOptions();
    if (this.checkbox) {
      this.setHovering();
    }
  }

  toggleSelection(checked: boolean): void {
    const selectedRowType = this.isIndeterminate || !checked ? 'deselectPage' : 'allPage';

    this.col.rowSelection$.next({ selectedRowType, rowId: '', rowData: {} });
  }

  runDropdownAction(data: string): void {
    this.col.rowSelection$.next({ selectedRowType: data as RowSelectType, rowId: '', rowData: {} });
  }

  toggleDropdown(): void {
    this.createOptions();
  }

  private setHovering(): void {
    switch (this.config?.currentPageState) {
      case 'none':
      case 'deselectPage':
        this.renderer.addClass(this.checkbox.nativeElement, 'selection-checkbox-light');
        this.renderer.addClass(this.arrow.nativeElement, 'selection-arrow-visible');
        this.renderer.addClass(this.container.nativeElement, 'selection-container-none');
        break;
      case 'some':
        this.renderer.addClass(this.arrow.nativeElement, 'selection-arrow-visible');
        break;
      case 'allGrid':
      case 'allPage':
        this.renderer.addClass(this.arrow.nativeElement, 'selection-arrow-visible');
        break;
      default:
        break;
    }
  }

  private createOptions(): void {
    this.options = [];
    if (this.config.currentPageState !== 'none') {
      this.options.push({ text: 'Reset selection', value: 'none' });
    }

    if (this.config.currentPageState !== 'allGrid') {
      this.options.push({ text: `Select all ${this.config.totalItems}`, value: 'allGrid' });
    }
  }
}
