import { HttpErrorResponse } from '@angular/common/http';
import { ApiService, SelectionData, apiResponseToFeatureState } from '@guardicore-ui/shared/api';
import { GlobalError, RowOperations, FeatureWithPaginationAndFiltersState, RowDataObject, SortState } from '@guardicore-ui/shared/data';
import { PollingService } from '@guardicore-ui/shared/polling';
import { GridApi, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-enterprise';
import { Subject, EMPTY, of, timer, Observable, ReplaySubject } from 'rxjs';
import { take, tap, catchError, takeUntil, switchMap, delay, finalize, map } from 'rxjs/operators';

import { GridComponentQuery } from './grid-component.query';
import { GridComponentStore } from './grid-component.store';
import { GridSelectionService } from './grid-selection.service';
import { RowOperationEvent } from '../entities';
import { rowSelectionPagination } from '../entities/row-selection';
import { extractErrorDescription, setPageStatus } from '../utils';

export interface RefreshOptions {
  doNotPurge?: boolean;
  doNotSetBusy?: boolean;
}

/**
 * Extend this class in your feature, and provide it in your feature component
 *
 * @example
 * Injectable()
 * export class FeatureGridDataSourceService extends GridDataSource {
 *  constructor(
 *    protected readonly featureService: FeatureService,
 *    readonly store: FeatureStore,
 *    pollingService: PollingService,
 *  ) {
 *   super(featureService, store, pollingService);
 * }
 *
 *
 * @example
 * providers: [{ provide: GridDataSource, useClass: FeatureGridDataSourceService }, FeatureStore],
 *
 */
export abstract class GridDataSource<T extends RowDataObject = RowDataObject> implements IServerSideDatasource {
  private _gridSelection?: GridSelectionService;
  private _showLoader = true;
  private readonly triggerAdditionalRowManipulationsSubj = new Subject<void>();
  private readonly triggerRowExpansionSubj = new Subject<number>();

  /**
   * Use this subject in your overrides if need one.
   */
  protected readonly destroy$ = new Subject<void>();
  protected readonly getRowsDoneSubject = new Subject<void>();
  protected readonly refreshStartedSubject = new Subject<void>();
  protected readonly sortStateSubject = new ReplaySubject<SortState>();
  protected pollingInterval = 30000;
  protected noDataPollingInterval = 60000;
  protected readonly stopPolling$ = new Subject<void>();
  protected setBusy = true;
  protected _featureState?: FeatureWithPaginationAndFiltersState;
  preventDataRefresh = false;

  get showLoader(): boolean {
    return this._showLoader;
  }

  readonly triggerAdditionalRowManipulations$ = this.triggerAdditionalRowManipulationsSubj.asObservable();
  readonly refreshStarted$ = this.refreshStartedSubject.asObservable();
  readonly triggerRowExpansion$ = this.triggerRowExpansionSubj.asObservable();
  readonly sortState$ = this.sortStateSubject.asObservable();
  readonly query = new GridComponentQuery(this.store);

  readonly getRowsDone$ = this.getRowsDoneSubject.asObservable();

  gridApi?: GridApi;
  params?: IServerSideGetRowsParams;
  set featureState(value: FeatureWithPaginationAndFiltersState) {
    this._featureState = value;
    this.sortStateSubject.next(value.sortState);
    this.store.update({ featureState: value });
    this.gridApi &&
      this._gridSelection?.rowSelectedEvent({
        pageState: this._featureState?.pageState,
        rowSelection: rowSelectionPagination,
        gridApi: this.gridApi,
        rowData: this._featureState.entities,
      });
  }

  constructor(protected readonly apiService: ApiService<T>, readonly store: GridComponentStore<T>, pollingService: PollingService) {
    pollingService.reloadAll$.pipe(takeUntil(this.destroy$)).subscribe(() => this.refresh());
    pollingService.stop$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.stopPolling$.next();
    });
    const hasDataFlag = this.apiService.hasDataFlag();

    store.update({ pageStatus: setPageStatus(hasDataFlag) });
  }

  set gridSelection(gridSelection: GridSelectionService) {
    this._gridSelection = gridSelection;
  }

  /**
   * Called automatically by ag-grid when it demands data.
   *
   * Feel free to override this method in you extensions to extend or provide
   * different data supply to your grid. Until you don't want to completely
   * change the behavior of this class, call `this._getRows(params);`
   * in your overriden method.
   *
   * @param params provided by ag-grid
   */
  getRows(params: IServerSideGetRowsParams): void {
    this.params = params;
    this._getRows(params);
  }

  /**
   * Manually refresh the grid and call `getRows` method
   */
  refresh(options?: RefreshOptions): void {
    if (this.preventDataRefresh) {
      return;
    }

    this.stopPolling$.next();
    this.setBusy = !options?.doNotSetBusy;
    this.gridApi?.refreshServerSide({ purge: !options?.doNotPurge });
  }

  destroy(): void {
    this.destroy$.next();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  rowOperation(operationEvent: RowOperationEvent): void {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  autoRefreshCondition(objects: unknown[]): boolean {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any
  setRowOperations(items?: any): RowOperations<any>[] {
    return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  setPageWarning(items?: any): boolean {
    return false;
  }

  // eslint-disable-next-line max-lines-per-function
  protected _getRows(params: IServerSideGetRowsParams): void {
    if (this.preventDataRefresh) {
      return;
    }

    this.refreshStartedSubject.next();
    this.setBusy && this.store.setLoading(true);

    this.apiService
      .read()
      .pipe(
        map(res => {
          this._featureState = apiResponseToFeatureState(res);
          const isEmpty = !res.objects?.length;

          this.store.setError(undefined);
          const pageStatus = setPageStatus(this.apiService.hasDataFlag());

          this.store.setLoading(false);
          this.store.update({
            isEmpty,
            pageStatus,
            rowData: res.objects,
            rowOperations: this.setRowOperations(res.objects),
            showPageWarning: this.setPageWarning(res.objects),
          });
          this._gridSelection?.rowSelectedEvent({
            pageState: this._featureState?.pageState,
            rowSelection: rowSelectionPagination,
            gridApi: params.api,
            rowData: res.objects,
          });

          params.success({ rowData: res.objects, rowCount: res.objects?.length });
          if (this._gridSelection?.shouldRefreshHeaders()) {
            params.api.refreshHeader();
          }

          this.triggerAdditionalRowManipulationsSubj.next();

          return res;
        }),
        switchMap(res =>
          this.autoRefreshCondition(res.objects) ? of(1).pipe(delay(this.pollingInterval), takeUntil(this.stopPolling$)) : EMPTY,
        ),
        catchError((err: HttpErrorResponse) => {
          this.store.setError<GlobalError>({
            statusCode: err.status,
            title: err.error?.title || 'Internal Error',
            description: extractErrorDescription(err.error),
          });
          params.success({ rowData: [], rowCount: 0 });

          const pageStatus = setPageStatus(this.apiService.hasDataFlag());

          if (pageStatus === 'initial-loading') {
            this.store.update({ pageStatus: 'error' });
          }

          return EMPTY;
        }),
        take(1),
        finalize(() => {
          const pageStatus = setPageStatus(this.apiService.hasDataFlag());

          if (pageStatus === 'no-data') {
            this.startNoDataPolling();
          }

          this.store.setLoading(false);
        }),
      )
      .subscribe(() => this.refresh({ doNotSetBusy: true, doNotPurge: true }));
  }

  getPreviewDataForSelection(selection: SelectionData, limit = 10): Observable<Partial<T>[]> {
    // override in your inheriting classes to supply different preivew data
    return this._getPreviewDataForSelection(selection, limit);
  }

  protected _getPreviewDataForSelection(selection: SelectionData, limit = 10, idKey: keyof T = 'id'): Observable<Partial<T>[]> {
    const currentState = this.store.getValue();

    const hasSimpleSelection = selection.extendedSelection === undefined || selection.selected.length;

    const selectedData$ = hasSimpleSelection
      ? of(currentState.selectedRows.selectedData)
      : this.apiService.getSelected<Partial<T>>(undefined, limit, selection.extendedSelection?.unselected);

    return selectedData$.pipe(
      map(list => list.map(item => ({ ...item, id: item[idKey] }))),
      catchError(() => {
        // eslint-disable-next-line no-console
        console.error(`Bulk operation preview endpoint (./selected) is not implemented at the backend.
      Please verify, or correct your logic accordingly.`);

        return of([]);
      }),
    );
  }

  addAdditionalDataToRows(additionalData: Map<string, unknown>, key = 'additionalRowData'): void {
    if (!this.gridApi) {
      return;
    }

    this.gridApi.forEachNode(rowNode => {
      rowNode.setData({
        ...rowNode.data,
        [key]: additionalData.get(rowNode.data?.id),
      });
    });
  }

  private startNoDataPolling(): void {
    timer(this.noDataPollingInterval, this.noDataPollingInterval)
      .pipe(
        tap(() => this.refresh()),
        take(1),
        catchError(() => EMPTY),
      )
      .subscribe();
  }
}
