<section [class]="status">
  <div class="icon">
    <gc-icon [svgIcon]="icon"></gc-icon>
  </div>

  <div class="status" [ngClass]="{'multiple-lines': title || comment}">
    <div class="title" *ngIf="title">{{title}}</div>
    <div class="body"> {{body}}</div>
    <div class="comment" *ngIf="comment">{{comment}}</div>
  </div>

  <div *ngIf="callToAction" class="cta" [ngClass]="{'action': callToAction.icon !== 'spinner', 'spinner': callToAction.icon === 'spinner'}" (click)="action()">
    <gc-icon *ngIf="callToAction.icon" [svgIcon]="callToAction.icon"></gc-icon>
    <div *ngIf="callToAction.text">{{callToAction.text}}</div>
  </div>
</section>
