import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Asset } from '../entities';
import { AssetsQuery } from '../infrastructure/assets.query';
import { AssetsService } from '../infrastructure/assets.service';

@Injectable({
  providedIn: 'root',
})
export class AssetsMainFacade {
  constructor(private readonly assetsService: AssetsService, private readonly assetsQuery: AssetsQuery) {}

  read(id: string): Observable<Asset | undefined> {
    if (this.assetsQuery.hasAsset(id) === false) {
      return this.assetsService.read(id);
    } else {
      return this.assetsQuery.selectAsset(id);
    }
  }
}
