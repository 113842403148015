import { Component, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, EventType, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { PersistState, resetStores } from '@datorama/akita';
import { AssetsMainFacade } from '@guardicore-ui/assets/domain';
import { AuthenticationQuery, SessionExpirationFacade } from '@guardicore-ui/authentication/domain';
import { HybridMainFacade } from '@guardicore-ui/hybrid/domain';
import { QuickLinksFacadeService } from '@guardicore-ui/quick-links/domain';
import { RouteData } from '@guardicore-ui/shared/data';
import { PollingService } from '@guardicore-ui/shared/polling';
import { SystemStatusFacade, SystemStatusQuery } from '@guardicore-ui/shared/system-status';
import { getRouteData } from '@guardicore-ui/shared/utils';
import { merge, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

import { CoreStore, CoreQuery } from './core/state';

@Component({
  selector: 'gc-root',
  template: `<gc-main-layout></gc-main-layout>`,
  styles: [''],
})
export class AppComponent {
  private readonly routerEvent$ = this.router.events.pipe(
    map(event => {
      if (event.type === EventType.NavigationStart) {
        const e = event as NavigationStart;

        if (e.navigationTrigger === 'popstate' || e.id === 1) {
          this.clearNavigationState();
        }
      }

      return event;
    }),
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    tap(event => this.processNavigationEnd(event)),
  );

  readonly quickLinksData$ = merge(this.routerEvent$, this.quickLinksFacadeService.hasData$).pipe(
    debounceTime(10),
    switchMap(() => of(getRouteData(this.route))),
    map((routeData: RouteData) => this.quickLinksFacadeService.getQuickLinks(routeData?.viewName as string)),
  );

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly store: CoreStore,
    private readonly query: CoreQuery,
    private readonly assets: AssetsMainFacade,
    private readonly systemStatus: SystemStatusQuery,
    private readonly systemStatusService: SystemStatusFacade,
    private readonly hybridCommunication: HybridMainFacade,
    private readonly sessionExpiration: SessionExpirationFacade,
    private readonly pollingService: PollingService,
    private readonly auth: AuthenticationQuery,
    private readonly titleService: Title,
    private readonly quickLinksFacadeService: QuickLinksFacadeService,
    @Inject('persistStorage') private readonly persistStorage: PersistState,
  ) {
    this.quickLinksData$.subscribe(viewQuickLinks => this.store.update({ viewQuickLinks }));
    auth.authStatus$
      .pipe(
        distinctUntilChanged((a, b) => a.isLoggedIn === b.isLoggedIn),
        switchMap(authData => {
          // Check if system status was already read by application initializer
          if (authData.isLoggedIn && !this.systemStatus.wasSystemStatusJustRead()) {
            return this.systemStatusService.readSystemStatus().pipe(map(() => authData));
          } else {
            return of(authData);
          }
        }),
      )
      .subscribe(authData => {
        if (authData.isLoggedIn) {
          // mark user as logged in in core store
          store.login();
          // send user id to old app
          this.hybridCommunication.setAuthData(authData);
          // start system status polling
          if (query.arePollingsAllowed()) {
            this.systemStatusService.startPolling();
          }

          // start checking user idle time
          this.sessionExpiration.startSessionExpirationCheck(this.systemStatusService.systemStatus);
        } else {
          // User logged out
          // stop all pollings
          pollingService.stopPollings();
          // send the message to the old app that user is logged out
          this.hybridCommunication.logout();
          // clear auth token
          persistStorage.clearStore();
          // reset all in-memory stores
          resetStores();
          // stop checking user idle time
          this.sessionExpiration.stopSessionExpirationCheck();
        }
      });

    this.hybridCommunication.title$.subscribe(title => titleService.setTitle(title));
  }

  private clearNavigationState(): void {
    const currentNavigation = this.router.getCurrentNavigation();

    if (currentNavigation?.extras?.state) {
      currentNavigation.extras.state['oldAppOrigin'] = false;
    }
  }

  private processNavigationEnd(event: NavigationEnd): void {
    if (this.query.isResetPasswordRequired()) {
      return;
    }

    const currentNavigation = this.router.getCurrentNavigation();

    if (currentNavigation?.extras.state?.['oldAppOrigin'] && currentNavigation.id === 1) {
      currentNavigation.extras.state['oldAppOrigin'] = false;
    }

    let url = event.urlAfterRedirects;
    const urlSplitted = event.urlAfterRedirects.split('/');
    let routeData = getRouteData(this.route, currentNavigation?.extras?.state?.['oldAppOrigin']);

    // Set default title after logout
    if (routeData?.title) {
      this.titleService.setTitle(routeData.title);
    }

    if (routeData?.isPublicRoute) {
      return;
    }

    if (urlSplitted.length === 2) {
      if (urlSplitted[1] === '') {
        url = this.query.defaultUrl() as string;
      } else {
        url = this.query.savedUrlPath() || (this.query.defaultUrl() as string);
      }

      this.router.navigateByUrl(url);

      return;
    }

    routeData = { ...routeData, queryParamMap: this.route.snapshot.queryParamMap };
    this.store.navigationEnd(routeData, url);
  }
}
