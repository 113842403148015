<div class="gc-dialog-content">
  <div class="gc-dialog-content-header">
    <gc-icon class="icon" *ngIf="icon === 'warning'" svgIcon="warning"></gc-icon>
    <h3>{{ title }}</h3>
    <button *ngIf="closeIcon" class="header-close-button" type="button" (click)="close()">
      <gc-icon svgIcon="close"></gc-icon>
    </button>
  </div>
  <div class="gc-dialog-content-body">
    <ng-content></ng-content>
  </div>
  <div class="gc-dialog-content-footer footer" *ngIf="primaryButtonLabel || secondaryButtonLabel" [ngClass]="{'help-link': helpLink}">
    <a *ngIf="helpLink" class="footer-link" [attr.href]="helpLink" target="_blank">Need help?</a>
    <div class="action-buttons">
      <button class="{{secondaryButtonsGhost ? 'ghost' : 'default'}}-button" *ngIf="secondaryButtonLabel" (click)="dismiss()">{{ secondaryButtonLabel }}</button>
      <button class="{{ showConfirmAsDanger ? 'danger' : 'primary' }}-button" *ngIf="primaryButtonLabel" (click)="confirm()" [disabled]="primaryButtonDisabled">{{ primaryButtonLabel }}</button>
    </div>
  </div>
</div>
