import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogData } from '../dialog';

@Component({
  selector: 'gc-quit-wizard-dialog',
  templateUrl: './quit-wizard-dialog.component.html',
  styleUrls: ['./quit-wizard-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuitWizardDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
