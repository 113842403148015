import { Component, ChangeDetectionStrategy, Inject, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleToastrState, SvgIconName } from '@guardicore-ui/shared/data';

import { TOASTR_DATA } from '../toastr-config';

@Component({
  selector: 'gc-simple-toastr',
  templateUrl: './simple-toastr.component.html',
  styleUrls: ['./simple-toastr.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleToastrComponent {
  iconClass = {
    error: 'error',
    success: 'check-circle',
    warning: 'warning-transparent',
    info: 'info',
  }[this.toastrData.state] as SvgIconName;

  @HostListener('click', ['$event'])
  onClick($event: Event): void {
    const element = $event.target as HTMLAnchorElement;

    if (element?.tagName !== 'A') {
      return;
    }

    const url = element?.href.replace(location.origin, '');

    if (url.startsWith('/')) {
      this.router.navigateByUrl(url);
      $event.preventDefault();
    }
  }

  constructor(
    private router: Router,
    @Inject(TOASTR_DATA) public toastrData: { message: string; title?: string; state: SimpleToastrState },
  ) {}
}
