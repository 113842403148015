import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'gc-session-expiration-dialog',
  templateUrl: './session-expiration-dialog.component.html',
  styleUrls: ['./session-expiration-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionExpirationDialogComponent implements OnInit, OnDestroy {
  countdown!: number;
  isExpired = false;
  private timer!: number;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      counterDuration: number;
    },
    private ngZone: NgZone,
    protected readonly ref: MatDialogRef<SessionExpirationDialogComponent>,
    private readonly cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
  ) {
    this.countdown = data.counterDuration;
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => {
      clearInterval(this.timer);
      this.timer = window.setInterval(() => {
        if (this.countdown <= 0) {
          clearInterval(this.timer);
          this.timerFinished();
        } else {
          this.countdown--;
        }

        this.elementRef.nativeElement.querySelector('#countdownButton').innerHTML = `Continue Session (${this.countdown})`;
      }, 1000);
    });
  }

  timerFinished(): void {
    this.ngZone.run(() => {
      this.isExpired = true;
      this.close();
    });
  }

  close(): void {
    clearInterval(this.timer);
    this.ref.close(this.isExpired);
  }
}
