export interface FeederPage {
  parentKey: string;
  key: string;
  apiUrl: string;
  name: string;
}

export interface FeederPages {
  [key: string]: FeederPage;
}

export const feederPages = {
  'dns-security': {
    parentKey: 'dnsSecurityFeeder',
    key: 'dnsFeedOn',
    apiUrl: '/visibility/policy/dns-security/feed/status',
    name: 'DNS Security',
  },
};
