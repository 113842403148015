import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[gcTemplateStateIndex]',
})
export class TemplateStateIndexDirective {
  @Input('gcTemplateStateIndex') index?: number;

  constructor(public templateRef: TemplateRef<unknown>) {}
}
