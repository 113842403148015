import { Injectable } from '@angular/core';
import { Store } from '@datorama/akita';
import { ViewStates } from '@guardicore-ui/shared/data';

import { createInitialState, NavbarItemState, NavbarState } from './navbar-state';

@Injectable()
export class NavbarComponentStore extends Store<NavbarState> {
  constructor() {
    super(createInitialState(), { name: 'navbar', resettable: true });
  }

  updateFromViewStates(viewStates: ViewStates): void {
    this.update(state => {
      const navbarListStates = Object.keys(viewStates).reduce((acc, viewName) => {
        const currentState: Partial<NavbarItemState> = state.itemsState[viewName] ?? {};

        return {
          ...acc,
          [viewName]: {
            ...currentState,
            hidden: viewStates[viewName].isHidden,
            disabled: viewStates[viewName].isDisabled === true ? viewStates[viewName].isDisabled : undefined,
            tooltip: viewStates[viewName].tooltip,
          },
        };
      }, {});

      return { ...state, itemsState: navbarListStates };
    });
  }
}
