import { AuthenticationQuery, AuthenticationStore, LOGIN_ROUTE } from '@guardicore-ui/authentication/domain';
import { HybridMainFacade } from '@guardicore-ui/hybrid/domain';
import { SystemStatusFacade, SystemStatusState } from '@guardicore-ui/shared/system-status';
import { Observable, EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { CoreStore } from './core';

export function appInitializer(
  authS: AuthenticationStore,
  authQ: AuthenticationQuery,
  coreStore: CoreStore,
  systemStatusService: SystemStatusFacade,
  hybrid: HybridMainFacade,
) {
  return (): Observable<SystemStatusState | null> => {
    systemStatusService.initialize();
    const isLoggedIn = authS.getValue().isLoggedIn;

    if (!isLoggedIn) {
      if (window.location.pathname !== LOGIN_ROUTE) {
        const urlToRedirect = window.location.pathname
          ? LOGIN_ROUTE + `?backlink=${encodeURIComponent(window.location.pathname || '')}`
          : LOGIN_ROUTE;

        location.href = urlToRedirect;
      }

      return EMPTY;
    }

    const id = authQ.id();

    return systemStatusService.readSystemStatus().pipe(
      tap(status => {
        coreStore.login(status);
        hybrid.setAuthData({ id });
      }),
      catchError(() => {
        authS.update(store => ({ ...store }));
        location.href = LOGIN_ROUTE;

        return EMPTY;
      }),
    );
  };
}
