<div class="wrapper">
  <div class="internal-wrapper">
    <div class="cluster-manager-indication" *ngIf="coreQuery.isManagerCentra$ | async">
      <span>Cluster Manager</span>
    </div>
    <gc-quick-links [links]="coreQuery.currentViewQuickLinks$ | async"></gc-quick-links>
    <span class="spacer"></span>
    <section class="right-section">
      <ng-container *ngIf="systemStatus.isMsspUser$ | async">
        <div class="tenant-filter-button">
          <gc-mssp-dropdown [disabled]="!(coreQuery.isMsspApplicable$ | async)"></gc-mssp-dropdown>
        </div>
        <div *ngIf="!(drafts$ | async)?.length" class="divider"></div>
      </ng-container>
      <ng-container *ngIf="(drafts$ | async)?.length">
        <button
          [gcPopoverClickAnchor]="unpublishedMenu"
          class="unpublished-changes-button"
          panelCssClass="header-dropdown-overlay"
          [aligns]="{ horizontal: 'center' }"
        >
          <gc-icon svgIcon="alert" [color]="'warning'"></gc-icon>
          <span>Unpublished Changes</span>
          <gc-icon svgIcon="expand-more"></gc-icon>
        </button>

        <ng-template #unpublishedMenu let-ref>
          <gc-unpublished-changes-menu [drafts$]="drafts$" [ref]="ref"></gc-unpublished-changes-menu>
        </ng-template>
        <div class="divider"></div>
      </ng-container>

      <ng-container *ngIf="installingPatch$ | async">
        <span
          class="live-marker upgrading-message"
          gcTooltip="For further information use
          the management command line tool"
          [aligns]="{ vertical: 'below', horizontal: 'center' }"
        ><gc-icon svgIcon="info"></gc-icon>Upgrading
        </span>
        <div class="divider"></div>
      </ng-container>

      <button
        class="control-button refresh-button"
        gcTooltip="Reload Data"
        [aligns]="{ vertical: 'below', horizontal: 'center' }"
        (click)="pollingService.reloadAll()"
        [disabled]="!(coreQuery.arePollingsAllowed$ | async)"
        gcAnalytics="Data Reload Clicked"
      ><gc-icon svgIcon="refresh"></gc-icon>
      </button>
      <div class="divider"></div>
      <ng-container *ngIf="(notificationsPermissions$ | async)?.view">
        <gc-notifications-bell
          [notificationsTemplate]="notificationsTemplate"
          gcTooltip="System Log Notifications"
          [aligns]="{ vertical: 'below', horizontal: 'center' }"
          [close]="(closeNotifications$ | async) || undefined"
        ></gc-notifications-bell>
        <div class="divider"></div>
      </ng-container>
      <button
        [gcPopoverClickAnchor]="userMenuTemplate"
        class="user-menu-button"
        panelCssClass="header-dropdown-overlay"
        gcAnalytics="User Menu Clicked"
      >
        <span>{{ (user$ | async)?.username }}</span>
        <gc-icon svgIcon="expand-more"></gc-icon>
      </button>

      <ng-template #userMenuTemplate>
        <gc-user-menu
          [userMenuSet]="userMenuSet$ | async"
          [systemStatusLogUrl]="systemStatusLogUrl"
          [isInDebugMode]="(coreQuery.isInDebugMode$ | async) || false"
          [isEvaluationMode]="(coreQuery.isEvaluationMode$ | async) || false"
          (action)="onUserMenuAction($event)">
        </gc-user-menu>
      </ng-template>
    </section>
  </div>
</div>
<ng-template #notificationsTemplate>
  <gc-notifications
    (action)="onNotificationsAction($event)"
    [notificationsPermissions]="notificationsPermissions$ | async"
    [notifications]="notifications$ | async"
    [items]="notificationsItems$ | async"
  >
  </gc-notifications>
</ng-template>
