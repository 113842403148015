import { Inject, Injectable, isDevMode, OnDestroy } from '@angular/core';
import { ModalStatus, HybridMessagePayload } from '@guardicore-ui/shared/data';
import { HotkeyCombination } from '@guardicore-ui/shared/hotkeys';
import { PollingService } from '@guardicore-ui/shared/polling';
import { SystemStatusQuery } from '@guardicore-ui/shared/system-status';
import { fromEvent, Observable, ReplaySubject, Subject, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';

import { HybridConfig, hybridConfigToken, HybridMessage, HybridMessageSubject, AuthData } from '../entities';

const ignoreMultipleLocationChangesThresholdMilliseconds = 500;

const NOT_QUEUED_MESSAGES: HybridMessageSubject[] = [
  HybridMessageSubject.PollingsResume,
  HybridMessageSubject.PollingsStop,
  HybridMessageSubject.ReloadAllApiCalls,
  HybridMessageSubject.Logout,
];

@Injectable({
  providedIn: 'root',
})
export class HybridCommunicationService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private _iframe?: HTMLIFrameElement;
  private oldAppServerConnectivityLost: Subject<boolean> = new Subject();
  private readonly isOldAppLoadingSubj = new ReplaySubject<boolean>(1);
  private openModalBackdropCounter = 0;
  private openModalBackdropSubject: Subject<boolean> = new Subject();
  private matDialogModalSubject: Subject<boolean> = new Subject();
  private draftsSubject: Subject<HybridMessagePayload> = new Subject();
  private sectionMessagesSubject: Subject<HybridMessagePayload> = new Subject();
  private toastsSubject: Subject<HybridMessagePayload> = new Subject();
  private titleSubject: Subject<string> = new Subject();
  private sessionId?: string;
  private messageQueue: { subject: HybridMessageSubject; payload?: HybridMessagePayload }[] = [];
  private lastNavigationMessage?: HybridMessagePayload;
  private toastrSubject: Subject<HybridMessagePayload> = new Subject();
  private dialogSubject: Subject<HybridMessagePayload> = new Subject();
  private analyticsSubject: Subject<HybridMessagePayload> = new Subject();
  private navigationLinkSubject: Subject<HybridMessagePayload> = new Subject();
  private violationsDialogSubject: Subject<HybridMessagePayload> = new Subject();

  readonly isOldAppLoading$ = this.isOldAppLoadingSubj.asObservable();

  public isOldAppServerConnectivityLost$: Observable<boolean> = this.oldAppServerConnectivityLost.asObservable();
  public isModalBackdropOpen$: Observable<boolean> = this.openModalBackdropSubject.asObservable();
  public matDialogModal$: Observable<boolean> = this.matDialogModalSubject.asObservable();
  public drafts$: Observable<HybridMessagePayload> = this.draftsSubject.asObservable();
  public sectionMessages$: Observable<HybridMessagePayload> = this.sectionMessagesSubject.asObservable();
  public title$: Observable<string> = this.titleSubject.asObservable();
  public toastr$: Observable<HybridMessagePayload> = this.toastrSubject.asObservable();
  public analytics$: Observable<HybridMessagePayload> = this.analyticsSubject.asObservable();
  public dialog$: Observable<HybridMessagePayload> = this.dialogSubject.asObservable();
  public navigationLink$: Observable<HybridMessagePayload> = this.navigationLinkSubject.asObservable();
  public violationsDialog$: Observable<HybridMessagePayload> = this.violationsDialogSubject.asObservable();

  set iframe(iframe: HTMLIFrameElement) {
    this.sessionId = undefined;
    this._iframe = iframe;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private _authData?: AuthData;
  set authData(ad: AuthData) {
    this.sessionId = undefined;
    this.isOldAppLoadingSubj.next(true);
    this._authData = ad;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private readonly locationOutputSubj = new Subject<string>();
  readonly location$ = this.locationOutputSubj.pipe(
    distinctUntilChanged(),
    debounceTime(ignoreMultipleLocationChangesThresholdMilliseconds),
  );

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private _isIframeCollapsed = false;
  set isIframeCollapsed(value: boolean) {
    this._isIframeCollapsed = value;
  }

  // Click inside iframe event
  readonly clickOnIframe$ = new Subject<void>();
  readonly hotkey$ = new Subject<HotkeyCombination>();

  constructor(
    @Inject(hybridConfigToken) private readonly config: HybridConfig,
    private readonly pollingService: PollingService,
    systemStatus: SystemStatusQuery,
  ) {
    this.listen();
    pollingService.start$.pipe(takeUntil(this.destroy$)).subscribe(() => this.sendMessage(HybridMessageSubject.PollingsResume));
    pollingService.stop$.pipe(takeUntil(this.destroy$)).subscribe(() => this.sendMessage(HybridMessageSubject.PollingsStop));
    pollingService.reloadAll$.pipe(takeUntil(this.destroy$)).subscribe(() => this.sendMessage(HybridMessageSubject.ReloadAllApiCalls));
    systemStatus.state$
      .pipe(
        distinctUntilChanged((a, b) => a.systemTime === b.systemTime),
        takeUntil(this.destroy$),
      )
      .subscribe(state => this.sendMessage(HybridMessageSubject.SystemStatusUpdate, state, true));
  }

  private listen(): void {
    if (!window) {
      return;
    }

    fromEvent<MessageEvent>(window, 'message')
      .pipe(
        takeUntil(this.destroy$),
        filter(event => event?.origin === location.origin && !!(event?.source as Window)?.frameElement),
        map(event => event.data as HybridMessage),
      )
      .subscribe({
        next: message => this.processMessage(message),
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private resendLastNavigationMessage(): void {
    if (this.lastNavigationMessage) {
      this.sendMessage(HybridMessageSubject.Navigation, this.lastNavigationMessage);
    }
  }

  // TODO: refactor this method as split on multiple ones
  // eslint-disable-next-line max-lines-per-function
  private processMessage(message: HybridMessage): void {
    if (!message || !message.subject) {
      return;
    }

    switch (message.subject) {
      case HybridMessageSubject.Loaded: {
        this.sessionId = message.sessionId;
        this.sendMessage(HybridMessageSubject.Token, { ...this._authData, baseUrl: location.origin }, true);
        this.resendLastNavigationMessage();
        this.isOldAppLoadingSubj.next(false);
        this.processMessageQueue();
        break;
      }

      case HybridMessageSubject.Navigation:
        if (message.sessionId === this.sessionId && message.payload['url']) {
          const url = (message.payload['url'] as string).replace(location.origin, '').replace(this.config.iframeUrl, '');

          if (!url || url === '/') {
            break;
          }

          const target = message.payload['target'];

          if (target && target !== '_self') {
            window.open(url, target, message.payload['params']);

            return;
          }

          this.locationOutputSubj.next(url);
        }

        break;

      case HybridMessageSubject.ServerConnectivityLost:
        if (message.sessionId === this.sessionId) {
          this.oldAppServerConnectivityLost.next(true);
        }

        break;

      case HybridMessageSubject.Click:
        this.clickOnIframe$.next();
        break;

      case HybridMessageSubject.HotKey:
        this.hotkey$.next(message.payload['combination']);
        break;

      case HybridMessageSubject.DialogModal:
        if (message.payload['status'] === ModalStatus.Open) {
          if (this.openModalBackdropCounter === 0) {
            this.openModalBackdropSubject.next(true);
            this.matDialogModalSubject.next(!!message.payload['matDialogModal']);
          }

          this.openModalBackdropCounter += 1;
        } else if (message.payload['status'] === ModalStatus.Close) {
          this.openModalBackdropCounter -= 1;
          if (this.openModalBackdropCounter !== 0) {
            break;
          }

          if (message.payload['matDialogModal']) {
            this.openModalBackdropSubject.next(false);
          } else {
            timer(200)
              .pipe(
                tap(() => this.openModalBackdropSubject.next(false)),
                takeUntil(this.destroy$),
              )
              .subscribe();
          }
        }

        break;

      case HybridMessageSubject.WindowTitle:
        this.titleSubject.next(message.payload?.['title']);
        break;

      case HybridMessageSubject.Draft:
        this.draftsSubject.next(message.payload);
        break;

      case HybridMessageSubject.ReloadAllApiCalls:
        if (message.payload?.['systemStatusOnly']) {
          this.pollingService.reloadSystemStatus();
          break;
        }

        this.pollingService.reloadAll();
        break;

      case HybridMessageSubject.Track:
        this.analyticsSubject.next(message.payload);
        break;

      case HybridMessageSubject.SectionMessage:
        this.sectionMessagesSubject.next(message.payload);
        break;

      case HybridMessageSubject.ToastrMessage: {
        this.toastrSubject.next(message.payload);
        break;
      }

      case HybridMessageSubject.GenericModal: {
        this.dialogSubject.next(message);
        break;
      }

      case HybridMessageSubject.NavigationLink: {
        this.navigationLinkSubject.next(message.payload);
        break;
      }

      case HybridMessageSubject.ViolationsDialog: {
        this.violationsDialogSubject.next(message.payload);
        break;
      }

      default:
        break;
    }
  }

  private processMessageQueue(): void {
    if (this.messageQueue.length === 0) {
      return;
    }

    while (this.messageQueue.length > 0) {
      const message = this.messageQueue.shift();

      if (message) {
        this.sendMessage(message.subject, message.payload, true);
      }
    }
  }

  public sendMessage(subject: HybridMessageSubject, payload?: HybridMessagePayload, force = false): void {
    if (this._isIframeCollapsed && !force) {
      return;
    }

    if (!this._iframe || !this._iframe.contentWindow || !this.sessionId) {
      if (NOT_QUEUED_MESSAGES.includes(subject)) {
        return;
      }

      this.messageQueue.push({ subject, payload });

      return;
    }

    if (isDevMode() && subject === HybridMessageSubject.Navigation) {
      this.lastNavigationMessage = payload;
    }

    this._iframe.contentWindow.postMessage(
      {
        subject,
        sessionId: this.sessionId,
        payload,
      },
      `${location.origin}${this.config.iframeUrl}`,
    );
  }

  public closeModalBackdrop(): void {
    this.sendMessage(HybridMessageSubject.DialogModal);
  }
}
