import { Component, ChangeDetectionStrategy, Input, ContentChildren, QueryList, AfterContentInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NavbarComponentQuery } from '../infrastructure/navbar-component-query.service';
import { NavbarItemComponent } from '../navbar-item/navbar-item.component';

@Component({
  selector: 'gc-navbar-sub-group',
  templateUrl: './navbar-sub-group.component.html',
  styleUrls: ['./navbar-sub-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarSubGroupComponent implements AfterContentInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @ContentChildren(NavbarItemComponent, { descendants: true }) content!: QueryList<NavbarItemComponent>;

  @Input() label?: string;
  @Input() hasBottomSeparator?: boolean;
  @Input() hasTopSeparator?: boolean;

  isVisible$ = new BehaviorSubject<boolean>(false);
  constructor(private query: NavbarComponentQuery) {}

  ngAfterContentInit(): void {
    this.query
      .isSubGroupVisible(this.content.map(item => item.viewName as string))
      .pipe(takeUntil(this.destroy$))
      .subscribe(isVisible => {
        this.isVisible$.next(isVisible);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
