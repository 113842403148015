import { DOCUMENT } from '@angular/common';
import { ElementRef, Inject, Injectable, NgZone } from '@angular/core';
import { Observable, debounceTime, fromEvent, map, share } from 'rxjs';

import { ClickState } from './entities';
import { isInsideHost } from './utils';

@Injectable({
  providedIn: 'root',
})
export class ClickObserver {
  private readonly _click$ = this.ngZone.runOutsideAngular(() => fromEvent<MouseEvent>(this.doc, 'click').pipe(debounceTime(100), share()));

  constructor(@Inject(DOCUMENT) private readonly doc: Document, private readonly ngZone: NgZone) {}

  observe(): Observable<MouseEvent>;
  observe(host: ElementRef | ElementRef[]): Observable<ClickState>;
  observe(host?: ElementRef | ElementRef[]): Observable<MouseEvent | ClickState> {
    if (host && !Array.isArray(host)) {
      host = [host];
    }

    if (host?.length) {
      return this._click$.pipe(
        map(e => {
          const { x, y } = e;
          const isInside = Array.isArray(host) && host?.map(h => isInsideHost({ x, y }, h)).some(Boolean);

          return { isInsideHost: isInside, coord: { x, y } };
        }),
      );
    }

    return this._click$;
  }
}
