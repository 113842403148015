import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { PasswordRequirements } from '@guardicore-ui/shared/system-status';

export const PASSWORD_REQUIREMENTS_VALIDATOR_KEY = 'passwordRequirements';

export interface PasswordRequirementsStatus {
  letter?: boolean;
  lowercase?: boolean;
  minimalLength: boolean;
  number?: boolean;
  symbol?: boolean;
  uppercase?: boolean;
}

export function passwordRequirementsValidator(
  passwordRequirements: PasswordRequirements,
): (abstractControl: AbstractControl) => ValidationErrors | null {
  return (control: AbstractControl): ValidationErrors | null => {
    const requirementsStatus: PasswordRequirementsStatus = {
      minimalLength: Validators.minLength(passwordRequirements.minimalLength)(control) === null,
    };

    if (passwordRequirements.lowercaseRequired || passwordRequirements.uppercaseRequired) {
      if (passwordRequirements.lowercaseRequired) {
        requirementsStatus.lowercase = Validators.pattern(new RegExp('[a-z]'))(control) === null;
      }

      if (passwordRequirements.uppercaseRequired) {
        requirementsStatus.uppercase = Validators.pattern(new RegExp('[A-Z]'))(control) === null;
      }
    } else if (passwordRequirements.letterRequired) {
      requirementsStatus.letter = Validators.pattern(new RegExp('[a-zA-Z]'))(control) === null;
    }

    if (passwordRequirements.numberRequired) {
      requirementsStatus.number = Validators.pattern(new RegExp('[0-9]'))(control) === null;
    }

    if (passwordRequirements.symbolRequired) {
      requirementsStatus.symbol = Validators.pattern(new RegExp('[^a-zA-Z0-9]'))(control) === null;
    }

    return Object.values(requirementsStatus).every(r => r) ? null : { [PASSWORD_REQUIREMENTS_VALIDATOR_KEY]: requirementsStatus };
  };
}

export function passwordRequirementsValidatorInitialStatus(passwordRequirements: PasswordRequirements): PasswordRequirementsStatus {
  const requirementsStatus: PasswordRequirementsStatus = {
    minimalLength: false,
  };

  if (passwordRequirements.lowercaseRequired || passwordRequirements.uppercaseRequired) {
    if (passwordRequirements.lowercaseRequired) {
      requirementsStatus.lowercase = false;
    }

    if (passwordRequirements.uppercaseRequired) {
      requirementsStatus.uppercase = false;
    }
  } else if (passwordRequirements.letterRequired) {
    requirementsStatus.letter = false;
  }

  if (passwordRequirements.numberRequired) {
    requirementsStatus.number = false;
  }

  if (passwordRequirements.symbolRequired) {
    requirementsStatus.symbol = false;
  }

  return requirementsStatus;
}
