export const tenant = `<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.0818 8.53132L15.873 6.05909L16.3953 6.86759L11.6041 9.33982L11.0818 8.53132Z" fill="#4934B7"/>
  <path d="M10.0966 12.4231L6.26062 16.2879L5.52518 15.6824L9.36112 11.8176L10.0966 12.4231Z" fill="#4934B7"/>
  <path d="M8.47601 10.6578H3.61169V9.85088H8.47601V10.6578Z" fill="#4934B7"/>
  <path d="M11.9963 11.613L17.7367 14.8964L16.8222 15.6311L11.0818 12.3477L11.9963 11.613Z" fill="#4934B7"/>
  <path d="M9.23825 9.33982L5.35875 4.16651L6.09125 3.18973L9.97074 8.36304L9.23825 9.33982Z" fill="#4934B7"/>
  <path d="M11.455 9.10906L16.2462 6.63684L16.3953 6.86759L11.6041 9.33982L11.455 9.10906Z" fill="#4934B7"/>
  <path d="M9.57104 11.9905L5.7351 15.8553L5.52518 15.6824L9.36112 11.8176L9.57104 11.9905Z" fill="#4934B7"/>
  <path d="M8.47601 10.0812H3.61169V9.85088H8.47601V10.0812Z" fill="#4934B7"/>
  <path d="M11.3427 12.1381L17.0831 15.4215L16.8222 15.6311L11.0818 12.3477L11.3427 12.1381Z" fill="#4934B7"/>
  <path d="M9.76198 8.64178L5.88248 3.46847L6.09125 3.18973L9.97074 8.36304L9.76198 8.64178Z" fill="#4934B7"/>
  <path d="M5.39439 5.50452C4.08219 5.14069 3.30348 3.745 3.65508 2.38717C4.00668 1.02933 5.35545 0.223529 6.66764 0.587362C7.97984 0.951195 8.75856 2.34688 8.40696 3.70472C8.05536 5.06256 6.70658 5.86836 5.39439 5.50452Z" fill="#A68DFF"/>
  <path d="M16.8547 4.23384C17.8289 4.23384 18.6186 5.05104 18.6186 6.05911C18.6186 7.06718 17.8289 7.88438 16.8547 7.88438C15.8805 7.88438 15.0908 7.06718 15.0908 6.05911C15.0908 5.05104 15.8805 4.23384 16.8547 4.23384Z" fill="#A68DFF"/>
  <path d="M17.7361 14.0527C18.7103 14.0527 19.5 14.8699 19.5 15.878C19.5 16.8861 18.7103 17.7033 17.7361 17.7033C16.7619 17.7033 15.9722 16.8861 15.9722 15.878C15.9722 14.8699 16.7619 14.0527 17.7361 14.0527Z" fill="#4934B7"/>
  <path d="M6.66764 0.587362C6.97966 0.673874 7.27187 0.823152 7.52814 1.02663C7.78441 1.23012 7.99943 1.48384 8.16094 1.77331C8.32245 2.06278 8.42728 2.38234 8.46945 2.71374C8.51161 3.04513 8.49056 3.38186 8.40696 3.70472C8.32335 4.02759 8.17883 4.33027 7.98219 4.59545C7.78555 4.86063 7.54036 5.08314 7.26062 5.25027C6.98087 5.4174 6.67206 5.52587 6.3518 5.5695C6.03155 5.61313 5.7064 5.59104 5.39439 5.50452C5.39439 5.50452 6.78709 5.11897 7.23725 3.3805C7.68742 1.64202 6.66764 0.587362 6.66764 0.587362Z" fill="#654CDB"/>
  <path d="M16.8547 7.88438C16.6231 7.88438 16.3937 7.83717 16.1797 7.74544C15.9657 7.65371 15.7712 7.51926 15.6074 7.34977C15.4437 7.18028 15.3137 6.97906 15.2251 6.75761C15.1364 6.53616 15.0908 6.29881 15.0908 6.05911C15.0908 5.81941 15.1364 5.58206 15.2251 5.36061C15.3137 5.13916 15.4437 4.93794 15.6074 4.76845C15.7712 4.59896 15.9657 4.46451 16.1797 4.37278C16.3937 4.28105 16.6231 4.23384 16.8547 4.23384C16.8547 4.23384 15.959 4.76845 15.959 6.05911C15.959 7.34977 16.8547 7.88438 16.8547 7.88438Z" fill="#654CDB"/>
  <path d="M10.0682 13.4607C8.21226 13.4607 6.70774 11.9039 6.70774 9.98339C6.70774 8.0629 8.21226 6.50604 10.0682 6.50604C11.9241 6.50604 13.4287 8.0629 13.4287 9.98339C13.4287 11.9039 11.9241 13.4607 10.0682 13.4607Z" fill="#654CDB"/>
  <path d="M2.3741 12.2653C1.33906 12.2653 0.5 11.3971 0.5 10.3261C0.5 9.25502 1.33906 8.38677 2.3741 8.38677C3.40913 8.38677 4.2482 9.25502 4.2482 10.3261C4.2482 11.3971 3.40913 12.2653 2.3741 12.2653Z" fill="#654CDB"/>
  <path d="M5.6506 19.5C4.19694 19.5 3.01852 18.2806 3.01852 16.7764C3.01851 15.2721 4.19694 14.0527 5.6506 14.0527C7.10425 14.0527 8.28268 15.2721 8.28268 16.7764C8.28268 18.2806 7.10426 19.5 5.6506 19.5Z" fill="#A68DFF"/>
  <path d="M17.7361 17.7033C17.5044 17.7033 17.2751 17.6561 17.0611 17.5643C16.847 17.4726 16.6526 17.3382 16.4888 17.1687C16.325 16.9992 16.1951 16.798 16.1064 16.5765C16.0178 16.3551 15.9722 16.1177 15.9722 15.878C15.9722 15.6383 16.0178 15.401 16.1064 15.1795C16.1951 14.9581 16.325 14.7568 16.4888 14.5873C16.6526 14.4179 16.8471 14.2834 17.0611 14.1917C17.2751 14.1 17.5044 14.0527 17.7361 14.0527C17.7361 14.0527 16.8404 14.5873 16.8404 15.878C16.8404 17.1687 17.7361 17.7033 17.7361 17.7033Z" fill="#654CDB"/>
  <path d="M10.0682 6.50604C10.5095 6.50604 10.9465 6.59598 11.3542 6.77074C11.7619 6.94549 12.1324 7.20163 12.4444 7.52453C12.7564 7.84743 13.004 8.23077 13.1729 8.65266C13.3417 9.07455 13.4287 9.52673 13.4287 9.98339C13.4287 10.44 13.3417 10.8922 13.1729 11.3141C13.004 11.736 12.7564 12.1193 12.4444 12.4422C12.1324 12.7651 11.7619 13.0213 11.3542 13.196C10.9465 13.3708 10.5095 13.4607 10.0682 13.4607C10.0682 13.4607 11.7747 12.4422 11.7747 9.98339C11.7747 7.52453 10.0682 6.50604 10.0682 6.50604Z" fill="#4934B7"/>
  <path d="M2.3741 8.38677C2.62021 8.38677 2.86391 8.43693 3.09128 8.53439C3.31866 8.63185 3.52526 8.7747 3.69929 8.95478C3.87331 9.13486 4.01136 9.34864 4.10554 9.58393C4.19972 9.81921 4.2482 10.0714 4.2482 10.3261C4.2482 10.5807 4.19972 10.8329 4.10554 11.0682C4.01136 11.3035 3.87331 11.5173 3.69929 11.6973C3.52526 11.8774 3.31866 12.0203 3.09128 12.1177C2.86391 12.2152 2.62021 12.2653 2.3741 12.2653C2.3741 12.2653 3.32578 11.6973 3.32578 10.3261C3.32578 8.95478 2.3741 8.38677 2.3741 8.38677Z" fill="#4934B7"/>
  <path d="M5.6506 14.0527C5.99625 14.0527 6.33851 14.1232 6.65785 14.2601C6.97719 14.3969 7.26735 14.5976 7.51176 14.8505C7.75617 15.1034 7.95005 15.4036 8.08232 15.7341C8.2146 16.0645 8.28268 16.4187 8.28268 16.7764C8.28268 17.134 8.2146 17.4882 8.08232 17.8187C7.95005 18.1491 7.75617 18.4494 7.51176 18.7023C7.26735 18.9552 6.97719 19.1558 6.65785 19.2927C6.33851 19.4296 5.99625 19.5 5.6506 19.5C5.6506 19.5 6.98718 18.7023 6.98718 16.7764C6.98718 14.8505 5.6506 14.0527 5.6506 14.0527Z" fill="#654CDB"/>
</svg>

`;
