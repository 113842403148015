import { Icon } from './icon';
import { RowOperationDivider } from './row-operation';

export interface OperationItem<IdType> {
  id: IdType | RowOperationDivider;
  title: string;
  icon?: Icon;
  isHidden?: boolean;
  isDisabled?: boolean;
  tooltip?: string;
  rowClass?: string;
}
