import { Component, ChangeDetectionStrategy, ContentChildren, QueryList, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { QueryParams } from '@guardicore-ui/shared/data';
import { SvgIconName } from '@guardicore-ui/shared/data';
import { PopoverHoverAnchorDirective } from '@guardicore-ui/ui/popovers/popover';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { NavbarComponentQuery } from '../infrastructure/navbar-component-query.service';
import { NavbarItemState } from '../infrastructure/navbar-state';
import { NavbarItemComponent } from '../navbar-item/navbar-item.component';

@Component({
  selector: 'gc-navbar-group',
  templateUrl: './navbar-group.component.html',
  styleUrls: ['./navbar-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarGroupComponent implements OnDestroy, OnInit {
  private readonly destroy$ = new Subject<void>();
  @ContentChildren(NavbarItemComponent, { descendants: true }) content!: QueryList<NavbarItemComponent>;
  @ViewChild(PopoverHoverAnchorDirective) popoverPanel?: PopoverHoverAnchorDirective;

  @Input() label?: string;
  @Input() viewName?: string;
  @Input() url?: string;
  @Input() queryParams: QueryParams = {};
  @Input() icon!: SvgIconName;

  state$?: Observable<NavbarItemState | undefined>;
  isActive$ = this.query.activeItem$.pipe(
    takeUntil(this.destroy$),
    switchMap(active => {
      if (!active) {
        return of(false);
      }

      return of([...(this.content || []).map(item => item.viewName), ...[this.viewName]].includes(active));
    }),
    tap(isActive => {
      if (isActive && this.popoverPanel) {
        this.popoverPanel.close();
      }
    }),
  );

  constructor(private query: NavbarComponentQuery, private router: Router) {}

  analyticsProps(state?: NavbarItemState | null): { [key: string]: string } {
    return {
      item: this.label ?? '',
      route: this.url ?? '',
      currentRoute: this.router.url.split('?')[0],
      isDisabled: (state?.disabled ?? 'false').toString(),
    };
  }

  ngOnInit(): void {
    if (!this.label) {
      throw new Error(`Label wasn't provided for the navbar group`);
    }

    if (!this.viewName) {
      this.viewName = this.label;
    }

    const viewName = this.viewName as string;

    this.state$ = this.query.itemState(viewName);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
