import { Component, ChangeDetectionStrategy, Inject, Input, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DialogData } from './dialog-data';

@Component({
  selector: 'gc-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent {
  @Input() icon?: string;
  @Input() set dialogTitle(title: string | undefined) {
    this._title = title;
  }

  get title(): string | undefined {
    return this._title;
  }

  @Input() content?: string;
  @Input() primaryButtonLabel?: string;
  @Input() primaryButtonDisabled?: boolean;
  @Input() secondaryButtonLabel?: string;
  @Input() secondaryButtonsGhost?: boolean;
  @Input() showConfirmAsDanger?: boolean;
  @Input() closeIcon?: boolean;
  @Input() helpLink?: string;
  @Input() keepOpenAfterConfirm?: boolean;

  @Output() primaryButtonClicked = new EventEmitter<void>();
  @Output() secondaryButtonClicked = new EventEmitter<void>();

  protected _title: string | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: DialogData = {},
    private readonly ref: MatDialogRef<DialogComponent>,
  ) {
    this.icon = data.icon;
    this._title = data.title;
    this.content = data.content;
    this.primaryButtonLabel = data.primaryButtonLabel;
    this.secondaryButtonLabel = data.secondaryButtonLabel;
    this.showConfirmAsDanger = data.showConfirmAsDanger;
    this.primaryButtonDisabled = data.primaryButtonDisabled;
    this.secondaryButtonsGhost = data.secondaryButtonsGhost;
    this.closeIcon = data.closeIcon;
    this.helpLink = data.helpLink;
    this.keepOpenAfterConfirm = data.keepOpenAfterConfirm;
  }

  close(): void {
    this.ref.close({ closeIcon: true });
  }

  dismiss(): void {
    if (this.data.keepOpenAfterDismiss) {
      this.secondaryButtonClicked.next();

      return;
    }

    this.ref.close({ dismissed: true });
  }

  confirm(): void {
    if (this.keepOpenAfterConfirm) {
      this.primaryButtonClicked.next();

      return;
    }

    this.ref.close({ confirmed: true });
  }
}
