import { Component, ChangeDetectionStrategy } from '@angular/core';

import { MultiStateDialogBaseComponent } from '../multi-state-dialog-base/multi-state-dialog-base.component';

@Component({
  selector: 'gc-new-navbar-introduction',
  templateUrl: './new-navbar-introduction.component.html',
  styleUrls: ['./new-navbar-introduction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewNavbarIntroductionComponent extends MultiStateDialogBaseComponent {
  analyticsProps(): { [key: string]: string } {
    return { slide: `${this.state$.getValue() + 1}` };
  }
}
