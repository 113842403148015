import { Injectable } from '@angular/core';
import { Project } from '@guardicore-ui/projects/domain';
import { GridComponentStore } from '@guardicore-ui/ui/grid';

@Injectable()
export class ProjectsFeatureStore extends GridComponentStore<Project> {
  constructor() {
    super(ProjectsFeatureStore.name);
  }
}
