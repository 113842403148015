<ng-container *ngIf="{ state: state$ | async } as state">
  <div class="wrapper" *ngIf="!state.state?.hidden">
    <div *ngIf="isActive$ | async" class="active-mark" automation-id="navbar-group__active-mark"></div>

    <ng-container *ngIf="url else panel">
      <a *ngIf="!state.state?.disabled else disabled"
         [routerLink]="url"
         [queryParams]="queryParams"
         [state]="{ navigationReference: 'Navbar', navigationLinkText: label }"
      >
        <div class="group-button link" automation-id="navbar-group__router-link">
          <ng-template [ngTemplateOutlet]="groupsButton"></ng-template>
        </div>
      </a>
    </ng-container>

    <ng-template #disabled>
      <div
        class="group-button disabled"
        [gcTooltip]="state.state?.tooltip"
        [panelCssClass]="'tooltip white-tooltip'"
        [aligns]="{ vertical: 'center', horizontal: 'after' }"
      >
        <ng-template [ngTemplateOutlet]="groupsButton"></ng-template>
      </div>
    </ng-template>

    <ng-template #panel>
      <div class="group-button"
           automation-id="navbar-group__panel-hover"
           [gcPopoverHoverAnchor]="group"
           [panelCssClass]="'dashboard-group'"
           [aligns]="{ vertical: 'center', horizontal: 'after' }"
           [margin]="10"
           [closeTransition]="'0ms'"
           [openTransition]="'0ms'"
           [delays]="{ show: 300, hide: 300 }"
      >
        <ng-template [ngTemplateOutlet]="groupsButton"></ng-template>
      </div>
    </ng-template>
  </div>

  <ng-template #groupsButton>
    <div class="inner-wrapper">
      <gc-icon [svgIcon]="icon"></gc-icon>
      <p class="title" automation-id="navbar-group__title">{{label}}</p>
    </div>
  </ng-template>

  <ng-template #group>
    <div class="group-panel">
      <ng-content></ng-content>
    </div>
  </ng-template>
</ng-container>
