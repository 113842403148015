import { Clipboard } from '@angular/cdk/clipboard';
import { Component, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, from, Subscription } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

@Component({
  selector: 'gc-text-copy',
  templateUrl: './text-copy.component.html',
  styleUrls: ['./text-copy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextCopyComponent implements OnDestroy {
  @Input() textToCopy?: string;

  private popupSub!: Subscription;
  public iconType$ = new BehaviorSubject<string>('clipboard');
  isTextCopied = false;
  timeoutDelay = 5000;

  constructor(private clipboard: Clipboard) {}

  private copyToClipboard(): string {
    if (this.textToCopy === undefined) {
      throw new Error('No text to copy given');
    }

    this.clipboard.copy(this.textToCopy);

    return this.textToCopy;
  }

  copyToClipboardClickHandler(): void {
    this.popupSub?.unsubscribe();
    this.popupSub = from(this.copyToClipboard())
      .pipe(
        tap(() => {
          this.isTextCopied = true;
          this.iconType$.next('done-circle');
        }),
        delay(this.timeoutDelay),
        tap(() => {
          this.isTextCopied = false;
          this.iconType$.next('clipboard');
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.popupSub?.unsubscribe();
  }
}
