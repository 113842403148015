import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormFieldComponent } from './form-field/form-field.component';
import { InputDirective } from './input.directive';
import { PrefixDirective } from './prefix.directive';
import { SuffixDirective } from './suffix.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [FormFieldComponent, InputDirective, SuffixDirective, PrefixDirective],
  exports: [FormFieldComponent, InputDirective, SuffixDirective, PrefixDirective],
})
export class UiFormComponentsModule {}
