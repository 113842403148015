<div class="gc-dialog-content" *ngIf="this.steps$ | async as steps">
  <div class="gc-dialog-content-header">
    <h3>
      {{ steps[currentStepIndex].title }}
      <span *ngIf="steps.length > 1"> (Step {{ currentStepIndex + 1 }} / {{ steps.length }}) </span>
    </h3>
  </div>
  <div class="gc-dialog-content-body">
    <iframe
      *ngIf="steps[currentStepIndex]?.resourceUrl as srcUrl"
      class="document-iframe"
      [src]="srcUrl"
      frameborder="0"
      width="100%"
      height="100%"
    ></iframe>
  </div>
  <div class="gc-dialog-content-footer">
    <button class="primary-button" (click)="onAccept()">I Accept</button>
    <button class="default-button" (click)="onCancel()">Log Out</button>
  </div>
</div>
