<div class="gc-dialog-content" automation-id="violation-modal">
  <form [formGroup]="form" (ngSubmit)="apply()">
    <div class="gc-dialog-content-header">
      <h3 automation-id="violations-title">View violations</h3>
      <button class="header-close-button close" type="button" (click)="close()">
        <gc-icon svgIcon="close"></gc-icon>
      </button>
    </div>
    <div class="gc-dialog-content-body">
      <ng-container *ngIf="loadingData | async">
        <div class="disabled-overlay"></div>
        <div class="data-loading-spinner">
          <gc-icon svgIcon="spinner"></gc-icon>
        </div>
      </ng-container>
      <div class="content-block">
        <div class="input-row" automation-id="violations-last-loaded">
          <input type="radio"
            id="last-loaded"
            formControlName="violationsMap"
            name="violationsMap"
            [value]="violationsMap.lastLoaded"
            [attr.disabled]="!savedMaps?.length ? true : null">
          <label for="last-loaded">Last loaded maps</label>
        </div>
        <div class="actions">
          <button
            automation-id="violations-loaded__actions-button"
            class="default-button actions-button"
            type="button"
            (opened)="setViolationsMap('lastLoaded')"
            [disabled]="!savedMaps?.length"
            [gcPopoverClickAnchor]="actionsTemplate"
            [panelCssClass]="'dropdown-actions-list'"
            [aligns]="{ vertical: 'below', horizontal: 'start' }"
            [margin]="4"
          >
            <span>{{ selected[violationsMap.lastLoaded]?.value || 'Select a map' }}</span>
            <gc-icon svgIcon="expand-more"></gc-icon>
          </button>
          <ng-template #actionsTemplate>
            <gc-action-list *ngIf="savedMaps" (itemClick)="chooseAction($event)">
              <ng-container *ngFor="let savedMap of savedMaps; let last = last">
                <button type="button" class="action-button" action-list-item [showDivider]="false" [itemId]="savedMap.id">
                  <div class="map-main-text">
                    <span>{{ savedMap?.startTimeFilter | date: 'yyyy-MM-dd' }} - {{ savedMap?.endTimeFilter | date: 'yyyy-MM-dd'}}</span>
                  </div>
                  <div class="map-additional-text">
                    <span>Last used {{ (savedMap?.lastAccessTime || savedMap.creationTime || 0 ) | timeAgo : false }}</span>
                    <span class="separator"></span>
                    <span>Storage Size: {{ (savedMap?.storageSizeBytes || 0) | formatBytes: 1:false }}</span>
                  </div>
                </button>
              </ng-container>
            </gc-action-list>
          </ng-template>
        </div>
      </div>
      <div class="content-block">
        <div class="input-row" automation-id="violations-timeline">
          <input type="radio" id="timeline" formControlName="violationsMap" name="violationsMap" [value]="violationsMap.timeline">
          <label for="timeline">Select the timeline you wish to generate</label>
        </div>
        <div class="actions">
          <button
            #timelineButton
            automation-id="violations-timeline__actions-button"
            class="default-button actions-button"
            type="button"
            (opened)="setViolationsMap('timeline')"
            [gcPopoverClickAnchor]="timelineTemplate"
            [panelCssClass]="'dropdown-actions-list'"
            [aligns]="{ vertical: 'below', horizontal: 'start' }"
            [margin]="4"
          >
            <span>{{ timelineDates || 'Select dates' }}</span>
            <gc-icon svgIcon="expand-more"></gc-icon>
          </button>
          <ng-template #timelineTemplate>
            <gc-datetime-picker
              formControlName="timeline"
              name="timeline"
              [minDate]="minDate"
              [maxDate]="maxDate">
            </gc-datetime-picker>
            <div class="datepicker-footer">
              <button class="ghost-button" (click)="setTimeline(undefined); closeDatetimePicker()">Clear</button>
              <button class="primary-button" (click)="selectRange(); closeDatetimePicker()" [disabled]="!timelineDates">Apply</button>
            </div>
          </ng-template>
        </div>
      </div>
      <div *ngIf="showRangeWarning" class="warning">
        <gc-icon svgIcon="warning-transparent"></gc-icon>
        <span class="message">Time range limited to {{maxRange}} days only, please revise your selection</span>
      </div>
    </div>
    <div class="gc-dialog-content-footer">
      <button
        type="button"
        class="ghost-button"
        (click)="close()"
        automation-id="violation-modal__close-button">
          Close
      </button>
      <button
        type="submit"
        class="primary-button"
        [disabled]="isApplyDisabled"
        automation-id="violation-modal__apply-button">
          Apply
      </button>
    </div>
  </form>
</div>
