import { Direction, OpenPopoverOptions } from '../entities';

export function getDirection(config: OpenPopoverOptions): Direction {
  // Forbidden combinations
  if (
    !config.alignment ||
    (config.alignment.horizontal === 'center' && config.alignment.vertical === 'center') ||
    ((config.alignment.vertical === 'above' || config.alignment.vertical === 'below') &&
      (config.alignment.horizontal === 'before' || config.alignment.horizontal === 'after'))
  ) {
    throw new Error('Leaf is not applicable with this alignment.');
  }

  if (config.alignment.vertical === 'below') {
    return 'top';
  } else if (config.alignment.vertical === 'above') {
    return 'bottom';
  }

  if (config.alignment.horizontal === 'before') {
    return 'right';
  } else if (config.alignment.horizontal === 'after') {
    return 'left';
  }

  throw new Error('Leaf is not applicable with this alignment.');
}
