import { Inject, Injectable } from '@angular/core';
import { RouteData } from '@guardicore-ui/shared/data';

import { HybridConfig, hybridConfigToken, HybridMessageSubject, AuthData } from '../entities';
import { HybridCommunicationService } from '../infrastructure';

@Injectable({ providedIn: 'root' })
export class HybridUiFacade {
  readonly iframeUrl = this.config.iframeUrl;
  readonly location$ = this.communicationService.location$;
  readonly clickOnIframe$ = this.communicationService.clickOnIframe$;

  constructor(
    private readonly communicationService: HybridCommunicationService,
    @Inject(hybridConfigToken) private readonly config: HybridConfig,
  ) {}

  setIframe(iframe: HTMLIFrameElement): void {
    this.communicationService.iframe = iframe;
  }

  setAuthData(ad: AuthData): void {
    this.communicationService.authData = ad;
  }

  sendNavMessage(routeData: RouteData): void {
    const queryParams: { [key: string]: string | number | null | undefined } = {};

    routeData.queryParamMap?.keys.forEach(key => {
      queryParams[key] = routeData?.queryParamMap?.get(key);
    });

    this.communicationService.sendMessage(HybridMessageSubject.Navigation, {
      navState: routeData.navState,
      params: { ...routeData.params, ...queryParams },
    });
  }
}
