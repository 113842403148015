import { Directive, OnDestroy, Output, EventEmitter, ElementRef } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { filter, tap, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[gcHover]',
})
export class HoverDirective implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private isHovered = false;
  @Output() readonly gcHover = new EventEmitter<boolean>();
  constructor(readonly el: ElementRef) {
    fromEvent(el.nativeElement, 'mouseover')
      .pipe(
        filter(() => !this.isHovered),
        tap(() => {
          this.isHovered = true;
          this.gcHover.emit(this.isHovered);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
    fromEvent(el.nativeElement, 'mouseleave')
      .pipe(
        tap(() => {
          this.isHovered = false;
          this.gcHover.emit(this.isHovered);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
