import { animate, query, stagger, state, style, transition, trigger } from '@angular/animations';

export const TOASTR_ANIMATIONS = [
  trigger('toast', [
    state('show', style({ opacity: 1 })),
    state('hide', style({ opacity: 0 })),
    transition(
      '* => hide',
      query(':self', [animate('0.3s', style({ opacity: 0 })), stagger(3000, animate('0.3s ease-out', style({ height: 0 })))]),
    ),
    transition('void => *', [style({ opacity: 0 }), animate('0.3s', style({ opacity: 1 }))]),
  ]),
];
