import { Component, ChangeDetectionStrategy, ViewChildren, QueryList, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, filter, map, tap } from 'rxjs';

import { TemplateStateIndexDirective } from './template-state-index.directive';

@Component({
  selector: 'gc-multi-state-dialog-base',
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiStateDialogBaseComponent implements AfterViewInit {
  @ViewChildren(TemplateStateIndexDirective) statesTemplates?: QueryList<TemplateStateIndexDirective>;

  state$ = new BehaviorSubject<number>(0);
  stateTemplate$ = this.state$.pipe(
    filter(() => !!this.statesTemplates),
    tap(state => {
      this.lastState = state === (this.statesTemplates?.length || 0) - 1;
    }),
    map(stateIndex => {
      const template = this.statesTemplates?.find(statesTemplate => statesTemplate.index === stateIndex)?.templateRef;

      return template ? template : null;
    }),
  );

  lastState = false;

  constructor(readonly ref: MatDialogRef<MultiStateDialogBaseComponent>, private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.state$.next(this.state$.getValue());
    this.cd.detectChanges();
  }

  nextState(): void {
    if (this.lastState) {
      return;
    }

    const currentState = this.state$.getValue();

    this.state$.next(currentState + 1 === this.statesTemplates?.length ? 0 : currentState + 1);
  }
}
