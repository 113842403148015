import { Pipe, PipeTransform } from '@angular/core';

import { formatBytes } from './format-bytes.util';

@Pipe({
  name: 'formatBytes',
})
export class FormatBytesPipe implements PipeTransform {
  public transform(value: number, roundDigits = 0, toLower = true): string {
    return formatBytes(value, roundDigits, toLower);
  }
}
