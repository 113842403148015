type CamelizeString<T extends PropertyKey> = T extends string
  ? string extends T
    ? string
    : T extends `${infer F}_${infer R}`
    ? `${F}${Capitalize<CamelizeString<R>>}`
    : T
  : T;

export type Camelize<T> = T extends Array<infer R>
  ? Array<Camelize<R>>
  : {
      [K in keyof T as CamelizeString<K>]: K extends number
        ? Camelize<T[K]>
        : T[K] extends Record<string, unknown>
        ? Camelize<T[K]>
        : T[K] extends Array<infer R2>
        ? Array<Camelize<R2>>
        : T[K];
    };

type CharSelfOrSnaked<T extends string> = string extends T
  ? T
  : T extends `${Uppercase<T>}`
  ? T extends `${Lowercase<T>}`
    ? T
    : `_${Lowercase<T>}`
  : T;

type SnakeizeString<T extends PropertyKey> = T extends string
  ? string extends T
    ? T
    : T extends `${infer First}${infer Rest}`
    ? `${CharSelfOrSnaked<First>}${SnakeizeString<Rest>}`
    : T
  : T;

export type Snakeize<T> = T extends Array<infer R>
  ? Array<Snakeize<R>>
  : {
      [K in keyof T as SnakeizeString<K>]: K extends number
        ? Snakeize<T[K]>
        : T[K] extends Record<string, unknown>
        ? Snakeize<T[K]>
        : T[K] extends Array<infer R2>
        ? Array<Snakeize<R2>>
        : T[K];
    };
