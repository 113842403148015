import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AnalyticsModule } from '@guardicore-ui/analytics';
import { UiCommonModule } from '@guardicore-ui/ui/common';
import { UiIconModule } from '@guardicore-ui/ui/icon';
import { UiPopoverModule } from '@guardicore-ui/ui/popovers/popover';
import { UiTooltipModule } from '@guardicore-ui/ui/popovers/tooltip';

import { NavbarComponent } from './navbar/navbar.component';
import { NavbarGroupComponent } from './navbar-group/navbar-group.component';
import { NavbarItemComponent } from './navbar-item/navbar-item.component';
import { NavbarSubGroupComponent } from './navbar-sub-group/navbar-sub-group.component';

@NgModule({
  imports: [UiCommonModule, CommonModule, RouterModule, UiTooltipModule, UiPopoverModule, AnalyticsModule, UiIconModule],
  declarations: [NavbarItemComponent, NavbarComponent, NavbarGroupComponent, NavbarSubGroupComponent],
  exports: [NavbarComponent],
})
export class NavbarUiModule {}
