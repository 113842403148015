import { Injectable } from '@angular/core';
import { SVG_NAMES, SvgIconName } from '@guardicore-ui/shared/data';

import { SVG_DATA } from './svg-data';

@Injectable({
  providedIn: 'root',
})
export class SvgService extends Map<SvgIconName, string> {
  constructor() {
    super();
    SVG_NAMES.forEach(svgName => this.set(svgName, SVG_DATA[svgName]));
  }

  getNamedSvgIcon(svgName: SvgIconName): HTMLElement | Error {
    const stringContainingXMLSource = this.get(svgName);

    if (!stringContainingXMLSource) {
      return new Error(`${svgName} was not found`);
    }

    return new DOMParser().parseFromString(stringContainingXMLSource, 'image/svg+xml').documentElement;
  }
}
