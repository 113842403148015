import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { GcSelectionCell, RowSelection, RowSelectEventType } from '../entities/row-selection';

@Component({
  selector: 'gc-select-row',
  templateUrl: './select-row.component.html',
  styleUrls: ['./select-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectRowComponent implements ICellRendererAngularComp {
  isMarked = false;
  public params?: GcSelectionCell;
  readonly selectedRowClassName = 'gc-selected-row';
  constructor(private changeDetectionRef: ChangeDetectorRef) {}

  refresh(params: GcSelectionCell): boolean {
    this.setConfiguration(params);

    return true;
  }

  agInit(params: GcSelectionCell): void {
    this.setConfiguration(params);
  }

  private setConfiguration(params: GcSelectionCell): void {
    this.params = params;

    const { selectedRowIds, unselectedRowIds, isUnselectionMode, currentPageState } = this.params.configuration.getSelectionState();
    const isMarked = ['allGrid', 'allPage'].includes(currentPageState)
      ? true
      : ['none', 'deselectPage'].includes(currentPageState)
      ? false
      : isUnselectionMode
      ? !unselectedRowIds.has(this.params.data.id)
      : selectedRowIds.has(this.params.data.id);

    if (this.isMarked !== isMarked) {
      this.params.eGridCell?.parentElement?.classList.toggle(this.selectedRowClassName);
      this.isMarked = isMarked;
      this.changeDetectionRef.markForCheck();
    }
  }

  toggleSelection(value: boolean): void {
    this.isMarked = value;
    this.params?.eGridCell?.parentElement?.classList.toggle(this.selectedRowClassName);
    const selection: RowSelection<RowSelectEventType> = {
      selectedRowType: this.isMarked ? 'select' : 'deselect',
      rowId: this.params?.node.data.id,
      rowData: this.params?.node.data,
    };

    this.params?.rowSelection$.next(selection);
  }
}
