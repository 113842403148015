import { Pipe, PipeTransform } from '@angular/core';

import { EllipsisConfiguration } from './types';

@Pipe({
  name: 'ellipsis',
})
export class EllipsisPipe implements PipeTransform {
  public transform(value: string, ellipsisConfiguration = new EllipsisConfiguration()): string {
    if (ellipsisConfiguration.isEllipsisRequired(value)) {
      const firstChunk = value.substring(0, ellipsisConfiguration.firstChunkLength);
      const lastChunk = value.substring(value.length - ellipsisConfiguration.lastChunkLength, value.length);

      return `${firstChunk}...${lastChunk}`;
    }

    return value;
  }
}
