export const APPLICATION_ADMIN_VIEWS = [
  'Aggregators',
  'Auditing',
  'Collectors',
  'Configuration',
  'Deception Servers',
  'Cloud App',
  'Deployment',
  'Detection',
  'Email',
  'Firewalls',
  'Inspection Policy',
  'Integrity Monitoring',
  'Templates',
  'Log',
  'Mitigation',
  'Orchestrations',
  'Permission Schemes',
  'Plugins',
  'Repo Key',
  'Reputation',
  'Slack',
  'STIX',
  'Azure Threat Feed',
  'Interflow',
  'Syslog',
  'User Directories',
  'Users',
];

export const APPLICATION_VIEWS = [
  ...APPLICATION_ADMIN_VIEWS,
  'Security Events',
  'Agent Installation Profiles',
  'Agent Installation',
  'Agent Upgrade History',
  'Agents Log',
  'Agents',
  'All Incident Groups',
  'All Incidents',
  'Integrity Violations',
  'API Documentation',
  'Agent SDK API Documentation',
  'Asset',
  'Assets',
  'Cloud',
  'DNS Security',
  'Create Policy',
  'Dashboard',
  'DNS Requests',
  'Explore',
  'Guardicore Query',
  'Incident',
  'Integrity Log',
  'Kubernetes Clusters',
  'Label Groups',
  'Labels Log',
  'Labels',
  'Network Log',
  'Projects',
  'Redirection Log',
  'Reputation Log',
  'Revisions',
  'Saved Maps',
  'Scheduled Queries',
  'Segmentation Rules',
  'User Groups',
  'Centra Cluster',
];

export type ApplicationViewName = typeof APPLICATION_VIEWS[number];

export interface ViewToRbacNameMapping {
  [key: string]: ApplicationViewName;
}

export function isAdminView(viewName: string): boolean {
  return APPLICATION_ADMIN_VIEWS.includes(viewName);
}

export const CLUSTER_INSTANCE_VIEWS_TO_HIDE: ApplicationViewName[] = ['Projects', 'Orchestrations', 'Create Policy'];

export const CLUSTER_MANAGER_ALLOWED_VIEWS: ApplicationViewName[] = [
  'Aggregators',
  'Centra Cluster',
  'Auditing',
  'Configuration',
  'Email',
  'Orchestrations',
  'User Groups',
  'Permission Schemes',
  'Slack',
  'STIX',
  'Azure Threat Feed',
  'Interflow',
  'Syslog',
  'User Directories',
  'Users',
  'Agent Installation Profiles',
  'Agent Installation',
  'Agents',
  'API Documentation',
  'Agent SDK API Documentation',
  'Asset',
  'Assets',
  'Explore',
  'Labels Log',
  'Labels',
  'Network Log',
  'Revisions',
  'Saved Maps',
  'Segmentation Rules',
  'Log',
];
