<form [formGroup]="form" (ngSubmit)="save()">
  <div class="gc-dialog-content">
    <div class="gc-dialog-content-header">
      <h3>Edit User Preferences</h3>
      <button class="header-close-button" type="button" (click)="cancel()">
        <gc-icon svgIcon="close"></gc-icon>
      </button>
    </div>
    <div class="gc-dialog-content-body">
      <div class="form-row">
        <div class="form-label">Color blind mode</div>
        <input id="color-blind" type="checkbox" formControlName="isColorBlind">
        <label for="color-blind">Make Reveal color suitable for color blindness</label>
      </div>
    </div>
    <div class="gc-dialog-content-footer">
      <button type="button" class="default-button" (click)="cancel()">Cancel</button>
      <button type="submit" class="primary-button">Save</button>
    </div>
  </div>
</form>
