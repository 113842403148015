<div class="wrapper" *ngIf="state$ | async as state">
  <section class="search-section" *ngIf="withSearchBox">
    <gc-form-field fullWidth>
      <input gcInput type="search" [formControl]="searchBoxControl" [placeholder]="searchBoxPlaceholder"/>
    </gc-form-field>
  </section>
  <div class="loading" *ngIf="state.isLoading; else content">
    <gc-icon svgIcon="spinner"></gc-icon>
  </div>
  <ng-template #content>
    <section class="content">
      <ul class="selected" *ngIf="state.selectedOptions.length > 0 && !state.searchTerm">
        <li *ngFor="let ent of state.selectedOptions">
          <gc-filters-content-row [entity]="ent" [inputType]="inputType" (selected)="processSelection(ent.value)"
                                  [withCount]="withCount"></gc-filters-content-row>
        </li>
      </ul>
      <ul class="available" *ngIf="state.filteredAvailableOptions.length > 0; else noAvailabaleOptions">
        <li *ngFor="let ent of state.filteredAvailableOptions">
          <gc-filters-content-row [entity]="ent" [inputType]="inputType" (selected)="processSelection(ent.value)"
                                  [withCount]="withCount"></gc-filters-content-row>
        </li>
      </ul>

      <ng-template #noAvailabaleOptions>
        <p class="no-available-options">No additional options found</p>
      </ng-template>
    </section>
    <p class="load-more" *ngIf="state.showLoadMore" (click)="loadMoreAction()">
      Load more ({{ state.filteredAvailableOptions.length }}/{{ state.totalCount }})
    </p>
    <div class="spacer"></div>
  </ng-template>
</div>
<footer *ngIf="withActions">
  <button gc-outline-button (click)="action('clear')">{{clearBtnText}}</button>
  <button gc-outline-button (click)="action('apply')">{{applyBtnText}}</button>
</footer>
