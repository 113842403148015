<div class="warning" *ngIf="customWarning">
  <gc-icon svgIcon="alert"></gc-icon>
  <p>{{ customWarning }}</p>
</div>

<div class="warning conflict" *ngIf="hasConflict">
  <gc-icon svgIcon="alert"></gc-icon>
  <p>
    <span>Multiple Different Values </span>
    <span class="conflict-action" (click)="toggleOverrideConflict.emit()">{{ overrideConflict ? 'Keep Unchanged' : 'Override' }}</span>
  </p>
</div>
