import { SatPopoverHorizontalAlign, SatPopoverVerticalAlign } from '@ncstate/sat-popover';

export type AnchorCategory = 'click' | 'hover';

export interface PopoverConfigurationAlign {
  vertical: SatPopoverVerticalAlign;
  horizontal: SatPopoverHorizontalAlign;
}

export interface PopoverConfigurationHoverDelays {
  show: number;
  hide: number;
}

export interface PopoverConfigurationLeaf {
  size?: number;
  position?: number;
}

export interface PopoverConfiguration {
  category?: AnchorCategory;
  aligns: PopoverConfigurationAlign;
  margin: number;
  closeTransition: string;
  openTransition: string;
  delays: PopoverConfigurationHoverDelays;
  withLeaf: boolean;
  leafConfig?: PopoverConfigurationLeaf;
  panelCssClass?: string;
}

export function createDefaultConfiguration(): PopoverConfiguration {
  return {
    aligns: {
      vertical: 'below',
      horizontal: 'center',
    },
    margin: 0,
    closeTransition: '50ms',
    openTransition: '50ms',
    delays: {
      show: 350,
      hide: 400,
    },
    withLeaf: false,
  };
}
