import { Location } from '@angular/common';
import { Provider } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, Query } from '@datorama/akita';
import {
  Constructor,
  DEFAULT_PAGE_SIZE,
  DEFINED_QUERY_PARAMS,
  FeatureWithPaginationAndFiltersState,
  FiltersState,
  RowDataObject,
  createDefaultPageState,
  createInitialFeatureWithPaginationAndFiltersState,
  parseSortParam,
  sortStateToSortParam,
} from '@guardicore-ui/shared/data';
import { Subject, takeUntil } from 'rxjs';

import { updateLocationWithQueryParams } from '../operators';
import { parseIntQueryParam } from '../parsers';

export abstract class FeatureWithPaginationAndFilters<T extends RowDataObject = RowDataObject> extends Store<
  FeatureWithPaginationAndFiltersState<T>
> {
  protected readonly destroy$ = new Subject<void>();

  constructor(name: string, route: ActivatedRoute, private readonly location: Location) {
    super(createInitialFeatureWithPaginationAndFiltersState(), { name });
    route.queryParamMap.pipe(takeUntil(this.destroy$)).subscribe(paramMap => this.updateFromQueryParams(paramMap));
  }

  updateState(state: Partial<FeatureWithPaginationAndFiltersState>): void {
    const newState = Object.keys(state)
      .filter(key => key !== 'entities')
      .map(key => ({
        [key]: {
          ...this.getValue()[key as keyof FeatureWithPaginationAndFiltersState],
          ...state[key as keyof FeatureWithPaginationAndFiltersState],
        },
      }))
      .reduce<Partial<FeatureWithPaginationAndFiltersState>>((prev, curr) => ({ ...prev, ...curr }), {});

    if (state.entities) {
      newState.entities = state.entities;
    }

    this.update(_state => ({ ..._state, ...newState }));
    this.updateLocation();
  }

  clearState(key: keyof FeatureWithPaginationAndFiltersState): void {
    this.update(_state => ({ ..._state, [key]: {} }));
    this.updateLocation();
  }

  override destroy(): void {
    super.destroy();
    this.destroy$.next();
  }

  private updateFromQueryParams(paramMap: ParamMap): void {
    const keys = paramMap.keys.map(key => key?.trim()?.toLowerCase());
    const filterKeys = keys.filter(k => !DEFINED_QUERY_PARAMS.includes(k));
    const offset = parseIntQueryParam(paramMap.get('offset'));
    const limit = parseIntQueryParam(paramMap.get('limit'), DEFAULT_PAGE_SIZE);

    const filtersState = filterKeys.reduce<FiltersState>((prev, curr) => {
      const value = paramMap.get(curr);
      const result = { ...prev };

      if (value) {
        result[curr] = value
          .split(',')
          .filter(Boolean)
          .map(v => ({ value: v }));
      }

      return result;
    }, {});

    this.update({
      pageState: { ...createDefaultPageState(), offset, limit },
      filtersState,
      sortState: parseSortParam(paramMap.get('sort')),
    });
  }

  private updateLocation(): void {
    const { pageState, filtersState, sortState } = this.getValue();
    let urlParams = `?`;

    urlParams += `offset=${pageState.offset}&limit=${pageState.limit}`;
    const filterUrlParams = Object.keys(filtersState)
      .filter(key => filtersState[key].length)
      .map(key => ({ key, value: filtersState[key].map(_v => _v.value).join(',') }))
      .map(({ key, value }) => `${key}=${value}`)
      .join('&');

    urlParams = filterUrlParams.length ? `${urlParams}&${filterUrlParams}` : urlParams;

    const sortUrlParams = sortStateToSortParam(sortState);

    urlParams = sortUrlParams ? `${urlParams}&sort=${sortUrlParams}` : urlParams;
    updateLocationWithQueryParams(this.location, urlParams);
  }
}

export abstract class FeatureWithPaginationAndFiltersQuery<T extends RowDataObject = RowDataObject> extends Query<
  FeatureWithPaginationAndFiltersState<T>
> {
  state$ = this.select();
  pageState$ = this.select('pageState');
  filtersState$ = this.select('filtersState');

  constructor(store: FeatureWithPaginationAndFilters<T>) {
    super(store);
  }
}

export function provideFeatureWithPaginationAndFilters<T extends RowDataObject = RowDataObject>(
  store: Constructor<FeatureWithPaginationAndFilters<T>>,
  query: Constructor<FeatureWithPaginationAndFiltersQuery<T>>,
): Provider[] {
  return [
    { provide: FeatureWithPaginationAndFilters, useClass: store },
    { provide: FeatureWithPaginationAndFiltersQuery, useClass: query },
    query,
  ];
}
