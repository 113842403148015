import { Injectable } from '@angular/core';
import { UrlTree, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthenticationQuery } from './authentication.query';
import { LOGIN_ROUTE } from './public-routes';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private readonly authenticationQuery: AuthenticationQuery, private readonly router: Router) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.can(state.url);
  }

  canActivateChild(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.can(state.url);
  }

  canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const state = this.router.getCurrentNavigation();

    return this.can(state?.extractedUrl.toString());
  }

  private can(linkAddress?: string): Observable<boolean | UrlTree> {
    return this.authenticationQuery.authStatus$.pipe(
      map(({ isLoggedIn }) => {
        if (!isLoggedIn) {
          let urlToRedirect = LOGIN_ROUTE;

          urlToRedirect = linkAddress ? urlToRedirect + `?backlink=${encodeURIComponent(linkAddress || '')}` : urlToRedirect;

          return this.router.parseUrl(urlToRedirect);
        }

        return true;
      }),
    );
  }
}
