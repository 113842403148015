/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { AbstractConstructor, Constructor } from '@guardicore-ui/shared/data';

/*
  Add 'withKey' to the components `inputs`.
*/

export interface CanHaveKey {
  withKey: boolean;
}

type CanHaveKeyConstructor = AbstractConstructor<CanHaveKey>;

export function mixinWithKey<T extends AbstractConstructor<{}>>(base: T): CanHaveKeyConstructor & T;
export function mixinWithKey<T extends Constructor<{}>>(base: T): CanHaveKeyConstructor & T {
  return class extends base {
    protected _withKey = false;

    get withKey(): boolean {
      return this._withKey;
    }

    set withKey(value: BooleanInput) {
      this._withKey = coerceBooleanProperty(value);
    }

    constructor(...args: any[]) {
      super(...args);
    }
  };
}
