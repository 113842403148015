import { Injectable } from '@angular/core';
import { UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthenticationQuery } from './authentication.query';

@Injectable({
  providedIn: 'root',
})
export class NotAuthGuard {
  constructor(private readonly authenticationQuery: AuthenticationQuery, private readonly router: Router) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.can();
  }

  canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.can();
  }

  private can(): Observable<boolean> {
    return this.authenticationQuery.isLoggedIn$.pipe(
      map((isLoggedin: boolean) => {
        if (isLoggedin) {
          this.router.navigate(['']);

          return false;
        }

        return true;
      }),
    );
  }
}
