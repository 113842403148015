<div class="wrapper">
  <div class="header">
    <h4 class="header-title">System Log Notifications</h4>
    <a *ngIf="notificationsPermissions?.edit" class="header-dismiss-all" (click)="handleDismissAll()">Dismiss All</a>
  </div>
  <div class="body">
    <div class="items-wrapper" *ngIf="items?.length; else noNotifications">
      <gc-notification
        (dismiss)="onDismissNotification($event)"
        (notificationClick)="action.emit({ action: 'clickEvent' })"
        *ngFor="let item of items"
        [notification]="item"
        [notificationsPermissions]="notificationsPermissions"
      ></gc-notification>
      <div class="divider"></div>
    </div>
  </div>
  <div class="footer">
    <a [routerLink]="['administration/system/log']" [state]="{ navigationReference: 'In-page link', navigationLinkText: navigateToSystemLogText }" [queryParams]="{ 'status': 'ERROR,WARNING' }" class="footer-link" (click)="action.emit({ action: 'clickEvent' })">{{
      navigateToSystemLogText
    }}</a>
  </div>
</div>

<ng-template #noNotifications>
  <h4 class="no-notifications">No new notifications</h4>
</ng-template>
