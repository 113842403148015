import { EntityState } from '@datorama/akita';
import { SystemLogItem, Toast } from '@guardicore-ui/shared/data';

export interface NotificationsState extends EntityState<SystemLogItem> {
  newCount: number;
  totalCount: number;
  toasts: Toast[];
}

export function createInitialState(): NotificationsState {
  return {
    newCount: 0,
    totalCount: 0,
    toasts: [],
  };
}
