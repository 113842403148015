export const deleteSvg = `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.79598 14.8552L3.69998 14.7586L3.26798 2.9407H2.24078L2.14478 2.84415V2.39035L2.24078 2.2938H6.12878V1.24139L6.22477 1.14484H9.77677L9.92077 1.24139V2.2938H13.7608L13.8568 2.39035V2.84415L13.7608 2.9407H12.7336L12.3016 14.7586L12.2056 14.8552H3.79598ZM11.6872 14.2083L12.0904 2.9407H3.91118L4.31438 14.2083H11.6872ZM9.23917 2.2938V1.79173H6.77197V2.2938H9.23917Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.7824 14.3048H4.21759L3.80479 2.84415H12.1952L11.7824 14.3048ZM6.67519 2.39036H9.32479V1.69519H6.67519V2.39036ZM13.76 2.39036H9.77599V1.24139H6.22399V2.39036H2.23999V2.84415H3.36319L3.79519 14.7586H12.2048L12.6368 2.84415H13.76V2.39036Z"/>
<path d="M3.6512 15L3.5552 14.9034L3.1328 3.08552H2.096H2V2.11034H2.096H5.984V1.09655L6.08 1H9.92L10.016 1.09655V2.14897H13.3025H13.685H14V2.34207L13.9775 3.03724H13.565H12.8672L12.4448 14.8552L12.3488 14.9517L3.6512 15ZM11.552 14.0634L11.9456 3.08552H4.0544L4.448 14.0634H11.552ZM9.0848 2.14897V1.93655H6.9152V2.14897H9.0848Z"/>
<path d="M11.783 14.3048V14.4497H11.9174V14.3048H11.783ZM4.21816 14.3048H4.07416V14.4497H4.20856L4.21816 14.3048ZM3.80536 2.84414V2.69932H3.66136V2.8538L3.80536 2.84414ZM12.1958 2.84414H12.3398V2.69932H12.1958V2.84414ZM6.67576 2.39035H6.53176V2.53518H6.67576V2.39035ZM9.32536 2.39035V2.53518H9.46936V2.39035H9.32536ZM9.32536 1.69518H9.46936V1.55035H9.32536V1.69518ZM6.67576 1.69518V1.55035H6.53176V1.69518H6.67576ZM9.77656 2.39035H9.63256V2.53518H9.77656V2.39035ZM9.77656 1.24139H9.92056V1.09656H9.77656V1.24139ZM6.22456 1.24139V1.09656H6.08056V1.24139H6.22456ZM6.22456 2.39035V2.53518H6.36856V2.39035H6.22456ZM2.24056 2.39035V2.2069H2.09656V2.35173L2.24056 2.39035ZM2.24056 2.84414H2.09656V2.98897H2.24056V2.84414ZM3.36376 2.84414H3.50776V2.69932H3.36376V2.84414ZM3.79576 14.7586H3.65176V14.8938H3.79576V14.7586ZM12.2054 14.7586V14.9035H12.3494V14.7586H12.2054ZM12.6374 2.84414V2.69932H12.4934V2.84414H12.6374ZM11.783 14.16H4.21816V14.4497H11.783V14.16ZM4.36216 14.2952L3.94936 2.84414H3.66136L4.07416 14.2952H4.36216ZM3.80536 2.98897H12.1958V2.69932H3.80536V2.98897ZM12.0518 2.84414L11.639 14.2952H11.927L12.3398 2.84414H12.0518ZM6.67576 2.53518H9.32536V2.2069H6.67576V2.53518ZM9.46936 2.39035V1.69518H9.18136V2.39035H9.46936ZM9.32536 1.55035H6.67576V1.84001H9.32536V1.55035ZM6.53176 1.69518V2.39035H6.81976V1.69518H6.53176ZM9.92056 2.39035V1.24139H9.63256V2.39035H9.92056ZM9.77656 1.09656H6.22456V1.38621H9.77656V1.09656ZM6.08056 1.24139V2.39035H6.36856V1.24139H6.08056ZM6.22456 2.2069H2.24056V2.49656H6.22456V2.2069ZM2.09656 2.35173V2.80552H2.38456V2.39035L2.09656 2.35173ZM2.24056 2.98897H3.36376V2.69932H2.24056V2.98897ZM3.20056 2.8538L3.65176 14.7586H3.93976L3.50776 2.84414L3.20056 2.8538ZM3.77656 14.9035H12.1862V14.6138H3.79576L3.77656 14.9035ZM12.3494 14.7586L12.8006 2.8538H12.5126L12.0614 14.7586H12.3494ZM12.6374 2.97932H13.7606V2.68966H12.6374V2.97932Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.77923 12.3352L7.68323 12.2386V4.91033L7.77923 4.81378H8.22083L8.31683 4.91033V12.2386L8.22083 12.3352H7.77923Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.77917 12.2386H8.22077V4.91034H7.77917V12.2386Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.2081 12.3448H9.76645L9.68005 12.2483L9.92005 4.90069L10.0161 4.80414H10.4577L10.5537 4.91035L10.3137 12.2483L10.2081 12.3448ZM9.92005 12.1421H10.1697L10.3425 5.0069H10.0833L9.92005 12.1421Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.4384 4.91035H9.9968L9.776 12.2386H10.2176L10.4384 4.9007"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.79198 12.3448L5.68638 12.2483L5.46558 4.91999L5.56158 4.81378H6.00318L6.09918 4.91033L6.31998 12.2386L6.23358 12.3352L5.79198 12.3448ZM5.65758 5.00689L5.87838 12.1421H6.12798L5.91678 4.99723L5.65758 5.00689Z"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.56165 4.91035L5.78245 12.2483H6.22405L6.00325 4.9007H5.56165"/>
</svg>
`;
