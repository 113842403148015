export type UserPermissions = { [key: string]: string[] };

export interface User {
  acceptedEula: boolean;
  acceptedEvaluation: boolean;
  defaultView: string;
  email: { emailAddress: string; smtpStatus: boolean };
  isRadius2fa: boolean;
  lastLogin: bigint;
  temporaryPassword: boolean;
  passwordExpired: boolean;
  twoFactorAuthEnabled: boolean;
  username: string;
  isExternal: boolean;
  isSupportUser: boolean;
  viewNeighboringAssets: boolean;
  limitedToNonOverrideRules: boolean;
  scopingLabels: string[];
  isColorBlind: boolean;
  isMsspUser: boolean;
  allowedViewsPermissions: UserPermissions;
  recoveryCodesNum?: number;
  confirmedV45Navbar?: boolean;
}
