/**
 * Extends the destination object with source, without overriding unchanged values
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function extendDeep(destination: any, source: any): any {
  Object.keys(source).forEach(key => {
    if (typeof source[key] === 'object' && !Array.isArray(source[key]) && source[key] !== null) {
      destination[key] = destination[key] || {};
      extendDeep(destination[key], source[key]);
    } else {
      destination[key] = source[key];
    }
  });

  return destination;
}
