import { Route } from '@angular/router';
import { ApplicationViewName, SystemStatusFeatureFlags } from '@guardicore-ui/shared/data';

import { keyToCamel } from './to-camel';

export function checkViewFeatureFlag(
  viewName: ApplicationViewName,
  route: Route,
  featureFlags: SystemStatusFeatureFlags | undefined,
): boolean {
  const flagName = keyToCamel(viewName.trim().replace(/\s+/g, '_')) as keyof SystemStatusFeatureFlags;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const enableNewView = (featureFlags?.[flagName] as any)?.enableNew;

  return enableNewView ? !!route?.data?.['v3'] : !route?.data?.['v3'];
}
