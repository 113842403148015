import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { QuickLink } from '@guardicore-ui/shared/api';

@Component({
  selector: 'gc-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickLinksComponent {
  @Input() links: QuickLink[] | null = [];

  constructor(private router: Router) {}

  analyticsProps(link: QuickLink): { [key: string]: string } {
    return {
      item: link.label,
      route: link.link,
      currentRoute: this.router.url.split('?')[0],
    };
  }
}
