import { Injectable } from '@angular/core';
import { Store, Query } from '@datorama/akita';
import { FilterOptionsEntity } from '@guardicore-ui/shared/data';
import { equals, isObjectsEmpty } from '@guardicore-ui/shared/utils';

import { FilterOptions } from './filter-options';

interface FilterOptionsState {
  options?: FilterOptions;
}

@Injectable()
export class FilterOptionsStore extends Store<FilterOptionsState> {
  constructor() {
    super({}, { name: FilterOptionsStore.name });
  }

  updateFilterOptions(filterOptions: FilterOptions, filterValue?: FilterOptionsEntity[]): void {
    let options = { ...(this.getValue().options as FilterOptions) };

    if (equals(options, filterOptions)) {
      return;
    }

    options = this.applyFilterSelectedOptions(filterOptions, filterValue || []);

    this.update({ options });
  }

  addMoreAvailableOptions(filterOptions: FilterOptions): void {
    if (!this.getValue().options) {
      throw new Error(`Can't update non-existing options`);
    }

    let options = { ...(this.getValue().options as FilterOptions) };

    options = {
      ...options,
      availableOptions: [...options.availableOptions, ...filterOptions.availableOptions],
    };

    this.update({ options });
  }

  private applyFilterSelectedOptions(filterOptions: FilterOptions, filterValue: FilterOptionsEntity[]): FilterOptions {
    if (filterValue?.length) {
      const selectedOptions = new Map<FilterOptionsEntity['value'], FilterOptionsEntity>();

      filterValue.forEach(fv => {
        if (fv) {
          const selected = { ...filterOptions.availableOptions.find(o => o.value === fv.value) } as FilterOptionsEntity;

          // TODO selected value that not yet received; need to get api `filter-options-resolve`
          if (isObjectsEmpty(selected)) {
            selected.text = selected.text ?? '';
            selected.value = selected.value ?? fv.value;
          }

          selected.isSelected = true;

          selectedOptions.set(selected.value, selected);
        }
      });

      return {
        ...filterOptions,
        availableOptions: filterOptions.availableOptions.filter(o => !selectedOptions.has(o.value)),
        selectedOptions: Array.from(selectedOptions.values()),
      };
    }

    return filterOptions;
  }
}

@Injectable()
export class FilterOptionsQuery extends Query<FilterOptionsState> {
  options$ = this.select('options');

  constructor(override store: FilterOptionsStore) {
    super(store);
  }
}
