import { Component, ChangeDetectionStrategy, TemplateRef } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

import { GcCellRendererParams } from '../entities';

@Component({
  selector: 'gc-cell',
  templateUrl: './cell.component.html',
  styleUrls: ['./cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellComponent implements AgRendererComponent {
  cellTemplate?: TemplateRef<unknown>;
  removeDefaultPadding?: boolean;
  params?: ICellRendererParams;

  agInit(params: GcCellRendererParams): void {
    this.setConfiguration(params);
  }

  refresh(params: GcCellRendererParams): boolean {
    this.setConfiguration(params);

    return true;
  }

  private setConfiguration(params: GcCellRendererParams): void {
    this.cellTemplate = params.cellTemplate;
    this.removeDefaultPadding = params.removeDefaultPadding;
    this.params = params;
  }
}
