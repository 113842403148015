import { Injectable } from '@angular/core';
import { QueryEntity, ID } from '@datorama/akita';
import { Observable } from 'rxjs';

import { AssetsState, AssetsStore } from './assets.store';
import { Asset } from '../entities';

@Injectable({ providedIn: 'root' })
export class AssetsQuery extends QueryEntity<AssetsState> {
  constructor(protected override readonly store: AssetsStore) {
    super(store);
  }

  hasAsset(id: ID): boolean {
    return this.hasEntity(id);
  }

  selectAsset(id: ID): Observable<Asset | undefined> {
    return this.selectEntity(id);
  }
}
